import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { InputOTP } from "antd-input-otp";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import ExportApi from "../../api/ExportApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
 

function g2fa() {
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [secret, setSecret] = useState();
  const [qrcode, setQRCode] = useState();
  const [otp, setOTP] = useState("");
  const [notp, setNOTP] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessagenew, setErrorMessageNew] = useState("");


  const admintoken = localStorage.getItem("admin");
  const adminData = JSON.parse(admintoken);

 

  const id = adminData?.id;

  const handleSingleUserData = (id) => {
    if (!id) {
      console.error("ID is not available");
      return;
    }

    ExportApi.getSingleUserData(id)
      .then((resp) => {
        if (resp.data.message === "user not found") {
          // window.dispatchEvent(new Event("Loginout"));

        } else {
          let Data = resp?.data?.data;
          let number = Data?.phone;
          let numberString = number == null ? null : number?.toString();
          let maskedResponse = "";
          if (numberString == null) {
            maskedResponse = null;
          } else {
            maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
              3,
              6
            )}-${numberString.slice(6)}`;
          }


          if (resp?.data?.data?.twoFactorEnabled === true) {
            setIs2FAEnabled(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleSingleUserData(id);
  }, [id]);

  const handleSwitchChange = async () => {
    if (!is2FAEnabled) {
      // Enabling 2FA
      try {
        const response = await ExportApi.enable2FA(id);
        const { qrCodeUrl, secret } = response.data;

        // Store or use the secret and QR code URL
        setQRCode(qrCodeUrl);
        setSecret(secret);

        // Open the modal to show the QR code
        setShowQRModal(true);

      } catch (error) {
        console.error("Error enabling 2FA:", error);
        // Handle error, e.g., show a message to the user
        // Optionally, revert the switch state if the API call fails
        setIs2FAEnabled(false);
      }
    } else {
      // Disabling 2FA
      try {
        setShowOTPModal(true);
        // const resp = await ExportApi.disable2FA(id,otp);
        // setIs2FAEnabled(false);

        // toast.success(resp?.data?.message);
      } catch (error) {
        console.error("Error disabling 2FA:", error);
        toast.error(error);
      }
    }
  };

  const handleOTPSubmit = async () => {
    try {
      const resp = await ExportApi.verifyotp(otp, secret, id);
      if (resp.data.status == true) {
        toast.success(resp.data.message);
        setIs2FAEnabled(true);
        setShowQRModal(false);

        setOTP("");
      } else {
        // toast.error(resp.data.message);
        setErrorMessageNew(resp.data.message); 
        setIs2FAEnabled(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    //   toast.error("Internal server error");
      setErrorMessageNew("Internal server error");
    }
  };
  const handleOTPSubmitdisab = async () => {
    try {
      const resp = await ExportApi.disable2FA(id, notp);
      if (resp.data.status) {
        toast.success(resp.data.message);
        setIs2FAEnabled(false);
        setShowOTPModal(false);
        setNOTP("");
      } else {
        // toast.error(resp.data.message);
        setErrorMessage(resp.data.message); 
      }
    } catch (err) {
      console.error(err);
    //   toast.error("Internal server error");
    setErrorMessage("Internal server error"); 
    }
  };
  const handleOTPChange = (value) => {
    // Join array values into a single string
    setOTP(value.join(""));
  };

  const handleNOTPChange = (value) => {
    // Join array values into a single string
    setNOTP(value.join(""));
  };
  return (
    <div>
                 <ToastContainer />
        <br></br>
         <h5>Google Authenticator</h5>
      <div className="switch-container">
        
        <Switch
          checked={is2FAEnabled}
          onChange={handleSwitchChange}
          inputProps={{ "aria-label": "controlled" }}
        />

        <p className="fs-5 fw-bold m-0 w-25">
          2FA is {is2FAEnabled ? "Enabled" : "Disabled"}
        </p>
      </div>
      {/* Start QR Modal */}
      <Modal
        show={showQRModal}
        onHide={() => setShowQRModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Up Authenticator App</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            maxHeight: "400px", // Adjust height as needed
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <p className="qrspace">
            {" "}
            <b> STEP ONE</b>
          </p>
          <p className="qrspace">
            Download/Use any authenticator application (Google Authenticator,
            Microsoft Authenticator,etc.)on your mobile device
          </p>
          <p className="qrspace">
            {" "}
            <b> STEP TWO</b>
          </p>
          <p>
            On your authenticator application, tap the "+" icon and then select
            "Scan QR code" to scan the QR code below
          </p>
          <img src={qrcode} alt="QR Code" height="100px" width="100px" />
          <p className="qrspace">
            {" "}
            <b> STEP THREE</b>
          </p>
          <p className="qrspace">
            Once this code has been scanned, enter the 6-digit code generateed
            by the app
          </p>
          <div style={{ display:"flex"}}> 
          <Form.Group
            className="mb-3"
            style={{ display: "flex", justifyContent: "left" }}
          >
            <InputOTP
              autoFocus
              value={otp.split("")}
              onChange={handleOTPChange}
              length={6}
              style={{ height: "50px" }}
            />
            &nbsp;&nbsp;&nbsp;{errorMessagenew && <p className="error-messagen">{errorMessagenew}</p>}  
          </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQRModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOTPSubmit}>
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End QR Modal */}

      {/* OTP Verification Modal */}
      <Modal
        show={showOTPModal}
        onHide={() => setShowOTPModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter the OTP to disable 2FA.</p>
          <InputOTP
            type="text"
            value={notp.split("")}
            length={6}
            style={{ height: "50px" }}
            onChange={handleNOTPChange}
            className="form-control"
          />
            {errorMessage && <p className="error-messagen">{errorMessage}</p>}  
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleOTPSubmitdisab}>
            Verify OTP
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setShowOTPModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default g2fa;
