import React, { useEffect, useState, useCallback, useRef } from "react";
import { Form,Button } from "react-bootstrap";
import ExportApi from "../../api/ExportApi";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';

export default function Threshold() {
  const { register, handleSubmit, setValue, control, reset, formState: { errors }, } = useForm();

  const [count, setCount] = useState();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [thresholdData, setThresholdData] = useState(null);

  const fetchData = async () => {
    try {
      const res = await ExportApi.getThresholdData();
      const data = await res?.data?.detail?.margin
      setThresholdData(data);
    } catch (error) {
      console.error('Error fetching threshold data:', error);
    }
  };
  const onSubmit = (data) => {
    setButtonDisabled(true);
    setCount(0)


    ExportApi.addThreshold(data?.threshold)
    .then((resp) => {
      const data = resp?.data;
      if (data?.Status) {
        toast.success(data?.message)
        reset();
        setCount(1)
        setButtonDisabled(false);
        fetchData();
      } else {
        toast.error(data?.message)
        setCount(1)
        setButtonDisabled(false);
      }
    }).catch((err) => console.log(err))
    setCount(1)
  };

 
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {count == 0 ?
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
        :
        <Form onSubmit={handleSubmit(onSubmit)} className="py-5">
          <h2 className="mb-4">Create Margin</h2>
          <Form.Group controlId="name" className="mb-4">
            <Form.Control
              type="text"
              placeholder="Enter Threshold"
              
              {...register('threshold', { required: 'Threshold is required' })}
              defaultValue={thresholdData || ''}
            />
            {errors.name && <p className="form-error">{errors.name.message}</p>}
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-5" disabled={isButtonDisabled}>
            {isButtonDisabled ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      }

    </>

  );
}
