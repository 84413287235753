// Import React and necessary dependencies
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap"; // Import components from react-bootstrap
import Tablepart from "../../src/components/tablepart"; // Import the Tablepart component
import Bidtab from "../../src/components/bidtab"; // Import the Bidtab component
import ExportApi from "../api/ExportApi"; // Import an API utility
import StokChart from "./StokChart"; // Import the StokChart component
import { LazyLoadImage } from "react-lazy-load-image-component"; // Import LazyLoadImage for lazy-loading images
import "react-lazy-load-image-component/src/effects/blur.css"; // Import a CSS effect
import { toast, ToastContainer } from "react-toastify"; // Import toast notifications
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom for routing

// Define and export the Placeask component
export default function Placeask(props) {
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL  
  const params = useParams(); 
  const [Data, setData] = useState([]);  
  const [ask, setAsk] = useState([]);  
  const [Bid, setBid] = useState([]);  
  const [bidAmount, setBidAmount] = useState();  
  const [askAmount, setAskAmount] = useState();  
  const [count, setCount] = useState(0);  
  const [secondHouseAskPrice, setSecondHouseAskPrice] = useState();  
  const [secondHouseBidPrice, setSecondHouseBidPrice] = useState();  
  const [websitePrice, setWebsitePrice] = useState();  
  const [usedPrice, setUsedPrice] = useState();  
  const [productType, setProductType] = useState();  
  const [usedBidPrice, setUsedBidPrice] = useState();  
  const [count1, setCount1] = useState(0);  
  const [userId, setUserId] = useState(0); 
  const [image, setImage] = useState([]);  
  const [currentSortOrder, setCurrentSortOrder] = useState(1);  
  const [currentSortKey, setCurrentSortKey] = useState(null);  
  const [shippingFee, setShippingFee] = useState();  
  const [isLoading, setLoading] = useState(false);  
  const [shippingFeeError, setShippingFeeError] = useState(false);  

  const [finalPriceBuy , setFinalPriceBuy] = useState();
  const [finalPriceSell , setFinalPriceSell] = useState();

 
 
  // Function to fetch product data
  const handleSearch = () => {
    setLoading(true); // Set loading state to true
    let user = JSON.parse(localStorage.getItem("tokenuser"));
    let userIds = user?user.id:""
    ExportApi.GetSingleProductData(props.prams,userIds)
      .then((resp) => {
      
        setData(resp.data.Data);
        setSecondHouseBidPrice(resp.data.Data.new_second_hand_house_bid);
        setSecondHouseAskPrice(resp.data.Data.new_second_hand_house_ask);
        setWebsitePrice(resp.data.Data.new_retail_website_price);
        setUsedPrice(resp.data.Data.used_house_ask);
        setUsedBidPrice(resp.data.Data.used_house_bid);
        setProductType(resp.data.Data.type);
        setImage(resp.data.Data.image[0]);
    
        if (resp.data.Data.type === 1) {
          setFinalPriceBuy(resp.data.Data.new_retail_website_price)
          setFinalPriceSell(null)
        }
        else{
          setFinalPriceBuy(resp?.data?.Data?.lowest_ask)
          setFinalPriceSell(resp?.data?.Data?.highest_bid)
        }
     
        setLoading(false); // Set loading state to false
      })
      .catch((err) => console.log(err));
  };

  // Function to fetch ask data
  const handleAsk = () => {
    ExportApi.getask(props.prams)
      .then((resp) => {
        if (resp.data.data?.length > 0) {
          setAsk(resp.data.data);
          setAskAmount(resp.data.data[0].askAmount);
          setCount(1);
        } else {
          setCount(2);
          setAsk([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // Function to sort ask table
  const sortAskHere = (key) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    const sortedProducts = ask.sort((a, b) => {
      if (a[key] < b[key]) return -1 * sortOrder;
      if (a[key] > b[key]) return sortOrder;
      return 0;
    });
    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
  };

  // Function to fetch bid data
  const handleBid = () => {
    ExportApi.getBid(props.prams)
      .then((resp) => {
       
        if (resp.data.data?.length > 0) {
          setCount1(1);
          setBid(resp?.data?.data);
          setBidAmount(resp.data.data[0].bidAmount);
 
        } else {
          setCount1(2);
          setBid([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // Function to sort bid table
  //key used for the column name which we have to sort
  const sortBidHere = (key) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    const sortedProducts = Bid.sort((a, b) => {
      if (a[key] < b[key]) return -1 * sortOrder;
      if (a[key] > b[key]) return sortOrder;
      return 0;
    });
    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
  };

  // Function to handle shipping fee
  const HandleShippingFee = async (userId) => {
    const shippingFeeResult = await ExportApi.getshippingFee(userId);
    if (shippingFeeResult.data.message === "Data fetched successfully") {
      setShippingFee(Number(shippingFeeResult?.data?.Data?.shippingFee));
      setShippingFeeError(false);
    } else if (params.buy === "buy" || params.buy === "sell" || params.buy === undefined) {
    
      // toast.error(shippingFeeResult?.data?.message);
      // setShippingFeeError(true);
    }
  };

 

  // Effect to fetch data when the component mounts
  useEffect(() => {
    handleSearch();
    handleAsk();
    handleBid();
  }, []);

  // Effect to listen for custom events and refresh data
  useEffect(() => {
    window.addEventListener("List", () => {
      handleSearch();
      handleAsk();
      handleBid();
    });
  }, []);

  // Effect to get user ID and handle shipping fee
  useEffect(() => {
    if (localStorage.getItem("tokenuser")) {
      setUserId(JSON.parse(localStorage.getItem("tokenuser")).id);
      HandleShippingFee(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      setUserId(JSON.parse(localStorage.getItem("admin")).id);
      HandleShippingFee(JSON.parse(localStorage.getItem("admin")).id);
    } else {
    }
  }, [localStorage.getItem("tokenuser"), localStorage.getItem("admin")]);

  // Conditional rendering for loading indicator
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      </div>
    );
  }

 

  // Render the main content of the component
  return (
    <div className="ask-part">
      <Container className="py-5">
        <Row>
          <Col xs="12" lg="8">
            {/* Render product information */}
            <h1 className="fs-3">{`${Data?.brand}  ${Data?.series} ${Data?.model}`}</h1>
            <p className="condition">
              Condition :
              <span>
                {/* {Data?.type == 1
                  ? " New - Benchmarked, Inspected, and Tested"
                  : Data?.type == 2
                  ? " 2nd Hand - Benchmarked, Inspected, and Tested"
                  : " Used - Benchmarked, Inspected, and Tested"} */}
                     {Data?.type == 2
                  ? " New (2nd Hand) - Inspected"
                  : " Used - Benchmarked, Inspected, and Tested"}
              </span>
            </p>
            <div className="inner-bid-cont d-flex align-items-start gap-4 mt-5">
              <div className="bid_product_image">
                {/* Lazy-load the product image */}
                <LazyLoadImage 
                src={`${image}`} 
                // src={image?.includes('http') ? image : `${imageUrl}${image}`} 
                effect="blur" />
              </div>
              {Data?.type == 1 ? (
                ""
              ) : (
                <>
                  <div className="bid-table">
                 
                      <p className="bidask_label text-center">Bids</p>
                    <Table responsive="sm" striped bordered hover>
                      <thead>
                          <tr>
                            <th onClick={() => sortBidHere("bidAmount")}>
                              Bid{" "}
                            </th>
                            <th>Qty</th>
                          </tr>
                      </thead>
                      <tbody>
                        {count1 == 0 ? (
                          <tr>
                            <td>
                              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                            </td>
                          </tr>
                        ) : count1 == 1 ? (
                          <>
                            {Bid?.length > 0 || secondHouseBidPrice ? (
                              Bid.map((val) => {
                                return val?.userId == userId ? (
                                  <>
                                    <tr style={{ backgroundColor: "#75b299" }}>
                                      <td>${val?.bidAmount}</td>
                                      <td> 1</td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>${val?.bidAmount}</td>
                                      <td> 1</td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : null}
                          </>
                        ) : (
                          <tr>
                          <td colSpan="2" className="text-center">No Bids </td>
                        </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div className="bid-table">
                    {/* Render the Tablepart component for asks */}
                    <Tablepart
                      h1={"Quantity"}
                      h2={"Ask"}
                      data={ask}
                      amount={secondHouseAskPrice}
                      count={count}
                      sortAskHere={sortAskHere}
                    />
                  </div>
                </>
              )}
            </div>
            {/* Render the StokChart component */}
            {/* <StokChart /> */}
          </Col>
          <Col xs="12" lg="4" style={{marginTop: "60px"}}>
            {/* Render the Bidtab component */}
            <Bidtab
              Bidid={Bid?.[0]?._id}
              Askid={ask?.[0]?._id}
              Bid={bidAmount}
              Ask={askAmount}
              website={websitePrice}
              used={usedPrice}
              second={secondHouseAskPrice}
              secondBid={secondHouseBidPrice}
              usedBid={usedBidPrice}
              type={productType}
              shippingFee={shippingFee}
              error={shippingFeeError}
              finalPriceBuy={finalPriceBuy}
              FinalPriceSell={finalPriceSell}
            />
          </Col>
        </Row>
      </Container>
      {/* Render the ToastContainer for notifications */}
      <ToastContainer />
    </div>
  );
}
