import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ExportApi from '../../api/ExportApi';
import "./Help.css"
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";

const Help = (props) => {
    const navigate = useNavigate();
    let imageUrl = process.env.REACT_APP_IMAGEBASEURL
    const [showDescription, setShowDescription] = useState("");
    const [allData, setAllData] = useState();
    const [mainTopicData, setMainTopicData] = useState();
    const [topicDataWithoutCategory, setTopicDataWithoutCategory] = useState();
    const [highlightBorder, setHighlightBorder] = useState(""); 
    const [searchTerm, setSearchTerm] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState(false);

    useEffect(() => {
        getTopicData();
    }, [])


    // const handleShow = (val) => {

    //     setHighlightBorder(val?.name);
    //     setShowDescription(val?.description);
    // }
   
       

 
    useEffect(() => {
        const pathName = location.pathname;
        let activeText;
    
        switch (pathName) {
          case "/QualityStandards":
            activeText = "Quality Standards";
            break;
            case "/help":
                activeText = "Quality Standards";
                break;
          case "/ShippingInstructions":
            activeText = "Shipping Instructions";
            break;
          case "/BuyingandSellingGuide":
            activeText = "Buying and Selling Guide";
            break;
          case "/PreSaleInspectionGuide":
            activeText = "Pre-Sale Inspection Guide";
            break;
          default:
            activeText = "";
            break;
        }
    
        localStorage.setItem("activeText", activeText);
      }, [location.pathname]);
    
 




    const handleShow = (val) => {
   
        setHighlightBorder(val?.name);
    
        if (val?.name === "Quality Standards") {
            localStorage.setItem("activeText", "Quality Standards");
            navigate("/QualityStandards");
        } else if (val?.name === "Shipping Instructions") {
            localStorage.setItem("activeText", "Shipping Instructions");
            navigate("/ShippingInstructions");
        } else if (val?.name === "Buying and Selling Guide") {
            localStorage.setItem("activeText", "Buying and Selling Guide");
            navigate("/BuyingandSellingGuide");
        } else if (val?.name === "Pre-Sale Inspection Guide") {
            localStorage.setItem("activeText", "Pre-Sale Inspection Guide");
            navigate("/PreSaleInspectionGuide");
        } else {
            localStorage.setItem("activeText", val?.name);
        }
    
        setShowDescription(val?.description);
        setSearch(false);
        setSearchResults([]);  
                
    }
    
    
    
    const getTopicData = () => {
        ExportApi.getHelpTopics().then((resp) => {
            const data = resp?.data;
            if (data?.status == 201) {
                const mainData = data?.detail.filter((item) => item?.ismain == true);
              
                setMainTopicData(mainData)
                const filteredArray = data?.detail.filter(item => {
                    return item.ismain === false && item.topic_categotyId == null;
                });
                setTopicDataWithoutCategory(filteredArray)
            } else {
                toast.error(data?.message)
            }
        }).catch((err) => console.log(err))

        ExportApi.getHelpData('').then((resp) => {
            const data = resp?.data;
            if (data?.status == 201) {
                setAllData(data?.detail);
            } else {
                toast.error(data?.message)
            }
        }).catch((err) => console.log(err))
    }

    const stripHtmlTags = (html) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };
    useEffect(() => {

    
        if (allData?.length > 0 || topicDataWithoutCategory?.length > 0) {
        
    
            const fetchData = () => {
                const combinedData = [...(allData || []), ...(topicDataWithoutCategory || [])];
           
    
                if (searchTerm) {
                    const normalizedSearchTerm = searchTerm.toLowerCase();
                    const searchWords = normalizedSearchTerm.split(' '); // Split the search term into words
    
                    // Step 1: Find matches for both types of data
                    const allMatches = combinedData.flatMap(obj => {
                        if (Array.isArray(obj?.topicDetails) && obj.topicDetails.length > 0) {
                            return obj.topicDetails.map(topic => ({
                                ...topic,
                                parentObj: obj // Attach parent object for reference
                            }));
                        } else {
                            return [{
                                name: obj.name,
                                short_description: obj.short_description,
                                description: obj.description,
                                parentObj: obj // Attach parent object for reference
                            }];
                        }
                    });
    
            
    
                    // Step 2: Find exact matches
                    const exactMatches = allMatches.filter(topic => {
                        const name = topic.name.toLowerCase();
                        return name === normalizedSearchTerm;
                    });
    
              
    
                    // Step 3: Find partial matches
                    const partialMatches = allMatches.filter(topic => {
                        const name = topic.name.toLowerCase();
                        const description = topic.description.toLowerCase();
                        const includesSearchTerm = name.includes(normalizedSearchTerm) || description.includes(normalizedSearchTerm);
                        return includesSearchTerm && !exactMatches.includes(topic);
                    });
    
             
                    // Step 4: Combine exact matches first, then partial matches
                    const filteredTopics = [...exactMatches, ...partialMatches];
    
                    // Step 5: If both exactMatches and partialMatches are empty, search by individual words
                    if (filteredTopics.length === 0) {
                        const wordsMatches = allMatches.filter(topic => {
                            const name = topic.name.toLowerCase();
                            const description = topic.description.toLowerCase();
                            return searchWords.some(word => 
                                name.includes(word) || description.includes(word)
                            );
                        });
    
          
                        setSearchResults(wordsMatches);
                    } else {
                        // Step 6: Sort to prioritize exact matches
                        const sortedFilteredTopics = filteredTopics.sort((a, b) => {
                            const aIsExact = exactMatches.includes(a);
                            const bIsExact = exactMatches.includes(b);
                            return aIsExact === bIsExact ? 0 : aIsExact ? -1 : 1;
                        });
    
                   
                        setSearchResults(sortedFilteredTopics);
                    }
    
                    setSearch(true);
                } else {
                    setSearchResults([]);
                    setSearch(false);
                }
            };
    
            fetchData();
        }
    }, [searchTerm]);
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

 
    // useEffect(() => {
 
    //     const fetchData = async () => {
    //         if (searchTerm) {  
    //             try {
    //                 const resp = await ExportApi.getHelpData(searchTerm);
    //                 const allData = resp?.data?.detail || [];
    //                 const allTopics = allData.flatMap((obj) => obj?.topicDetails || []);
    //                 setSearchResults(allTopics);
    //                 setSearch(true);
    //             } catch (err) {

    //                 setSearch(false);
    //             }
    //         } else {
    //             setSearchResults([]);  
    //             setSearch(false);
    //         }
    //     };

    //     fetchData();
    // }, [searchTerm]);

    const handleInputChange = (event) => {
 
        
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        const activetext = localStorage.getItem("activeText");
  
        setHighlightBorder(activetext);
       
        if (activetext) {
            ExportApi.getHelpTopics()
                .then((resp) => {
                    const data = resp?.data;
                    if (data?.status === 201) {
                        const mainData = data?.detail.filter((item) => item?.ismain === true);
                       
                        setMainTopicData(mainData);
                        const filteredArray = data?.detail.filter(item => {
                            return item.ismain === false && item.topic_categotyId == null;
                        });
                        setTopicDataWithoutCategory(filteredArray);
    
                        // Now, access mainTopicData inside the .then() block
                        const selectedTopic = mainData?.find(topic => topic.name === activetext);
                         
                        if (selectedTopic && selectedTopic?.description) {
                            setShowDescription(selectedTopic?.description);
                          
                                setSearch(false);
                                setSearchResults([]);  
                        
                        }
                    } else {
                        toast.error(data?.message);
                    }
                })
                .catch((err) => console.log(err));
        } 
 
    }, [localStorage.getItem("activeText")]);
    
   
    return (
        <>
            <Header />
            <div className='tabs'>
                <div className="tabs-banner w-100 p-4">
                    <div className="container">
                        <h2 className='mb-0 text-center'>How can we help?</h2>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder='Search the knowledge base'
                            className='my-4 help-search w-100 py-2 px-3 rounded border-0 focus-ring-0'
                        />
                    </div>
                    <div className="tabs-main">
                        <div className="container d-flex flex-wrap justify-content-between">
                            {mainTopicData?.length > 0 ? mainTopicData.map((val, i) => (
                                <div className={`tab-heading d-flex justify-content-between ${highlightBorder === val?.name ? 'tab-active' : ''}`} onClick={() => { handleShow(val) }}>
                                    <img src={`${val?.logo}`} alt="" />
                                    
                                    <div className="tab-content">
                                        <h6><strong>{val?.name} </strong></h6>
                                        <p className="main-heading-help" dangerouslySetInnerHTML={{ __html: val?.short_description }}></p>
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-accordion-main py-5">
                <div className="container">
                    <div className="tabs-accordion-container d-flex flex-wrap justify-content-between">
                        <div className="accordion-container py-4 col-12 col-md-3 py-md-0">
                            <h4 className='text-center mb-4'>Help Topics</h4>
                            <Accordion defaultActiveKey="0">
                                {allData?.length > 0 && allData.map((val, i) => (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>{val?.name}</Accordion.Header>
                                        
                                        <Accordion.Body>
                                            {val?.topicDetails.length > 0 && val?.topicDetails.map((data, j) => {
                                                return data?.ismain === false ? (
                                                    <p
                                                        key={data?._id}
                                                        className={highlightBorder === data?.name ? "help-topic-active" : "help-topic"}
                                                        onClick={() => { handleShow(data) }}
                                                        style={{ cursor: 'pointer' }} 
                                                    >
                                                        {data?.name}
                                                    </p>
                                                ) : (
                                                    ""
                                                );
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                            {topicDataWithoutCategory?.length > 0 && topicDataWithoutCategory.map((val, i) =>
                                <p
                                    key={val?._id}
                                    className={highlightBorder === val?.name ? "help-topic-active" : "help-topic"}
                                    onClick={() => { handleShow(val) }}
                                    style={{ cursor: 'pointer' }} 
                                >
                                    {val?.name}
                                </p>
                            )}
                        </div>
                        <div className="tab-content py-4 col-12 col-md-9 ps-5 py-md-0">
                            {!search ? (
                                showDescription ? (
                                    <div dangerouslySetInnerHTML={{ __html: showDescription }} ></div>
                                ) : (<></>)
                            ) : (
                                searchResults.length > 0 ? (
                                    <Accordion defaultActiveKey="0">
                                        {searchResults?.length > 0 && searchResults.map((val, i) => (
                                            <Accordion.Item eventKey={i}>
                                                <Accordion.Header>{val?.name}</Accordion.Header> 
                                                <Accordion.Body>
                                                    <div dangerouslySetInnerHTML={{ __html: val?.description }}></div>
                                                
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                ) : (<div>No Search Found</div>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Help;
