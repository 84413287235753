import React, { useEffect, useState } from 'react';
import ExportApi from "../../api/ExportApi";
import Pagination from "@mui/material/Pagination";

function OrderBook() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false); // New loading state for download

  useEffect(() => {
    const fetchOrders = async (pageNumber = currentPage) => {
      try {
        const resp = await ExportApi.orderbookdata(pageNumber);
        if (resp?.data?.status) {
          setOrders(resp?.data?.data);
          setTotalPages(resp?.data?.pagination?.totalPages);
        } else {
          setError('Failed to fetch orders');
        }
      } catch (err) {
        setError('An error occurred while fetching orders');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders(currentPage);
  }, [currentPage]);

  const downloadCSV = async () => {
    setIsDownloading(true); // Set loading state to true
    try {
      const resp = await ExportApi.allorderbookdata(); // Fetch all data
      if (resp?.data?.status) {
        const allOrders = resp.data.data;
        const csvContent = [
          ['Date', 'Time', 'User', 'Action', 'Product', 'Price'],
          ...allOrders.map(order => [order.date, order.time, order.user, order.action, order.product, order.price])
        ].map(e => e.join(",")).join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'order_book.csv');
        a.style.visibility = 'hidden';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setError('Failed to fetch all orders for CSV');
      }
    } catch (err) {
      setError('An error occurred while downloading the CSV');
    } finally {
      setIsDownloading(false); // Reset loading state
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const styles = {
    container: {
      margin: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
      textAlign: 'center',
      color: '#333',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    thead: {
      backgroundColor: '#f4f4f4',
    },
    th: {
      padding: '12px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      fontWeight: 'bold',
      color: '#555',
    },
    td: {
      padding: '12px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    },
    button: {
      marginTop: '20px',
      padding: '10px 15px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    loadingButton: {
      marginTop: '20px',
      padding: '10px 15px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'not-allowed',
    },
  };

  if (loading) {
    return (
      <div>
        <div style={styles.container}>
          <h1 style={styles.title}>Order Book</h1>
          <div><b>Loading...</b></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <div style={styles.container}>
          <h1 style={styles.title}>Order Book</h1>
          <div><b>{error}</b></div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Order Book</h1>
      <table style={styles.table}>
        <thead style={styles.thead}>
          <tr>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Time</th>
            <th style={styles.th}>User</th>
            <th style={styles.th}>Action</th>
            <th style={styles.th}>Product</th>
            <th style={styles.th}>Price</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td style={styles.td}>{order.date}</td>
              <td style={styles.td}>{order.time}</td>
              <td style={styles.td}>{order.user}</td>
              <td style={styles.td}>{order.action}</td>
              <td style={styles.td}>{order.product}</td>
              <td style={styles.td}>${order.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        style={isDownloading ? styles.loadingButton : styles.button}
        onClick={downloadCSV}
        disabled={isDownloading} // Disable the button while downloading
      >
        {isDownloading ? 'Downloading...' : 'Download CSV'}
      </button>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </div>
  );
}

export default OrderBook;
