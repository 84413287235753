
import {useLocation, useNavigate } from "react-router-dom";
import ExportApi from "../api/ExportApi";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import { toast, ToastContainer } from "react-toastify";
import "./Style.css";
import { Button } from "react-bootstrap";
import React, { useState } from 'react';

function NewSignup(props) {
 
  const navigate = useNavigate()
  const location = useLocation()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [statusUSER, setStatusUSER] = useState(false);
  const [statusAdmin, setStatusAdmin] = useState(false);
  const [error, setError] = useState(false);
  const [stap, setStap] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [signup, setSignup] = useState(true);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSignup = () => {
    setStap(false);
    setSignup(true);
    setForgot(false);
  };

  const handleLogin = () => {
    setStap(true);
    setSignup(false);
    setForgot(false);
  };

  const handleForgotPassword = () => {
    setStap(false);
    setSignup(false);
    setForgot(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoader(true);
    if (username && password) {
            ExportApi.UserLogin(username, password)
              .then((resp) => {
                if (resp.data.Status == true) {
                  setError(true) 
                  localStorage.setItem(
                    "favourite",
                    JSON.stringify(resp.data.Data.favourite)
                  );
   
                
                  if (resp.data.Data.role === "user") {
                    setButtonLoader(false);
                    localStorage.setItem("tokenuser", JSON.stringify(resp.data.Data));
                    const loginTime = new Date().getTime();
                    localStorage.setItem("loginTime", loginTime);
                    let data = resp.data.Data.shipping_address.street;
                    localStorage.setItem("useraddress", JSON.stringify(data));
            
                    if (location.pathname == "/") {
                      navigate('/profile/' + resp.data.Data.id)
                      props.modal()
                    } else {
                      navigate(location.href)
                      props.modal()
                    }
                    // return;
                    // {
                    //   props.modalClose ? props.modalClose() : ;
                    // }
                    // window.dispatchEvent(new Event("Login"));
                  } else {
      
                    setButtonLoader(false);
                    localStorage.setItem("admin", JSON.stringify(resp.data.Data));
                    const loginTime = new Date().getTime();
                    localStorage.setItem("loginTime", loginTime);
                    setStatusAdmin(true);
                    // window.dispatchEvent(new Event("Login"));
                    navigate("/productlist");
                  }
                } else {
                  toast.error("Email and Password don't match")
                  // this.setState({ error: "Email and password don't match" });
                 setButtonLoader(false)
                }
              })
              .catch((err) => console.log(err));
          }
  };
 
  return (
    <div>
      {stap ? (
        <form className="form w-100 p-0 custom-logindata" onSubmit={handleSubmit}>
          {/* {statusUSER && <Navigate to="/buySellManagment/pending" />}
          {statusAdmin && <Navigate to="/productlist" />} */}
          <div className="input-container w-100">
            <label className="label fs-5 mb-2">Username: </label>
            <input
              type="text"
              name="username"
              className="input form-control"
              placeholder="Username"
              value={username}
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-container w-100">
            <label className="label fs-5 mb-2">Password: </label>
            <div className="inner_part_icon">
              <input
                type={passwordStatus ? 'text' : 'password'}
                name="password"
                className="input form-control mb-3"
                placeholder="Password "
                value={password}
                required
                onChange={handleChange}
              />
              {passwordStatus ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-slash-fill position-absolute eye_icon"
                  viewBox="0 0 16 16"
                  onClick={() => setPasswordStatus(!passwordStatus)}
                >
                  {/* SVG paths */}
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="currentColor"
                  className="bi bi-eye-fill position-absolute eye_icon"
                  viewBox="0 0 16 16"
                  onClick={() => setPasswordStatus(!passwordStatus)}
                >
                  {/* SVG paths */}
                </svg>
              )}
            </div>
          </div>
          <div className="forgot_password">
            <Button
              className="custom-forgot float-right border-0 bg-none text-dark"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </Button>
          </div>
          <button
            type="submit"
            className=" d-block  custom-login"
            id="login-btn"
            disabled={buttonLoader}
          >
            {buttonLoader ? 'Please Wait...' : 'Login'}
          </button>
        </form>
      ) : signup ? (
        <Signup modal={props.modal} navigate={props.navigate}  email={props.email}  />
      ) : forgot ? (
        <ForgotPassword modal={props.modal} navigate={props.navigate}  />
      ) : null}

      {stap ? (
        <button
          className="d-block mb-3 custom-sign"
          id="login-btn"
          onClick={handleSignup}
        >
          Sign Up
        </button>
      ) : signup ? (
        <button
          className=" d-block mb-3 custom-log"
          id="login-btn"
          onClick={handleLogin}
        >
          Login
        </button>
      ) : null}
      <ToastContainer />
    </div>
  );
}

export default NewSignup;