import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ExportApi from "../api/ExportApi";
import Login from "./Login";
import { TroubleshootOutlined } from "@mui/icons-material";
import states from "states-us";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeElement from "./StripeElement";

const stripePromise = loadStripe(
  "pk_test_51N5ispHPaRDrtdL2GeydDgCPMGxVg4i7NhYNw6IO1MgsghsqzAFzvZlrV5RPQUFLYIehV8CmPBN1IFKh7XMxjNnr00TT87qqvt"
);

import InputMask from "react-input-mask";

export default function Sonnet(props) {
 
  const location = useLocation();
  // const updateBidAmout = ;

  const [updateAskAmount, setUpdateAskAmount] = useState(
    location?.state?.askamount ? location.state?.askamount : 0
  );
  const [updating, setUpdating] = useState(false);
 
  const prams = useParams();
  const navigate = useNavigate();
  const [Show, setShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [askValue, setAskValue] = useState(0);
  const [Transction, setTransction] = useState(0);
  const [Payment, setPayment] = useState(0);
  const [total, settotal] = useState(0);
  const [Finalchecks, setFinalchecks] = useState();
  const [Finalchecks1, setFinalchecks1] = useState();
  const [FinalchecksError, setFinalchecksError] = useState(false);
  const [Finalchecks1Error, setFinalchecks1Error] = useState(false);
  const [expiration, setExpiration] = useState();
  const [input, setInput] = useState(false);
  const [sellNowStatus, setSellNowStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [shippingFee, setShippingFee] = useState();
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [messageShown, setMessageShown] = useState(false);
  const [askStatus, setAskStatus] = useState(false);
  const [bid, setBid] = useState();

  const [cardExpiry, setCardExpiry] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  // const [shippingAddress , setShippingAddress] = useState();

  const [street, setStreet] = useState();
  const [subscribe, setSubscribe] = useState();
  const [validEmail, setValidEmail] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [shippingAddress, setShippingAddress] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [validStreet, setValidStreet] = useState();
  const [validCity, setValidCity] = useState();
  const [validState, setValidState] = useState();
  const [validcountry, setValidCountry] = useState();
  const [validPostalCode, setValidPostalCode] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [buttonAddressLoader, setButtonAddressLoader] = useState(false);

  const [primaryAccountModel, setPrimaryAccountModel] = useState(false);

  const [mobileNumber, setMobileNumber] = useState();

  const [payoutLink, setPayoutLink] = useState();
  const [payoutBankName, setPayoutBankName] = useState();
  const [payoutCardNumber, setPayoutCardNumber] = useState();
  const [showPayoutModal, setShowPayoutModal] = useState(false);
  const [intialdata, setIntialdata] = useState();
   
  //  const [isLoading, setIsLoading] = useState(false);

  // Function to create an order with the given product and user ID
  const handleCreateOrder = (productId, userId) => {
    // Display a loading indicator while creating the order.
    setLoading(true);
    // Call an API to create an order.
    ExportApi.createOrder(productId, userId, total)
      .then((resp) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

   

  // Function to create a second-hand order with the given product ID, user ID, and askValue
  const handleCreateSecondHandOrder = (productId, userId, askValue) => {
 
    setLoading(true);
    // Call an API to create a second-hand order.
    ExportApi.CreateSecondHandOrderSell(
      productId,
      userId,
      props?.FinalPriceSell1,
      "Sell",
      props?.Askid ? props?.Askid : 0
    )
      .then((resp) => {
        if (resp.data.message == "Please Create Ask/Bid") {
          toast.error(resp.data.message);
          setLoading(false);
        } else if (resp?.data?.message == "Order Created Successfully") {
          setLoading(false);
          navigate("/order");
        } else {
          setLoading(false);
      
          toast.error(resp.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
   
        toast.error(err.message);
      });
  };

  
  // useEffect(() => {
     
  // if(props.Data){

  //   const transactionaskValue = (props?.Data / 100) * 9.5;
  
  //   setTransction(transactionaskValue.toFixed(2));

  //   const paymentaskValue = (props?.Data / 100) * 3;
 
  //   setPayment(paymentaskValue.toFixed(2));

  // }


  // }, [props?.Data])
  
  



  //get the card Data
  const handleCardData = (id) => {
    ExportApi.getCardData(id)
      .then((resp) => {
    
        let data = resp.data.data;
  
        setCardBrand(data.brand);
        setCardExpiry(data.exp_year);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //     ExportApi.getStripeCardData(id)
  //     .then((resp) => {
  
  //       // let data = resp.data.data;
  //      

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [])

  // Function to handle user bid

  const handleUserBid = (userid) => {
 

    if (props.error) {
 
      toast.error(
        "Shipping Service not available Please Contact to Support Service22"
      );
    } else if (updateAskAmount) {
      if (updateAskAmount) {
      
       
        ExportApi.handleUpdateAsk(
          location?.state?.askId,
          userid,
          JSON.parse(askValue),
          JSON.parse(Transction),
          JSON.parse(Payment),
          total
        )
          .then((resp) => {
         
            if (resp.data.message == "Ask updated successfully") {
              window.dispatchEvent(new Event("List"));
              toast.success("Ask updated successfully");
              setLoading(false);
              setFullPageLoader(false);
              setAskValue(0);
              setUpdateAskAmount(0);
              navigate("/buySellManagment/selling");
            } else {
              setFullPageLoader(false);
              toast.error("Ask is not created! Something Went Wrong");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      ExportApi.getSingleUserData(userid).then((resp) => {
        let data = resp.data.data;
  
        if (data?.shipping_address?.street != null) {
          if (data.stripe_account_id) {
            if (prams.buy != undefined) {
              if (props.Data6 == 1) {
                setFullPageLoader(false);
                handleCreateOrder(prams.id, userid);
                navigate("/order");
              } else {
                setFullPageLoader(false);
                handleCreateSecondHandOrder(prams.id, userid, askValue);
              }
            } else {
              if (sellNowStatus) {
                if (props.Data6 == 1) {
                  setFullPageLoader(false);
                  handleCreateOrder(prams.id, userid);
                  navigate("/order");
                } else {
                  setFullPageLoader(false);
                  handleCreateSecondHandOrder(prams.id, userid, askValue);
                  navigate("/order");
                }
              } else {
                setLoading(true);
                ExportApi.ask(
                  prams.id,
                  userid,
                  Transction,
                  Payment,
                  askValue,
                  total,
                  expiration,
                  shippingFee,
                  askExp
                )
                  .then((resp) => {
                    if (resp.data.message == "Ask created successfully") {
                      window.dispatchEvent(new Event("List"));
                      toast.success("Ask created successfully");
                      setLoading(false);
                      setFullPageLoader(false);
                    } else {
                      setFullPageLoader(false);
                      //toast.error("Ask is not created! Something Went Wrong");
                      toast.error(resp?.data?.message);
                      setLoading(false);
                    }
                  })
                  .catch((err) => console.log(err));
              }
            }
          } else {
            toast.error("Add payouts information to place an order");
            // navigate("/stripeconnectaccount/" + userid);
          }
        } else {
          toast.error("Please complete User information");
          // navigate(`/profile/${userid}`);
        }
      });
    }
  };

  // Function to handle bid submission
  const hendleBid = () => {
    if (askStatus) {
      // Display an error toast if the ask is higher than the higher bid.
      toast.error("Your ask is higher than the higher bid");
    } else {
      setLoading(true);
      if (Finalchecks) {
        setFinalchecksError(false);
        setLoading(false);
        if (Finalchecks1) {
          setFinalchecks1Error(false);
          setLoading(false);
          if (localStorage.getItem("tokenuser")) {
            setLoading(false);
            if (input) {
              setLoading(false);
            } else {
              // Confirm the user's intent to create an order.
              let confirm = window.confirm(
                "Are you sure you want to create an order"
              );
              if (!confirm) {
                // User canceled the operation.
              } else {
                // Call a function to handle user bid with their ID.
                handleUserBid(JSON.parse(localStorage.getItem("tokenuser")).id);
              }
            }
          } else if (localStorage.getItem("admin")) {
            setLoading(false);
            if (input) {
              setLoading(false);
            } else {
              // Confirm the user's intent to create an order.
              let confirm = window.confirm(
                "Are you sure you want to create an order"
              );
              if (!confirm) {
                // User canceled the operation.
              } else {
                // Call a function to handle user bid with their ID.
                handleUserBid(JSON.parse(localStorage.getItem("admin")).id);
              }
            }
          } else {
            setShowModal(true);
          }
        } else {
          setLoading(false);
          setFinalchecks1Error(true);
          toast.error("Complete necessary checkboxes for seamless shopping");
        }
      } else {
        setLoading(false);
        setFinalchecksError(true);
        toast.error("Complete necessary checkboxes for seamless shopping");
      }
    }
  };

  // Function to handle Place Order
  // // Function to handle bid submission
  // const handlePlaceOrder = () => {

  //   alert("clie")
  //   if (askStatus) {
  //     // Display an error toast if the ask is higher than the higher bid.
  //     toast.error("Your ask is higher than the higher bid");
  //   } else {
  //     setLoading(true);
  //     if (Finalchecks) {
  //       setFinalchecksError(false);
  //       setLoading(false);
  //       if (Finalchecks1) {
  //         setFinalchecks1Error(false);
  //         setLoading(false);
  //         if (localStorage.getItem("tokenuser")) {
  //           setLoading(false);
  //           if (input) {
  //             setLoading(false);
  //           } else {
  //             // Confirm the user's intent to create an order.
  //             let confirm = window.confirm(
  //               "Are you sure you want to create an order"
  //             );
  //             if (!confirm) {
  //               // User canceled the operation.
  //             } else {
  //               // Call a function to handle user bid with their ID.
  //               handleUserBid(JSON.parse(localStorage.getItem("tokenuser")).id);
  //             }
  //           }
  //         } else if (localStorage.getItem("admin")) {
  //           setLoading(false);
  //           if (input) {
  //             setLoading(false);
  //           } else {
  //             // Confirm the user's intent to create an order.
  //             let confirm = window.confirm(
  //               "Are you sure you want to create an order"
  //             );
  //             if (!confirm) {
  //               // User canceled the operation.
  //             } else {
  //               // Call a function to handle user bid with their ID.
  //               handleUserBid(JSON.parse(localStorage.getItem("admin")).id);
  //             }
  //           }
  //         } else {
  //           setShowModal(true);
  //         }
  //       } else {
  //         setLoading(false);
  //         setFinalchecks1Error(true);
  //         toast.error("Complete necessary checkboxes for seamless shopping");
  //       }
  //     } else {
  //       setLoading(false);
  //       setFinalchecksError(true);
  //       toast.error("Complete necessary checkboxes for seamless shopping");
  //     }
  //   }
  // };

  // Function to handle the input of the ask value
  const handlePlaceAskValue = (askValue) => {
 
    if (Number(askValue) > props.Data) {
      // If askValue is higher than Data, calculate and set various values.
      setAskValue(askValue);
      let transactionaskValue = (askValue / 100) * 9.5;
      setTransction(transactionaskValue.toFixed(2));
      let paymentaskValue = (askValue / 100) * 3;
      setPayment(paymentaskValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * askValue;
      let fee = shippingCost;
      let sf = Number(props.shipping);
      // let shipping = (fee + sf)?.toFixed(2);
      let shipping = 10;
      setShippingFee(shipping);
      let totalNumber;
    if (localStorage.getItem("admin")) {
      totalNumber = askValue.toFixed(2); // Logic for admin total
 
    } else {
      totalNumber = (
        Number(askValue) -
        (askValue / 100) * 3 -
        shipping -
        (askValue / 100) * 9.5
      ).toFixed(2); // Logic for non-admin total
    }
     

      settotal(totalNumber);
      setInput(false);
      setAskStatus(false);
    } else if (Number(askValue) < props.Data2) {
      // If askValue is lower than Data2, set askStatus to true (for validation).
      setAskValue(askValue);
      let transactionaskValue = (askValue / 100) * 9.5;
      setTransction(transactionaskValue.toFixed(2));
      let paymentaskValue = (askValue / 100) * 3;
      setPayment(paymentaskValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * askValue;
      let fee = shippingCost;
      let sf = Number(props.shipping);
      //let shipping = (fee + sf)?.toFixed(2);
      let shipping = 10;
      setShippingFee(shipping);
      let totalNumber;
    if (localStorage.getItem("admin")) {
      totalNumber = askValue.toFixed(2); // Logic for admin total
    } else {
      totalNumber = (
        Number(askValue) -
        (askValue / 100) * 3 -
        shipping -
        (askValue / 100) * 9.5
      ).toFixed(2); // Logic for non-admin total
    }
      settotal(totalNumber);
      setInput(false);
      setAskStatus(true);
      return;
    } else {
      // If askValue is within acceptable range, set various values and reset askStatus.
      setInput(false);
      setAskValue(askValue);
      let transactionaskValue = (askValue / 100) * 9.5;
      setTransction(transactionaskValue.toFixed(2));
      let paymentaskValue = (askValue / 100) * 3;
      setPayment(paymentaskValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * askValue;
      let fee = shippingCost;
      let sf = Number(props.shipping);
      // let shipping = (fee + sf)?.toFixed(2);
      let shipping = 10;
      setShippingFee(shipping);
      let totalNumber;
      if (localStorage.getItem("admin")) {
        totalNumber = askValue
      } else {
        totalNumber = (
          Number(askValue) -
          (askValue / 100) * 3 -
          shipping -
          (askValue / 100) * 9.5
        ).toFixed(2); // Logic for non-admin total
      }
      settotal(totalNumber);
      setAskStatus(false);
    }
  };

  // Function to handle "Sell Now" button click
  const handleSellNow = (price) => {
    // Set various values based on the provided price.
    setSellNowStatus(true);
    setShow(false);
    setAskValue(price);
    let transactionaskValue = (price / 100) * 9.5;
    setTransction(transactionaskValue.toFixed(2));
    let paymentaskValue = (price / 100) * 3;
    setPayment(paymentaskValue.toFixed(2));
    let shippingCost = Number(0.5 / 100) * price;
    let fee = shippingCost;
    let sf = Number(props.shipping);
    // let shipping = (fee + sf)?.toFixed(2);
    let shipping = 10;
    setShippingFee(shipping);
    let totalNumber =
      Number(price) - (price / 100) * 3 - shipping - (price / 100) * 9.5;
    settotal(totalNumber.toFixed(2));
    // handleReviewAsk();
  };

  // Function to handle the "Back" button click in the review step
  const handleReviewAsk = () => {
 
    if (Number(askValue) <= 0) {
      toast.error("Please Input the Ask first");
      return;
    } else {
      if (Number(askValue) < props.Data2) {
        // If askValue is less than Data2, show an error toast.
        toast.error("Your Ask is less than the Highest Bid");
      } else {
 
        if (!localStorage.getItem("token")) {
          setShowModal(true);
        } else if (cardBrand && cardExpiry && shippingAddress && askExp) {
       
          setShow(false);
          // props.update(false)
          // props.handleUserAsk1(false);
        } else {
          toast.error("Please Fill all User Information");
        }
        // If askValue is acceptable, toggle the "Show" state and call a prop function.
      }
    }
  };

  const handlebackAsk = () => {
    if (prams.buy) {
      navigate(-1);
    } else {
      setShow(true);
      props.update(true);
    }
  };

  // Function to handle validation for a checkbox
  const HandleFinalCheck = (askValue) => {
    if (askValue) {
      setFinalchecks(true);
      setFinalchecksError(false);
    } else {
      setFinalchecks(false);
      setFinalchecksError(true);
    }
  };
  // Another function to handle validation for a different checkbox
  const HandleFinalcheck1 = (askValue) => {
    if (askValue) {
      setFinalchecks1(true);
      setFinalchecks1Error(false);
    } else {
      setFinalchecks1(false);
      setFinalchecks1Error(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("tokenuser")) {
      // If there's a "tokenuser" in local storage, handle something (e.g., shipping fee).
      // HandleShippingFee(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      // If there's an "admin" in local storage, handle something else (e.g., shipping fee).
      // HandleShippingFee(JSON.parse(localStorage.getItem("admin")).id);
    }
  }, [askValue, messageShown]);

  useEffect(() => {
 

    if (localStorage.getItem("tokenuser")) {
      var id = JSON.parse(localStorage.getItem("tokenuser"))?.id;
    } else {
      var id = JSON.parse(localStorage.getItem("admin"))?.id;
    }

    ExportApi.getSingleUserData(id)
      .then((resp) => {
        // let addressData = []
        let Data = resp?.data?.data;
 

        setStreet(Data?.shipping_address?.street);
        setValidStreet(Data?.shipping_address?.street);
        setCity(Data?.shipping_address?.city);
        setValidCity(Data?.shipping_address?.city);
        setState(Data?.shipping_address?.state);
        setValidState(Data?.shipping_address?.state);
        setCountry(Data?.shipping_address?.country);
        setValidCountry(Data?.shipping_address?.country);
        setPostalCode(Data?.shipping_address?.postal_code);
        setValidPostalCode(Data?.shipping_address?.postal_code);
        setPayoutLink(Data?.accountLink);

        const newdata = {
          street: Data.shipping_address.street,
          city:Data?.shipping_address?.city,
          country:Data?.shipping_address?.country,
          postal_code:Data?.shipping_address?.postal_code,
          state:Data?.shipping_address?.state,
          phone:Data?.phone
        }
        setIntialdata(newdata)
      })

      .catch((err) => {
        console.log(err);
      });
    if (localStorage.getItem("tokenuser")) {
      handleCardData(JSON.parse(localStorage.getItem("tokenuser")).id);

      let number = JSON.parse(localStorage.getItem("tokenuser")).phone;
      let numberString = number == null ? null : number?.toString();
      let maskedResponse = "";
      if (numberString == null) {
        maskedResponse = null;
      } else {
        maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
          3,
          6
        )}-${numberString.slice(6)}`;
      }

 

      setMobileNumber(maskedResponse);
    } else if (localStorage.getItem("admin")) {
      handleCardData(JSON.parse(localStorage.getItem("admin")).id);
      let number = JSON.parse(localStorage.getItem("admin")).phone;
      let numberString = number == null ? null : number?.toString();
      let maskedResponse = "";
      if (numberString == null) {
        maskedResponse = null;
      } else {
        maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
          3,
          6
        )}-${numberString.slice(6)}`;
      }
   
      setMobileNumber(maskedResponse);
    } else {
    }
  }, []);

  // }, [askValue, props])

  useEffect(() => {
    if (prams.buy === "sell") {
    
   
      if (props.FinalPriceSell1) {
        // Set ask value based on props and calculate transaction, payment, and shipping fees.
     
        // return
        setAskValue(props.FinalPriceSell1);
        if (localStorage.getItem("tokenuser")) {
          var transactionaskValue = (props.FinalPriceSell1 / 100) * 9.5;
        } else if (localStorage.getItem("admin")) {
          var transactionaskValue = 0;
        } else {
          var transactionaskValue = (props.FinalPriceSell1 / 100) * 9.5;
        }
        // let transactionaskValue = (props.FinalPriceSell1 / 100) * 9.5;
        setTransction(transactionaskValue.toFixed(2));
        let paymentaskValue = (props.FinalPriceSell1 / 100) * 3;
        setPayment(paymentaskValue.toFixed(2));
        let shippingCost = Number(0.5 / 100) * props.FinalPriceSell1;
        let fee = shippingCost;
        let sf = Number(props.shipping);
        // let shipping = (fee + sf)?.toFixed(2);
        let shipping = 10;
        setShippingFee(shipping);
        
          let totalNumber;
          if (localStorage.getItem("admin")) {
            totalNumber = props.FinalPriceSell1 
          } else {
           totalNumber =
          Number(props.FinalPriceSell1) -
          (props.FinalPriceSell1 / 100) * 3 -
          Number(shipping) -
          transactionaskValue;
          }
        settotal(totalNumber);
        setShow(false);
      } else {
        // Set ask value based on props and calculate transaction, payment, and shipping fees.
        setAskValue(props.Data2);
        if (localStorage.getItem("tokenuser")) {
          var transactionaskValue = (props.Data2 / 100) * 9.5;
        } else if (localStorage.getItem("admin")) {
          var transactionaskValue = 0;
        } else {
          var transactionaskValue = (props.Data2 / 100) * 9.5;
        }
        // let transactionaskValue = (props.Data2 / 100) * 9.5;
        setTransction(transactionaskValue.toFixed(2));
        let paymentaskValue = (props.Data2 / 100) * 3;
        setPayment(paymentaskValue.toFixed(2));
        let shippingCost = Number(0.5 / 100) * props.Data2;
        let fee = shippingCost;
        let sf = Number(props.shipping);
        //let shipping = (fee + sf)?.toFixed(2);
        let shipping = 10;
        setShippingFee(shipping);
        
         
          let totalNumber;
          if (localStorage.getItem("admin")) {
            totalNumber = props.Data2
          } else {
            totalNumber =
            Number(props.Data2) -
            (props.Data2 / 100) * 3 -
            Number(shipping) -
            transactionaskValue;
           
          }
          
        settotal(totalNumber);
        setShow(false);
      }
    } else {
      setAskValue(updateAskAmount);
      if (localStorage.getItem("tokenuser")) {
        var transactionaskValue = (updateAskAmount / 100) * 9.5;
      } else if (localStorage.getItem("admin")) {
        var transactionaskValue = 0;
      } else {
        var transactionaskValue = (updateAskAmount / 100) * 9.5;
      }
      // let transactionaskValue = (updateAskAmount / 100) * 9.5;
      setTransction(transactionaskValue.toFixed(2));
      let paymentaskValue = (updateAskAmount / 100) * 3;
      setPayment(paymentaskValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * updateAskAmount;
      let fee = shippingCost;
      let sf = Number(props.shipping);
      //let shipping = (fee + sf)?.toFixed(2);
      let shipping = 10;
      setShippingFee(shipping);
       

        let totalNumber;
        if (localStorage.getItem("admin")) {
          totalNumber = Number(updateAskAmount)
        } else {
          totalNumber =
          Number(updateAskAmount) -
        (updateAskAmount / 100) * 3 -
        Number(shipping) -
        transactionaskValue;
         
        }
      settotal(totalNumber);
      setShow(true);
    }

    // else {
    //   // Handle other cases if needed.
    // }
    // if (prams.buy === "sell2") {
    //   // Set ask value based on props and calculate transaction, payment, and shipping fees.
    //   setAskValue(props.Data2);
    //   let transactionaskValue = (props.Data2 / 100) * 9.5;
    //   setTransction(transactionaskValue.toFixed(2));
    //   let paymentaskValue = (props.Data2 / 100) * 3;
    //   setPayment(paymentaskValue.toFixed(2));
    //   let shippingCost = Number(0.5 / 100) * props.Data2;
    //   let fee = Math.floor(shippingCost);
    //   let sf = Number(props.shipping);
    //   let shipping = (fee + sf)?.toFixed(2);
    //   setShippingFee(shipping);
    //   let totalNumber =
    //     Number(props.Data2) -
    //     (props.Data2 / 100) * 3 -
    //     Number(shipping) -
    //     (props.Data2 / 100) * 9.5;
    //   settotal(totalNumber.toFixed(2));
    //   setShow(false);
    // } else if (prams.buy === "sell3") {
    //   // Set ask value based on props and calculate transaction, payment, and shipping fees.
    //   setAskValue(props.Data2);
    //   let transactionaskValue = (props.Data2 / 100) * 9.5;
    //   setTransction(transactionaskValue.toFixed(2));
    //   let paymentaskValue = (props.Data2 / 100) * 3;
    //   setPayment(paymentaskValue.toFixed(2));
    //   let shippingCost = Number(0.5 / 100) * props.Data2;
    //   let fee = Math.floor(shippingCost);
    //   let sf = Number(props.shipping);
    //   let shipping = (fee + sf)?.toFixed(2);
    //   setShippingFee(shipping);
    //   let totalNumber =
    //     Number(props.Data2) -
    //     (props.Data2 / 100) * 3 -
    //     Number(shipping) -
    //     (props.Data2 / 100) * 9.5;
    //   settotal(totalNumber.toFixed(2));
    //   setShow(false);
    // }
  }, []);
  // }, [props]);

  const [askExp, setAskExp] = useState(30);
  const askExpiration = (e) => {
 
    setAskExp(e.target.value);
  };

  const handlePaymentEditClick = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem("token")) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      // const currentUrl = window.location.href;
      // let sellid = currentUrl.split('/bidask/')[1].split('/')[0];
      // const id = encodeURIComponent(sellid);
  
      //const redirectUri = encodeURIComponent(`https://gpuwiz.com/bidask/${id}/sell`);
      // let newlink = `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=ca_NzhOJRYQ0VEDugR4xgi0bmL5XuD24lxr&scope=read_write&redirect_uri=https://staging.gpuwiz.com?sendpage=sellpage `
 

      window.location.replace(payoutLink);
      // window.location.replace(newlink);
      // if(localStorage.getItem('admin')){
      //   navigate("/profile/"+userId)
      // }
      // else{
      //   navigate("/profile/"+userId)
      // }
    } else {
      setShowModal(true);
    }
    // window.location.replace(payoutLink);
    // navigate(payoutLink);
  };

  const handleEditPayouts = async () => {
    setLoading(true);
    if (localStorage.getItem("tokenuser")) {
      let data = JSON.parse(
        localStorage.getItem("tokenuser")
      )?.stripe_account_id;
      if (data == undefined) {
        let Data = localStorage.getItem("Account_id");
        ExportApi.updateExpressStripeAccount(Data)
          .then((resp) => {
            let data = resp.data.data;
            setLoading(false);
            window.open(`${data?.url}`, "_self");
          })
          .catch((err) => {
            console.log(err, "err>>>>handleEditPayouts>>123");
          });
      } else {
        ExportApi.updateExpressStripeAccount(data)
          .then((resp) => {
            let data = resp.data.data;
            setLoading(false);
            window.open(`${data?.url}`, "_self");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const handleStripeAccountdsell = () => {
    // setIsLoading(true);
    let userId = JSON.parse(localStorage.getItem("tokenuser"));
  

    let pagename = "sellpage";

    ExportApi.handleCard(userId?.id, userId?.email, pagename)
      .then((resp) => {
   
        if (resp.data.message == "Account Created Successfully") {
          setShowPayoutModal(false);
          let data = resp.data.data;
     
          window.open(`${data.link}`, "_self");
          // setIsLoading(false);
        } else if (resp.data.message == "Account Already Exists") {
          setShowPayoutModal(false);
          toast.error("Email already exists");
          // setIsLoading(false);
        } else {
          toast.error(resp.data.message);
          setTimeout(() => {
            if (
              resp.data.message == "Add payment information to place an order"
            ) {
              navigate("/profile/" + userId?.id);
            } else {
              setShowPayoutModal(false);
              toast.error(resp.data.message);
              // setIsLoading(false);
            }
          }, 1000);
        }
      })
      .catch((err) => {
        setShowPayoutModal(false);
        console.log(err);
        // setIsLoading(false);
      });
  };
  const handleStripeAccount = () => {
    // setIsLoading(true);
    if (!localStorage.getItem("tokenuser") || !localStorage.getItem("token")) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      setShowModal(true);
      return;
    }
    let userId = JSON.parse(localStorage.getItem("tokenuser"));
 

    let pagename = "bidask";

    ExportApi.handleCard(userId?.id, userId?.email, pagename)
      .then((resp) => {
    
        if (resp.data.message == "Account Created Successfully") {
          setShowPayoutModal(false);
          let data = resp.data.data;
     
          window.open(`${data.link}`, "_self");
          // setIsLoading(false);
        } else if (resp.data.message == "Account Already Exists") {
          setShowPayoutModal(false);
          toast.error("Email already exists");
          // setIsLoading(false);
        } else {
          toast.error(resp.data.message);
          // setTimeout(() => {
          if (
            resp.data.message == "Add payment information to place an order"
          ) {
            navigate("/profile/" + userId?.id);
          } else {
            setShowPayoutModal(false);
            toast.error(resp.data.message);
            // setIsLoading(false);
          }
          // }, 1000)
        }
      })
      .catch((err) => {
        setShowPayoutModal(false);
 
        // setIsLoading(false);
      });
  };
  // const handleEditPayouts = async () => {
  //   setIsLoading(true)
  //   if (localStorage.getItem('tokenuser')) {
  //       let data = JSON.parse(localStorage.getItem('tokenuser'))?.stripe_account_id
  //       if (data == undefined) {
  //           let Data = localStorage.getItem('Account_id')
  //           ExportApi.updateExpressStripeAccount(Data).then((resp) => {
  //               let data = resp.data.data
  //               setIsLoading(false)
  //               window.open(`${data?.url}`, '_self')
  //           }).catch((err) => {
  //               console.log(err,"err>>>>handleEditPayouts>>123")
  //           })
  //       } else {
  //           ExportApi.updateExpressStripeAccount(data).then((resp) => {
  //               let data = resp.data.data
  //               setIsLoading(false)
  //               window.open(`${data?.url}`, '_self')
  //           }).catch((err) => {
  //               console.log(err)
  //           })
  //       }
  //   }

  // }

  const handleEditClick = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem("token")) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      const currentUrl = window.location.href;
      let sellid = currentUrl.split("/bidask/")[1].split("/")[0];
      const id = encodeURIComponent(sellid);
 
      //const redirectUri = encodeURIComponent(`https://gpuwiz.com/bidask/${id}/sell`);
      let newlink = `https://connect.stripe.com/express/oauth/v2/authorize?response_type=code&client_id=ca_NzhOJRYQ0VEDugR4xgi0bmL5XuD24lxr&scope=read_write&redirect_uri=https://staging.gpuwiz.com?sendbidask=bidask`;
   

      window.location.replace(payoutLink);
      // if(localStorage.getItem('admin')){
      //   navigate("/profile/"+userId)
      // }
      // else{
      //   navigate("/profile/"+userId)
      // }
    } else {
      setShowModal(true);
    }
    // window.location.replace(payoutLink);
    // navigate(payoutLink);
  };

  const handleShippingAddressEditClick = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem("token")) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      setShowAddressModal(true);
      // if(localStorage.getItem('admin')){
      //   navigate("/stripeconnectaccount/"+userId)
      // }
      // else{
      //   navigate("/stripeconnectaccount/"+userId)
      // }
    } else {
      setShowModal(true);
      setShowAddressModal(false);
    }
  };

  //update the address
  const handleUpdateAddress = () => {
    setButtonAddressLoader(true);

    if (!validStreet?.trim() || !validCity?.trim() || !validState?.trim() || !validcountry?.trim()) {
      toast.error("Address fields cannot be empty or contain only spaces");
      setButtonAddressLoader(false);
      return;
    }

    if (!mobileNumber) {
      toast.error("Phone Number is Required");
      setButtonAddressLoader(false);
      return;
    }

    const newPhoneNumber = mobileNumber.toString().replace(/\D/g, ""); // Ensure mobileNumber is treated as a string before replace
    const postalCodeRegex = /^\d{5}(-\d{4})?$/;

    if (!postalCodeRegex.test(validPostalCode)) {
      toast.error('Postal Code must be 5 digits or in the format "12345" or "12345-6789"');
      setButtonAddressLoader(false);
      return;
    }
 
    // Check if the new address is the same as the current address
    if (
      intialdata.street == validStreet  &&
      intialdata.city == validCity  &&
      intialdata.state ==  validState  &&
      intialdata.country ==validcountry &&
      intialdata.postal_code == validPostalCode  &&
      intialdata.phone ==   newPhoneNumber 
    ) {
      toast.error("Address is the same as the current address");
      setButtonAddressLoader(false);
      return;
    }

    // Proceed with updating the address if it has changed
    let id;
    if (localStorage.getItem("tokenuser")) {
      id = JSON.parse(localStorage.getItem("tokenuser"))?.id;
    } else {
      id = JSON.parse(localStorage.getItem("admin"))?.id;
    }

    ExportApi.updateSingleUserData(id, validStreet, validCity, validState, validcountry, validPostalCode, newPhoneNumber)
      .then((resp) => {
        const Data = resp?.data?.result;
        setPostalCode(Data?.shipping_address?.postal_code);
        setCountry(Data?.shipping_address?.country);
        setState(Data?.shipping_address?.state);
        setCity(Data?.shipping_address?.city);
        setStreet(Data?.shipping_address?.street);
        localStorage.setItem("useraddress", JSON.stringify(validStreet));
        const newdata = {
          street: Data.shipping_address.street,
          city:Data?.shipping_address?.city,
          country:Data?.shipping_address?.country,
          postal_code:Data?.shipping_address?.postal_code,
          state:Data?.shipping_address?.state,
          phone:Data?.phone
        }
        setIntialdata(newdata);
        toast.success("Address Updated Successfully");
        setShowAddressModal(false);
        setButtonAddressLoader(false);
        setTimeout(() => {
          window.location.reload();
        },  2000);
       

        let localData;
        if (localStorage.getItem("tokenuser")) {
          localData = localStorage.getItem("tokenuser");
        } else {
          localData = localStorage.getItem("admin");
        }
        const parsedData = JSON.parse(localData);
        parsedData.phone = Data?.phone;
        if (localStorage.getItem("tokenuser")) {
          localStorage.setItem("tokenuser", JSON.stringify(parsedData));
        } else {
          localStorage.setItem("admin", JSON.stringify(parsedData));
        }
      })
      .catch((err) => {
        setButtonAddressLoader(false);
        console.log(err);
      });
  };

  const tokenUser = localStorage.getItem("tokenuser");
  const adminUser = localStorage.getItem("admin");

  let userId;

  const useridnew = JSON.parse(tokenUser);
  const adminidnew = JSON.parse(adminUser);

  if (useridnew && useridnew.id) {
    userId = useridnew?.id;
  } else {
    userId = adminidnew?.id;
  }

  const handleStripConnectedCardData = () => {
    setLoading(true);
    ExportApi.getStripeCardData(userId)
      .then((resp) => {
         
        let data = resp?.data?.data?.data[0];
       
        let data2 = resp?.data;
    
        if (resp?.data?.data?.data?.length <= 0) {
       
          setPayoutCardNumber(data2?.last4);
          setPayoutBankName(data2?.brand);
          // setPayoutsStatus(resp?.data?.isActive)
          // setPayoutExpireMonth(data2?.exp_month)
          // setPayoutExpireYear(data2?.exp_year)
          setLoading(false);
        } else {
          setPayoutCardNumber(data?.last4);
          setPayoutBankName(data?.bank_name);
          // setPayoutsStatus(resp?.data?.isActive)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleStripConnectedCardData();
  }, []);

   

  const askValueToAdd = isNaN(parseFloat(askValue)) ? 0 : parseFloat(askValue);

  const TransctionToAdd = isNaN(parseFloat(Transction))
    ? 0
    : parseFloat(Transction);

  const PaymentToAdd = isNaN(parseFloat(Payment)) ? 0 : parseFloat(Payment);

  const shippingFeeToAdd = isNaN(parseFloat(shippingFee))
    ? 0
    : parseFloat(shippingFee);

  let subtotal =
    askValueToAdd - TransctionToAdd - PaymentToAdd + shippingFeeToAdd;
 
  return (
    <div>
      {fullPageLoader ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <div>
          {Show ? (
            <>
              {props.Data ? <p>Lowest Ask: $ {props.Data}</p> : ""}
              <div className="d-flex  border rounded p-2 gap-2">
                <div className="quantity-custom">
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      id="basic-addon1"
                      className="bg-none border-end-0 text-dark"
                    >
                      $
                    </InputGroup.Text>

                    <Form.Control
                      value={askValue}
                      onChange={(e) => {
                        handlePlaceAskValue(e.target.value);
                      }}
                      placeholder=""
                      type="number"
                      className="border-start-0"
                      aria-label="number"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
                {/* &nbsp; */}
                {/* start commented Code for buy Now button
            <div className="ms-auto customsell-btn">
              {props.Data2 ? (
                <Button
                  className="sell-btn border-0 sell-button"
                  onClick={() => handleSellNow(props.Data2)}
                >
                  Sell Now for ${props.Data2}
                </Button>
              ) : props.Data6 == 1 ? (
                <Button
                  className="sell-btn border-0"
                  onClick={() => handleSellNow(props.Data7)}
                >
                  Sell Now for ${props.Data7}
                </Button>
              ) : props.Data6 == 2 ? (
                props.Data3 > 0 ? (
                  <Button
                    className="sell-btn border-0"
                    onClick={() => handleSellNow(props.Data3)}
                  >
                    Sell Now for ${props.Data3}
                  </Button>
                ) : (
                  ""
                )
              ) : props.Data6 == 3 ? (
                props.Data5 > 0 ? (
                  <Button
                    className="sell-btn border-0"
                    onClick={() => handleSellNow(props.Data5)}
                  >
                    Sell Now for ${props.Data5}
                  </Button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
            End Commented Code for buy now Button */}

                <div className="ms-auto customsell-btn">
   
                  {props.Data2 ? (
               
                    <Button
                      className="sell-btn border-0 sell-button"
                      onClick={() => {
                        navigate("/bidask/" + prams?.id + "/sell");
                        window.location.reload();
                      }}
                    >
                      Sell Now for ${props.Data2}
                    </Button>
                  ) : props.Data6 == 1 ? (
                    <Button
                      className="sell-btn border-0"
                      onClick={() => {
                        navigate("/bidask/" + prams?.id + "/sell");
                        window.location.reload();
                      }}
                    >
                      Sell Now for ${props.Data7}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {localStorage.getItem("tokenuser") ? (
                askValue > 0 ? (
                  props.Data >= askValue ? (
                    <h2 className="text-capitalize text-center fs-4 my-4">
                      you are the lowest ask!
                    </h2>
                  ) : props.Data < askValue ? (
                    <h2 className="text-capitalize text-center fs-4 my-4">
                      you are not the lowest ask!
                    </h2>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : localStorage.getItem("admin") ? (
                askValue > 0 ? (
                  props.Data >= askValue ? (
                    <h2 className="text-capitalize text-center fs-4 my-4">
                      you are the lowest ask!
                    </h2>
                  ) : props.Data < askValue ? (
                    <h2 className="text-capitalize text-center fs-4 my-4">
                      you are not the lowest ask!
                    </h2>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0">Your Ask</p>
                {/* <p className="ms-auto mb-0"> {isNaN(askValue) ? "TBD" : "$" + askValue}</p> */}
                {/* <p className="ms-auto mb-0"> {isNaN(askValue) ? "TBD" : "$" + props.Data}</p> */}
                {/* <p className="ms-auto mb-0">{isNaN(askValue) ? "TBD" : "$" + (props.Data !== undefined ? props.Data : 0)} </p> */}
                <p className="ms-auto mb-0 truncated-number">
                  ${askValue
                    ? askValue
                    : isNaN(askValue)
                    ? "TBD"
                    :(props.Data !== undefined ? props.Data : 0)}

              
                </p>
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0">Selling Fee (9.5%)</p>
                {localStorage.getItem("admin") ? (
                  <p className="ms-auto mb-0">- $0</p>
                ) : (
                  props.Data ? (
                    <p className="ms-auto mb-0 truncated-number">- ${((props?.Data / 100) * 9.5).toFixed(2)}</p>
                  ) : (
                    <p className="ms-auto mb-0 truncated-number" >- ${Transction} </p>
                  )
                 
                )}
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0">Payout Fee (3%)</p>
                {localStorage.getItem("admin") ? (
                  <p className="ms-auto mb-0">- $0</p>
                ) : (
                  props.Data ? (
                    <p className="ms-auto mb-0 truncated-number">- ${((props?.Data / 300) * 3).toFixed(2)}</p>
                  ) : (
                    <p className="ms-auto mb-0 truncated-number">- ${Payment}</p>
                  )
                  // <p className="ms-auto mb-0 truncated-number">- ${Payment}</p>
                )}
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0">Shipping</p>
                {/* <p className="ms-auto mb-0">
                  {isNaN(shippingFee) ? "TBD" : "- $" + shippingFee}
                </p> */}

                {localStorage.getItem("admin") ? (
                  <p className="ms-auto mb-0">- $0</p>
                ) : (
                  <p className="ms-auto mb-0">
                    {/* {parseFloat(Payment) === 0.0
                      ? "- $0"
                      : isNaN(shippingFee)
                      ? "TBD"
                      : `- $${shippingFee.toFixed(2)}`} */}
                      - $10
                  </p>
                )}
              </div>

              {/* <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0">Discount Code</p>
                <Button
                  className="add-discount-btn text-capitalize ms-auto bg-transparent p-0 border-0 fw-bold"
                  disabled
                >
                  add discount{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 15 15"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </Button>
              </div> */}

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0"> Total</p>
                {/* <p className="ms-auto mb-0">
                  {isNaN(total) ? "" : "$" + total}
                </p> */}
         

                {parseFloat(Payment) === 0.0 ? (
                  <p className="ms-auto mb-0">
                    {isNaN(total)
                      ? "0.00"
                      : `$${
                          props?.Data !== undefined
                            ? props?.Data - 10 - ((props?.Data / 300) * 3) -((props?.Data / 100) * 9.5)
                            : "0.00"
                        }`}
                       
                  </p>
                ) : (
                  <p className="ms-auto mb-0 ">
                    {isNaN(total) ? "$0.00" : `$${Number(total).toFixed(2)}`}
                  </p>
                )}
              </div>

              <div class="border rounded p-2 ask-main">
                <div class="ask-expiration d-flex">
                  <p class="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-stopwatch me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"></path>
                      <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"></path>
                    </svg>
                    Ask Expiration
                  </p>
                  <div class="ms-auto">
                    <select
                      class="border py-1 px-2"
                      onChange={(e) => askExpiration(e)}
                    >
                      <option value="1">1 day</option>
                      <option value="3">3 days</option>
                      <option value="7">7 days</option>
                      <option value="14">14 days</option>
                      <option selected value="30">
                        30 days
                      </option>
                      <option value="60">60 days</option>
                    </select>
                  </div>
                </div>
                <div className="payment-status">
                  {/* 
                  <div class="ask-expiration d-flex mt-3"> */}
                  {/* <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet me-2" viewBox="0 0 16 16"><path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path></svg>Payment method : {cardBrand && cardExpiry ? "Active" : (<span className="error-messages">Required</span>)}

                    </p> */}
                  {/* <div class="ms-auto">
                                <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handlePaymentEditClick}>edit</button>
                            </div> */}
                  {/* </div> */}

                  <div class="ask-expiration d-flex mt-3">
                    <p class="mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-wallet me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path>
                      </svg>
                      Payout method :{" "}
                      {payoutBankName ? (
                        <span>&nbsp;****{payoutCardNumber} </span>
                      ) : (
                        <span className="error-messages">Required</span>
                      )}
                      {/* {paymentStatus != 'null' ? 'Visa 4242' : <span className='text-danger fw-bold'>Required</span>} */}
                    </p>
                    <div class="ms-auto">
                      {/* {payoutBankName ? 
                      <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" 
                      // onClick={() => handleEditClick()}
                      onClick={() => handleEditPayouts()}
                      >edit</button>

                      : 
                      <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" 
                       onClick={() => handleEditClick()}
                      // onClick={() => handleEditPayouts()}
                      >add</button>} */}

                      {payoutBankName ? (
                        <button
                          type="button"
                          class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                          onClick={() => handleEditPayouts()}
                        >
                          edit
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                          onClick={() => handleStripeAccount()}
                        >
                          {" "}
                          {!localStorage.getItem("tokenuser") ||
                          !localStorage.getItem("token") ? (
                            <>edit</>
                          ) : (
                            <>add</>
                          )}{" "}
                        </button>
                      )}
                    </div>
                  </div>

                  <div class="ask-expiration d-flex mt-3 ">
                    <p class="mb-0 d-flex align-items-start me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        fill="currentColor"
                        class="bi bi-wallet me-2 mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path>
                      </svg>
                      Shipping Address :&nbsp;
                      {street ? (
                        <div className="d-flex flex-column ms-3">
                          <p className="mb-0">{street}</p>
                          <p className="mb-0">{city}</p>
                          <p className="mb-0">{postalCode}</p>
                        </div>
                      ) : (
                        <span className="error-messages">Required</span>
                      )}
                      {/* {paymentStatus != 'null' ? '3864 Rhodes Ave' : <span className='text-danger fw-bold'>Required</span>} */}
                    </p>
                    <div class="ms-auto">
                      <button
                        type="button"
                        class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                        onClick={handleShippingAddressEditClick}
                      >
                        edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p>
                Your ask may appear higher due to local duties
                <br /> and taxes
              </p> */}
              {/* <div className="border rounded p-2 ask-main">
                <div className="ask-expiration d-flex">
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-stopwatch me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                      <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                    </svg>
                    Ask Expiration
                  </p>

                  <div className="ms-auto">
                    <select
                      className="border py-1 px-2"
                      onChange={(e) => setExpiration(e.target.value)}
                    >
                      <option askValue="1">1 day</option>
                      <option askValue="2">2 day</option>
                      <option askValue="3">3 day</option>
                      <option askValue="4">4 day</option>
                      <option askValue="5">5 day</option>
                      <option askValue="10">10 day</option>
                      <option askValue="15">15 day</option>
                      <option askValue="30">30 day</option>
                    </select>
                  </div>
                </div>

                <div className="ask-expiration d-flex mt-3">
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-wallet me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    Payout method : Active
                  </p>

                  <div className="ms-auto">
                    {localStorage.getItem("tokenuser") ? (
                      <Button
                        className="text-uppercase edit-btn bg-transparent border-0"
                        onClick={() =>
                          navigate(
                            "/stripeconnectaccount/" +
                              JSON.parse(localStorage.getItem("tokenuser")).id
                          )
                        }
                      >
                        edit
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}
            </>
          ) : (
            <div className="review-part">
              {prams.buy != undefined ? (
                <h2 className="text-capitalize text-center fs-4 mt-4 mb-2">
                  Place Order
                </h2>
              ) : (
                <h2 className="text-capitalize text-center fs-4 mt-4 mb-2">
                  Place Ask
                </h2>
              )}
              <p className="text-center">
                Please confirm your {prams.buy != undefined ? "order" : "ask"}{" "}
                details below
              </p>
              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0 text-capitalize">
                  {prams.buy != undefined ? "sale price" : "Your Ask"}
                </p>

                <p className="ms-auto mb-0">
                  {isNaN(askValue) ? "TBD" : "$" + askValue}
                </p>
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0 text-capitalize">Selling Fee (9.5%)</p>
                {/* <p className="ms-auto mb-0"> */}
                {localStorage.getItem("admin") ? (
                  <p className="ms-auto mb-0">$0</p>
                ) : (
                  <p className="ms-auto mb-0">
                    {isNaN(Transction) ? "TBD" : "- $" + Transction}
                  </p>
                )}

                {/* </p> */}
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0 text-capitalize">Payout Fee (3%)</p>
                <p className="ms-auto mb-0">
                  {isNaN(Payment) ? "TBD" : "- $" + Payment}
                </p>
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0 text-capitalize">shipping</p>
                {/* <p className="ms-auto mb-0">
                  {isNaN(shippingFee) ? "TBD" : "- $" + shippingFee}
                </p> */}
                {localStorage.getItem("admin") ? (
                  <p className="ms-auto mb-0">$0</p>
                ) : (
                  <p className="ms-auto mb-0">
                    {isNaN(shippingFee) ? "TBD" : "- $" + shippingFee}
                  </p>
                )}
              </div>

              <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                <p className="mb-0 text-capitalize">Total</p>
                <p className="ms-auto mb-0 fw-bold ">
                  {/* {isNaN(total) ? `$${subtotal}` : `$${total}`} */}
                  {isNaN(Number(total))
                    ? `$${Number(subtotal).toFixed(2)}`
                    : `$${Number(total).toFixed(2)}`}

          
                </p>
              </div>

              {/* {localStorage.getItem("tokenuser") ? (
                askValue > 0 ? (
                  props.Data >= askValue ? (
                    <p>you are the lowest ask!</p>
                  ) : props.Data < askValue ? (
                    <p>you are not the lowest ask!</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : localStorage.getItem("admin") ? (
                askValue > 0 ? (
                  props.Data >= askValue ? (
                    <p>you are the lowest ask!</p>
                  ) : props.Data < askValue ? (
                    <p>you are not the lowest ask!</p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )} */}
              {/* <div className="pb-2 mb-3">
                <p className="mb-0 text-capitalize border-bottom pb-2 mb-2">
                  {props.cardBrand} ending in {props.cardExpiry}
                </p>
                <p className="ms-auto mb-0">Payout Method:Active</p>
              </div> */}

              <div class="border rounded p-3 ask-main">
                {prams.buy != undefined ? (
                  <></>
                ) : (
                  <div class="ask-expiration d-flex">
                    <p class="mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-stopwatch me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"></path>
                        <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"></path>
                      </svg>
                      Ask Expiration
                    </p>
                    <div class="ms-auto">
                      {/* <select class="border py-1 px-2">
                            <option askvalue="1">1 day</option>
                            <option askvalue="2">2 day</option>
                            <option askvalue="3">3 day</option>
                            <option askvalue="4">4 day</option>
                            <option askvalue="5">5 day</option>
                            <option askvalue="10">10 day</option>
                            <option askvalue="15">15 day</option>
                            <option askvalue="30">30 day</option>
                        </select> */}
                      {askExp} Day
                    </div>
                  </div>
                )}
                <div className="payment-status">
                  <div class="ask-expiration d-flex mt-3">
                    <p class="mb-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-wallet me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path>
                      </svg>
                      Payout method :{" "}
                      {payoutBankName && payoutCardNumber ? (
                        <span>&nbsp;****{payoutCardNumber} </span>
                      ) : (
                        <span className="error-messages">Required</span>
                      )}
                    </p>
                    <div class="ms-auto d-flex">
                      {/* <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary">edit</button> */}
                      {prams.buy != undefined ? (
                        <div class="ms-auto">
                          {/* {payoutCardNumber ? 
                          <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary ms-1" 
                          // onClick={() => handlePaymentEditClick()}
                          onClick={() => handleEditPayouts()}
                          
                          >edit</button>
                          : 
                          <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary ms-1" 
                          onClick={() => handlePaymentEditClick()}
                          //onClick={() => handleEditPayouts()}
                          
                          >add</button>} */}
                          {/* <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary ms-1"
                    
                             onClick={() => handleEditClick()}
                             
                             >edit</button> */}
                          {payoutBankName ? (
                            <button
                              type="button"
                              class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                              onClick={() => handleEditPayouts()}
                            >
                              edit
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                              onClick={() => handleStripeAccountdsell()}
                            >
                              add
                            </button>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div class="ask-expiration  d-flex mt-3">
                    <p class="mb-0 d-flex align-items-start ">
                      <svg
                        fill="currentColor"
                        className="me-2 mt-1"
                        height="20px"
                        width="20px"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 487.4 487.4"
                        enable-background="new 0 0 487.4 487.4"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <path d="M381.6,144.1h-63.3V25.9H0v369.2h33.2c1.1,36.4,31.8,66.4,68.4,66.4s67.3-30,68.4-66.4h154.6c1.1,36.4,31.8,66.4,68.4,66.4 s67.3-30,68.4-66.4h26V251L381.6,144.1z M101.6,440.7c-25.9,0-47.7-21.8-47.7-47.7s21.8-47.7,47.7-47.7s47.7,21.8,47.7,47.7 S127.5,440.7,101.6,440.7z M297.6,374.4H167.4c-8.3-28.5-34.9-49.8-65.8-49.8s-57.5,21.3-65.8,49.8H20.7V46.6h276.9V374.4z M387.9,179.6l65.5,66.2h-65.5V179.6z M393,440.7c-25.9,0-47.7-21.8-47.7-47.7s21.8-47.7,47.7-47.7s47.7,21.8,47.7,47.7 S419,440.7,393,440.7z M466.7,374.4h-7.9l0,0c-8.3-28.5-34.9-49.8-65.8-49.8s-57.5,21.3-65.8,49.8h-7.9V164.9H367v101.6h99.7V374.4 z"></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                      <p style={{ whiteSpace: "nowrap" }}>   
                      Return Shipping Address :&nbsp;
                      </p>
                    </p>
                    <div class="ms-auto">
                      {street ? (
                        <>
                          <p className="mb-0">{street}</p>

                          <p className="mb-0">{city}</p>

                          <p className="mb-0">{postalCode}</p>
                        </>
                      ) : (
                        <span className="error-messages">Required</span>
                      )}
                    </div>
                    {prams.buy != undefined ? (
                      <div class="">
                        <button
                          type="button"
                          class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                          onClick={handleShippingAddressEditClick}
                        >
                          edit
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="pb-2 mb-3 border rounded p-4">
                <h5 className="mb-4">Pre Sale Verification</h5>

                <p
                  className="d-flex align-items-center mb-0 pb-2 mb-2"
                  style={{ lineHeight: 1.3, fontSize: "14px" }}
                >
                  <input
                    className="me-2"
                    type="checkbox"
                    onChange={(e) => {
                      HandleFinalCheck(e.target.checked);
                    }}
                  />
                  I understand that I will be charged a fee if I do not ship the
                  graphics card within 2 business days of sale date.
                </p>
                <hr />

                <p className="d-flex align-items-center mb-0 pb-2 mb-2">
                  <input
                    className="me-2"
                    type="checkbox"
                    onChange={(e) => {
                      HandleFinalcheck1(e.target.checked);
                    }}
                  />
                  <div style={{ lineHeight: 1.3, fontSize: "14px" }}>
                    I have confirmed that the graphics card meets the{" "}
                    <Link
                      to="/"
                      style={{ color: "#0303cc", textDecoration: "none" }}
                    >
                      Quality Standards.
                    </Link>{" "}
                    I understand that I will be charged a fee if it is
                    determined that the graphics card does not meet the Quality
                    Standards.
                  </div>
                </p>
                <hr />
              </div>
            </div>
          )}

          <div className="d-flex align-items-center mt-5">
            {Show ? (
              ""
            ) : (
              <Button
                className="text-capitalize border rounded bg-transparent text-black"
                onClick={() => handlebackAsk()}
              >
                back
              </Button>
            )}
            {Show ? (
              <Button
                className="text-capitalize ms-auto bg-dark text-white border-0"
                onClick={handleReviewAsk}
              >
                review ask
              </Button>
            ) : prams.buy != undefined || sellNowStatus ? (
              <Button
                className="text-capitalize ms-auto bg-dark text-white border-0"
                disabled={isLoading}
                onClick={() => hendleBid()}
              >
                {isLoading ? "Please Wait..." : "Place Order"}
              </Button>
            ) : (
              <Button
                disabled={isLoading}
                className="text-capitalize ms-auto bg-dark text-white border-0"
                onClick={() => hendleBid()}
              >
                {isLoading ? "Please wait..." : "Place Ask"}
              </Button>
            )}
          </div>

          {/* Start Login Model */}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop={true}
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollable-modal-body">
              <h6>
                <strong>
                  In order to buy and sell, you need to login/sign up
                </strong>
              </h6>
              <Login modal={() => setShowModal(false)} />
            </Modal.Body>
          </Modal>
          {/* End Login Model */}

          {/* Start Shipping Address */}
          <Modal
            show={showAddressModal}
            onHide={() => setShowAddressModal(false)}
            backdrop={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Address</Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollable-modal-body">
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Address"
                  value={validStreet}
                  onChange={(e) => setValidStreet(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your City"
                  value={validCity}
                  onChange={(e) => setValidCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                {/* <Form.Control type="text" placeholder="Enter Your State" value={validState} onChange={(e) => setValidState(e.target.value)} /> */}
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setValidState(e.target.value)}
                >
                  {validState ? (
                    <option selected>{validState}</option>
                  ) : (
                    <option selected>Select State</option>
                  )}
                  {states?.map((item) => {
                    return (
                      <option value={item?.abbreviation}>{item?.name}</option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Country"
                  value={validcountry}
                  onChange={(e) => setValidCountry(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Postal Code"
                  value={validPostalCode}
                  onChange={(e) => setValidPostalCode(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <InputMask
                  className="form-control"
                  mask="(999) 999-9999"
                  placeholder="(123) 456-7890"
                  type="tel"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                {/* <Form.Control type="number" placeholder="Enter Your Phone" pattern="[0-9]{10}" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                {/* <p style={{ color: "red" }}>{phone?.length == 10 ? '' : 'Invalid mobile number'}</p> */}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddressModal(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                disabled={buttonAddressLoader}
                onClick={handleUpdateAddress}
              >
                {buttonAddressLoader ? "Please Wait..." : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* End Shipping Address */}

          {/* Model start Primary Payment Account */}
          <Modal
            show={primaryAccountModel}
            size="lg"
            onHide={() => setPrimaryAccountModel(false)}
            backdrop={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Primary Payment Account</Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollable-modal-body">
              <StripeElement />
            </Modal.Body>
          </Modal>
          {/* Model End Primary Payment Account */}
        </div>
      )}
    </div>
  );
}
