import React, { useState, useEffect } from "react";
import ExportApi from "../../../api/ExportApi";
import axios from "axios";
import { Container } from "react-bootstrap";
import BannerCarousel from "./carousel/BannerCarousel";
import "./banner.css";
import { useNavigate } from "react-router-dom";
//import Loader from "../../../images/loader/loaderrrr.gif"

function Banner() {
  const navigate = useNavigate();
  const [bannerImages, setBannerImages] = useState([]);
  const [miniLeftBanner, setMiniLeftBanner] = useState([]);
  const [miniRightBanner, setMiniRightBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    ExportApi.getBannerAllList()
      .then(function (response) {
    
        const data = response.data.data;
      
     
        // Filter data for mini left banner
        let MiniFilterBannerData = data?.filter((item) => {
          return (
            item?.category_type === "Mini Banner left" && item?.active === true
          );
        });
        setMiniLeftBanner(MiniFilterBannerData);
        setLoad(false);
        // setMiniBannerCount(MiniFilterBannerData[0]?.imageCount);

        // Filter data for mini right banner
        let MiniRightFilterBannerData = data?.filter((item) => {
          return (
            item?.category_type == "Mini Banner right" && item?.active === true
          );
        });
        setMiniRightBanner(MiniRightFilterBannerData);
        setLoad(false);
        // setMiniBannerCountRight(MiniRightFilterBannerData[0]?.imageCount);

        // Filter data for main banner
        let filterData = data?.filter((item) => {
          return (
            item?.add === true &&
            item?.active === true &&
            item?.category_type == "Main Banner"
          );
        });
       
        setBannerImages(filterData);
        setLoad(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

 
 

  {
    load ? console.log(" ") : console.log(" ");
    setTimeout(() => {
      localStorage.setItem("load", "true");
    }, 200);
  }

  useEffect(() => {
    // Check if the page is being reloaded
    if (
      window.performance.navigation.type ===
      window.performance.navigation.TYPE_RELOAD
    ) {
      // Page is being reloaded, perform your action here
      ExportApi.updateVisitPageCount(bannerImages[0]?.pageCount + 1)
        .then(function (response) {
       
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [bannerImages]);

  const handleImageClick = (e, id, i) => {
 
    setLoading(true);
    e.preventDefault();
    ExportApi.updateBannerCountRate(
      id,
      bannerImages[i]?.pageCount,
      bannerImages[i]?.imageCount + 1
    )
      .then(function (response) {
     
        window.location.href = bannerImages[i]?.image_link;
        // navigate(bannerImages[i]?.image_link)
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleImageLoaded = () => {
    setLoaded(true);
  };

 

  return (
    <div className="banner py-4 py-lg-5">
      {/* {loading &&
            <div className="loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        } */}
      <Container>
        <div className="banner-container d-flex flex-wrap">
          <div className="filter-box pe-sm-1 col-lg-6 col-md-6 text-center">
            <ul className="m-0 mb-0 p-0 d-flex align-items-center justify-content-center">
              {/* <li className='d-flex' onClick={() =>
                    navigate("/categories/63ff36fb23ad0386e761641f/RX 5000")
                  }>RX 5000 Series</li> */}

              {/* <li className='d-flex'
                    onClick={() => {
                      const selectedFilterData = {
                        type: "series",
                        name: "RX 6000",
                        checked: true,
                        index: 21
                      };
                      localStorage.setItem('selectedFilterData', JSON.stringify(selectedFilterData));
                      navigate("/categories/63ff36fb23ad0386e761641f");
                    }}
                     >RX 6000 Series</li> */}

              <li
                className="d-flex"
                onClick={() => {
                  let storedData =
                    JSON.parse(localStorage.getItem("selectedFilterData")) ||
                    [];
                  if (!Array.isArray(storedData)) {
                    storedData = [];
                  }
                  const newFilterData = {
                    type: "series",
                    name: "RX 6000",
                    checked: true,
                    index: 23,
                  };

                  const isDuplicate = storedData.some(
                    (item) =>
                      item.type === newFilterData.type &&
                      item.name === newFilterData.name &&
                      item.index === newFilterData.index
                  );

                  if (!isDuplicate) {
                    storedData.push(newFilterData);
                  }
                  localStorage.setItem(
                    "selectedFilterData",
                    JSON.stringify(storedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RX 6000 Series
              </li>

              {/* <li
                className="d-flex"
                onClick={() => {
                  const selectedData = {
                    type: "series",
                    name: "RX 7000",
                    checked: true,
                    index: 31,
                  };
                  localStorage.setItem(
                    "selectedData",
                    JSON.stringify(selectedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RX 7000 Series
              </li> */}

              <li
                className="d-flex"
                onClick={() => {
                  let storedData =
                    JSON.parse(localStorage.getItem("selectedFilterData")) ||
                    [];
                  if (!Array.isArray(storedData)) {
                    storedData = [];
                  }
                  const newFilterData = {
                    type: "series",
                    name: "RX 7000",
                    checked: true,
                    index: 31,
                  };

                  const isDuplicate = storedData.some(
                    (item) =>
                      item.type === newFilterData.type &&
                      item.name === newFilterData.name &&
                      item.index === newFilterData.index
                  );

                  if (!isDuplicate) {
                    storedData.push(newFilterData);
                  }
                  localStorage.setItem(
                    "selectedFilterData",
                    JSON.stringify(storedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RX 7000 Series
              </li>
            </ul>
            <div
              className="filter-img"
              // onClick={(e) => {
              //   handleImageClick(e, "/categories/63ff36fb23ad0386e761641f", 0);
              // }}
            >
              {/* <img src={process.env.REACT_APP_IMAGEBASEURL + miniLeftBanner[0]?.imageName} 
                    alt="" className='w-100' /> */}
              {miniLeftBanner[0]?.imageName ? (
                <img
                  src={miniLeftBanner[0]?.imageName}
                  alt=""
                  className="w-100"
                  style={{ height: "221.6px" }}
                />
              ) : (
                <div className="custom-loader">
                  <svg
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="blue"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </div>
              )}
            </div>
            <div
              className="filter-img"
              onClick={() => {
                let storedData =
                  JSON.parse(localStorage.getItem("selectedFilterData")) || [];
                if (!Array.isArray(storedData)) {
                  storedData = [];
                }
                const newFilterData = {
                  type: "chipset",
                  name: "AMD",
                  checked: true,
                  index: 0,
                };

                const isDuplicate = storedData.some(
                  (item) =>
                    item.type === newFilterData.type &&
                    item.name === newFilterData.name &&
                    item.index === newFilterData.index
                );

                if (!isDuplicate) {
                  storedData.push(newFilterData);
                }
                localStorage.setItem(
                  "selectedFilterData",
                  JSON.stringify(storedData)
                );
                navigate("/categories/63ff36fb23ad0386e761641f");
              }}
            >
              {" "}
              <p className="mt-0 fw-bold">AMD</p>
            </div>
          </div>
          <div className="filter-box ps-sm-1 col-lg-6 col-md-6 text-center">
            <ul className="m-0 p-0 d-flex aling-items-center justify-content-center mb-0">
              {/* <li className='d-flex' onClick={() =>
                    navigate("/categories/63ff36fb23ad0386e761641f/RTX 20")
                  }>RTX 20 Series</li> */}
              {/* <li
                className="d-flex"
                onClick={() => {
                  const selectedData = {
                    type: "series",
                    name: "RTX 30",
                    checked: true,
                    index: 0,
                  };
                  localStorage.setItem(
                    "selectedData",
                    JSON.stringify(selectedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RTX 30 Series
              </li> */}
              <li
                className="d-flex"
                onClick={() => {
                  let storedData =
                    JSON.parse(localStorage.getItem("selectedFilterData")) ||
                    [];
                  if (!Array.isArray(storedData)) {
                    storedData = [];
                  }
                  const newFilterData = {
                    type: "series",
                    name: "RTX 30",
                    checked: true,
                    index: 0,
                  };

                  const isDuplicate = storedData.some(
                    (item) =>
                      item.type === newFilterData.type &&
                      item.name === newFilterData.name &&
                      item.index === newFilterData.index
                  );

                  if (!isDuplicate) {
                    storedData.push(newFilterData);
                  }
                  localStorage.setItem(
                    "selectedFilterData",
                    JSON.stringify(storedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RTX 30 Series
              </li>

              {/* <li
                className="d-flex"
                onClick={() => {
                  const selectedData = {
                    type: "series",
                    name: "RTX 40",
                    checked: true,
                    index: 12,
                  };
                  localStorage.setItem(
                    "selectedData",
                    JSON.stringify(selectedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RTX 40 Series
              </li> */}
              <li
                className="d-flex"
                onClick={() => {
                  let storedData =
                    JSON.parse(localStorage.getItem("selectedFilterData")) ||
                    [];
                  if (!Array.isArray(storedData)) {
                    storedData = [];
                  }
                  const newFilterData = {
                    type: "series",
                    name: "RTX 40",
                    checked: true,
                    index: 12,
                  };

                  const isDuplicate = storedData.some(
                    (item) =>
                      item.type === newFilterData.type &&
                      item.name === newFilterData.name &&
                      item.index === newFilterData.index
                  );

                  if (!isDuplicate) {
                    storedData.push(newFilterData);
                  }
                  localStorage.setItem(
                    "selectedFilterData",
                    JSON.stringify(storedData)
                  );
                  navigate("/categories/63ff36fb23ad0386e761641f");
                }}
              >
                RTX 40 Series
              </li>
            </ul>
            <div
              className="filter-img"
              // onClick={(e) => {
              //   handleImageClick(
              //     e,
              //     "/categories/63ff36fb23ad0386e761641f/Nvidia",
              //     1
              //   );
              // }}
            >
              {/* <img src={process.env.REACT_APP_IMAGEBASEURL + miniRightBanner[0]?.imageName} alt="" className='w-100' /> */}
              {miniLeftBanner[0]?.imageName ? (
                <img
                  src={miniRightBanner[0]?.imageName}
                  alt=""
                  className="w-100"
                  style={{ height: "221.6px" }}
                />
              ) : (
                <div className="custom-loader">
                  <svg
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 0 0"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="blue"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </div>
              )}
            </div>
            <div
              className="filter-img"
              onClick={() => {
                let storedData =
                  JSON.parse(localStorage.getItem("selectedFilterData")) || [];
                if (!Array.isArray(storedData)) {
                  storedData = [];
                }
                const newFilterData = {
                  type: "chipset",
                  name: "Nvidia",
                  checked: true,
                  index: 1,
                };

                const isDuplicate = storedData.some(
                  (item) =>
                    item.type === newFilterData.type &&
                    item.name === newFilterData.name &&
                    item.index === newFilterData.index
                );

                if (!isDuplicate) {
                  storedData.push(newFilterData);
                }
                localStorage.setItem(
                  "selectedFilterData",
                  JSON.stringify(storedData)
                );
                navigate("/categories/63ff36fb23ad0386e761641f");
              }}
            >
              <p className="mt-0 fw-bold">Nvidia</p>
            </div>
          </div>

          <div className="filter-box w-100">
            <BannerCarousel
              bannerImages={bannerImages}
              onClick={(e, id, i) => handleImageClick(e, id, i)}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Banner;
