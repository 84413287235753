import React, { useState, useRef, useEffect } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
  Modal,
  Nav,
  Tab,
} from "react-bootstrap";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../../components/header";
import moment from "moment";
import ExportApi from "../../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { jsPDF } from "jspdf";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { identity } from "lodash";
// import EmojiPicker from 'emoji-picker-react';

export default function OrderDetail() {
  const location = useLocation();

  const product_orderId = location.state?.product_orderId;
 
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  let navigate = useNavigate();
  const params = useParams();
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const [hide, setHide] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [orderId, setOrderId] = useState();
  const [rbtn, setRbtn] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [orderStatusNew, setOrderStatusNew] = useState();
  const [image, setImage] = useState([]);
  const [productName, setProductName] = useState();
  const [type, setType] = useState();
  const [sku, setSku] = useState();
  const [date, setDate] = useState();
  const [subTotal, setSubTotal] = useState();
  const [customRefund, setCustomRefund] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [total, setTotal] = useState();
  const [tax, setTax] = useState();
  const [buyerName, setBuyerName] = useState();
  const [buyerEmail, setBuyerEmail] = useState();
  const [validBuyerEmail, setValidBuyerEmail] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerId, setSellerId] = useState();
  const [orderDaysLate, setOrderDaysLate] = useState();
  const [sellerEmail, setSellerEmail] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [sellerShippingAddress, setSellerShippingAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipCode, setZipCode] = useState();
  const [phone, setPhone] = useState();
  const [sellerPhone, setSellerPhone] = useState();
  const [processingFee, setProcessingFee] = useState();
  const [sellerProcessingFee, setSellerProcessingFee] = useState();
  const [sellerShippingFee, setSellerShippingFee] = useState();
  const [sellerTotalDue, setSellerTotalDue] = useState();
  const [sellerFee, setSellerFee] = useState();
  const [sellerBillingCity, setSellerBillingCity] = useState();
  const [sellerBillingCountry, setSellerBillingCountry] = useState();
  const [sellerBillingState, setSellerBillingState] = useState();
  const [sellerShippingCity, setSellerShippingCity] = useState();
  const [sellerShippingCountry, setSellerShippingCountry] = useState();
  const [sellerShippingState, setSellerShippingState] = useState();
  const [comment, setComment] = useState();
  const [authentication, setAuthentication] = useState(true);
  const [reason, setReason] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState();
  const [authenticationStatus, setAuthenticationStatus] = useState();
  const [sellerShippingZipCode, setSellerShippingZipCode] = useState();
  const [totalMargin, setTotalMargin] = useState();
  const [trackingDetails, setTrackingDetails] = useState();
  const [btnloading, setBtnLoading] = useState(false);
  // const [trackingDetailsFromAdmin, setTrackingDetailsFromAdmin] = useState()
  const [emojiStatus, setEmojiStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showBuyerEmailModel, setShowBuyerEmailModel] = useState(false);
  const [sellerPaymentStatus, setSellerPaymentStatus] = useState();
  //Buyer
  const [buyerShippingAddress, setBuyerShippingAddress] = useState();
  const [buyerBillingAddress, setBuyerBillingAddress] = useState();
  const [buyerCity, setBuyerCity] = useState();
  const [buyerCountry, setBuyerCountry] = useState();
  const [buyerState, setBuyerState] = useState();
  const [buyerBillingCity, setBuyerBillingCity] = useState();
  const [buyerBillingCountry, setBuyerBillingCountry] = useState();
  const [buyerBillingState, setBuyerBillingState] = useState();
  const [buyerZipCode, setBuyerZipCode] = useState();
  const [buyerPhone, setBuyerPhone] = useState();
  const [orderCreateDate, setOrderCreateDate] = useState();

  //Authentication
  const [visualInspection, setVisualInspection] = useState(false);
  const [acousticInspection, setAcousticInspection] = useState(false);
  const [benchMark, setBenchMark] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [waiveFee, setWaiveFee] = useState(false);
  const [chargeId, setChargeId] = useState();
  const [createShipmentStatus, setCreateShipmentStatus] = useState("pass");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonFailLoader, setButtonFailLoader] = useState(false);
  const [fromStreet, setFromStreet] = useState();
  const [fromCity, setFromCity] = useState();
  const [fromState, setFromState] = useState();
  const [fromPhone, setFromPhone] = useState();
  const [fromZipCode, setFromZipCode] = useState();
  const [toStreet, setToStreet] = useState();
  const [toCity, setToCity] = useState();
  const [toState, setToState] = useState();
  const [toPhone, setToPhone] = useState();
  const [toZipCode, setToZipCode] = useState();
  const [validStreet, setValidStreet] = useState();
  const [validCountry, setValidCountry] = useState();
  const [validCity, setValidCity] = useState();
  const [validState, setValidState] = useState();
  const [validPhone, setValidPhone] = useState();
  const [validZipCode, setValidZipCode] = useState();
  const [returnStreet, setReturnStreet] = useState();
  const [returnCity, setReturnCity] = useState();
  const [returnState, setReturnState] = useState();
  const [returnPhone, setReturnPhone] = useState();
  const [returnZipCode, setReturnZipCode] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [width, setWidth] = useState();
  const [shipmentId, setShipmentId] = useState();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [products1, setproducts1] = useState();
  const [productLength, setProductLength] = useState();
  const [previosButtonStatus, setPreviousButtonStatus] = useState(false);
  const [nextButtonStatus, setNextButtonStatus] = useState(false);
  const [authenticationOrderStatus, setAuthenticationOrderStatus] =
    useState(false);
  const [shipmentCreateStatus, setShipmentCreateStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [showCreatePrintLabelLoader, setShowCreatePrintLabelLoader] =
    useState(false);
  const [returnOrder, setReturnOrder] = useState(false);
  const [message, setMessage] = useState(false);
  const [buyerId, setBuyerId] = useState();
  const [addressButtonLoader, setAddressButtonLoader] = useState(false);
  const [showShipingAddressModel, setShowShipingAddressModel] = useState(false);
  const [dropdownMenuShow, setDropdownMenuShow] = useState(false);
  const [actionShow, setActionShow] = useState(false);
  const [refundShow, setRefundShow] = useState(false);
  const [orderDropdownData, setOrderDropdownData] = useState({});
  const [selectedRadioValue, setSelectedRadioValue] = useState(null);

  const [showEmojis, setShowEmojis] = useState(false);
  const [comments, setComments] = useState("");
  const [updateLogo, setUpdateLogo] = useState();
  const [sendAttachment, setSendAttachment] = useState();

  const [showAllOrderComments, setShowAllOrderComments] = useState();
  const [onlyComments, setOnlyComments] = useState(true);
  const [refund, setRefund] = useState();
  const [isLoading1, setLoading1] = useState(false);
  const [isloading2, setIsLoading2] = useState(false);
  const [trackinghistory, setTrackinghistory] = useState([]);
  const [orders, setOrders] = useState();
  const [updatetrackn, setUpdateTrackn] = useState([]);
  // const { orderId } = useParams();
  // const [norderId, setNorderId] = useState(null);

  const [orderNumber, setOrderNumber] = useState();
  const [id, setId] = useState(parseInt(params?.id, 10)); 

  // const [orderIdFromPath, setOrderIdFromPath] = useState(null); 

 
  // useEffect(() => {
  //   const path = window.location.pathname;
  //   const orderId = path.substring(path.lastIndexOf('/') + 1);
  //   setOrderNumber(orderId);

 
  //   if (orderNumber) {
  //     getHandleSingleOrderData(id);
  //   }
  // }, []);

  
  useEffect(() => {
    // Function to fetch data
    const fetchData = () => {
      getHandleSingleOrderData(id);
  
    };

    // Fetch data immediately
    fetchData();

    // Set up interval to fetch data every 20 seconds
    const intervalId = setInterval(fetchData, 20000); // 20 seconds
    return () => {
      clearInterval(intervalId);
  
    };
  }, [id]);

 

  const getHandleSingleOrderData = (id) => {
    ExportApi.getSingleOrderData(id)
      .then((resp) => {
        let data = resp?.data?.Data;
        let rbtnData = resp?.data?.return_button;
        setOrderId(data?.orderId);
        setRbtn(rbtnData);
        let status = data?.deliveryStatusId?.deliveryStatus?.length;
        let index = status - 1;
        setOrderStatus(data?.deliveryStatusId?.deliveryStatus?.status);
        setOrderStatusNew(data?.deliveryStatusId?.deliveryStatusNew?.status);
        setImage(data?.productId?.image[0]);
        setProductName(data?.productId?.productname);
        setType(data?.productId?.type);
        setSku(data?.productId?.sku);
        setDate(data?.createdAt);
        setTotal(data?.bidId?.subTotal);
        //setTotal(data?.askId?.subTotal);
        setSubTotal(data?.bidId?.bidAmount);
        //setSubTotal(data?.askId?.subTotal);
        setCustomRefund(data?.bidId?.subTotal);
        //setCustomRefund(data?.askId?.subTotal)
        setShippingFee(data?.bidId?.shippingFee);

        //setShippingFee(data?.askId?.shippingFee);

        setTax(data?.bidId?.salesTax);
        //setTax(data?.askId?.salesTax);
        setProcessingFee(data?.bidId?.processingFee);
        // setProcessingFee(data?.askId?.processingFee);
        setBuyerName(data?.buyerId?.firstname);
        setBuyerId(data?.buyerId?._id);
        setSellerName(data?.sellerId?.firstname);
        setSellerId(data?.sellerId?._id);
        setBuyerEmail(data?.buyerId?.email);
        setSellerEmail(data?.sellerId?.email);
        setSellerProcessingFee(data?.askId?.processingFee);
        setSellerShippingFee(data?.askId?.shippingFee);
        setBuyerShippingAddress(data?.shipping_address?.street);
        setValidStreet(data?.shipping_address?.street);
        setValidCountry(data?.shipping_address?.country);
        setSellerTotalDue(data?.askId?.subTotal);
        setSellerFee(data?.askId?.transactionFee);
        setBuyerCity(data?.shipping_address?.city);
        setValidCity(data?.shipping_address?.city);
        setBuyerCountry(data?.shipping_address?.country);
        setBuyerState(data?.shipping_address?.state);
        setValidState(data?.shipping_address?.state);
        setSellerShippingCity(data?.sellerId?.shipping_address?.city);
        setSellerShippingState(data?.sellerId?.shipping_address?.state);
        setBuyerZipCode(data?.shipping_address?.postal_code);
        setValidZipCode(data?.shipping_address?.postal_code);
        setBillingAddress(data?.sellerId?.billing_address?.street);
        setSellerShippingAddress(data?.sellerId?.shipping_address?.street);
        setSellerShippingCountry(data?.sellerId?.shipping_address?.country);
        setSellerBillingCountry(data?.sellerId?.billing_address?.country);
        setPhone(data?.buyerId?.phone);
        setSellerPhone(data?.sellerId?.phone);
        setPaymentStatus(data?.payment_details?.status);
        setSellerShippingZipCode(data?.sellerId?.shipping_address?.postal_code);
        setTotalMargin(data?.bidId?.subTotal - data?.askId?.subTotal);
        setBuyerBillingAddress(data?.buyerId?.billing_address?.street);
        setBuyerBillingCity(data?.buyerId?.billing_address?.city);
        setBuyerBillingCountry(data?.buyerId?.billing_address?.country);
        setBuyerBillingState(data?.buyerId?.billing_address?.state);
        setSellerBillingCity(data?.sellerId?.billing_address?.city);
        setSellerBillingState(data?.sellerId?.billing_address?.state);
        setChargeId(data?.bidId?.chargeId);
        setSellerPaymentStatus(data?.payment_transfer_to_seller);
        setLoading(false);

        // Given date in the format "2023-10-29T15:04:01.123Z"
        const givenDateString = data?.createdAt;

        const givenDate = new Date(givenDateString);

    
        // Step 2: Get the current date
        const currentDate = new Date();

        // Step 3: Calculate the time difference in milliseconds with switched dates
        const timeDifference = currentDate - givenDate;

        // Step 4: Convert milliseconds to days
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );

        setOrderDaysLate(daysDifference);
      })
      .catch((err) => {
        console.log(err);
      });
  };

   


  const shippingDtails = [0, 1];
  const hideData = (data) => {
    setHide(data);
  };
  const dropdownRef = useRef();
  const orderidnew = trackingDetails?.orderId?._id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownMenuShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Save radio button value
  const handleRadioChange = (value) => {
    setSelectedRadioValue(value);
  };



  useEffect(() => {
    if (
      orderStatus == "Shipped From Seller" ||
      "Shipped Out" ||
      "Ready For Shipment"
    ) {
      trackingStatus();
    }
  }, [orderStatus]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        orderStatus === "Shipped From Seller" ||
        orderStatus === "Shipped Out" ||
        orderStatus === "Ready For Shipment"
      ) {
        
        trackingStatus();
      }
    }, 15000); 
  
    return () => clearInterval(intervalId);
  }, [orderStatus]);

  const trackingStatus = () => {
  
    const currentTime = new Date().toISOString();
    const data = {
      event: "track_updated",
      test: true,
      data: {
        address_from: {
          city: "San Francisco",
          state: "CA",
          zip: "94103",
          country: "US",
        },
        address_to: {
          city: "Chicago",
          state: "IL",
          zip: "60611",
          country: "US",
        },
        tracking_status: {
          object_created: currentTime,
          object_updated: null,
          object_id: "b60735c78ae34b6aa6eb721011476494",
          status: "DELIVERED",
          status_details: "Your shipment has been delivered.",
          status_date: currentTime,
          substatus: null,
          location: {
            city: "Chicago",
            state: "IL",
            zip: "60611",
            country: "US",
          },
        },
        tracking_number: "SHIPPO_DELIVERED",
        tracking_history: [
          {
            status: "DELIVERED",
            status_date: currentTime,
            location: {
              city: "Chicago",
              state: "IL",
              zip: "60611",
              country: "US",
            },
          },
        ],
        carrier: "shippo",
        eta: currentTime,
        original_eta: currentTime,
        servicelevel: {
          token: "shippo_priority",
          name: null,
        },
        metadata: "Shippo test webhook",
        transaction: null,
        messages: [],
        test: true,
      },
      carrier: "shippo",
    };
    ExportApi.trackingwebhook(data)
      .then((resp) => {
    

        const trackingData = [
          resp?.data?.data
        ];

        setTrackinghistory(trackingData);
      })
      .catch((err) => {
        console.error("Error in trackingAllStatus:", err);
      });
  };

  // useEffect(() => {
  //   if (orderStatus == "Shipped From Seller") {
  //     updatetrackingStatus();
  //   }
  // }, [orderStatus]);
  useEffect(() => {
    // Define a function to check the status and call updatetrackingStatus
    const checkStatusAndUpdate = () => {
      if (orderStatus === "Shipped From Seller") {
        updatetrackingStatus();
      }
    };
  
    // Initial check when component mounts
    checkStatusAndUpdate();
  
    // Set up an interval to check status every 20 seconds
    const intervalId = setInterval(checkStatusAndUpdate, 15000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [orderStatus]); 
  
  //updatetracking

  const updatetrackingStatus = () => {
    const data = {
      orderid: orderId,
      trackstatus: "DELIVERED",
    };
    ExportApi.updatetracking(data)
      .then((resp) => {
        setUpdateTrackn(resp)
    
      })
      .catch((err) => {
        console.error("Error in trackingAllStatus:", err);
      });
  };

  //handle checkbox for the UPS services
  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value === selectedOption ? "" : value);
  };

  // Start handle Authentication
  const handleAuthentication = (status, index, data) => {
    if (data == "visual") {
      if (status) {
        reason.splice(index, 0, "Visual Authentication");
        setReason([...reason]);
      } else {
        reason.splice(index, 1);
        setReason([...reason]);
      }
    } else if (data == "Acoustic") {
      if (status) {
        reason.splice(index, 0, "Acoustic Authentication");
        setReason([...reason]);
      } else {
        reason.splice(index, 1);
        setReason([...reason]);
      }
    } else if (data == "benchmark") {
      if (status) {
        reason.splice(index, 0, "benchmark");
        setReason([...reason]);
      } else {
        reason.splice(index, 1);
        setReason([...reason]);
      }
    } else if (data == "attachment") {
      if (status) {
        reason.splice(index, 0, "attachment");
        setReason([...reason]);
      } else {
        reason.splice(index, 1);
        setReason([...reason]);
      }
    }
  };

  // window.addEventListener("Loginout", () => {
  //   localStorage.clear();
  //   navigate("/");
  // });
  //handle Pass Authentication

  //download pdf for return order
  const handleDownloadReturnPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const tableContent = document.getElementById("returnn").innerHTML;
    doc.html(tableContent, {
      callback: () => {
        doc.save("Return.pdf");
      },
    });
  };

  // Start Handle Order Tarcking
  const handleOrderTracking = (onlyComments) => {
    ExportApi.getOrderTracking(params?.id)
      .then((resp) => {
        setRefund(resp?.data?.Data?.deliveryStatusForBuyer?.status);
        let data = resp?.data?.Data;
        setTrackingDetails(data);
        getOrderDetailsData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    ExportApi.getCommentsonOrder(params?.id)
      .then((resp) => {
        let data = resp?.data;
        if (onlyComments === true) {
          const onlyCustom = data?.detail.filter(
            (item) => item.type === "custom" || item.type === ""
          );
          setShowAllOrderComments(onlyCustom);
        } else {
          const onlyCustomn = data?.detail.filter((item) => item.type === "");
          setShowAllOrderComments(onlyCustomn);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // start Handle Pass Authentication
  const handlePassAuthentication = () => {
    setButtonLoader(true);
    // setShowLoader(true)
    ExportApi.PassAuthentication(
      reason,
      comment,
      waiveFee,
      "pass",
      "Ready For Shipment",
      "Sale Complete",
      orderidnew
      // params.id
    )

      .then((resp) => {
        if (resp?.data?.message) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setAuthentication(true);
          toast.success(resp?.data?.message);
          setCreateShipmentStatus("pass");
          setAuthenticationOrderStatus(true);
          setShow(false);
          setButtonLoader(false);
          // setShowLoader(false)
          getHandleSingleOrderData(id);
          handleOrderTracking(onlyComments);
        }
        //  else {
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 1000);
        //   toast.success(resp.data.message)
        //   setButtonLoader(false);
        //   // setShowLoader(false)
        //   setShow(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setButtonLoader(false);
        toast.error("Something Went Wrong!!!");
        setShow(false);
      });
  };

  //handle Fail Authentication
  const handleFailAuthentication = () => {
    setButtonFailLoader(true);
    // setShowLoader(true)
    // ExportApi.FailAuthentication(reason, comment, waiveFee, "fail", params.id)
    ExportApi.FailAuthentication(reason, comment, waiveFee, "fail", orderidnew)
      .then((resp) => {
        if (resp?.data?.message == "not  authenticated") {
          setTimeout(() => {
            window.location.reload();
          }, 100);
          setAuthentication(false);
          toast.error(resp?.data?.message);
          setShow(false);
          setCreateShipmentStatus("fail");
          setAuthenticationOrderStatus(true);
          setButtonFailLoader(false);
          // setShowLoader(false)
          handleDownloadReturnPdf();
          getHandleSingleOrderData(id);
          handleOrderTracking(onlyComments);

          window.open(resp?.data?.Data?.postage_label?.label_url, "_blank");
        } else {
          toast.error(resp?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setButtonFailLoader(false);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShow(false);
        setButtonFailLoader(false);
        toast.error("Something went Wrong !!!");
        // window.dispatchEvent(new Event("Loginout"));
      });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await ExportApi.getallorders();
        setOrders(response?.data?.data);
         
      } catch (error) {
        console.error('Error fetching orders:', error);
        // Handle error as needed, e.g., show error message
      }
    };

    fetchOrders();
  }, []);

 
  //to get the previous order
  // const handlePreviousOrder = () => {
  //   // setShowLoader(true);
  //   let index = Math.random() * productLength;
  //   let ProperNumber;
  //   if (index < 1) {
  //     ProperNumber = Math.floor(0);
  //   } else {
  //     ProperNumber = Math.floor(index - 1);
  //   }
  //   let number = products1[ProperNumber];
  //   let id = number?.orderId;
  //   // navigate("/OrderDetail/" + id);
  //   // setTimeout(() => {
  //   //   setShowLoader(false);
  //   // }, 1000);
  // };


  const handlePreviousOrder = () => {
    const currentOrderId = parseInt(orderId, 10);

    const previousOrderId = currentOrderId - 1;
  
     
    const previousOrder = orders.find(order => order?.orderId === previousOrderId);
  
    if (previousOrder) {
      const previousOrderMongoId = previousOrder._id;
      navigate(`/OrderDetail/${previousOrderId}`);
      getHandleSingleOrderData(previousOrderMongoId);
      fetchOrderDetails();
      window.location.reload();
    } else {
      console.log('Previous order not found');
    }
  };
  
  const handleNextOrder = () => {

    const currentOrderId = parseInt(orderId, 10);
    const previousOrderId = currentOrderId + 1;
    const NextOrder = orders.find(order => order.orderId === previousOrderId);

    if (NextOrder) {
      navigate(`/OrderDetail/${previousOrderId}`);
      const previousOrderMongoId = NextOrder._id;
      navigate(`/OrderDetail/${previousOrderId}`);
      getHandleSingleOrderData(previousOrderMongoId);
      fetchOrderDetails();
      window.location.reload();
    } else {
      console.log('Next order ID not found');
      
    }
  };
  
  // const handleNextOrder = () => {
  //   setShowLoader(true);
  //   let index = Math.random() * productLength;
  //   let ProperNumber;
  //   if (index < 1) {
  //     ProperNumber = Math.floor(0);
  //   } else {
  //     ProperNumber = Math.floor(index - 1);
  //   }
  //   let number = products1[ProperNumber];
  //   let id = number?._id;
  //   navigate("/OrderDetail/" + id);
  //   setTimeout(() => {
  //     setShowLoader(false);
  //   }, 1000);
  // };

 
  // start Handle Shipping Label
  const handleShippingLabel = () => {
    // if (selectedOption == "" || selectedOption == null) {
    //   toast.error("Please select shipping option")
    // } else {
    if (alreadyCreatedShippingLabel == null) {
      ExportApi.updateShippingStatus(
        orderidnew,
        shipmentId,
        selectedOption,
        "Shipped Out"
      )
        .then((resp) => {
          if (resp.data.message == "Buy shipment successfully") {
            toast.success(resp?.data?.message);
            setShow1(false);
            setShipmentCreateStatus(true);
            DownloadPdf();
            window.open(resp?.data?.Data?.postage_label?.label_url, "_blank");
          } else {
            toast.error(resp?.data?.message);
            setShow1(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // }
    } else {
      setButtonLoader(false);
      window.open(alreadyCreatedShippingLabel, "_blank");
      DownloadPdf();
    }
  };

  // Start Handle Shipping Fail Label
  // const handleShippingFailLabel = () => {
  //   ExportApi.updateShippingStatus(params.id, "Return ")
  //     .then((resp) => {
  //       setShow1(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  //End Handle Shipping Fail Label

  // const handleauthenticatestatus = () => {
  //   ExportApi.getAuthenticateStatus(params.id).then((resp) => {
  //     let data = resp.data.Data;
  //     // setAuthenticationStatus(data?.authStatus[0]?.status)
  //     setLoading(false)
  //   }).catch((err) => {
  //     console.log(err);
  //   })
  // }

  //Handle Place with new Buyer
  const handlePlaceBuyerWithNewSeller = () => {
    setShowLoader(true);
    ExportApi.handlewithnewseller(params?.id)
      .then((resp) => {
        if (resp?.data?.message == "new seller assigned successfully") {
          toast.success(resp?.data?.message);
          setShowLoader(false);
        } else {
          toast.error(resp?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //handle to initiate refund request
  const handleRefund = (amount, selectedRadioValue) => {

    setIsLoading2(true);
    setActionShow(true);
    // setBtnLoading(true);
    if (!amount || amount == 0) {
      toast.error("Refund amount is required");
      // setBtnLoading(false);
      setIsLoading2(false);
      return;
    }
    if (amount > total) {
      toast.error("Refund amount should not be greater than the Order Amount");
      // setBtnLoading(false);
      setIsLoading2(false);
      return;
    }
    // setShowLoader(true)
    ExportApi.handlebuyerRefund(
      orderidnew,
      chargeId,
      amount,
      selectedRadioValue
    )
      .then((resp) => {
        if (resp.data["statusCode"] == 500) {
          // toast.error(resp.data.message)
          toast.error("This request is already refunded");
          // setBtnLoading(false);
          setIsLoading2(false);
          setActionShow(false);
        } else {
          if (resp?.data?.message == "Payment Send Successfully") {
            toast.success(resp?.data?.message);
            setRefundShow(false);
            setCustomRefund("");
            // setShowLoader(false)
            // setBtnLoading(false);
            setIsLoading2(false);
            setActionShow(false);
            handleOrderTracking(onlyComments);
            getHandleSingleOrderData(id);
          } else {
            if (resp?.data?.message == message) {
              setMessage(true);
              toast.error(resp.data.message);
              setShowLoader(false);
              // setBtnLoading(false);
              setIsLoading2(false);
              setActionShow(false);
            } else {
              setMessage(false);
              // setShowLoader(false)
              // setBtnLoading(false);
              setIsLoading2(false);
              setActionShow(false);
            }
            getHandleSingleOrderData(id);
            // setBtnLoading(false);
            setIsLoading2(false);
            setActionShow(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.message);
        // setBtnLoading(false);
        setIsLoading2(false);
      });
  };

  //get the order data
  const handleOrderPagination = () => {
    ExportApi.getOrderPaginationData()
      .then((resp) => {
        let data = resp?.data?.Data;
        setproducts1(data);
        setProductLength(data?.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [alreadyCreatedShippingLabel, setAlreadyCreatedShippingLabel] =
    useState();
  //craete shipping label data
  const handleCreateShippingLabel = () => {
    handleShow1();
    setShowModalLoader(true);
    setShowCreatePrintLabelLoader(true);
    //ExportApi.getCreateLabelData(params.id).then((resp) => {
    ExportApi.getCreateLabelData(orderidnew)
      .then((resp) => {
        if (resp?.data?.message == "Label created sucessfully") {
          let data = resp?.data?.Data;

  
          let rateId = data?.rates.filter(
            (item) => item.service == process.env.REACT_APP_EASYPOST_SERVICE
          );
      
          // return
          setFromStreet(data?.addressFrom?.street1);
          setFromCity(data?.addressFrom?.city);
          setFromState(data?.addressFrom?.state);
          setFromZipCode(data?.addressFrom?.zip);
          setFromPhone(data?.addressFrom?.phone);
          setToStreet(data?.addressTo?.street1);
          setToCity(data?.addressTo?.city);
          setToState(data?.addressTo?.state);
          setToZipCode(data?.addressTo?.zip);
          setToPhone(data?.addressTo?.phone);
          setValidStreet(data?.addressTo?.street1);
          setValidCountry(data?.addressTo?.country);
          setValidCity(data?.addressTo?.city);
          setValidState(data?.addressTo?.state);
          setValidZipCode(data?.addressTo?.zip);
          setValidPhone(data?.addressTo?.phone);
          setReturnStreet(data?.return_address?.street1);
          setReturnCity(data?.return_address?.city);
          setReturnState(data?.return_address?.state);
          setReturnZipCode(data?.return_address?.zip);
          setReturnPhone(data?.return_address?.phone);
          setLength(18);
          setHeight(6);
          setWeight(128);
          setWidth(13);
          setPaymentOptions(data?.rates);
          setShipmentId(data?.id);
          setSelectedOption(rateId[0]?.id);
          setOrderCreateDate(data?.created_at);
          setReturnOrder(data?.is_return);
          setShowModalLoader(false);
          setShowCreatePrintLabelLoader(false);
          setAlreadyCreatedShippingLabel(resp?.data?.ShipLevelbyAdmin);
        } else {
          toast.error(resp?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  //update the shipping address
  const handleUpdateShippingAddress = () => {
    if (
      validStreet.trim() === "" ||
      validCity.trim() === "" ||
      validState.trim() === "" ||
      validZipCode.trim() === "" ||
      validCountry.trim() === ""
    ) {
      toast.error("Address fields cannot be empty or contain only spaces");
      return;
    }
    if (validZipCode && validZipCode.length !== 5) {
      toast.error("Postal Code must be exactly 5 digits");
      return;
    }
    setAddressButtonLoader(true);
    ExportApi.updateOrderShippingAddress(
      params.id,
      validStreet,
      validCity,
      validState,
      validZipCode,
      validCountry
    ).then((resp) => {
      if (resp?.data?.message == "Order Updated Successfully") {
        toast.success("Shipping Address Updated Successfully");
        getHandleSingleOrderData(id);
        setShowShipingAddressModel(false);
        setAddressButtonLoader(false);
      } else {
        toast.error(resp?.data?.message);
        setShowShipingAddressModel(false);
        setAddressButtonLoader(false);
      }
    });
  };

  //update Buyer Email Address
  const handleEmail = (value) => {
    setValidBuyerEmail(value);
    setIsValidEmail(true);
  };

  //validate the email
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleUpdateEmail = () => {
    if (validBuyerEmail == "" || validBuyerEmail == null) {
      toast.error("Please Enter Email");
    } else if (!validateEmail(email)) {
      toast.error("Please Enter Valid Email");
      setLoading(false);
    } else {
      ExportApi.updateEmail(params.id, email)
        .then((resp) => {
          if (resp.data.message == "Data updated sucessfully") {
            toast.success("Email Updated Successfully");
            setShowUserModal(false);
            setBuyerEmail(resp?.data?.result?.email);
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  
  
  //download the pdf for order
  const DownloadPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const tableContent = document.getElementById("werkk").innerHTML;
    doc.html(tableContent, {
      callback: () => {
        doc.save("Product.pdf");
      },
    });
  };

  //to get the next order
 

  useEffect(() => {
    // handleauthenticatestatus()
    handleOrderTracking(onlyComments);
    handleOrderPagination();
  }, []);

  // useEffect(() => {
  //   getHandleSingleOrderData();
  // }, [params.id]);


  function fetchOrderDetails() {
    ExportApi.getOrderDetailsData(params?.id)
      .then((resp) => {
        setOrderDropdownData(resp?.data?.data);
        getHandleSingleOrderData(id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchOrderDetails(params?.id);
  }, [params?.id]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      </div>
    );
  }
  //change seller
  // const handleProceedButton = () => {
  //   setLoading(true);
  //   ExportApi.changeSeller(params?.id , selectedRadioValue , orderDropdownData?.newAskId , orderDropdownData?.newSellerId)
  //   .then((resp) =>{
  //     if (resp?.data?.message == "new seller assigned successfully") {
  //       toast.success(resp?.data?.message);
  //       fetchOrderDetails(params?.id);
  //       setActionShow(false);
  //       setShow(false);
  //       setSelectedRadioValue(false);
  //     }
  //   })
  //   .catch((err) => {
  //     toast.error(err);
  //     setActionShow(false);
  //     setShow(false);
  //   });
  // }
  const handleProceedButton = () => {
    setLoading1(true); // Set loading to true when starting the API call
    // trackingDetails
    // params?.id
    ExportApi.changeSeller(
      trackingDetails,
      selectedRadioValue,
      orderDropdownData?.newAskId,
      orderDropdownData?.newSellerId
    )
      .then((resp) => {
        if (resp?.data?.message === "new seller assigned successfully") {
          toast.success(resp?.data?.message);
          fetchOrderDetails(params?.id);
          setActionShow(false);
          setShow(false);
          setSelectedRadioValue(false);
          handleOrderTracking(onlyComments);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => {
        setLoading1(false); // Set loading to false whether the request succeeds or fails
      });
  };

  // const handleRefundButton = () =>{
  //   ExportApi.changeSeller(params?.id , selectedRadioValue , orderDropdownData?.newAskId , orderDropdownData?.newSellerId)
  //   .then((resp) =>{
  //     if (resp?.data?.message == "new seller assigned successfully") {
  //       toast.success(resp?.data?.message);
  //       setActionShow(false)
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // }

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setComments(comments + emoji);
  };

  const showOnlyComments = () => {
    const updatedValue = !onlyComments;
    setOnlyComments(updatedValue);
    handleOrderTracking(updatedValue);
  };

  // const showOnlyComments = (data) => {
  //   setOnlyComments(!onlyComments)
  //   if (data) {
  //     const onlyManual = showAllOrderComments.filter((item) => item?.type == "custom");
  //     setShowAllOrderComments(onlyManual);
  //   } else {
  //     handleOrderTracking(onlyComments);
  //   }
  // }
  const saveComment = () => {
    const cmt = comments?.trim();
    const formData = new FormData();
    if (!cmt) {
      toast.error("Comment is required");
      return;
    }
    formData.append("status", comments);
    formData.append("orderId", orderidnew);


    if (sendAttachment) {
      formData.append("image", sendAttachment);
    }

    ExportApi.addCommentsonOrder(formData)
      .then((resp) => {
        handleOrderTracking(onlyComments);
        toast.success("Commented Successfully");
        setShowEmojis(false);
        setComments("");
      })
      .catch((err) => {
        toast.error("Failed to comment");
      });
  };

  const convertTime = (time) => {
    const utcTime = new Date(`1970-01-01T${time}`);
    // Convert to PST (Pacific Standard Time) and get the time string
    const pstTime = utcTime.toLocaleTimeString("en-US", {
      timeZone: "America/Los_Angeles",
      hour12: true,
    });
    return pstTime;
  };


  return (
    <div>
      <ToastContainer />
      {/* <button onClick={DownloadPdf}>Download</button> */}
      <div id="werkk" style={{ display: "none" }}>
        <div className="pdf-content">
          <div className="pdf-header">
            <span>GPUWIZ</span>
          </div>
          <hr />
          <div className="center-text m-30 order-condition">
            <h2>Order: {params.id}</h2>
            <p>{productName}</p>
            <span>
              Condition: {type == 1 ? "New" : type == 2 ? "2nd Hand" : "Used"}
            </span>
            <span>MPN: {sku}</span>
          </div>
          <hr />
          <div className="m-30 shipping-instruction center-text">
            <h4>Congratulations on your purchase!</h4>
            <p>
              This GPU has passed our stringent inspection requirements, testing
              requirements, and has been verified to meet our quality standards.
            </p>
            <input
              type="checkbox"
              id="vehicle1"
              name="benchmark passed"
              value=""
              checked
            />
            <label for="benchmark passed"> Benchmark Passed</label>
            <br />
            <input
              type="checkbox"
              id="vehicle2"
              name="inspection passed"
              value=""
              checked
            />
            <label for="inspection passed"> Inspection Passed</label>
          </div>
        </div>
      </div>

      <div id="returnn" style={{ display: "none" }}>
        <div className="pdf-content">
          <div className="pdf-header">
            <span>GPUWIZ</span>
          </div>
          <hr />
          <div className="center-text m-30 order-condition">
            <h2>Order: {params?.id}</h2>
            <p>{productName}</p>
            <span>
              Condition: {type == 1 ? "New" : type == 2 ? "2nd Hand" : "Used"}
            </span>
            <span>MPN: {sku}</span>
          </div>
          <hr />
          <div className="m-30 shipping-instruction">
            <h4>
              This item has been returned because it did not meet our Quality
              Standards
            </h4>
            <p>
              We don't want any items to fail inspection. When this happens the
              Buyer is upset because their order is delayed or canceled, the
              Seller is upset because they are charged a fee and get their item
              returned, and we are upset because we failed to facilitate a
              successful transaction.
            </p>
            <p>
              So we put together a Pre-Inspection guide for sellers that will
              greatly reduce the chance of an item not passing. Check it out
              before listing items for sale!
            </p>
          </div>
        </div>
      </div>

      {showLoader ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <div>
          <Header hideData={hideData} />
          <div className="container">
            <Row className="py-3 mt-5 mb-3">
              <Col lg={6}>
                <div className="d-sm-flex gap-3 align-items-center">
                  <Button
                    variant="outline-secondary"
                    className="bg-none"
                    onClick={() => navigate("/orderView")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="#000"
                      className="bi bi-arrow-left-short"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                      />
                    </svg>
                  </Button>
                  <h5 className="fs-2 mb-0">{orderId}</h5>
                  <span className="fw-bold bg-custom-light py-1 px-3 rounded-pill">
                    {paymentStatus} 
                  </span>
                  {orderStatus == "Waiting on Seller" ? (
                    <Button
                      className="order_status_waiting border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Shipped From Seller" ? (
                    <Button
                      className="order_status_shipped_seller border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Ready For Test" ? (
                    <Button
                      className="order_status_ready_test border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Ready For Shipment" ? (
                    <Button
                      className="order_status_ready_shipment border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Shipped Out" ? (
                    <Button
                      className="order_status_shipped_out border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Recently Delivered" ? (
                    <Button
                      className="order_status_recently_delivered border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Return" ? (
                    <Button
                      className="order_status_return border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : orderStatus == "Refunded" ? (
                    <Button
                      className="order_status_refunded border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  ) : (
                    <Button
                      className="order_status_return border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      {orderStatus}
                    </Button>
                  )}
                  {rbtn === true && (
                    <Button
                      className="order_status_return border text-white"
                      style={{ minWidth: "fit-content" }}
                    >
                      Ready For Return Shipment
                    </Button>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-sm-flex gap-3 align-items-center justify-content-end">
                  {refund === "Refunded" ? (
                    <Button
                      className="bg-none border-0 text-custom-light fw-bold "
                      // disabled={message}
                    >
                      Refund
                    </Button>
                  ) : (
                    <Button
                      className="bg-none border-0 text-dark fw-bold"
                      disabled={message}
                      onClick={() => handleRefund(total)}
                    >
                      Refund
                    </Button>
                  )}
                  {/* <Button className="bg-none border-0 text-dark fw-bold" disabled>Edit</Button> */}

                  <Dropdown
                    show={dropdownMenuShow}
                    onToggle={() => {}}
                    ref={dropdownRef}
                  >
                    <Dropdown.Toggle
                      className="border-0 bg-none text-dark fw-bold"
                      id="dropdown-basic"
                      onClick={() => {
                        setDropdownMenuShow(!dropdownMenuShow);
                        setActionShow(false); // Hide actionShow when Dropdown.Toggle is clicked
                        setRefundShow(false);
                      }}
                    >
                      More action
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className={`mt-2 ${dropdownMenuShow ? "show" : ""}`}
                    >
                      <Dropdown.Item href="#" disabled>
                        Manual Payout to Sellout
                      </Dropdown.Item>
                      <Dropdown.Item href="#" disabled>
                        Flag Order
                      </Dropdown.Item>
                      <Dropdown.Item href="#" disabled>
                        Send Payment Request
                      </Dropdown.Item>
                      {refund === "Refunded" ? (
                        <Dropdown.Item href="#" disabled>
                          Place Buyer with new seller
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          href="#"
                          disabled={
                            paymentStatus == "Refunded" ||
                            orderStatus == "Placed with New Seller" ||
                            orderStatus == "Ready for Return Shipment"
                              ? true
                              : false
                          }
                          onClick={() => {
                            setActionShow(!actionShow);
                            actionShow ? setRefundShow(false) : "";
                            setDropdownMenuShow(false);
                          }}
                        >
                          Place Buyer with new seller
                        </Dropdown.Item>
                      )}

                      {refund === "Refunded" ? (
                        <Dropdown.Item
                          href="#"
                          // disabled={ paymentStatus == "Refunded" || orderStatus == "Placed with New Seller" || orderStatus == "Ready for Return Shipment" ? true : false} onClick={() => { setRefundShow(!refundShow); setDropdownMenuShow(false); refundShow ? setActionShow (false) : ''; }}
                          disabled
                        >
                          Refund
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          href="#"
                          disabled={
                            paymentStatus == "Refunded" ||
                            orderStatus == "Placed with New Seller" ||
                            orderStatus == "Ready for Return Shipment"
                              ? true
                              : false
                          }
                          onClick={() => {
                            setRefundShow(!refundShow);
                            setDropdownMenuShow(false);
                            refundShow ? setActionShow(false) : "";
                          }}
                        >
                          Refund
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* Place New Seller Modal */}
                  {actionShow && (
                    <div
                      className="action-container border p-3 bg-white"
                      style={{
                        minWidth: 240,
                        position: "absolute",
                        zIndex: 9,
                        top: 235,
                      }}
                    >
                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Old Seller: </p>
                        <Link to={`/customerDetail/${sellerId}`}>
                          <p className="mb-0">{sellerName}</p>
                        </Link>
                      </div>
                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Days Late: </p>
                        <p className="mb-0">{orderDaysLate} Days</p>
                      </div>
                      <div className="action-box d-flex justify-content-between mb-1 mt-4">
                        <p className="mb-0">New Seller: </p>
                        {orderDropdownData?.newSellerName ? (
                          <Link
                            to={`/customerDetail/${orderDropdownData?.newSellerId}`}
                          >
                            <p className="mb-0">
                              {orderDropdownData?.newSellerName}
                            </p>
                          </Link>
                        ) : (
                          <p className="mb-0">No seller found</p>
                        )}
                      </div>
                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Ask: </p>
                        <p className="mb-0">
                          {orderDropdownData?.newAsk
                            ? "$" + orderDropdownData?.newAsk
                            : "-"}
                        </p>
                      </div>

                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Ask Age: </p>
                        <p className="mb-0">
                          {orderDropdownData?.newAskAge
                            ? orderDropdownData?.newAskAge + "Days"
                            : "-"}{" "}
                        </p>
                      </div>

                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Price Difference: </p>
                        <ReactTooltip
                          className="custom-tooltip_buy-sell"
                          anchorId="app-batch-buying-tab"
                          place="bottom"
                          content=<div
                            dangerouslySetInnerHTML={{
                              __html:
                                "<p>Price difference = (New Sell Price) - (Previous sell Price) </p>",
                            }}
                          />
                          style={{ textTransform: "none" }}
                        />
                        <svg
                          id="app-batch-buying-tab"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-question-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                        </svg>
                        <p className="mb-0">
                          {orderDropdownData?.priceDifference
                            ? "$" + orderDropdownData?.priceDifference
                            : "-"}
                        </p>
                      </div>

                      <div className="action-box d-flex justify-content-between mb-1">
                        <p className="mb-0">Service Fee: </p>
                        <ReactTooltip
                          className="custom-tooltip_buy-sell"
                          anchorId="app-batch-buying-tab1"
                          place="bottom"
                          content=<div
                            dangerouslySetInnerHTML={{
                              __html:
                                "<p>Service Fees = Testing Fees + Selling Fee + Processing Fee </p>",
                            }}
                          />
                          style={{ textTransform: "none" }}
                        />
                        <svg
                          id="app-batch-buying-tab1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-question-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                        </svg>
                        <p className="mb-0">
                          {orderDropdownData?.serviceFee
                            ? "$" + orderDropdownData?.serviceFee
                            : "-"}
                        </p>
                      </div>

                      <div className="mx-auto mt-3" style={{ maxWidth: "80%" }}>
                        <div className="action-check d-flex align-items-center mb-1">
                          <input
                            type="radio"
                            className="form-input"
                            id="cancelled"
                            name="orderStatus"
                            onChange={() => handleRadioChange(1)}
                            checked={selectedRadioValue === 1}
                          />
                          <label
                            for="cancelled"
                            className="form-label ms-1 mb-0"
                            style={{ fontSize: 13 }}
                          >
                            Order Cancelled by Seller
                          </label>
                        </div>

                        <div className="action-check d-flex align-items-center mb-1">
                          <input
                            type="radio"
                            className="form-input"
                            id="ship-failed"
                            name="orderStatus"
                            onChange={() => handleRadioChange(2)}
                            checked={selectedRadioValue === 2}
                          />
                          <label
                            for="ship-failed"
                            className="form-label ms-1 mb-0"
                            style={{ fontSize: 13 }}
                          >
                            Failed to Ship
                          </label>
                        </div>

                        <div className="action-check d-flex align-items-center mb-1">
                          <input
                            type="radio"
                            className="form-input"
                            id="authenticate-failed"
                            name="orderStatus"
                            onChange={() => handleRadioChange(3)}
                            checked={selectedRadioValue === 3}
                          />
                          <label
                            for="authenticate-failed"
                            className="form-label ms-1 mb-0"
                            style={{ fontSize: 13 }}
                          >
                            Authentication Failed
                          </label>
                        </div>
                        <div className="refund-buttons">
                          <Button
                            variant="success"
                            //onClick={()=>handleProceedButton()}
                            onClick={handleProceedButton}
                            //disabled={ selectedRadioValue && orderDropdownData?.previousSellerDetailName ? false : true}
                            disabled={
                              isLoading1 ||
                              !selectedRadioValue ||
                              !orderDropdownData?.newAskId ||
                              !orderDropdownData?.newSellerId
                            }
                          >
                            {/* Place Buyer w/ New Seller */}
                            {isLoading1
                              ? "Please Wait................"
                              : "Place Buyer w/ New Seller"}
                          </Button>

                          {refund === "Refunded" ? (
                            <Button
                              variant="success"
                              // onClick={()=>handleRefund(total)}
                              disabled={
                                orderDropdownData?.previousSellerDetailName
                                  ? true
                                  : false
                              }
                            >
                              Refund
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              onClick={() =>
                                handleRefund(total, selectedRadioValue)
                              }
                              disabled={
                                orderDropdownData?.previousSellerDetailName
                                  ? true
                                  : false
                              }
                            >
                              {isloading2 ? "Loading...." : "Refund"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Custom refund Amount Modal */}
                  {refundShow && (
                    <div
                      className="action-container border p-3 bg-white"
                      style={{
                        minWidth: 240,
                        position: "absolute",
                        zIndex: 9,
                        top: 235,
                      }}
                    >
                      Refund Buyer__
                      <Form.Control
                        type="number"
                        placeholder="Search GPU"
                        value={customRefund}
                        onChange={(e) => setCustomRefund(e.target.value)}
                      />
                      <Button onClick={() => handleRefund(customRefund)}>
                        {btnloading ? "Loading..." : "Refund"}
                      </Button>
                    </div>
                  )}

                  <ButtonGroup
                    aria-label="Basic example"
                    className="border border-dark"
                  >
                    <Button
                      variant=""
                      className="border-end"
                      onClick={handlePreviousOrder}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    </Button>
                    <Button variant="" onClick={handleNextOrder}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col lg={9}>
                <div className="bg-white border p-5">
                  <h5 className="fw-bold mb-3">Product Info</h5>
                  <Table>
                    <tbody>
                      <tr className="border-0 border-white">
                        <td>
                          <div className="d-flex gap-5">
                            <img
                              // src={`${imageUrl}${image}`}
                              src={
                                image?.includes("http")
                                  ? image
                                  : `${imageUrl}${image}`
                              }
                              style={{ width: "150px", height: "100px" }}
                            />
                            <div>
                              <h6 className="mb-0">{productName}</h6>
                              <span>
                                {type == 1
                                  ? "New"
                                  : type == 2
                                  ? "2nd Hand"
                                  : "Used"}
                              </span>
                              <p className="mb-0 fw-bold text-muted">
                                MPN: {sku}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="fw-bold">{moment(date).format("l")}</td>
                        <td className="fw-bold">${subTotal}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <h5 className="fw-bold  my-3">Payment Info</h5>
                  <Table className="payment-table">
                    <tbody>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Subtotal</td>
                        <td className="fw-500">{"$" + subTotal}</td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Shipping</td>
                        <td className="fw-500">{"$" + shippingFee}</td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Testing Fee</td>
                        <td className="fw-500">$25</td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Processing Fee</td>
                        <td className="fw-500">${processingFee}</td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Taxes (9%)</td>
                        <td className="fw-500">${tax}</td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-bold">Total</td>
                        <td className="fw-bold">
                          ${total}
                          {paymentStatus == "Pending" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="red"
                              className="bi bi-x-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="green"
                              className="bi bi-check-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table className="mt-3 payment-table">
                    <tbody>
                      <tr className="border-0 border-white">
                        <td className="fw-500">9.5% Seller Fee</td>
                        <td className="fw-500">
                          {sellerFee ? "$" + sellerFee : null}
                        </td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Payment Processing Fee 3%</td>
                        <td className="fw-500">
                          {sellerProcessingFee
                            ? "$" + sellerProcessingFee
                            : null}
                        </td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-500">Shipping</td>
                        <td className="fw-500">
                          {sellerShippingFee ? "$" + sellerShippingFee : null}
                        </td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-bold">Total due to seller</td>
                        <td className="fw-bold">
                          {sellerTotalDue ? "$" + sellerTotalDue : null}
                          {sellerPaymentStatus == "Pending" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="red"
                              className="bi bi-x-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="green"
                              className="bi bi-check-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                          )}
                        </td>
                      </tr>
                      <tr className="border-0 border-white">
                        <td className="fw-bold">Total Margin</td>
                        <td className="fw-bold">
                          {totalMargin > 0 ? totalMargin.toFixed(2) : null}
                          {sellerPaymentStatus == "Pending" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="red"
                              className="bi bi-x-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="green"
                              className="bi bi-check-circle-fill ms-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="text-end p-3 border  border-top-0">
                  {authenticationOrderStatus ||
                  orderStatus != "Ready For Test" ||
                  refund === "Refunded" ? (
                    <Button
                      className="border-0 px-3 m-3 py-2 bg-primary text-white custom_authenticate_pointer"
                      style={{ cursor: "pointer" }}
                      onClick={handleShow}
                      disabled
                    >
                      Authenticate
                    </Button>
                  ) : (
                    <Button
                      className="border-0 px-3 m-3 py-2 bg-primary text-white"
                      style={{ cursor: "pointer" }}
                      onClick={handleShow}
                    >
                      Authenticate
                    </Button>
                  )}

                  {/* { createShipmentStatus == "fail" || orderStatus == "Waiting on Seller" || orderStatus == "Shipped From Seller" || orderStatus == "Ready For Test" || orderStatus == "Shipped Out" || orderStatus == "Return to Seller" || orderStatus == "Out For Delivery" || orderStatus == "Recently Delivered" ?
                        <Button
                          className="border-0 px-3 py-2 bg-success text-white"
                          onClick={handleCreateShippingLabel}
                          style={{cursor: "pointer"}}
                          disaled                          
                        >
                         {orderStatus == "Ready For Return Shipment" ? "Return Shipment" : "Create Shipping Label"}
                        </Button> 
                        :  */}
                  <Button
                    className="border-0 px-3 py-2 bg-success text-white"
                    style={{ cursor: "pointer" }}
                    onClick={handleCreateShippingLabel}
                    // disabled={showCreatePrintLabelLoader}
                    disabled={
                      trackingDetails?.authStatus?.status == "pending" ||
                      trackingDetails?.deliveryStatus?.status ==
                        "Waiting on Seller" ||
                      trackingDetails?.deliveryStatus?.status ==
                        "Shipped From Seller" ||
                      trackingDetails?.deliveryStatus?.status ==
                        "Out For Delivery" ||
                      trackingDetails?.deliveryStatus?.status ==
                        "Ready For Test" ||
                      showCreatePrintLabelLoader
                        ? true
                        : false
                    }
                  >
                    {showCreatePrintLabelLoader
                      ? "Please Wait..."
                      : orderStatus == "Ready For Return Shipment"
                      ? "Return Shipment"
                      : "Create Shipping Label"}
                  </Button>
                  {/* } */}
                </div>

                {/* start authentication Popup */}
                <Modal
                  show={show}
                  onHide={handleClose}
                  className="authentication-modal"
                  backdrop={true}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Authentication</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="scrollable-modal-body">
                    <p
                      className="fw-bold text-danger"
                      style={{ textAlign: "right" }}
                    >
                      Fail ?
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="fw-bold">Visual Inspection</p>
                      <Form.Check
                        aria-label="option 1"
                        defaultChecked={visualInspection ? true : false}
                        onChange={(e) =>
                          handleAuthentication(e.target.checked, 0, "visual")
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="fw-bold">Acoustic Inspection</p>
                      <Form.Check
                        aria-label="option 1"
                        defaultChecked={acousticInspection ? true : false}
                        onChange={(e) =>
                          handleAuthentication(e.target.checked, 1, "Acoustic")
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="fw-bold">Benchmark</p>
                      <Form.Check
                        aria-label="option 1"
                        defaultChecked={benchMark ? true : false}
                        onChange={(e) =>
                          handleAuthentication(e.target.checked, 2, "benchmark")
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="fw-bold">Other Attachment</p>
                      <Form.Check
                        aria-label="option 1"
                        defaultChecked={attachment ? true : false}
                        onChange={(e) =>
                          handleAuthentication(
                            e.target.checked,
                            3,
                            "attachment"
                          )
                        }
                      />
                    </div>
                    <div className="">
                      <p className="fw-bold mb-2">Comments</p>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></Form.Control>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="orderdetail align-items-start">
                    <Button
                      variant="success"
                      disabled={buttonLoader}
                      onClick={handlePassAuthentication}
                    >
                      {buttonLoader ? "Please Wait..." : "Pass"}
                    </Button>
                    <div className="">
                      {authenticationOrderStatus ||
                      orderStatus == "Waiting on Seller" ||
                      orderStatus == "Shipped From Seller" ? (
                        <Button
                          variant="danger"
                          disabled
                          onClick={handleFailAuthentication}
                        >
                          {buttonFailLoader ? "Please Wait..." : "Fail"}
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          disabled={buttonFailLoader}
                          onClick={handleFailAuthentication}
                        >
                          {buttonFailLoader ? "Please Wait..." : "Fail"}
                        </Button>
                      )}
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          aria-label="option 1"
                          defaultChecked={waiveFee ? true : false}
                          onChange={(e) => setWaiveFee(e.target.checked)}
                        />
                        <p className="fw-bold m-0 ms-2 text-danger">
                          Waive Fee
                        </p>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
                {/* end authentication Popup */}

                {/* start shipping popup */}
                {showModalLoader ? (
                  <div className="loader-icon" style={{ marginBlock: "80px" }}>
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  </div>
                ) : (
                  <Modal show={show1} onHide={handleClose1} backdrop={true}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <h5 className="my-3 ">Ship From</h5>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="scrollable-modal-body">
                      {alreadyCreatedShippingLabel == null ? (
                        <Form>
                          <div className="px-2">
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Name"
                                value="Skewws llc"
                                // onChange={(e) => setSellerName(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Address
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Address"
                                value={fromStreet}
                                // onChange={(e) => setBillingAddress(e.target.value)}
                              />
                            </Form.Group>
                            <div className="d-sm-flex justify-content-start gap-2">
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  City
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your City"
                                  value={fromCity}
                                  // onChange={(e) => setCity(e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0 ">
                                  State
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your State"
                                  value={fromState}
                                  // onChange={(e) => setState(e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0                        ">
                                  Zip Code
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your Zip Code"
                                  value={fromZipCode}
                                  // onChange={(e) => setZipCode(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Phone
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Your Phone No."
                                value={fromPhone}
                                // onChange={(e) => setPhone(e.target.value)}
                              />
                            </Form.Group>
                          </div>
                          <h5 className="my-3 ">Ship To</h5>
                          {orderStatus != "Ready For Return Shipment" ? (
                            <div className="px-2">
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your Name"
                                  value={buyerName}
                                  onChange={(e) => setBuyerName(e.target.value)}
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Address
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your Address"
                                  value={toStreet}
                                  // onChange={(e) => setBuyerShippingAddress(e.target.value)}
                                />
                              </Form.Group>
                              <div className="d-sm-flex justify-content-between gap-2">
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    City
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your City"
                                    value={toCity}
                                    // onChange={(e) => setBuyerCity(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    State
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your State"
                                    value={toState}
                                    // onChange={(e) => setBuyerState(e.target.value)}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    Zip Code
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your Zip Code"
                                    value={toZipCode}
                                    // onChange={(e) => setBuyerZipCode(e.target.value)}
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Phone
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter Your Phone"
                                  value={toPhone}
                                  // onChange={(e) => setPhone(e.target.value)}
                                />
                              </Form.Group>
                            </div>
                          ) : (
                            <div className="px-2">
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your Name"
                                  value={sellerName}
                                  onChange={(e) =>
                                    setSellerName(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Address
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Your Address"
                                  value={returnStreet}
                                  onChange={(e) =>
                                    setSellerShippingAddress(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <div className="d-sm-flex justify-content-between gap-2">
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    City
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your City"
                                    value={returnCity}
                                    onChange={(e) =>
                                      setSellerShippingCity(e.target.value)
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    State
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your State"
                                    value={returnState}
                                    onChange={(e) =>
                                      setSellerShippingState(e.target.value)
                                    }
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-2 d-flex align-items-center gap-2"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="fs-6 fw-bold mb-0">
                                    Zip Code
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Your Zip Code"
                                    value={returnZipCode}
                                    onChange={(e) =>
                                      setSellerShippingZipCode(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <Form.Group
                                className="mb-2 d-flex align-items-center gap-2"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="fs-6 fw-bold mb-0">
                                  Phone
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Enter Your Phone"
                                  value={returnPhone}
                                  onChange={(e) =>
                                    setSellerPhone(e.target.value)
                                  }
                                />
                              </Form.Group>
                            </div>
                          )}
                          <h5 className="my-3 ">Package</h5>
                          <div className="package-form-grid px-2 ">
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Length
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={length}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Width
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={width}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Height
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={height}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-2 d-flex align-items-center gap-2"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="fs-6 fw-bold mb-0">
                                Weight
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                value={weight}
                              />
                            </Form.Group>
                            {/* <Form.Group
                      className="mb-2 d-flex align-items-center gap-2"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="fs-6 fw-bold mb-0">Ibs</Form.Label>
                      <Form.Control type="text" placeholder="" />
                    </Form.Group> */}
                          </div>

                          <Table className="payment-table">
                            <tbody>
                              {paymentOptions.map((option) => (
                                <tr key={option.value} className="border-white">
                                  <td>
                                    <Form.Check
                                      className="fw-bold"
                                      type="checkbox"
                                      label={option.service}
                                      value={option.id}
                                      checked={
                                        option.service ===
                                        process.env.REACT_APP_EASYPOST_SERVICE
                                      }
                                      // checked={option.id === selectedOption}
                                      onClick={() => handleCheckboxChange}
                                    />
                                  </td>
                                  <td className="fw-bold">{option.rate}</td>
                                </tr>
                              ))}
                            </tbody>
                            <p className="fw-bold mb-0 px-2">
                              * All Pricing to include Insurance
                            </p>
                          </Table>
                        </Form>
                      ) : (
                        <h3>Shipping Label Already Created !!!</h3>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      {createShipmentStatus == "fail" ||
                      orderStatus == "Waiting on Seller" ||
                      orderStatus == "Shipped From Seller" ||
                      orderStatus == "Ready For Test" ||
                      orderStatus == "Return to Seller" ? (
                        <Button
                          variant="success"
                          className="d-block w-100"
                          disabled
                          // onClick={handleShippingFailLabel}
                        >
                          Print Label
                        </Button>
                      ) : orderStatus == "Waiting on Seller" ||
                        orderStatus == "Shipped From Seller" ||
                        orderStatus == "Ready For Test" ||
                        orderStatus == "Shipped Out" ||
                        orderStatus == "Ready For Return Shipment" ? (
                        <Button
                          variant="success"
                          className="d-block w-100"
                          onClick={handleShippingLabel}
                          // onClick={handleShippingFailLabel}
                        >
                          Print Label
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          className="d-block w-100"
                          disabled={buttonLoader}
                          onClick={handleShippingLabel}
                        >
                          {buttonLoader ? "Please Wait" : "Print Label"}
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>
                )}
                {/* end shipping popup */}

                <div className="incomingship p-4 border mt-4">
                  <Accordion className="order-accordian">
                    <Accordion.Item eventKey="0">
                      {trackingDetails?.deliveryStatus.status ==
                      "Waiting on Seller" ? (
                        <Accordion.Header className="text-black fw-bold">
                          Incoming Shipping(TBD)
                        </Accordion.Header>
                      ) : trackingDetails?.deliveryStatus.status ==
                        "Shipped From Seller" ? (
                        <Accordion.Header className="text-black fw-bold">
                          Incoming Shipping(In-Progress)
                        </Accordion.Header>
                      ) : trackingDetails?.deliveryStatus.status ==
                        "Ready For Test" ? (
                        <Accordion.Header className="text-black fw-bold">
                          Incoming Shipping(Delivered)
                        </Accordion.Header>
                      ) : (
                        <Accordion.Header className="text-black fw-bold">
                          Incoming Shipping(Delivered)
                        </Accordion.Header>
                      )}
                      <Accordion.Body className="py-3 px-0">
                        <h6>UPS Shipment Progress</h6>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Row>
                            <Col sm={12}>
                              <Nav
                                variant="pills"
                                className="flex-row tab-overview"
                              >
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="first"
                                    className="border px-5"
                                  >
                                    Overview
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="second"
                                    className="border px-5"
                                  >
                                    Detailed View
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <div className="d-flex gap-2">
                                    <div className="shipping-timeline">
                                      <div className="timeline-custom">
                                        {trackinghistory.map((item) => {
                                          return (
                                            <div className="timeline-block timeline-block-right active">
                                              <div className="marker active">
                                                <i
                                                  className="fa fa-check active"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {/* {trackingDetails?.tracking[1]?.trackingLocation?.map((item) => {
                                            return (
                                              <div className="timeline-block timeline-block-right active">
                                                <div className="marker active">
                                                  <i
                                                    className="fa fa-check active"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </div>
                                            )
                                          })
                                          } */}
                                      </div>
                                    </div>
                                    <Table className="mt-3 payment-table border border-start-0 border-end-0 rounded">
                                      <thead className="border-bottom">
                                        <th className="py-3"></th>
                                        <th className="py-3">Date</th>
                                        <th className="py-3">Location</th>
                                        <th className="py-3"></th>
                                      </thead>
                                      <tbody>
                                        {/* {trackinghistory?.slice(1).map((item) => {
 
                                            return (
                                              <tr className="border-0 border-white my-3">
                                                 <td> <div> 
                                              {item?.statusDetails?.substring(0,44)} 
                                              </div></td>  
                                                <td>{moment(item?.statusDate).format('lll')}</td> 
                                                <td>
                                                  {item?.location?.city}, 
                                                  {item?.location?.country}
                                                  </td> 
                                               
                                              </tr>
                                            )
                                          })
                                          } */}
                                        {trackinghistory
                                          ?.map((item, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="border-0 border-white my-3"
                                              >
                                                <td>
                                                  <div>
                                                    {(
                                                      item?.status_details
                                                    )?.substring(0, 44)}
                                                  </div>
                                                </td>
                                                <td>
                                                  {moment(
                                                    item?.status_date
                                                  ).format("lll")}
                                                </td>
                                                <td>
                                                  {item?.location?.city},{" "}
                                                  {item?.location?.country}
                                                </td>
                                              </tr>
                                            );
                                          })}

                                        {/* {trackingDetails?.tracking[1]?.trackingLocation?.map((item) => {
                                            return (
                                              <tr className="border-0 border-white my-3">
                                                <td>{item.message}</td>
                                                <td>{moment(item.datetime).format('lll')}</td>
                                                <td>{item.tracking_location.state ? item.tracking_location.state : ""}</td>
                                                <td>{item.tracking_location.country ? item.tracking_location.country : ""}</td>
                                              </tr>
                                            )
                                          })
                                          } */}
                                      </tbody>
                                    </Table>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  {/* <Sonnet /> */}
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        {/* <div className="d-flex align-items-center gap-4 mt-2">
                      <Button className="border-0 px-3 py-2 bg-primary text-white btn btn-primary">
                        Overview
                      </Button>
                      <Button className="border-0 px-3 ms-2 py-2 bg-success text-white btn btn-primary">
                        Detailed View
                      </Button>
                    </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* new  Outgoing Shipping(TBD) data */}
                <div className="incomingship p-4 border mt-4">
                  <Accordion className="order-accordian">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="text-black fw-bold">
                        Outgoing Shipping(TBD)
                      </Accordion.Header>
                      <Accordion.Body className="py-3 px-0">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Row>
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <div className="d-flex gap-2">
                                    <div className="shipping-timeline">
                                      <div className="timeline-custom">
                                        {trackingDetails?.trackingFromAdmin[0]?.trackingLocation?.map(
                                          (item) => {
                                            return (
                                              <div className="timeline-block timeline-block-right active">
                                                <div className="marker active">
                                                  <i
                                                    className="fa fa-check active"
                                                    aria-hidden="true"
                                                  ></i>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                    <Table className="mt-3 payment-table border border-start-0 border-end-0 rounded">
                                      <thead className="border-bottom">
                                        <th className="py-3"></th>
                                        <th className="py-3">Date</th>
                                        <th className="py-3">Location</th>
                                        <th className="py-3"></th>
                                      </thead>
                                      <tbody>
                                        {trackingDetails?.trackingFromAdmin[0]?.trackingLocation?.map(
                                          (item) => {
                                            return (
                                              <tr className="border-0 border-white my-3">
                                                <td>{item.message}</td>
                                                <td>
                                                  {moment(item.datetime).format(
                                                    "lll"
                                                  )}
                                                </td>
                                                <td>
                                                  {item.tracking_location.state
                                                    ? item.tracking_location
                                                        .state
                                                    : ""}
                                                </td>
                                                <td>
                                                  {item.tracking_location
                                                    .country
                                                    ? item.tracking_location
                                                        .country
                                                    : ""}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  {/* <Sonnet /> */}
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* old  backup */}
                {/* Outgoing Shipping(TBD) */}
                {/* <div className="incomingship p-4 border mt-4">
                    <Accordion className="order-accordian">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-black fw-bold">
                          Outgoing Shipping(TBD)
                        </Accordion.Header>
                        <Accordion.Body className="py-3 px-0">
                          <div className="d-flex py-3 gap-4">
                            <div className="w-100 position-relative">

                              <Table className="mt-3 payment-table border border-start-0 border-end-0 rounded">
                                <thead className="border-bottom">
                                  <th className="py-3"></th>
                                  <th className="py-3">Date</th>
                                  <th className="py-3">Location</th>
                                  <th className="py-3"></th>
                                </thead>
                                <tbody>
                                  {
                                    trackingDetails?.trackingFromAdmin[0]?.trackingLocation?.map((item) => {
                                      return (
                                        <tr className="border-0 border-white my-3">
                                          <td>{item.message}</td>
                                          <td>{moment(item.datetime).format('lll')}</td>
                                          <td>{item.tracking_location.state ? item.tracking_location.state : ""}</td>
                                          <td>{item.tracking_location.country ? item.tracking_location.country : ""}</td>
                                        </tr>
                                      )
                                    })
                                  }

                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div> */}

                {/* Timeline */}
                <div className="d-flex align-items-center gap-4 mt-2 border-bottom border-top py-3">
                  <p className="mb-0">Timeline</p>
                  <p className="mb-0 ms-auto">
                    <Form.Group controlId="formCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Show Comments"
                        checked={onlyComments}
                        onChange={() => showOnlyComments(!onlyComments)}
                      />
                    </Form.Group>
                  </p>
                </div>
                <div className="d-flex py-3 gap-4 shipping-order-main">
                  <div className="leave-comment">
                    <div className="timeline-custom">
                      <div className="timeline-block timeline-block-right mb-0 mt-2">
                        <div className="">
                          <div className="message-user">
                            <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" />
                          </div>
                        </div>
                      </div>
                      {/* {trackingDetails?.trackingFromAdmin[0]?.trackingLocation?.map((item) => {
                              return (
                                <div className="timeline-block timeline-block-right active">
                                  <div className="marker active">
                                    <i
                                      className="fa fa-check active"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              )
                            })
                          } */}
                    </div>
                  </div>
                  <div className="w-100 position-relative shipped-textarea">
                    <textarea
                      className="w-100 p-2 w-95"
                      placeholder="Leave a Comment"
                      onChange={(e) => setComments(e.target.value)}
                      value={comments}
                    ></textarea>
                    <div className="iconlist d-flex align-items-center gap-2 position-absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        className="bi bi-emoji-smile"
                        viewBox="0 0 16 16"
                        onClick={() => setShowEmojis(!showEmojis)}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                      </svg>
{/* 
                      <label for="custom-input" className="custom-input-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-file-earmark-code"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                          <path d="M8.646 6.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 9 8.646 7.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 9l1.647-1.646a.5.5 0 0 0 0-.708z" />
                        </svg>
                      </label> */}
                      {/* <Form.Control
                        type="file"
                        className="custom-input-file"
                        id="custom-input"
                        accept=".jpg, .jpeg, .png"
                        name="custom-input"
                        onChange={(e) => {
                          // Check if a file is selected
                          const file = e.target.files[0];
                          if (file) {
                            const reader = new FileReader();

                            reader.onloadend = () => {
                              // Set the image preview
                              setUpdateLogo(reader.result);
                            };

                            // Read the image as a data URL
                            reader.readAsDataURL(file);
                          }

                          setSendAttachment(file);
                        }}
                      /> */}

                      <Button
                        className="px-4 border-0 bg-secondary text-white"
                        onClick={saveComment}
                      >
                        Post
                      </Button>
                      {showEmojis && (
                        <div className="emoji-picker-custom">
                          <Picker data={data} onEmojiSelect={addEmoji} />
                          {/* <EmojiPicker /> */}
                        </div>
                      )}
                    </div>

                    {updateLogo && (
                      <img
                        src={updateLogo}
                        style={{ width: 140 }}
                        className="my-3"
                      />
                    )}
                    <p className="shipping-day">Today</p>
                    <div className="d-flex w-100 justify-content-start">
                      <ul className="list-parent">
                        {showAllOrderComments?.map((item) => (
                          <div className="d-flex list-main">
                            <div className="marker active">
                              <i
                                className="fa fa-check active"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <li>
                              <p>
                                {item?.comment == ""
                                  ? item?.status
                                  : item?.comment}
                              </p>
                              <div className="flex  ">
                                <span>
                                  {
                                    new Date(item?.createdAt)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </span>
                                <span>
                                  {convertTime(
                                    new Date(item?.createdAt)
                                      .toISOString()
                                      .split("T")[1]
                                  )}
                                </span>
                              </div>
                              {item?.image && (
                                <span className="w-100">
                                  <img
                                    // src={`${imageUrl}${item?.image}`}
                                    src={
                                      item?.image?.includes("http")
                                        ? item?.image
                                        : `${imageUrl}${item?.image}`
                                    }
                                    style={{ width: "100px" }}
                                  />
                                </span>
                              )}
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className="buyer-main-part">
                  <div className="bg-white border p-3">
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                      <h5 className="fw-bold mb-0">Buyer</h5>
                      <Button className="border-0 bg-none"></Button>
                    </div>
                    <Accordion className="order-accordian mb-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() => navigate("/customerDetail/" + buyerId)}
                        >
                          {buyerName}
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6>1 order</h6>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="bg-white border p-3 border-top-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="fw-bold mb-3">CONTACT INFORMATION</h6>
                      {/* <Button className="border-0 bg-none text-primary" onClick={() => setShowBuyerEmailModel(true)} disabled>Edit</Button> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center pe-3">
                      <p className="fw-bold text-primary">{buyerEmail}</p>
                      {/* <Form.Check aria-label="option 1" /> */}
                    </div>
                    <p className="fw-500 mb-3 text-mute">
                      {phone ? phone : "No Phone number"}
                    </p>
                  </div>
                  <div className="bg-white border p-3 border-top-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="fw-bold mb-3">SHIPPING ADDRESS</h6>
                      <Button
                        className="border-0 bg-none text-primary"
                        onClick={() => setShowShipingAddressModel(true)}
                      >
                        Edit
                      </Button>
                    </div>
                    <div className="d-flex justify-content-between pe-3">
                      <p className="fw-500">
                        {buyerShippingAddress}
                        <br />
                        {buyerCountry}
                        <br />
                        {buyerState}
                        <br />
                        {buyerCity}
                      </p>
                      {/* <Form.Check aria-label="option 1" /> */}
                    </div>
                    <Button className="fw-500 mb-3 text-primary bg-none border-0 p-0">
                      View map
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-up-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                        />
                        <path
                          filerule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                        />
                      </svg>
                    </Button>
                  </div>
                  {/* <div className="bg-white border p-3 border-top-0">
                <h6 className="fw-bold mb-3">BILLING ADDRESS</h6>
                <p className="fw-500">
                  {buyerBillingAddress}
                  <br />
                  {buyerBillingCountry}
                  <br />
                  {buyerBillingState}
                  <br />
                  {buyerBillingCity}
                </p>
              </div> */}
                </div>

                <div className="buyer-main-part mt-3">
                  <div className="bg-white border p-3">
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                      <h5 className="fw-bold mb-0">Seller 2</h5>
                      <Button className="border-0 bg-none">
                        {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill=""
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg> */}
                      </Button>
                    </div>
                    <Accordion className="order-accordian mb-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header
                          onClick={() =>
                            navigate("/customerDetail/" + sellerId)
                          }
                        >
                          {sellerName}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-star-fill ms-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6>1 order</h6>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="bg-white border p-3 border-top-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="fw-bold mb-3">CONTACT INFORMATION</h6>
                      {/* <Button className="border-0 bg-none text-primary" disabled>Edit</Button> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center pe-3">
                      <p className="fw-bold text-primary">{sellerEmail}</p>
                      {/* <Form.Check aria-label="option 1" /> */}
                    </div>
                    <p className="fw-500 mb-3 text-mute">
                      {sellerPhone ? sellerPhone : "No Phone number"}
                    </p>
                  </div>
                  <div className="bg-white border p-3 border-top-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="fw-bold mb-3">BILLING ADDRESS</h6>
                      {/* <Button className="border-0 bg-none text-primary" disabled>Edit</Button> */}
                    </div>
                    <div className="d-flex justify-content-between pe-3">
                      <p className="fw-500">
                        {sellerShippingAddress}
                        <br />
                        {sellerShippingCountry}
                        <br />
                        {sellerShippingState}
                        <br />
                        {sellerShippingCity}
                      </p>
                      {/* <Form.Check aria-label="option 1" /> */}
                    </div>
                    Modal
                    <Button className="fw-500 mb-3 text-primary bg-none border-0 p-0">
                      View map
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-up-right ms-2"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                        />
                        <path
                          filerule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                        />
                      </svg>
                    </Button>
                  </div>
                  {/* <div className="bg-white border p-3 border-top-0">
                <h6 className="fw-bold mb-3">BILLING ADDRESS</h6>
                <p className="fw-500">
                  {billingAddress}
                  <br />
                  {sellerBillingCountry}
                  <br />
                  {sellerBillingState}
                  <br />
                  {sellerBillingCity}
                </p>
              </div> */}
                </div>
                <div className="buyer-main-part mt-3">
                  <div className="bg-white border p-3">
                    <Accordion className="order-accordian mb-3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="text-primary text-decoration-underline">
                          Seller 1(non performing)
                        </Accordion.Header>
                        <Accordion.Body>
                          <h6>Aylin Sarabia</h6>
                          <p>1 order</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/* Buyer Email Model Start */}
          <Modal
            show={showBuyerEmailModel}
            onHide={() => setShowBuyerEmailModel(false)}
            backdrop={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Email</Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollable-modal-body">
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="false"
                  value={validBuyerEmail}
                  placeholder="Enter Your Email"
                  onChange={(e) => handleEmail(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowUserModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleUpdateEmail}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end Buyer email popup */}

          {/* start update address popup */}
          <Modal
            show={showShipingAddressModel}
            onHide={() => setShowShipingAddressModel(false)}
            backdrop={true}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="my-3 ">Update Shipping Address</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="scrollable-modal-body">
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  disabled={addressButtonLoader}
                  placeholder="Enter Your Address"
                  value={validStreet}
                  onChange={(e) => setValidStreet(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  disabled={addressButtonLoader}
                  placeholder="Enter Your Country"
                  value={validCountry}
                  onChange={(e) => setValidCountry(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  disabled={addressButtonLoader}
                  placeholder="Enter Your City"
                  value={validCity}
                  onChange={(e) => setValidCity(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  disabled={addressButtonLoader}
                  placeholder="Enter Your State"
                  value={validState}
                  onChange={(e) => setValidState(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="number"
                  disabled={addressButtonLoader}
                  placeholder="Enter Your Postal Code"
                  value={validZipCode}
                  onChange={(e) => setValidZipCode(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                className="d-block w-100"
                disabled={addressButtonLoader}
                onClick={handleUpdateShippingAddress}
              >
                {addressButtonLoader ? "Please Wait..." : "Save"}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end update address popup */}
        </div>
      )}
    </div>
  );
}
