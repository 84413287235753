import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ExportApi from "../api/ExportApi";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import validator from "validator";
import Header from "./header";
import Footer from "./footer";
import { jwtDecode } from "jwt-decode";

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [token, setToken] = useState(params.id);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorConfirmMessage, setErrorConfirmMessage] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isResetTokenValid, setIsResetTokenValid] = useState(false);


  useEffect(() => {
    // Get the token from localStorage
    const storedToken = localStorage.getItem("resetToken");
  
    // Check if the token matches the one in localStorage
    if (storedToken && storedToken === token) {
     
      // Perform any additional actions if needed
      setIsResetTokenValid(true);
    } else {
   
      setIsResetTokenValid(false);
      // Handle the case where the tokens don't match
    }
  }, [token]);
  

  useEffect(() => {
    const checkTokenValidity = () => {
      try {
 
  
        if (token) {
          const decodedToken = jwtDecode(token);
     
  
          const currentTime = Date.now() / 1000; // Current time in seconds
        
  
          // Check if `exp` is available and use it as a reference
          if (decodedToken.exp) {
            const expirationTime = decodedToken.exp; // `exp` is already in seconds
  
            if (currentTime > expirationTime) {
          
              setIsTokenValid(false);
            } else {
            
              setIsTokenValid(true);
            }
          } else {
        
            setIsTokenValid(false);
          }
        } else {
     
          setIsTokenValid(false);
        }
      } catch (error) {
    
        setIsTokenValid(false);
      }
    };
  
    checkTokenValidity();
  }, [token]);
  
  const handleresetpassword = () => {
    if (newPassword) {
      if (confirmPassword) {
        if (newPassword === confirmPassword) {
          if (errorMessage != undefined && errorConfirmMessage != undefined) {
            ExportApi.resetPassword(newPassword, token).then((res) => {
              if (res.status === 200) {
                toast.success(res.data.message);
                localStorage.setItem("resetToken", token);
            
                setTimeout(() => {
                   navigate("/");
                }, 2000);
              } else if(res.data.message === "Token is Expired"){  
                toast.error("You already changed your password");
              } else{
                toast.error("Password reset failed");
              }
            }); 
          } else {
            toast.error("Password Should Not Follow Rules");
          }
        } else {
          toast.error("Password does not match");
        }
      } else {
        toast.error("Please enter Confirm Password");
      }
    } else {
      toast.error("Please Enter Password");
    }
  };

  
  const handleNewPassword = (data) => {
    if (
      validator.isStrongPassword(data, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minSymbols: 1,
        minNumbers: 1,
      })
    ) {
      setErrorMessage("");
    } else {
      setErrorMessage(
        "Passsword Should Contains min. 1, lowercase 1, uppercase 1, number 1, special character"
      );
    }
    setNewPassword(data);
  };
  const handleNewConfirmPassword = (data) => {
    if (
      validator.isStrongPassword(data, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minSymbols: 1,
        minNumbers: 1,
      })
    ) {
      setErrorConfirmMessage("");
    } else {
      setErrorConfirmMessage(
        "Passsword Should Contains min. 1, lowercase 1, uppercase 1, number 1, special character"
      );
    }
    setConfirmPassword(data);
  };

 

  return (
    <div>
      <Header />
      {!isTokenValid || isResetTokenValid == true ? (
        <>
          <div className="forgot_container container mb-10">
            <h2 className="text-center">Reset Password</h2>
            <p className="text-center" style={{ color: "red" }}>
              The reset link has expired. Please request a new one.
            </p>
            <span className="d-flex justify-content-center mt-3">
              <Link to="/">Click Here For the Login</Link>
            </span>
          </div>
          <br></br>
            <br></br>
            <br></br>
        </>
      ) : (
        <>
          <div className="forgot_container container">
            <h2 className="text-center">Reset Password</h2>
            <div className="input-container w-100">
              <label className="label fs-5 mb-2">New Password </label>
              <input
                type="password"
                name="password"
                className="input form-control forgot_input"
                placeholder="New password"
                value={newPassword}
                onChange={(e) => handleNewPassword(e.target.value)}
              />
              {errorMessage === "" ? null : (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {errorMessage}
                </span>
              )}
            </div>
            <div className="input-container w-100">
              <label className="label fs-5 mb-2">Confirm Password</label>
              <input
                type="password"
                name="password"
                className="input form-control forgot_input"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => handleNewConfirmPassword(e.target.value)}
              />
              {errorConfirmMessage === "" ? null : (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {errorConfirmMessage}
                </span>
              )}
            </div>
            {errorConfirmMessage.length >0 ?  <Button
              className=" d-block py-2 text-white mt-4 w-10 mb-3 mx-auto px-4 border-0 resetpwd"
              onClick={handleresetpassword}
              style={{ backgroundColor: "#990000" }}
              disabled
            >
              Submit
            </Button> :  <Button
              className=" d-block py-2 text-white mt-4 w-10 mb-3 mx-auto px-4 border-0 resetpwd"
              onClick={handleresetpassword}
              style={{ backgroundColor: "#990000" }}
            >
              Submit
            </Button>  }
           
            <span className="d-flex justify-content-center mt-3">
              <Link to="/"> Click Here For the Login</Link>
            </span>
            <ToastContainer />
          </div>
          <br></br>
            <br></br>
            <br></br>
        </>
      )}

      <Footer />
    </div>
  );
};

export default ResetPassword;
