import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Container,
  Row,
  Button,
  Col,
  Form,
  Dropdown,
  Table,
  Modal,
  FormLabel,
  FormSelect,
  FormControl,
  ListGroup,
} from "react-bootstrap";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
//import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import ExportApi from "../api/ExportApi";
import { BiPlusCircle } from "react-icons/bi";
import { BiMinusCircle } from "react-icons/bi";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Font,
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
  Link,
  List,
  BlockQuote,
  Heading,
  Image,
  MediaEmbed,
  Undo,
} from "ckeditor5";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
 
import { toast, ToastContainer } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutsideClickHandler from "react-outside-click-handler";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import UserAskBidList from "./UserAskBidList";
import BuySpread from "./BuySpread";
import SellSpread from "./SellSpread";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import _ from 'lodash';
import Pagination from "@mui/material/Pagination";
import Stack from '@mui/material/Stack';


let date;
export default function GraphicTab() {
 
  const chipsetData = [
    { label: "AMD", value: "AMD" },
    { label: "Nvidia", value: "Nvidia" },
  ];
  const brandData = [
    {
      label: "MSI",
      value: "MSI",
    },
    {
      label: "Zotac",
      value: "Zotac",
    },
    {
      label: "PNY",
      value: "PNY",
    },
    {
      label: "EVGA",
      value: "EVGA",
    },
    {
      label: "GIGABYTE",
      value: "GIGABYTE",
    },
    {
      label: "ASUS",
      value: "ASUS",
    },
    {
      label: "Nvidia",
      value: "Nvidia",
    },
    {
      label: "XFX",
      value: "XFX",
    },
    {
      label: "AMD",
      value: "AMD",
    },
  ];

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  let nevigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShownew, setModalShownew] = useState(false);
  const [houseask, setHouseask] = useState("");
  const [housebid, setHousebid] = useState("");
  const [askList, setAskList] = useState();
  const [bidList, setBidList] = useState();
  const [option1Checked, setOption1Checked] = useState();
  const [option2Checked, setOption2Checked] = useState();
  const [seriesList, setSeriesList] = useState([]);
  const [ids, setIds] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [inventoryData, setInventoryData] = useState(0);
  const [askResult, setAskResult] = useState([]);
  const [bidResult, setBidResult] = useState([]);
  const [cogsPrice, setCOGSPrice] = useState(0);
  const [currentImage, setCurrentImage] = useState([]);
  const [removeMultiple, setRemoveMultiple] = useState([]);
  const [marketPrice, setMarketPrice] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [secondHandMarketPrice, setSecondHandMarketPrice] = useState("");
  const [secondHandHouseBid, setSecondHandHouseBid] = useState("");
  const [secondHandHouseAsk, setSecondHandHouseAsk] = useState("");
  const [usedMarketPrice, setUsedMarketPrice] = useState("");
  const [usedHouseBid, setUsedHouseBid] = useState("");
  const [productType, setProductType] = useState([]);
  const [productType1, setProductType1] = useState([]);
  const [productType2, setProductType2] = useState([]);
  const [productType3, setProductType3] = useState([]);
  const [usedHouseAsk, setUsedHouseAsk] = useState("");
  const [websitePrice, setWebsitePrice] = useState("");
  const [productData, setProductData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [inactiveData, setInactiveData] = useState([]);
  const [editId, setEditId] = useState();
  const [allloader, setALLLoader] = useState(false);
  const [Data, setData] = useState();
  const [mainloader, setMainLoader] = useState(false);
  const [image, setImage] = useState();
  const [Data1, setData1] = useState();
  const [descriptionindex, setdescriptionindex] = useState();
  const [ask, setAsk] = useState();
  const [Bid, setBid] = useState();
  const [AskBid, setAskBid] = useState();
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortKey, setCurrentSortKey] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [value, setvalue] = useState("");
  const [showFilterModel, setShowFilterModel] = useState(false);
  const [Chipsetvalue, setChipsetvalue] = useState(false);
  const [Brand1Value, setBrand1Value] = useState(false);
  const [category, setCategory] = useState();
  const [chipset2, setChipset2] = useState();
  const [showChipset2, setShowChipset2] = useState();

  const [brand2, setBrand2] = useState();
  const [showBrand2, setShowBrand2] = useState();
  const [series2, setSeries2] = useState();
  const [showSeries2, setShowSeries2] = useState();
  const [model2, setModel2] = useState();
  const [dupmodel, setDupModel] = useState();
  const [newModel, setNewModel] = useState('');
  const [seoTitle, setSeoTitle] = useState();
  const [metaDescription, setMetaDescription] = useState();
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [sku, setSku] = useState();
  const [upc, setUpc] = useState();
  const [chipset, setchipset] = useState([]);
  const [condition, setcondition] = useState([]);
  const [brand, setbrand] = useState([]);
  const [series, setseries] = useState([]);
  const [model, setmodel] = useState([]);
  const [conditionKey, setconditionKey] = useState([]);
  const [chipsetKey, setchipsetKey] = useState([]);
  const [brandKey, setbrandKey] = useState([]);
  const [seriesKey, setseriesKey] = useState([]);
  const [modalKey, setmodalKey] = useState([]);
  const [res, setres] = useState();
  const [productTypeStatus, setProductTypeStatus] = useState([]);
  const [FilterList, setFilterList] = useState();
  const [selectData, setSelectData] = useState();
  const [deleteData, setDeleteData] = useState([]);
  const [marketPriceDate, setMarketPriceDate] = useState();
  const [secondMarketPriceDate, setSecondMarketPriceDate] = useState();
  const [usedMarketDate, setUsedMarketDate] = useState();
  const [brandShow, setBrandShow] = useState(false);
  const [chipsetShow, setChipsetShow] = useState(false);
  const [seriesShow, setSeriesShow] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [condition1, setcondition1] = useState([]);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [secondHandHouseAskStatus, setSecondHandHouseAskStatus] =
    useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [inActiveStatus, setInActiveStatus] = useState(false);
  const [secondHandHouseBidStatus, setSecondHandHouseBidStatus] =
    useState(false);
  const [alreadyUpc, setAlreadyUpc] = useState([]);
  const [upcExists, setUpcExists] = useState(false);
  const [render, setRender] = useState(1);
  const [editorContent, setEditorContent] = useState("");
  const [selectedValue, setSelectedValue] = useState("63ff36fb23ad0386e761641f");
  const [newseries, setNewseries] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [apiloader, setApiloader] = useState(false);
  const [saveloading, setSaveLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [singlepdata, setSinglePData] = useState([]);
  const [inputList, setInputList] = useState([""]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectCheckBox, setSelectCheckBox] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [searchString, setSearchString] = useState(""); 
  const [page, setPage] = useState(1);
  const [inactivenew, setInactiveNew] = useState(false);
 
 
 



  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length > 0) {
      if (rejectedFiles[0]?.errors[0]?.code == "file-too-large") {
        alert("Please upload an image file with a maximum size of 1MB.");
      } else if (rejectedFiles[0]?.errors[0]?.code == "file-invalid-type") {
        alert("Please upload an image file with jpeg,png and webp format.");
      }
    } else if (acceptedFiles) {
      setMultipleFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
    },
    maxSize: 1024 * 1024,
  });

  const getnewseries = () => {
    ExportApi.getallseries()
      .then((resp) => {
        setNewseries(resp?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getnewseries();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    var list = [...inputList];
    list[index] = value;
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleRemoveClick = (index) => {
    var list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleOnSelectChipset = (item) => {
    productData.chipset = item;
    setProductData({ ...productData });
    setChipsetvalue(false);
  };

  const handleOnSelecBrand = (item) => {
    productData.brand = item;
    setProductData({ ...productData });
    setBrand1Value(false);
  };

  const handleItemss = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleCheckClickOne = (e) => {
    if (e.target.checked) {
      productTypeStatus[0] = true;
      setProductTypeStatus(productTypeStatus);
    } else {
      productTypeStatus[0] = false;
      setProductTypeStatus(productTypeStatus);
    }
  };
  const handleCheckClickTwo = (e) => {
    if (e.target.checked) {
      productTypeStatus[1] = true;
      setProductTypeStatus(productTypeStatus);
    } else {
      productTypeStatus[1] = false;
      setProductTypeStatus(productTypeStatus);
    }
  };
  const handleCheckClickThree = (e) => {
    if (e.target.checked) {
      productTypeStatus[2] = true;
      setProductTypeStatus(productTypeStatus);
    } else {
      productTypeStatus[2] = false;
      setProductTypeStatus(productTypeStatus);
    }
  };

  const handleCheckClickone1 = (e) => {
    if (e.target.checked) {
      productType[0] = 1;
    } else {
      productType[0] = 0;
    }
    setProductType(productType);
    setMarketPrice(productData.new_retail_market_price);
    setRetailPrice(productData.new_retail_price);
    setWebsitePrice(productData.new_retail_website_price);
    setRender(render + 1);
    setCheckbox1(!checkbox1);
  };

  const handleCheckClickTwo2 = (e) => {
    if (e.target.checked) {
      productType[1] = 2;
    } else {
      productType[1] = 0;
    }
    setProductType(productType);
    setSecondHandMarketPrice(productData.new_second_hand_market_price);
    setSecondHandHouseBid(productData.new_second_hand_house_bid);
    setSecondHandHouseAsk(productData.new_second_hand_house_ask);
    setRender(render + 1);
    setCheckbox2(!checkbox2);
  };

  const handleCheckClickThree3 = (e) => {
    if (e.target.checked) {
      productType[2] = 3;
    } else {
      productType[2] = 0;
    }
    setProductType(productType);
    setUsedMarketPrice(productData.used_market_price);
    setUsedHouseBid(productData.used_house_bid);
    setUsedHouseAsk(productData.used_house_ask);
    setRender(render + 1);
    setCheckbox3(!checkbox3);
  };

  const handleGetEventlistChange = () => {
    const id = JSON.parse(localStorage.getItem("admin"))?.id;
    //ExportApi.GetAllProductUserid(id)
    setApiloader(true);
    setMainLoader(true);
    setALLLoader(true);
    ExportApi.getallproducts()
      .then((resp) => {
        setMainLoader(false);
        setApiloader(false);
        setALLLoader(false);
      
        setTotalPages(resp?.data?.totalPages);
        let data = resp.data.products;
        const extractedIds = data.map((item) => item._id);
        setIds(extractedIds);
        let result = data.map((item) => {
          return { ...item, isSelected: false };
        });
        setData1(result);
        let result1 = result.filter((item) => item.isActive === false);
        setInactiveData(result1);
        let result2 = result.filter((item) => item.isActive === true);
        setActiveData(result2);
        let result3 = result.filter(
          (item) =>
            item.used_house_ask !== null &&
            item.new_second_hand_house_ask !== null &&
            item.used_house_ask > 0 &&
            item.new_second_hand_house_ask > 0
        );
        setAskResult(result3);
        let result4 = result.filter(
          (item) =>
            item.used_house_bid !== null &&
            item.new_second_hand_house_bid !== null &&
            item.used_house_bid > 0 &&
            item.new_second_hand_house_bid > 0
        );
        setBidResult(result4);
        setData([...result]);
      })
      .catch((err) => {
        setMainLoader(false);
        setALLLoader(false);
     
        setApiloader(false);
      });
  };

  const hendleask = (id) => {
    ExportApi.getask(id)
      .then((resp) => {
        if (resp.data.data) {
          setAsk(resp.data.data);
          setCount1(1);
        } else {
          setAsk();
          setCount1(2);
        }
      })
      .catch((err) => console.log(err));
  };
  const hendlebid = (id) => {
    ExportApi.getBid(id)
      .then((resp) => {
        if (resp?.data?.data?.length > 0) {
          setBid(resp.data.data);
          setCount(1);
        } else {
          setBid();
          setCount(2);
        }
      })
      .catch((err) => console.log(err));
  };

  const GetData1 = () => {
    setMainLoader(true);
    setALLLoader(true);
    ExportApi.GetFilterData()
      .then((resp) => {
        setALLLoader(false);
        setMainLoader(false);
        let chipsetKeyArray = [];
        let conditionKeyArray = [];
        let brandKeyArray = [];
        let modalKeyArray = [];
        let seriesKeyArray = [];
        setres(resp?.data.data);
        resp?.data.data?.chipset?.map((val, i) => {
          let key = { [val]: false };
          chipsetKeyArray.push(key);
        });
        setchipsetKey([...chipsetKeyArray]);
        resp?.data.data?.condition?.map((val) => {
          if (val == 1) {
            let key = { ["New-Retail"]: false };
            conditionKeyArray.push(key);
          } else if (val == 2) {
            let key = { ["New-2nd Hand"]: false };
            conditionKeyArray.push(key);
          } else {
            let key = { ["Used"]: false };
            conditionKeyArray.push(key);
          }
        });
        setconditionKey([...conditionKeyArray]);
        resp?.data.data?.series?.map((val) => {
          let key = { [val]: false };
          seriesKeyArray.push(key);
        });
        setseriesKey([...seriesKeyArray]);
        resp?.data.data?.brands?.map((val) => {
          let key = { [val]: false };
          brandKeyArray.push(key);
        });
        setbrandKey([...brandKeyArray]);
        resp?.data.data?.model?.map((val) => {
          let key = { [val]: false };
          modalKeyArray.push(key);
        });
        setmodalKey([...modalKeyArray]);
        setFilterList(resp?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  const adminId = JSON.parse(localStorage.getItem("admin"))?.id;

  useEffect(() => {
    const fetchAdminSeries = async (id) => {
      try {
        // setLoading(true);
        //const response = await ExportApi.getAdminSeries(id);
        const response = await ExportApi.getallseries();
        setSeriesList(response.data);
        // setLoading(false);
        // GetData1();
        // handleGetEventlistChange();
      } catch (error) {
        console.error("Error fetching admin series:", error);
      }
    };
    if (adminId) {
      fetchAdminSeries(adminId);
    }
  }, [adminId]);
  useEffect(() => {
    // handleGetEventlistChange();
    GetData1();
  }, []);
  const options = seriesList?.map((series) => ({
    label: series,
    value: series,
  }));

  const handleOnSearchadminSeries = (selectedOption) => {
    setSelectedSeries(selectedOption);
  };

  const ASKBID = (id, i) => {
    hendleask(id);
    hendlebid(id);
    setAskBid(i);
  };
  
  // const handleSearch = (e) => {
   
  //   if (e == "") {
  //     setData([...Data]);
  //   } else {
  //     let Filterdata = Data?.filter(
  //       (val) =>
  //         val.brand.toLowerCase().includes(e.toLowerCase()) ||
  //         val.chipset.toLowerCase().includes(e.toLowerCase()) ||
  //         val.model.toLowerCase().includes(e.toLowerCase()) ||
  //         val.series.toLowerCase().includes(e.toLowerCase())
  //     );
 
      
  //     setData([...Filterdata]);
  //   }
  // };


  

  // const handleSearch = (e) => {
  //   const search = e.trim(); 
  //   setPageOffset(0);
  //   setSearchString(search);
  //   const data = {
  //     searchString: search,
  //   };
  
  //   // Call the API with the search string
  //   ExportApi.productlistfilter(data)
  //     .then((resp) => {
 
  //       const totalpages = resp?.data?.Total;
  //       if (resp?.data?.Data.length > 0) {
  //         let products = resp?.data?.Data;
  
  //         setData(products);   
  //         setTotalPages(totalpages);
  //       } 
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

 
  const debouncedSearch = useCallback(
    _.debounce((search) => {
   
     

let isActive = activeStatus;

// Update isActive based on the current status
if (activeStatus === true) {
  isActive = true;
} else if (inActiveStatus === false && activeStatus === false) {
  isActive = true;
} else if (inActiveStatus === true) {
  isActive = false; 
} else {
  isActive = false; 
}

const data = {
  searchString: search,
  isActive: isActive, // Use the determined isActive value
};
      setALLLoader(true);
      // Call the API with the search string
      ExportApi.productlistfilter(data)
        .then((resp) => {
     
          const totalpages = resp?.data?.Total;
          if (resp?.data?.Data) {
            setALLLoader(false);
            let products = resp?.data?.Data;
        
 
            if(inActiveStatus === true){
              setInactiveData(products);
            }else{
              setData(products);
            }
         
            setTotalPages(resp?.data?.Total);
          }else{
            setALLLoader(false);
            setData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000), // 500ms debounce delay
    [activeStatus, inActiveStatus] 
  );

  // Handle search input
  const handleSearch = (e) => {
    const search = e.trim();
    setPageOffset(0);
    setPage(1);
    setSearchString(search);
    debouncedSearch(search);
  }
 

  const sortHereLowestAsk = (key, data) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    if (data == "ask") {
      const zeroLowestAskData = Data.filter(
        (item) => item.lowest_ask === 0 && item.type !== 1
      );
      const filteredData = Data.filter(
        (item) => item.lowest_ask !== 0 || item.type === 1
      );
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;
        if (a["type"] == 1) {
          aValue = a.new_retail_website_price;
        } else {
          aValue = a.lowest_ask;
        }
        if (b["type"] == 1) {
          bValue = b.new_retail_website_price;
        } else {
          bValue = b.lowest_ask;
        }
        if (aValue < bValue) return -1 * sortOrder;
        if (aValue > bValue) return sortOrder;
        return 0;
      });
      const finalSortedData = [...sortedData, ...zeroLowestAskData];
      setCurrentSortKey(key);
      setCurrentSortOrder(sortOrder);
      setData(finalSortedData);
      let result1 = finalSortedData.filter((item) => item.isActive == false);
      setInactiveData(result1);
      let result2 = finalSortedData.filter((item) => item.isActive == true);
      setActiveData(result2);
      let result3 = finalSortedData.filter((item) => {
        return (
          item.used_house_ask !== null &&
          item.new_second_hand_house_ask !== null &&
          item.used_house_ask > 0 &&
          item.new_second_hand_house_ask > 0
        );
      });
      setAskResult(result3);
      let result4 = finalSortedData.filter((item) => {
        return (
          item.used_house_bid !== null &&
          item.new_second_hand_house_bid !== null &&
          item.used_house_bid > 0 &&
          item.new_second_hand_house_bid > 0
        );
      });
      setBidResult(result4);
    } else {
      const zeroHighestBidData = Data.filter(
        (item) => item.highest_bid === 0 || item.type === 1
      );
      const filteredData = Data.filter(
        (item) => item.highest_bid !== 0 && item.type !== 1
      );
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;
        if (a["type"] == 1) {
          aValue = a.new_retail_website_price;
        } else {
          aValue = a.highest_bid;
        }
        if (b["type"] == 1) {
          bValue = b.new_retail_website_price;
        } else {
          bValue = b.highest_bid;
        }
        if (aValue < bValue) return -1 * sortOrder;
        if (aValue > bValue) return sortOrder;
        return 0;
      });
      const finalSortedData = [...sortedData, ...zeroHighestBidData];
      setCurrentSortKey(key);
      setCurrentSortOrder(sortOrder);
      setData(finalSortedData);
      let result1 = finalSortedData.filter((item) => item.isActive == false);
      setInactiveData(result1);
      let result2 = finalSortedData.filter((item) => item.isActive == true);
      setActiveData(result2);
      let result3 = finalSortedData.filter((item) => {
        return (
          item.used_house_ask !== null &&
          item.new_second_hand_house_ask !== null &&
          item.used_house_ask > 0 &&
          item.new_second_hand_house_ask > 0
        );
      });
      setAskResult(result3);
      let result4 = finalSortedData.filter((item) => {
        return (
          item.used_house_bid !== null &&
          item.new_second_hand_house_bid !== null &&
          item.used_house_bid > 0 &&
          item.new_second_hand_house_bid > 0
        );
      });
      setBidResult(result4);
    }
  };

  const sortHere = (key) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    const sortedProducts = Data.sort((a, b) => {
      if (a[key] < b[key]) return -1 * sortOrder;
      if (a[key] > b[key]) return sortOrder;
      return 0;
    });
    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
  };

  const handleUpdate = (data) => {
    ExportApi.updateMultiProductData(data?.sku)
      .then((resp) => {
        let data = resp.data.details;
  
  
        
        setInactiveNew(data[0]?.isActive)
        let result = data.filter((item) => item.type == 1);
        setProductType1([...result]);
        let result2 = data.filter((item) => item.type == 2);
        setProductType2([...result2]);
        let result3 = data.filter((item) => item.type == 3);
        setProductType3([...result3]);
        setProductTypeStatus([
          result?.length == 0 ? null : result[0].status == true,
          result2?.length == 0 ? null : result2[0].status == true,
          result3?.length == 0 ? null : result3[0].status == true,
        ]);
        let skuData = data.filter((item) => item.model == model2);
        setSinglePData(skuData);
      })
      .catch((err) => {
        console.log(err);
      });
    setModalShow(true);
    setMultipleFiles([]);
    setEditorContent(data?.description);
    setEditId(data?._id);
    setProductData(data);
    setMarketPriceDate(data?.market_price_new_update_date);
    setCategory(data?.category);
    setChipset2(data?.chipset);
    const selectedChipset = chipsetData.find(
      (brand) => brand?.value === data?.chipset
    );
    setShowChipset2(selectedChipset);
    setBrand2(data.brand);
    const selectedBrand = brandData.find((brand) => brand?.value === data?.brand);
    setShowBrand2(selectedBrand);
    setSeries2(data?.series);
    const selectedSeries = newseries.find(
      (brand) => brand?.value === data?.series
    );
    setShowSeries2(selectedSeries);
    setModel2(data.model);
    setDupModel(data.model)
    setCurrentImage(data?.image);
    setSeoTitle(data?.seoTitle);
    setMetaDescription(data?.metaDescription);
    setSku(data?.sku);
    setUpc(data?.upc);
    setMarketPrice(data?.new_retail_market_price);
    if (data?.new_retail_market_price) {
      setCheckbox1(true);
    }
    setSecondMarketPriceDate(data.market_price_second_update_date);
    setUsedMarketDate(data?.market_price_used_update_date);
    setRetailPrice(data?.new_retail_price);
    setWebsitePrice(data?.new_retail_website_price);
    setSecondHandMarketPrice(data?.new_second_hand_market_price);
    if (data?.new_second_hand_market_price) {
      setCheckbox2(true);
    }
    setSecondHandHouseBid(data?.new_second_hand_house_bid);
    setSecondHandHouseAsk(data?.new_second_hand_house_ask);
    setUsedMarketPrice(data?.used_market_price);
    if (data?.used_market_price) {
      setCheckbox3(true);
    }
    setUsedHouseBid(data?.used_house_bid);
    setUsedHouseAsk(data?.used_house_ask);
    setImage(data?.image);
    setInventoryData(data?.inventory);
    setCOGSPrice(data?.cogs);
    setInputList(data?.videos);
    setProductType([
      data.new_retail_market_price ? 1 : 0,
      data.new_second_hand_market_price ? 2 : 0,
      data.used_market_price ? 3 : 0,
    ]);
  };

  // const handleSubmit = () => {
  //   if (chipset2 == "" || chipset2 == undefined) {
  //     toast.error("Chipset field required");
  //   } else if (brand2 == "" || brand2 == undefined) {
  //     toast.error("Brand field required");
  //   } else if (series2 == "" || series2 == undefined) {
  //     toast.error("Series field required");
  //   } else if (model2 == "" || model2 == undefined) {
  //     toast.error("Model field required");
  //   }
  //   //  else if (sku == "" || sku == undefined) {
  //   //   toast.error("Sku field required");
  //   // }
  //   else {
  //     let error = false;
  //     if (productType.includes(1)) {
  //       if (websitePrice == "" || websitePrice == null) {
  //         toast.error("Website Price Field Required");
  //       }
  //     }
  //     if (!error) {
  //       // setLoading(true);
  //       setRender(render + 1);
  //       let formData = new FormData();
  //       if (
  //         productType1.length == 0 ||
  //         productType2.length == 0 ||
  //         productType3.length == 0
  //       ) {
  //         formData.append("category", selectedValue);
  //         formData.append("chipset", chipset2);
  //         formData.append("brand", brand2);
  //         // formData.append("current_image", currentImage);
  //         formData.append("current_image", multipleFiles[0]);
  //         formData.append("series", series2);
  //         formData.append("model", model2);
  //         formData.append("sku", sku);
  //         formData.append("upc", upc);
  //         formData.append("seoTitle", seoTitle),
  //           formData.append("metaDescription", metaDescription);
  //         if (marketPrice != null) {
  //           formData.append("new_retail_market_price", marketPrice);
  //         }
  //         if (retailPrice != null) {
  //           formData.append("new_retail_price", retailPrice);
  //         }
  //         if (websitePrice) {
  //       formData.append("new_retail_website_price", websitePrice);
  //         }
         
  //         if (secondHandMarketPrice != null) {
  //           formData.append(
  //             "new_second_hand_market_price",
  //             secondHandMarketPrice
  //           );
  //         }
  //         if (secondHandHouseBid != null) {
  //           formData.append("new_second_hand_house_bid", secondHandHouseBid);
  //         }
  //         if (secondHandHouseAsk != null) {
  //           formData.append("new_second_hand_house_ask", secondHandHouseAsk);
  //         }
  //         if (usedMarketPrice != null) {
  //           formData.append("used_market_price", usedMarketPrice);
  //         }
  //         if (usedHouseBid != null) {
  //           formData.append("used_house_bid", usedHouseBid);
  //         }
  //         if (usedHouseAsk != null) {
  //           formData.append("used_house_ask", usedHouseAsk);
  //         }
  //         formData.append("description", editorContent);
  //         for (let i = 0; i < multipleFiles.length; i++) {
  //           formData.append("image", multipleFiles[i]);
  //         }
  //         formData.append("type", productType);
  //         formData.append("remove_image", removeMultiple);
  //         if (marketPriceDate != undefined) {
  //           formData.append("market_price_update_date", marketPriceDate);
  //         }
  //         formData.append("product_type_status", productType);
  //         formData.append("productname", brand2 + " " + series2 + " " + model2);
  //       } else {
  //         formData.append("category", selectedValue),
  //           formData.append("chipset", chipset2),
  //           formData.append("brand", brand2),
  //           formData.append("current_image", currentImage),
  //           formData.append("series", series2),
  //           formData.append("model", model2),
  //           formData.append("sku", sku),
  //           formData.append("upc", upc);
  //         formData.append("seoTitle", seoTitle),
  //           formData.append("metaDescription", metaDescription);
  //         if (marketPrice != null) {
  //           formData.append("new_retail_market_price", marketPrice);
  //         }
  //         if (retailPrice != null) {
  //           formData.append("new_retail_price", retailPrice);
  //         }
  //         formData.append("new_retail_website_price", websitePrice);
  //         if (secondHandMarketPrice != null) {
  //           formData.append(
  //             "new_second_hand_market_price",
  //             secondHandMarketPrice
  //           );
  //         }
  //         if (secondHandHouseBid != null) {
  //           formData.append("new_second_hand_house_bid", secondHandHouseBid);
  //         }
  //         if (secondHandHouseAsk != null) {
  //           formData.append("new_second_hand_house_ask", secondHandHouseAsk);
  //         }
  //         if (usedMarketPrice != null) {
  //           formData.append("used_market_price", usedMarketPrice);
  //         }
  //         if (usedHouseBid != null) {
  //           formData.append("used_house_bid", usedHouseBid);
  //         }
  //         if (usedHouseAsk != null) {
  //           formData.append("used_house_ask", usedHouseAsk);
  //         }
  //         formData.append("description", editorContent);
  //         for (let i = 0; i < multipleFiles.length; i++) {
  //           formData.append("image", multipleFiles[i]);
  //         }
  //         formData.append("remove_image", removeMultiple);
  //         if (marketPriceDate != undefined) {
  //           formData.append("market_price_update_date", marketPriceDate);
  //         }
  //         formData.append("product_type_status", productType);
  //         // formData.append("videos", inputList),
  //         formData.append("productname", brand2 + " " + series2 + " " + model2);
  //       }

  //       setRender(render + 1);
  //       setSaveLoading(true);

  //       // for (const pair of formData.entries()) {
  //       //   console.log(pair[0], pair[1]);
  //       // }
       
  //       // setTimeout(() => {
  //       ExportApi.Updateproduct(editId, formData)
  //         .then((resp) => {
  //      
  //           if (resp.data.Message == "updated") {
  //             checkboxFilterCheck();
  //             // GetData1();
  //             // handleGetEventlistChange();
  //             setSaveLoading(false);
  //             toast.success("Update Product Successfully");

  //             // setLoading(false);
  //             setModalShow(false);
  //  

  //             //  window.location.reload();
  //           } else {
  //             toast.error("Rate limit exceeded. Please try again later.");
  //             setSaveLoading(false);
  //             // setLoading(false);
  //             setModalShow(false);
  //           }
  //         })
  //         .catch((err) => {
  //           toast.error(err);
  //           setSaveLoading(false);
  //           setModalShow(false);
  //         });
  //       // }, 2000);
  //     }
  //   }
  // };
  
  
 
  const handleSubmit = () => {

    if (!chipset2) {
      toast.error("Chipset field required");
      return;
    } else if (!brand2) {
      toast.error("Brand field required");
      return;
    } else if (!series2) {
      toast.error("Series field required");
      return;
    } else if (!model2) {
      toast.error("Model field required");
      return;
    }
  
    let error = false;
    if (productType.includes(1) && (!websitePrice)) {
      toast.error("Website Price Field Required");
      return;
    }
  
    if (!error) {
      setRender(render + 1);
      let formData = new FormData();
  
      // Common fields
      formData.append("category", selectedValue);
      formData.append("chipset", chipset2);
      formData.append("brand", brand2);
      formData.append("series", series2);
      formData.append("model", model2);
      formData.append("dupmodel", dupmodel);
      formData.append("isactivenew", inactivenew);
       
      formData.append("sku", sku);
      formData.append("upc", upc);
      formData.append("seoTitle", seoTitle);
      formData.append("metaDescription", metaDescription);
      if (marketPrice) formData.append("new_retail_market_price", marketPrice);
      if (retailPrice) formData.append("new_retail_price", retailPrice);
      if (websitePrice) formData.append("new_retail_website_price", websitePrice);
      if (secondHandMarketPrice) formData.append("new_second_hand_market_price", secondHandMarketPrice);
      if (secondHandHouseBid) formData.append("new_second_hand_house_bid", secondHandHouseBid);
      if (secondHandHouseAsk) formData.append("new_second_hand_house_ask", secondHandHouseAsk);
      if (usedMarketPrice) formData.append("used_market_price", usedMarketPrice);
      if (usedHouseBid) formData.append("used_house_bid", usedHouseBid);
      if (usedHouseAsk) formData.append("used_house_ask", usedHouseAsk);
      formData.append("description", editorContent);
      formData.append("remove_image", removeMultiple);
      if (marketPriceDate) formData.append("market_price_update_date", marketPriceDate);
      formData.append("product_type_status", productType);
      formData.append("productname", `${brand2} ${series2} ${model2}`);
  
      // Append images in their correct order
      multipleFiles.forEach((file) => {
        formData.append("image", file);
      });
  
      if (productType1.length > 0 && productType2.length > 0 && productType3.length > 0) {

        currentImage.forEach((image) => {
      
          formData.append("current_image", image);
        });
      } else if (multipleFiles.length > 0) {
        
        formData.append("current_image", multipleFiles[0]);
      }

      if (currentImage ) {
        currentImage.forEach((image) => {
  
          formData.append("current_image", image);
      })}
      
  
      setSaveLoading(true);
  
      ExportApi.Updateproduct(editId, formData)
        .then((resp) => {
          if (resp.data.Message === "updated") {
            toast.success("Update Product Successfully");
            // allproductdata();
            setModalShow(false);

            setSearchString("");
            setSelectedData([]);
            setSelectCheckBox(false);
            setActiveStatus(false);
            setInActiveStatus(false);
          
            allproductdata(1, "");


          } 
        })
        .catch((err) => {
    
          toast.error("Error updating product.");
        })
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };

  const handleSubmitdata = () => {
   
    if (!chipset2) {
      toast.error("Chipset field required");
      return;
    } else if (!brand2) {
      toast.error("Brand field required");
      return;
    } else if (!series2) {
      toast.error("Series field required");
      return;
    } else if (!model2) {
      toast.error("Model field required");
      return;
    }
  
    let error = false;
    if (productType.includes(1) && (!websitePrice)) {
      toast.error("Website Price Field Required");
      return;
    }
  
    if (!error) {
      setRender(render + 1);
      let formData = new FormData();
  
      // Common fields
      formData.append("category", selectedValue);
      formData.append("chipset", chipset2);
      formData.append("brand", brand2);
      formData.append("series", series2);
      formData.append("model", model2);
      formData.append("dupmodel", dupmodel);
      formData.append("isactivenew", inactivenew);
       
      formData.append("sku", sku);
      formData.append("upc", upc);
      formData.append("seoTitle", seoTitle);
      formData.append("metaDescription", metaDescription);
      if (marketPrice) formData.append("new_retail_market_price", marketPrice);
      if (retailPrice) formData.append("new_retail_price", retailPrice);
      if (websitePrice) formData.append("new_retail_website_price", websitePrice);
      if (secondHandMarketPrice) formData.append("new_second_hand_market_price", secondHandMarketPrice);
      if (secondHandHouseBid) formData.append("new_second_hand_house_bid", secondHandHouseBid);
      if (secondHandHouseAsk) formData.append("new_second_hand_house_ask", secondHandHouseAsk);
      if (usedMarketPrice) formData.append("used_market_price", usedMarketPrice);
      if (usedHouseBid) formData.append("used_house_bid", usedHouseBid);
      if (usedHouseAsk) formData.append("used_house_ask", usedHouseAsk);
      formData.append("description", editorContent);
      formData.append("remove_image", removeMultiple);
      if (marketPriceDate) formData.append("market_price_update_date", marketPriceDate);
      formData.append("product_type_status", productType);
      formData.append("productname", `${brand2} ${series2} ${model2}`);
  
      // Append images in their correct order
      multipleFiles.forEach((file) => {
        formData.append("image", file);
      });
  
      if (productType1.length > 0 && productType2.length > 0 && productType3.length > 0) {

        currentImage.forEach((image) => {
    
          formData.append("current_image", image);
        });
      } else if (multipleFiles.length > 0) {
    
        formData.append("current_image", multipleFiles[0]);
      }

      if (currentImage ) {
        currentImage.forEach((image) => {
      
          formData.append("current_image", image);
      })}
      
  
      setSaveLoading(true);
  
      ExportApi.Updateproductdata(editId, formData)
        .then((resp) => {
          if (resp.data.Message === "updated") {
            toast.success("Update Product Successfully");
            // allproductdata();
            setModalShow(false);

            setSearchString("");
            setSelectedData([]);
            setSelectCheckBox(false);
            setActiveStatus(false);
            setInActiveStatus(false);
          
            allproductdata(1, "");


          } 
        })
        .catch((err) => {

          toast.error("Error updating product.");
        })
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };
  
 
  
  
  
  const handleMultiData = () => {
    ExportApi.GetSkuData()
      .then((resp) => {
        let Data = resp.data.data;
        let upc = Data.upc;
        const lowercaseWordupc = upc.map((word) => word.toLowerCase());
        setAlreadyUpc(lowercaseWordupc);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (localStorage.getItem("admin")) {
      handleMultiData();
    }
  }, []);

  useEffect(() => {
    if (upc && alreadyUpc.includes(upc.toLowerCase())) {
      setUpcExists(true);
    } else {
      setUpcExists(false);
    }
  }, [upc, alreadyUpc]);

  const handleSelectAll = (e) => {
    let result = e.target.checked;
    if (inActiveStatus) {
      if (result) {
        let data = inactiveData.map((item, i) => {
          return { ...item, isSelected: true };
        });
 
        // const filterData = inactiveData?.map((item) => item?._id);
        const filterData = inactiveData.map((item) => ({
          id: item?._id,
          series: item?.series,
          model: item?.model,
          sku: item?.sku,
          upc: item?.upc,

        }));

        setSelectedData(filterData);
        setInactiveData([...data]);
        setSelectCheckBox(true);
      } else {
        let data = inactiveData.map((item) => {
          return { ...item, isSelected: false };
        });
        setSelectedData([]);
        setInactiveData([...data]);
        setSelectCheckBox(false);
      }
    } else if (activeStatus) {
      if (result) {
        let data = activeData.map((item, i) => {
          return { ...item, isSelected: true };
        });
        // const filterData = activeData?.map((item) => item?._id);
        const filterData = activeData.map((item) => ({
          id: item._id,
          series: item.series, 
          model: item.model, 
          sku: item?.sku,
          upc: item?.upc,
        }));
        setSelectedData(filterData);
        setActiveData([...data]);
        setSelectCheckBox(true);
      } else {
        let data = activeData.map((item) => {
          return { ...item, isSelected: false };
        });
        setSelectedData([]);
        setActiveData([...data]);
        setSelectCheckBox(false);
      }
    } else if (secondHandHouseAskStatus) {
      // let result = Data.filter(
      //   (item) => {
      //     return (
      //       item.used_house_ask !== null &&
      //       item.new_second_hand_house_ask !== null &&
      //       item.used_house_ask > 0 &&
      //       item.new_second_hand_house_ask > 0
      //     );
      //   });
      //   setAskResult(result);
      if (result) {
        let data = askResult.map((item, i) => {
          return { ...item, isSelected: true };
        });
        // const filterData = askResult?.map((item) => item?._id);
        const filterData = askResult.map((item) => ({
          id: item._id,
          series: item.series,
          model: item.model, 
          sku: item?.sku,
          upc: item?.upc,
        }));
        setSelectedData(filterData);
        setAskResult([...data]);
        setSelectCheckBox(true);
      } else {
        let data = askResult.map((item) => {
          return { ...item, isSelected: false };
        });
        setSelectedData([]);
        setAskResult([...data]);
        setSelectCheckBox(false);
      }
    } else if (secondHandHouseBid) {
      // let result = Data.filter(
      //   (item) => {
      //     return (
      //       item.used_house_bid !== null &&
      //       item.new_second_hand_house_bid !== null &&
      //       item.used_house_bid > 0 &&
      //       item.new_second_hand_house_bid > 0
      //     );
      //   });
      //   setBidResult(result);
      if (result) {
        let data = bidResult.map((item, i) => {
          return { ...item, isSelected: true };
        });
        // const filterData = bidResult?.map((item) => item?._id);
        const filterData = bidResult.map((item) => ({
          id: item._id,
          series: item.series, 
          model: item.model,  
          sku: item?.sku,
          upc: item?.upc,
        }));
        setSelectedData(filterData);
        setBidResult([...data]);
        setSelectCheckBox(true);
      } else {
        let data = bidResult.map((item) => {
          return { ...item, isSelected: false };
        });
        setSelectedData([]);
        setBidResult([...data]);
        setSelectCheckBox(false);
      }
    } else {
      if (result) {
        let data = Data.map((item, i) => {
          return { ...item, isSelected: true };
        });
        // const filterData = Data?.map((item) => item?._id);
        const filterData = Data.map((item) => ({
          id: item._id,
          series: item.series,
          model: item.model,
          sku: item?.sku,
          upc: item?.upc,
        }));
        setSelectedData(filterData);
        setData([...data]);
        setSelectCheckBox(true);
      } else {
        let data = Data.map((item) => {
          return { ...item, isSelected: false };
        });
        setSelectedData([]);
        setData([...data]);
        setSelectCheckBox(false);
      }
    }
  };

  const handleCheck = (data, id, list) => {
    const product_id = id;
    if (list == "inactive") {
      const updatedList = inactiveData?.map((item) => {
        if (item._id === product_id) {
          item.isSelected = data;
        }
        return item;
      });
      const allSelected = updatedList?.every((item) => item.isSelected);
      setInactiveData([...updatedList]);
      if (allSelected) {
        setSelectCheckBox(true);
  
        setSelectedData(updatedList.map((item) => ({ id: item._id, series: item.series ,model: item.model, 
          sku: item?.sku,
          upc: item?.upc,})));
      } else {
        setSelectCheckBox(false);
   
        setSelectedData(
          updatedList
            .filter((item) => item.isSelected)
            .map((item) => ({ id: item._id, series: item.series , model: item.model,  sku: item?.sku,
              upc: item?.upc, })) // Include series
        );
      }
    } else if (list == "active") {
      const updatedList = activeData?.map((item) => {
        if (item._id === product_id) {
          item.isSelected = data;
        }
        return item;
      });
      const allSelected = updatedList?.every((item) => item.isSelected);
      setActiveData([...updatedList]);
      if (allSelected) {
        setSelectCheckBox(true);
        // setSelectedData(updatedList.map((item) => item._id));
        setSelectedData(updatedList.map((item) => ({ id: item._id, series: item.series,model: item.model,  sku: item?.sku,
          upc: item?.upc }))); // Include series
      } else {
        setSelectCheckBox(false);
        // setSelectedData(
        //   updatedList.filter((item) => item.isSelected).map((item) => item._id)
        // );
        setSelectedData(
          updatedList.filter((item) => item.isSelected).map((item) => ({ id: item._id, series: item.series,
            model: item.model ,  sku: item?.sku,
            upc: item?.upc})) // Include series
        );
      }
    } else if (list == "secondHandAsk") {
      // let result = Data.filter(
      //   (item) => {
      //     return (
      //       item.used_house_ask !== null &&
      //       item.new_second_hand_house_ask !== null &&
      //       item.used_house_ask > 0 &&
      //       item.new_second_hand_house_ask > 0
      //     );
      //   });
      //   setAskResult(result);
      const updatedList = askResult?.map((item) => {
        if (item._id === product_id) {
          item.isSelected = data;
        }
        return item;
      });
      const allSelected = updatedList?.every((item) => item.isSelected);
      setAskResult([...updatedList]);
      if (allSelected) {
        setSelectCheckBox(true);
        // setSelectedData(updatedList.map((item) => item._id));
        setSelectedData(updatedList.map((item) => ({ id: item._id, series: item.series,model: item.model,
          sku: item?.sku,
          upc: item?.upc
         }))); // Include series
      } else {
        setSelectCheckBox(false);
        // setSelectedData(
        //   updatedList.filter((item) => item.isSelected).map((item) => item._id)
        // );
        setSelectedData(
          updatedList.filter((item) => item.isSelected).map((item) => ({ id: item._id, series: item.series,
            model: item.model,  sku: item?.sku,
            upc: item?.upc })) // Include series
        );
      }
    } else if (list == "secondHandBid") {
      // let result = Data.filter(
      //   (item) => {
      //     return (
      //       item.used_house_bid !== null &&
      //       item.new_second_hand_house_bid !== null &&
      //       item.used_house_bid > 0 &&
      //       item.new_second_hand_house_bid > 0
      //     );
      //   });
      //   setBidResult(result);
      const updatedList = bidResult?.map((item) => {
        if (item._id === product_id) {
          item.isSelected = data;
        }
        return item;
      });
      const allSelected = updatedList?.every((item) => item.isSelected);
      setBidResult([...updatedList]);
      if (allSelected) {
        setSelectCheckBox(true);
        // setSelectedData(updatedList.map((item) => item._id));
        setSelectedData(updatedList.map((item) => ({ id: item._id, series: item.series,model: item.model ,
          sku: item?.sku,
          upc: item?.upc
        }))); // Include series
      } else {
        setSelectCheckBox(false);
        // setSelectedData(
        //   updatedList.filter((item) => item.isSelected).map((item) => item._id)
        // );
        setSelectedData(
          updatedList.filter((item) => item.isSelected).map((item) => ({ id: item._id, series: item.series,
            model: item.model ,  sku: item?.sku,
            upc: item?.upc})) // Include series
        );
      }
    } else {
      const updatedList = Data?.map((item) => {
        if (item._id === product_id) {
          item.isSelected = data;
        }
        return item;
      });
      const allSelected = updatedList?.every((item) => item.isSelected);
      setData([...updatedList]);
      if (allSelected) {
        setSelectCheckBox(true);
        // setSelectedData(updatedList.map((item) => item._id));
        setSelectedData(updatedList.map((item) => ({ id: item._id, series: item.series,model: item.model,
          sku: item?.sku,
          upc: item?.upc
         }))); // Include series
      } else {
        setSelectCheckBox(false);
        // setSelectedData(
        //   updatedList.filter((item) => item.isSelected).map((item) => item._id)
        // );
        setSelectedData(
          updatedList.filter((item) => item.isSelected).map((item) => ({ id: item._id, series: item.series,
            model: item.model ,  sku: item?.sku,
            upc: item?.upc})) // Include series
        );
  
      }
    }
  };

  const handleInventory = () => {

    let result = Data?.filter(
      (item) => (item.type === 1 || item.type === 2) && item._id === selectData
    );


    if (result.length > 0) {
      if (selectData) {
        nevigate("/addInventory/" + selectData);
      } else {
        toast.error("Please Select Product First");
      }
    } else {
      toast.error("Please Select the New Product Only");
    }
  };

  const handleFilterHouseAsk = (data, action) => {
    if (action == "remove") {
      setSecondHandHouseAskStatus(false);
    } else {
      let result = Data.filter((item) => {
        return (
          item.used_house_ask !== null &&
          item.new_second_hand_house_ask !== null &&
          item.used_house_ask > 0 &&
          item.new_second_hand_house_ask > 0
        );
      });
      setAskResult(result);
      // setData(result);
      setSecondHandHouseAskStatus(!secondHandHouseAskStatus);
      setInActiveStatus(false);
      setSecondHandHouseBidStatus(false);
      setInActiveStatus(false);
    }
  };

  const handleFilterHouseBid = (data, action) => {
    if (action == "remove") {
      // setData(bidResult);
      setSecondHandHouseBidStatus(false);
    } else {
      let result = Data.filter((item) => {
        return (
          item.used_house_bid !== null &&
          item.new_second_hand_house_bid !== null &&
          item.used_house_bid > 0 &&
          item.new_second_hand_house_bid > 0
        );
      });
      setBidResult(result);
      // setData(result);
      setSecondHandHouseBidStatus(!secondHandHouseBidStatus);
      setInActiveStatus(false);
      setSecondHandHouseAskStatus(false);
      setInActiveStatus(false);
    }
  };

  const deleteHouseAskBid = () => {
    //setLoading(true);
    setSecondHandHouseAsk(0);
    setSecondHandHouseBid(0);
    //setMainLoader(true);
    ExportApi.Deleteproduct(selectData, secondHandHouseAsk, secondHandHouseBid)
      .then((resp) => {
        if (resp.data.Message == "updated") {
          toast.success("House Asks/Bids Deleted Successfully");
        } else {
          toast.error(resp.data.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleActiveProducts = (data) => {
  //   setActiveStatus(!activeStatus);
  //   let result = data.filter((item) => item.isActive == true);
  //   )
  //   setActiveData(result);
  //   setInActiveStatus(false);
  //   setSecondHandHouseAskStatus(false);
  //   setSecondHandHouseBidStatus(false);
  // };
  
  const fetchActiveProducts = () => {
 
  
    // Prepare the data object for the API call
    const data = {
      isActive: true, // Set to true for active products
    };
  
    // Call the API to fetch active products
    ExportApi.productlistfilter(data)
      .then((resp) => {
 
        const totalPages = resp?.data?.Total;
  
        if (resp?.data) {
          setALLLoader(false);
          let products = resp?.data?.Data;
     
          setPageOffset(0);
          setPage(1);

          // Set the products to state
          // setData(products);
          setTotalPages(totalPages);
          setActiveStatus(true);
          setActiveData(products);
          setInActiveStatus(false);
          setSecondHandHouseAskStatus(false);
          setSecondHandHouseBidStatus(false);
        } else {
        
          setData([]); // Clear data if no products are found
        }
      })
      .catch((error) => {
   
        setALLLoader(false); // Stop loader on error
      });
  };
  
  const fetchInactiveProducts = () => {
   
  
    // Prepare the data object for the API call
    const data = {
      isActive: "", // Set to empty string for inactive products
    };
  
    // Call the API to fetch inactive products
    ExportApi.productlistfilter(data)
      .then((resp) => {
   
        const totalPages = resp?.data?.Total;
  
        if (resp?.data) {
          setPageOffset(0);
          setPage(1);
          setALLLoader(false);
          let products = resp?.data?.Data;
       
  
          // Set the products to state
          // setData(products);
          setTotalPages(totalPages);
          setActiveStatus(false);
          setActiveData(products);
          setSecondHandHouseAskStatus(false);
          setSecondHandHouseBidStatus(false);
        } else {
       
          setData([]); // Clear data if no products are found
        }
      })
      .catch((error) => {
       
        setALLLoader(false); // Stop loader on error
      });
  };
  
  
  // const handleInActiveProducts = (data) => {
  //   setInActiveStatus(!inActiveStatus);
  //   let result = Data.filter((item) => item.isActive == false);
 
  //   setInactiveData(result);
  //   setActiveStatus(false);
  //   setSecondHandHouseAskStatus(false);
  //   setSecondHandHouseBidStatus(false);
  // };

  const fetchInActiveProductstrue = () => {
   
  
    // Prepare the data object for the API call
    const data = {
      type: [],
      chipset: [],
      brand: [],
      series: [],
      model: [],
      isActive: false,
      searchString: "",
      currentPage: 1
  
    };
  

    ExportApi.productlistfilter(data)
      .then((resp) => {
      
        // const totalPages = resp?.data?.Total;
 
       
        if (resp) {
          setALLLoader(false);
          let products = resp?.data?.Data;
       
          setTotalPages(resp?.data?.Total);
       
          setInActiveStatus(!inActiveStatus);
          setInactiveData(products);
     
         // setActiveStatus(true);
     
          setActiveStatus(false);
          setSecondHandHouseAskStatus(false);
          setSecondHandHouseBidStatus(false);
        } else {
      
          setData([]); // Clear data if no products are found
        }
      })
      .catch((error) => {
     
        setALLLoader(false); // Stop loader on error
      });
  };

  const fetchInActiveProductsfalse = () => {
  
  
    // Prepare the data object for the API call
    const data = {
      isActive: "", // Set to true for active products
    };
  
    // Call the API to fetch active products
    ExportApi.productlistfilter(data)
      .then((resp) => {
       
        const totalPages = resp?.data?.Total;
  
        if (resp?.data?.Data.length > 0) {
          setPageOffset(0);
          setPage(1);
          setALLLoader(false);
          let products = resp?.data?.Data;

  
          setInactiveData(products);
          setTotalPages(totalPages);
          setActiveStatus(true);
          setInActiveStatus(!inActiveStatus);
          setActiveStatus(false);
          setSecondHandHouseAskStatus(false);
          setSecondHandHouseBidStatus(false);
        } else {
   
          setData([]); // Clear data if no products are found
        }
      })
      .catch((error) => {
     
        setALLLoader(false); // Stop loader on error
      });
  };

 
 
  

  const handleMakeActiveInactive = () => {
  
  
 
    if (selectedData.length > 0) {
      ExportApi.makeProductActiveInactive(selectedData)
        .then((resp) => {
    
          if (resp?.data?.message === "Product updated successfully") {
            toast.success(resp?.data?.message);
            setSearchString("");
            setSelectedData([]);
            setSelectCheckBox(false);
            setActiveStatus(false);
            setInActiveStatus(false);
          
            allproductdata(1, "");

            // handleGetEventlistChange();
          } else {
            toast.error(resp?.data?.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      toast.error("Please select a Product First");
    }
  };
  const allproductdata = (page,searchString = "") => {
 

    // let isActive;
    // if (inActiveStatus === true) {
    //     isActive = false;
    // } else if (activeStatus === true) {
    //     isActive = true;
    // } else {
    //     isActive = ""; 
    // }

    const data = {
      condition,
      chipset,
      brand,
      series,
      model,
      currentPage: page,
      searchString: searchString,
      isActive: ""
    };
    ExportApi.productlistfilter(data)
      .then((resp) => {
        
   
        
      //   if (inActiveStatus === true) {
      //     setInactiveData(resp.data.Data);
      //     setTotalPages(resp?.data?.Total);
      // } else if (activeStatus === true) {
      //   setActiveData(resp.data.Data);
      //   setTotalPages(resp?.data?.Total);
      // } else {
        setPageOffset(0);
     
        setData(resp.data.Data);
        setTotalPages(resp?.data?.Total);
      // }
      })
      .catch((err) => {
        console.log(err);
      });
  };





  const handleFilterModel = () => {
    setShowFilterModel(true);
  };

  const SearchFilterCheck = () => {
    setMainLoader(true);
    ExportApi.GetAllProductFilter(condition, chipset, brand, series, model)
      .then((resp) => {
        setMainLoader(false);
        setData(resp.data.Data);
      })
      .catch((err) => {
        console.log(err);
        setMainLoader(false);
      });
  };

  useEffect(() => {
    checkboxFilterCheck(page);
  }, [page]);

  const checkboxFilterCheck = (page) => {
 

    let isActive;
    if (inActiveStatus === true) {
        isActive = false;
    } else if (activeStatus === true) {
        isActive = true;
    } else {
        isActive = ""; // Set to an empty string if both are false
    }

    const data = {
      condition,
      chipset,
      brand,
      series,
      model,
      currentPage: page,
      searchString: searchString,
      isActive: isActive
    };
    ExportApi.productlistfilter(data)
      .then((resp) => {
        
   
        
        if (inActiveStatus === true) {
          setInactiveData(resp.data.Data);
          setTotalPages(resp?.data?.Total);
      } else if (activeStatus === true) {
        setActiveData(resp.data.Data);
        setTotalPages(resp?.data?.Total);
      } else {
        setPageOffset(0);
      
        setData(resp.data.Data);
        setTotalPages(resp?.data?.Total);
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChipsetFilter = (e, i) => {
    const { name, checked } = e.target;
    let index = chipset.indexOf(name);
    if (checked || index === -1) {
      chipset.push(name);
      chipsetKey[i][name] = checked;
      setchipset([...chipset]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    } else {
      chipsetKey[i][name] = checked;
      chipset.splice(index, 1);
      setchipset([...chipset]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    }
  };

  const conditionKeyFilter = (e, i) => {
    const { name, checked, min } = e.target;
    let number = parseInt(min);
    let index = condition.indexOf(number);
    let index1 = condition1.indexOf(name);
    if (checked || index === -1 || index1 === -1) {
      condition.push(number);
      condition1.push(name);
      conditionKey[i][name] = checked;
      setcondition([...condition]);
      setcondition1([...condition1]);
      // SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    } else {
      conditionKey[i][name] = checked;
      condition.splice(index, 1);
      setcondition([...condition]);
      condition1.splice(index1, 1);
      setcondition1([...condition1]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    }
  };

  const modalFilter = (e, i) => {
    const { name, checked } = e.target;
    let index = chipset.indexOf(name);
    if (checked) {
      model.push(name);
      modalKey[i][name] = checked;
      setmodel([...model]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    } else {
      modalKey[i][name] = checked;
      model.splice(index, 1);
      setmodel([...model]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    }
  };

  const seriesFilter = (e, i) => {
    const { name, checked } = e.target;
    let index = series.indexOf(name);
    if (checked) {
      series.push(name);
      seriesKey[i][name] = checked;
      setseries([...series]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    } else {
      seriesKey[i][name] = checked;
      series.splice(index, 1);
      setseries([...series]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    }
  };

  const BrandtFilter = (e, i) => {
    const { name, checked } = e.target;
    let index = brand.indexOf(name);
    if (checked) {
      brand.push(name);
      brandKey[i][name] = checked;
      // brandKey[i][name] = checked;
      setbrand([...brand]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    } else {
      brandKey[i][name] = checked;
      brand.splice(index, 1);
      setbrand([...brand]);
      //SearchFilterCheck();
      checkboxFilterCheck();
      setPageOffset(0);
      setPage(1);
    }
  };

  const handlechipsetshowmore = (data) => {
    if (data == "More") {
      setChipsetShow(true);
    } else {
      setChipsetShow(false);
    }
  };

  const handlebrandshowmore = (data) => {
    if (data == "More") {
      setBrandShow(true);
    } else {
      setBrandShow(false);
    }
  };

  const handleSeriesshowmore = (data) => {
    if (data == "More") {
      setSeriesShow(true);
    } else {
      setSeriesShow(false);
    }
  };

  const handlemodelshowmore = (data) => {
    if (data == "More") {
      setModelShow(true);
    } else {
      setModelShow(false);
    }
  };

  const handleMarketPrice = (e) => {
    if (e.target.value) {
      date = new Date();
      setMarketPriceDate(moment(date).format("l"));
      setMarketPrice(e.target.value);
      handleItemss(e);
    }
  };

  const handleSecondHandMarketPrice = (e) => {
    if (e.target.value) {
      date = new Date();
      setSecondMarketPriceDate(moment(date).format("l"));
      setSecondHandMarketPrice(e.target.value);
      handleItemss(e);
    }
  };

  const handleUsedMarketPrice = (e) => {
    if (e.target.value) {
      date = new Date();
      setUsedMarketDate(moment(date).format("l"));
      setUsedMarketPrice(e.target.value);
      handleItemss(e);
    }
  };

  // const dragStart = (e, position) => {
  //   dragItem.current = position;
  // };

  // const dragEnter = (e, position) => {
  //   dragOverItem.current = position;
  // };

  // const drop = (e) => {
  //   const copyListItems = [...currentImage];
  //   const dragItemContent = copyListItems[dragItem.current];
  //   copyListItems.splice(dragItem.current, 1);
  //   copyListItems.splice(dragOverItem.current, 0, dragItemContent);
  //   dragItem.current = null;
  //   dragOverItem.current = null;
  //   setCurrentImage(copyListItems);
  // };

  // const dragNewStart = (e, position) => {
  //   dragItem.current = position;
  // };

  // const dragNewEnter = (e, position) => {
  //   dragOverItem.current = position;
  // };

  // const dropNew = (e) => {
  //   const copyListItems = [...multipleFiles];
  //   const dragItemContent = copyListItems[dragItem.current];
  //   copyListItems.splice(dragItem.current, 1);
  //   copyListItems.splice(dragOverItem.current, 0, dragItemContent);
  //   dragItem.current = null;
  //   dragOverItem.current = null;
  //   setMultipleFiles(copyListItems);
  // };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    e.preventDefault();
    const copyListItems = [...currentImage];
    const dragItemContent = copyListItems[dragItem.current];
    
    // Rearrange the images
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    
    // Reset refs
    dragItem.current = null;
    dragOverItem.current = null;
    
    setCurrentImage(copyListItems);
  };

  // Drag start for new uploaded images
  const dragNewStart = (e, position) => {
    dragItem.current = position;
  };

  const dragNewEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dropNew = (e) => {
    e.preventDefault();
    const copyListItems = [...multipleFiles];
    const dragItemContent = copyListItems[dragItem.current];
    
    // Rearrange the files
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    
    // Reset refs
    dragItem.current = null;
    dragOverItem.current = null;
    
    setMultipleFiles(copyListItems);
  };

  const handledeletesingleimage = async (name) => {
    let data = { imageName: [name], sku };
    try {
      await ExportApi.deletepicbyname(data);
      // toast.success("Image deleted successfully");
      if (multipleFiles?.length > 0) {
        let result = multipleFiles.filter((item) => item.path !== name);
        setMultipleFiles(result);
      } else {
        let result = currentImage.filter((file) => file !== name);
        setCurrentImage(result);
      }
      let index = removeMultiple.indexOf(name);
      if (index === -1) {
        removeMultiple.push(name);
        setRemoveMultiple(removeMultiple);
      }
      return { success: true, message: "Image deleted successfully." };
    } catch (error) {
      console.error("Error deleting image:", error);
      // toast.error("Error deleting image");
      return { success: false, message: "Failed to delete image." };
    }
  };

  const clearAllFilters = () => {
    setmodel([]);
    setseries([]);
    setbrand([]);
    setchipset([]);
    setcondition([]);
    setcondition1([]);
    setActiveStatus(false);
    setInActiveStatus(false);
    setPage(1);
    setSelectCheckBox(false);
    // setPageOffset(0);
    setPageOffset(0);
    if (localStorage.getItem("tokenuser")) {
      //GetData1(prams?.id, JSON.parse(localStorage.getItem("tokenuser")).id);
      GetData1();
    } else if (localStorage.getItem("admin")) {
      handleGetEventlistChange();
      //GetData1(prams?.id, JSON.parse(localStorage.getItem("admin")).id);
      GetData1();
    }
    //  else {
    //   handleGetEventlistChange();
    //   GetData1();
    // }
  };

  const conditionKeyFilterClose = (i, name) => {
    setPageOffset(0);
    setPage(1);
    let index = -1;
    for (let j = 0; j < conditionKey.length; j++) {
      if (Object.keys(conditionKey[j])[0] === name) {
        index = j;
   
        break;
      }
    }
    if (index !== -1) {
      conditionKey[index][name] = false;
      condition1.splice(i, 1);
      condition.splice(i, 1);
      setcondition1([...condition1]);
      setcondition([...condition]);
      //SearchFilterCheck();
      checkboxFilterCheck();
    } else {
      console.error(`Key ${name} not found in conditionKey`);
    }
  };

  const ChipsetFilterClose = (id, name) => {
    setPageOffset(0);
    setPage(1);
    let index = -1;
    for (let i = 0; i < chipsetKey.length; i++) {
      if (Object.keys(chipsetKey[i])[0] == name) {
        index = i;
      }
    }
    chipsetKey[index][name] = false;
    chipset.splice(id, 1);
    setchipset([...chipset]);
    //SearchFilterCheck();
    checkboxFilterCheck();
  };

  const brandtFilterClose = (i, name) => {
    setPageOffset(0);
    setPage(1);
    let index = -1;
    for (let i = 0; i < brandKey.length; i++) {
      if (Object.keys(brandKey[i])[0] == name) {
        index = i;
      }
    }
    brandKey[index][name] = false;
    brand.splice(i, 1);
    setbrand([...brand]);
    //SearchFilterCheck();
    checkboxFilterCheck();
  };

  const seriesFilterCsole = (i, name) => {
    setPageOffset(0);
    setPage(1);
    let index = -1;
    for (let i = 0; i < seriesKey.length; i++) {
      if (Object.keys(seriesKey[i])[0] == name) {
        index = i;
      }
    }
    seriesKey[index][name] = false;
    series.splice(i, 1);
    setseries([...series]);
    //SearchFilterCheck();
    checkboxFilterCheck();
  };

  const modalFilterCsole = (i, name) => {
    setPageOffset(0);
    setPage(1);
    let index = -1;
    for (let i = 0; i < modalKey.length; i++) {
      if (Object.keys(modalKey[i])[0] == name) {
        index = i;
      }
    }
    modalKey[index][name] = false;
    model.splice(i, 1);
    setmodel([...model]);
    //SearchFilterCheck();
    checkboxFilterCheck();
  };

  const handleOnSearchBrand = (selectedBrand) => {
    setShowBrand2(selectedBrand);
    setBrand2(selectedBrand.value);
  };
  const handleOnSearchChipset = (selectedChipset) => {
    setShowChipset2(selectedChipset);
    setChipset2(selectedChipset.value);
  };
  const handleOnSearchSeries = (selectedSeries) => {
    setShowSeries2(selectedSeries);
    setSeries2(selectedSeries.value);
  };
  const handleUpdateBulkPriceClick = () => {
    setModalShownew(true);
  };

  const handlebulkupdate = () => {
    if (!option1Checked && !option2Checked) {
      alert("Please select at least one checkbox");
      return;
    }
    if (!houseask && !housebid) {
      alert("You must enter either houseask or housebid.");
      return;
    }
    setBtnLoading(true);
    const data = selectedSeries;
    const series = data.value;
    const house_ask = houseask;
    const house_bid = housebid;
    const id = adminId;
    const used = option1Checked;
    const secondhand = option2Checked;
    const form = {
      series,
      house_ask,
      house_bid,
      id,
      used,
      secondhand,
    };
    ExportApi.Updatebulkproduct(form)
      .then((resp) => {
        if (resp.data.status === true) {
          toast.success(resp?.data?.message);
          setHouseask("");
          setHousebid("");
          setOption1Checked("");
          setOption2Checked("");
          setModalShownew(false);
          setBtnLoading(false);
          GetData1();
          handleGetEventlistChange();
        } else {
          toast.error(resp?.data?.message);
          setBtnLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setBtnLoading(false);
      });
  };

  const deleteallimages = async () => {
    let data = { imageName: currentImage, sku };
    try {
      const resp = await ExportApi.deletepicbyname(data);
      setCurrentImage([]);
      // toast.success("Images deleted successfully");
      return { success: true, message: "Images deleted successfully." };
    } catch (err) {
      console.error("Error deleting image:", err);
      // toast.error("Error deleting image");
      return { success: false, message: "Failed to delete image." };
    }
  };

  const handlePageClick =  (event, value) => {
    
    setPage(value);
    setSelectCheckBox(false);
  };

   

  return (
    <div>
      <ToastContainer />
      <Row className="py-2 mt-4">
        <Col lg={5}>
          <h5 className="mb-0 text-start fw-bold">Products</h5>
        </Col>
        <Col lg={7}>
          <div className="d-sm-flex gap-3 align-items-center justify-content-end">
            {/* <Button
              className="bg-none border-0 text-dark fw-bold"
              onClick={handleUpdateBulkPriceClick}
            >
              Update Bulk Price
            </Button> */}
            <Button
              className="bg-none border-0 text-dark fw-bold"
              onClick={() => nevigate("/Createproduct")}
            >
              Add Product
            </Button>
            <Button
              className="bg-none border-0 text-dark fw-bold"
              onClick={handleInventory}
            >
              Add Inventory
            </Button>
            <Dropdown>
              <Dropdown.Toggle
                className="border-0 bg-none text-dark fw-bold"
                id="dropdown-basic"
              >
                More action
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2">
                <Dropdown.Item href="#">Manual Payout to Sellout</Dropdown.Item>
                <Dropdown.Item href="#">Flag Order</Dropdown.Item>
                <Dropdown.Item href="#">Send Payment Request</Dropdown.Item>
                <Dropdown.Item href="#">
                  Place Buyer with new seller
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <div className="product-tool-list mb-3 bg-dark p-3 d-md-flex align-items-center justify-content-between rounded mt-3">
            <div className="d-flex align-items-center justify-content-between search-box">
              <h4 className="mb-0 text-white me-3 fw-bold text-nowrap">
                Product Tool
              </h4>
              <Form.Control
                type="search"
                placeholder="Search GPU"
                value={searchString}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <div className="view_ask_tab d-flex">
              {secondHandHouseAskStatus ? (
                <Button
                  className="bg-success text-white border-0 mb-2 mb-xl-0"
                  onClick={() => handleFilterHouseAsk(Data, "remove")}
                >
                  View House Asks
                </Button>
              ) : (
                <Button
                  className="bg-light text-black border-0 mb-2 mb-xl-0"
                  onClick={() => handleFilterHouseAsk(Data, "add")}
                >
                  View House Asks
                </Button>
              )}
              {secondHandHouseBidStatus ? (
                <Button
                  className="bg-success text-white border-0 mb-2 mb-xl-0 mx-2"
                  onClick={() => handleFilterHouseBid(Data, "remove")}
                >
                  View House Bids
                </Button>
              ) : (
                <Button
                  className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2"
                  onClick={() => handleFilterHouseBid(Data, "add")}
                >
                  View House Bids
                </Button>
              )}
              <Button
                className="bg-light text-black border-0 mb-2 mb-xl-0"
                onClick={() => deleteHouseAskBid()}
              >
                Delete House Asks/Bids
              </Button>
              {activeStatus ? (
                <Button
                  className="bg-success text-white border-0 mb-2 mb-xl-0 mx-2"
                  
                  onClick={fetchInactiveProducts}
                >
                  Active
                </Button>
              ) : (
                <Button
                  className="bg-light text-black border-0 mb-2 mb-xl-0 ms-2"
                  onClick={fetchActiveProducts}
                >
                  Active
                </Button>
              )}

              {inActiveStatus ? (
                <Button
                  className="bg-success text-white border-0 mb-2 mb-xl-0 mx-2"
                  // onClick={() => handleInActiveProducts(Data)}
                  onClick={fetchInActiveProductsfalse}
                >
                  Inactive
                </Button>
              ) : (
                <Button
                  className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2"
                  // onClick={() => handleInActiveProducts(Data)}
                  onClick={fetchInActiveProductstrue}
                >
                  Inactive
                </Button>
              )}

              <Button
                className="bg-light text-black border-0 mb-2 mb-xl-0 me-2"
                onClick={handleMakeActiveInactive}
              >
                Make Active/Inactive
              </Button>

              <Button
                className="bg-light text-black border-0 mb-2 mb-xl-0 px-4"
                onClick={handleFilterModel}
              >
                Filter
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={12} className="mt-3">
          {/* Start Clear Filter */}
          <div className="text-center text-sm-start">
            <Button
              className="rounded-0 bg-white border text-dark px-5 py-2 fw-bold"
              onClick={() => clearAllFilters()}
            >
              Clear all filters
            </Button>
            {conditionKey.map((val, i) => {
              let key = condition1[i];
              let data = conditionKey.filter((item) => item[key] == true);
              return (
                <>
                  {data.length != 0 ? (
                    <span
                      //  key={i}
                       key={i}
                      className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    >
                      {condition1[i]}
                      <Button
                        className="bg-none border-0 py-0 text-dark"
                        onClick={() =>
                          conditionKeyFilterClose(i, condition1[i])
                        }
                      >
                        X
                      </Button>
                    </span>
                  ) : null}
                </>
              );
            })}
            {chipsetKey.map((val, i) => {
              let key = chipset[i];
              let data = chipsetKey.filter((item) => item[key] == true);
              return (
                <>
                  {data.length != 0 ? (
                    <>
                      <span
                        //  key={i}
                         key={i}
                        className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                      >
                        {chipset[i]}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() => ChipsetFilterClose(i, chipset[i])}
                        >
                          X
                        </Button>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
            {brandKey?.map((val, i) => {
              let key = brand[i];
              let data = brandKey.filter((item) => item[key] == true);
              return (
                <>
                  {data.length != 0 ? (
                    <span
                      //  key={i}
                       key={i}
                      className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    >
                      {brand[i]}{" "}
                      <Button
                        className="bg-none border-0 py-0 text-dark"
                        onClick={() => brandtFilterClose(i, brand[i])}
                      >
                        X
                      </Button>
                    </span>
                  ) : null}
                </>
              );
            })}
            {seriesKey.map((val, i) => {
              let key = series[i];
              let data = seriesKey.filter((item) => item[key] == true);
              return (
                <>
                  {data.length != 0 ? (
                    <span
                       key={i}
                      className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    >
                      {series[i]}{" "}
                      <Button
                        className="bg-none border-0 py-0 text-dark"
                        onClick={() => seriesFilterCsole(i, series[i])}
                      >
                        X
                      </Button>
                    </span>
                  ) : null}
                </>
              );
            })}
            {modalKey.map((val, i) => {
              let key = model[i];
              let data = modalKey.filter((item) => item[key] == true);
              return (
                <>
                  {data.length != 0 ? (
                    <span
                       key={i}
                      className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    >
                      {model[i]}{" "}
                      <Button
                        className="bg-none border-0 py-0 text-dark"
                        onClick={() => modalFilterCsole(i, model[i])}
                      >
                        X
                      </Button>
                    </span>
                  ) : null}
                </>
              );
            })}
          </div>
          {/* End Clear Filter */}
          <div>
            <div className="product_list_table table-responsive mb-3">
              <Table bordered striped className="align-middle graphic-table product-table ">
                <thead>
                  <tr>
                    <th className="text-start">
                      <Form.Check
                        className="d-inline me-2"
                        type="checkbox"
                        checked={selectCheckBox}
                        onChange={(e) => handleSelectAll(e)}
                      />
                      <label>Select All</label>
                    </th>
                    <th onClick={() => sortHere("chipset")}>
                      Chipset
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("brand")}>
                      Brand
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("series")}>
                      Series
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("model")}>
                      Model
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("inventory")}>
                      Inventory
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("new_retail_market_price")}>
                      Market Price
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th>
                      Buy Spread
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th>
                      Sell Spread
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th>
                      Spread
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th
                      onClick={() =>
                        sortHereLowestAsk("new_retail_website_price", "bid")
                      }
                    >
                      Highest Bid
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th
                      onClick={() =>
                        sortHereLowestAsk("new_retail_market_price", "ask")
                      }
                    >
                      Lowest Ask
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg>
                    </th>
                    <th onClick={() => sortHere("new_second_hand_house_bid")}>
                      Your Ask/Bid
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-chevron-expand"
                        viewBox="0 0 16 16"
                      >
                        <path
                          filerule="evenodd"
                          d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                        />
                      </svg> */}
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
         
                  {/* {allloader == true ? <> 
                  <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="loader-icon custom-loader"
                        style={{ marginBlock: "80px" }}
                      >
                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                    </div></> :   */}
                    
                    {Data ? (
                    <>
                      {inActiveStatus ? (
                        inactiveData?.length > 0 ? (
                          inactiveData?.map((val, i) => {
                        
                            // inactiveData.map((val, i) => {
                            return (
                              <tr  key={i}>
                                <td>
                                  <span className="float-end" style={{ cursor: "default" }}>
                                    {/* {val?.type == 1
                                       ? "New"
                                       : val?.type == 2
                                         ? "2nd Hand"
                                         : "Used"} */}
                                    {val?.type == 2 ? "New" : "Used"}
                                  </span>
                                  <div className="product_description_view">
                                    <div className="d-flex align-items-end justify-content-between">
                                      <Form.Check
                                        className="d-inline"
                                        type="checkbox"
                                        checked={val?.isSelected}
                                        onChange={(e) =>
                                          handleCheck(
                                            e.target.checked,
                                            val?._id,
                                            "inactive"
                                          )
                                        }
                                      />
                                       <div className="buysellimg product-table-img"> 

                                      <LazyLoadImage
                                        src={`${val?.image[0]}`}
                                        //src={val?.image[0]?.startsWith('http') ? val?.image[0] : `${imageUrl}${val?.image[0]}`}
                                    
                                        // onClick={() =>
                                        //   nevigate(`/product/${val._id}`)
                                        // }
                                        onClick={() => window.open(`/product/${val._id}`, '_blank')}
                                      />
                                      </div>
                                      <div> 
                                      {descriptionindex == i ? (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex()}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                        
                                      ) : (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex(i)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                          </div>
                                    </div>
                                    <OutsideClickHandler
                                      onOutsideClick={(e) => {
                                        if (
                                          !e.target.closest(".modal_custom_")
                                        ) {
                                          setdescriptionindex();
                                        }
                                      }}
                                    ></OutsideClickHandler>
                                  </div>
                                  <div> 
                                  {descriptionindex == i ? (
                                    <div className="modal_custom_">
                                      <div className="inner_customModal_one">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: `${
                                              val?.description
                                                ? val?.description.substring(
                                                    0,
                                                    351
                                                  )
                                                : "No product description"
                                            }${
                                              val?.description?.length > 351
                                                ? "..."
                                                : ""
                                            }`,
                                          }}
                                        ></h5>
                                        {val?.description?.length > 351 ? (
                                          <a
                                            onClick={() =>
                                              nevigate(`/product/${val._id}`)
                                            }
                                          >
                                            See more
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) : ""}
                                  </div>
                                </td>

                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.chipset}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.brand}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.series}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="text-breakdown"
                                    style={{ cursor: "default" }}
                                    title={val.model}
                                  >
                                    {val.model}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                 {val.inventory}  
                                  </div>
                                </td>
                                <td>
                                  <div>
                               
                                 {/* {val?.type == 1
                                    ? val?.new_retail_market_price
                                      ? "$" + val?.new_retail_market_price
                                      : 0
                                    : val?.type == 2
                                    ? val.new_second_hand_house_ask
                                      ? val?.new_second_hand_market_price
                                        ? "$" +
                                          val?.new_second_hand_market_price
                                        : 0
                                      : 0
                                    : val.used_house_ask
                                    ? val?.used_market_price
                                      ? "$" + val?.used_market_price
                                      : 0
                                    : 0} */}
                          {val?.type == 1
                                  ? val?.new_retail_market_price
                                    ? "$" + val?.new_retail_market_price
                                    : 0
                                  : val?.type == 2
                                  ? val.new_second_hand_house_ask
                                    ? val?.new_second_hand_market_price
                                      ? "$" + val?.new_second_hand_market_price
                                      : 0
                                    : 0
                                  : val?.used_house_ask
                                  ? val?.used_market_price
                                    ? "$" + val?.used_market_price
                                    : 0
                                  : ""}
                                 
                                    </div>
                                </td>
                                {/* Buy Spread */}
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <BuySpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        )?.id
                                      }
                                      buy={
                                        typeof val.highest_bid === "number"
                                          ? val.highest_bid
                                          : 0
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <SellSpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      sell={val.lowest_ask}
                                    />
                                  )}
                                </td>

                                <td>
                                  {val?.type == 1
                                    ? "Retail Only"
                                    : Math.abs(
                                        val.highest_bid - val.lowest_ask
                                      ).toFixed(2)}
                                </td>

                                {/* Highest Bid */}
                                <td>
                                  {val?.type == 1 ? (
                                    " Retail Only"
                                  ) : val?.highest_bid > 0 ? (
                                    <Button
                                      className="border bg-danger"
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - {"$" + val?.highest_bid}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="border bg-danger"
                                      disabled
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - "TBD"
                                    </Button>
                                  )}
                                </td>

                                <td >
                                <div className="d-flex align-items-center"> 
                                  {
                                    //   <Button
                                    //   className="border bg-success"
                                    //   disabled
                                    //   onClick={() =>
                                    //     nevigate("/bidask/" + val?._id + "/buy")
                                    //   }
                                    // >
                                    //   Buy Now - ${val?.new_retail_website_price}
                                    // </Button>
                                    val?.type == 1 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - $
                                        {val?.new_retail_website_price}
                                      </Button>
                                    ) : val?.lowest_ask > 0 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open(  "/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - {"$" + val?.lowest_ask}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="border bg-success"
                                        disabled
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open( "/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - "TBD"
                                      </Button>
                                    )
                                  }
                                  <div> 
                                  {AskBid == i ? (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      disabled
                                      onClick={() => setAskBid()}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      onClick={() => ASKBID(val?._id, i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  )}
                                  </div>
                                  <div> 
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setAskBid();
                                      setCount(0);
                                      setCount1(0);
                                    }}
                                  >
                                    {AskBid == i ? (
                                      <div className="big-modal inner_customModal custom-tab-mode">
                                        <div
                                          className="overlay"
                                          onClick={() => setAskBid()}
                                        >
                                         
                                          <tr>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Bid</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Bid?.length > 0
                                                        ? Bid.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val?.bidAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Bids"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Ask</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count1 == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {ask?.length > 0
                                                        ? ask.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val.askAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Asks"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        </div>
                                      </div>
                                    ) : //   </div>
                                    // </div>
                                    ""}
                                  </OutsideClickHandler>
                                  </div>
                                  </div>
                                  
                                </td>

                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <UserAskBidList
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {/* {val.upc} */}
                                  <Button
                                    className="mx-2 border bg-none text-dark border-dark"
                                    onClick={() => handleUpdate(val)}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (

                  //         allloader == true ? <> 
                  // <div
                  //     style={{
                  //       alignItems: "center",
                  //       justifyContent: "center",
                  //     }}
                  //   >
                  //     <div
                  //       className="loader-icon custom-loader"
                       
                  //     >
                  //       <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  //     </div>
                  //   </div></> :  
                          <div className="d-flex justify-content-center">
                            <span>
                              <b>No Data Found</b>
                            </span>
                          </div>
                        )
                      ) : activeStatus ? (
                        activeData?.length > 0 ? (
                        
                          activeData?.map((val, i) => {
                      
                            return (
                              <tr  key={i}>
                                <td>
                                  <span className="float-end" style={{ cursor: "default" }}>
                                    {/* {val?.type == 1
                                       ? "New"
                                       : val?.type == 2
                                         ? "2nd Hand"
                                         : "Used"} */}
                                    {val?.type == 2 ? "New" : "Used"}
                                  </span>
                                  <div className="product_description_view">
                                    <div className="d-flex align-items-end justify-content-between">
                                      <Form.Check
                                        className="d-inline"
                                        type="checkbox"
                                        checked={val?.isSelected}
                                        onChange={(e) =>
                                          handleCheck(
                                            e.target.checked,
                                            val?._id,
                                            "active"
                                          )
                                        }
                                      />
                                       <div className="buysellimg product-table-img"> 

                                      <LazyLoadImage
                                        //src={`${imageUrl}${val?.image[0]}`}
                                        src={
                                          val?.image[0]?.startsWith("http")
                                            ? val?.image[0]
                                            : `${imageUrl}${val?.image[0]}`
                                        }
                                   
                                        // onClick={() =>
                                        //   nevigate(`/product/${val._id}`)
                                        // }
                                        onClick={() => window.open(`/product/${val._id}`, '_blank')}
                                      />
                                      </div>
                                      <div> 
                                      {descriptionindex == i ? (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex()}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      ) : (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex(i)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                      </div>
                                    </div>

                                    <OutsideClickHandler
                                      onOutsideClick={(e) => {
                                        if (
                                          !e.target.closest(".modal_custom_")
                                        ) {
                                          setdescriptionindex();
                                        }
                                      }}
                                    >
                                      {/* <div className="custom_modal_outside_popup"> */}

                                      {/* </div> */}
                                    </OutsideClickHandler>
                                  </div>
                                  <div> 
                                  {descriptionindex == i ? (
                                    <div className="modal_custom_">
                                      <div className="inner_customModal_one">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: `${
                                              val?.description
                                                ? val?.description.substring(
                                                    0,
                                                    351
                                                  )
                                                : "No product description"
                                            }${
                                              val?.description?.length > 351
                                                ? "..."
                                                : ""
                                            }`,
                                          }}
                                        ></h5>
                                        {val?.description?.length > 351 ? (
                                          <a
                                            onClick={() =>
                                              nevigate(`/product/${val._id}`)
                                            }
                                          >
                                            See more
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) :  ""}
                                  </div>
                                </td>

                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.chipset}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.brand}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.series}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="text-breakdown"
                                    style={{ cursor: "default" }}
                                    title={val.model}
                                  >
                                    {val.model}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.inventory}
                                  </div>
                                </td>
                                <td>
                                  
                                       <div style={{ cursor: "default" }}>
                                    
                                       {val?.type == 1
                                  ? val?.new_retail_market_price
                                    ? "$" + val?.new_retail_market_price
                                    : 0
                                  : val?.type == 2
                                  ? val.new_second_hand_house_ask
                                    ? val?.new_second_hand_market_price
                                      ? "$" + val?.new_second_hand_market_price
                                      : 0
                                    : 0
                                  : val?.used_house_ask
                                  ? val?.used_market_price
                                    ? "$" + val?.used_market_price
                                    : 0
                                  : ""}
                                   
                                  </div>
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <BuySpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      buy={
                                        typeof val.highest_bid === "number"
                                          ? val.highest_bid
                                          : 0
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <SellSpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      sell={val.lowest_ask}
                                    />
                                  )}
                                </td>

                                <td>
                                  {val?.type == 1
                                    ? "Retail Only"
                                    : Math.abs(
                                        val.highest_bid - val.lowest_ask
                                      ).toFixed(2)}
                                </td>

                                {/* Highest Bid */}
                                <td>
                                  {val?.type == 1 ? (
                                    " Retail Only"
                                  ) : val?.highest_bid > 0 ? (
                                    <Button
                                      className="border bg-danger"
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - {"$" + val?.highest_bid}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="border bg-danger"
                                      disabled
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - "TBD"
                                    </Button>
                                  )}
                                </td>

                                <td>
                                <div className="d-flex align-items-center"> 
                                  {
                                    //   <Button
                                    //   className="border bg-success"
                                    //   disabled
                                    //   onClick={() =>
                                    //     nevigate("/bidask/" + val?._id + "/buy")
                                    //   }
                                    // >
                                    //   Buy Now - ${val?.new_retail_website_price}
                                    // </Button>
                                    val?.type == 1 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - $
                                        {val?.new_retail_website_price}
                                      </Button>
                                    ) : val?.lowest_ask > 0 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - {"$" + val?.lowest_ask}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="border bg-success"
                                        disabled
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - "TBD"
                                      </Button>
                                    )
                                  }
                                  <div> 
                                  {AskBid == i ? (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      disabled
                                      onClick={() => setAskBid()}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      onClick={() => ASKBID(val?._id, i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  )}
                                  </div>
                                   <div> 
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setAskBid();
                                      setCount(0);
                                      setCount1(0);
                                    }}
                                  >
                                    {AskBid == i ? (
                                      <div className="big-modal inner_customModal custom-tab-mode">
                                        <div
                                          className="overlay"
                                          onClick={() => setAskBid()}
                                        >
                                        
                                          <tr>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Bid</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Bid?.length > 0
                                                        ? Bid.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val?.bidAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Bids"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Ask</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count1 == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {ask?.length > 0
                                                        ? ask.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val.askAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Asks"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        </div>
                                      </div>
                                    ) : //   </div>
                                    // </div>
                                    ""}
                                  </OutsideClickHandler>
                                  </div>
                                  </div>
                                </td>

                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <UserAskBidList
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mx-2 border bg-none text-dark border-dark"
                                    onClick={() => handleUpdate(val)}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                  //         allloader == true ? <> 
                  // <div
                  //     style={{
                  //       alignItems: "center",
                  //       justifyContent: "center",
                  //     }}
                  //   >
                  //     <div
                  //       className="loader-icon custom-loader"
               
                  //     >
                  //       <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  //     </div>
                  //   </div></> :  
                    <div> 
                          <span>No Data Found</span>
                          </div>
                        )
                      ) : secondHandHouseAskStatus ? (
                        askResult?.length > 0 ? (
                          askResult.map((val, i) => {
                      
                            return (
                              <tr  key={i}>
                                <td>
                                  <span className="float-end" style={{ cursor: "default" }}>
                                    {/* {val?.type == 1
                                       ? "New"
                                       : val?.type == 2
                                         ? "2nd Hand"
                                         : "Used"} */}
                                    {val?.type == 2 ? "New" : "Used"}
                                  </span>
                                  <div className="product_description_view">
                                    <div className="d-flex align-items-end justify-content-between ">
                                      <Form.Check
                                        className="d-inline"
                                        type="checkbox"
                                        checked={val?.isSelected}
                                        onChange={(e) =>
                                          handleCheck(
                                            e.target.checked,
                                            val?._id,
                                            "secondHandAsk"
                                          )
                                        }
                                      />
                                          <div className="buysellimg product-table-img"> 
                                      <LazyLoadImage
                                        //src={`${imageUrl}${val?.image[0]}`}
                                        src={
                                          val?.image[0]?.startsWith("http")
                                            ? val?.image[0]
                                            : `${imageUrl}${val?.image[0]}`
                                        }
                                   
                                        // onClick={() =>
                                        //   nevigate(`/product/${val._id}`)
                                        // }
                                        onClick={() => window.open(`/product/${val._id}`, '_blank')}
                                      />
                                      </div>
                                      <div> 
                                      {descriptionindex == i ? (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex()}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      ) : (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex(i)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                      </div>
                                    </div>

                                    <OutsideClickHandler
                                      onOutsideClick={(e) => {
                                        if (
                                          !e.target.closest(".modal_custom_")
                                        ) {
                                          setdescriptionindex();
                                        }
                                      }}
                                    ></OutsideClickHandler>
                                  </div>
                                  <div> 
                                  {descriptionindex == i ? (
                                    <div className="modal_custom_">
                                      <div className="inner_customModal_one">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: `${
                                              val?.description
                                                ? val?.description.substring(
                                                    0,
                                                    351
                                                  )
                                                : "No product description"
                                            }${
                                              val?.description?.length > 351
                                                ? "..."
                                                : ""
                                            }`,
                                          }}
                                        ></h5>
                                        {val?.description?.length > 351 ? (
                                          <a
                                            onClick={() =>
                                              nevigate(`/product/${val._id}`)
                                            }
                                          >
                                            See more
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) :  ""}
                                  </div>
                                </td>

                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.chipset}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.brand}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.series}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="text-breakdown"
                                    title={val.model}
                                  >
                                    {val.model}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.inventory}
                                  </div>
                                </td>
                                <td>
                                  
                                  {/* {val?.type == 1
                                    ? val?.new_retail_market_price
                                      ? "$" + val?.new_retail_market_price
                                      : ""
                                    : val?.type == 2
                                    ? val.new_second_hand_house_ask
                                      ? val?.new_second_hand_market_price
                                        ? "$" +
                                          val?.new_second_hand_market_price
                                        : ""
                                      : ""
                                    : val.used_house_ask
                                    ? val?.used_market_price
                                      ? "$" + val?.used_market_price
                                      : ""
                                    : ""} */}
                                    <div>
                                    {val?.type == 1
                                  ? val?.new_retail_market_price
                                    ? "$" + val?.new_retail_market_price
                                    : 0
                                  : val?.type == 2
                                  ? val.new_second_hand_house_ask
                                    ? val?.new_second_hand_market_price
                                      ? "$" + val?.new_second_hand_market_price
                                      : 0
                                    : 0
                                  : val?.used_house_ask
                                  ? val?.used_market_price
                                    ? "$" + val?.used_market_price
                                    : 0
                                  : ""}
                                  </div>
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <BuySpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      buy={
                                        typeof val.highest_bid === "number"
                                          ? val.highest_bid
                                          : 0
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <SellSpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      sell={val.lowest_ask}
                                    />
                                  )}
                                </td>

                                <td>
                                  {val?.type == 1
                                    ? "Retail Only"
                                    : Math.abs(
                                        val.highest_bid - val.lowest_ask
                                      ).toFixed(2)}
                                </td>

                                {/* Highest Bid */}
                                <td>
                                  {val?.type == 1 ? (
                                    " Retail Only"
                                  ) : val?.highest_bid > 0 ? (
                                    <Button
                                      className="border bg-danger"
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - {"$" + val?.highest_bid}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="border bg-danger"
                                      disabled
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - "TBD"
                                    </Button>
                                  )}
                                </td>

                                <td className="d-flex align-items-center">
                                <div className="d-flex align-items-center"> 
                                  {
                                    //   <Button
                                    //   className="border bg-success"
                                    //   disabled
                                    //   onClick={() =>
                                    //     nevigate("/bidask/" + val?._id + "/buy")
                                    //   }
                                    // >
                                    //   Buy Now - ${val?.new_retail_website_price}
                                    // </Button>
                                    val?.type == 1 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - $
                                        {val?.new_retail_website_price}
                                      </Button>
                                    ) : val?.lowest_ask > 0 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - {"$" + val?.lowest_ask}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="border bg-success"
                                        disabled
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - "TBD"
                                      </Button>
                                    )
                                  }
                                  <div> 
                                  {AskBid == i ? (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      disabled
                                      onClick={() => setAskBid()}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      onClick={() => ASKBID(val?._id, i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  )}
                                  </div>
                                  <div> 
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setAskBid();
                                      setCount(0);
                                      setCount1(0);
                                    }}
                                  >
                                    {AskBid == i ? (
                                      <div className="big-modal inner_customModal custom-tab-mode">
                                        <div
                                          className="overlay"
                                          onClick={() => setAskBid()}
                                        >
                                        
                                          <tr>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Bid</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Bid?.length > 0
                                                        ? Bid.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val?.bidAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Bids"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Ask</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count1 == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {ask?.length > 0
                                                        ? ask.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val.askAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Asks"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        </div>
                                      </div>
                                    ) : //   </div>
                                    // </div>
                                    ""}
                                  </OutsideClickHandler>
                                  </div>
                                  </div>
                                </td>
                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : val?.type == 2 ? (
                                    // <UserAskBidList
                                    //   productId={val._id}
                                    //   userId={
                                    //     JSON.parse(localStorage.getItem("admin")).id
                                    //   }
                                    //   askListStatus={secondHandHouseAskStatus}
                                    // />
                                    <Button className="border bg-success">
                                      {"$" + val?.new_second_hand_house_ask}
                                    </Button>
                                  ) : (
                                    //   <UserAskBidList
                                    //   productId={val._id}
                                    //   userId={
                                    //     JSON.parse(localStorage.getItem("admin")).id
                                    //   }
                                    //   askListStatus={secondHandHouseAskStatus}
                                    // />
                                    <Button className="border bg-success">
                                      {"$" + val?.used_house_ask}
                                    </Button>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mx-2 border bg-none text-dark border-dark"
                                    onClick={() => handleUpdate(val)}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          // allloader == true ? <> 
                          // <div
                          //     style={{
                          //       alignItems: "center",
                          //       justifyContent: "center",
                          //     }}
                          //   >
                          //     <div
                          //       className="loader-icon custom-loader"
                          
                          //     >
                          //       <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                          //     </div>
                          //   </div></> :  
                            <div> 
                                  <span>No Data Found</span>
                                  </div>
                        )
                      ) : secondHandHouseBidStatus ? (
                        bidResult?.length > 0 ? (
                          bidResult.map((val, i) => {
                
                            return (
                              <tr  key={i}>
                                <td>
                                  <span className="float-end" style={{ cursor: "default" }}>
                                    {/* {val?.type == 1
                                       ? "New"
                                       : val?.type == 2
                                         ? "2nd Hand"
                                         : "Used"} */}
                                    {val?.type == 2 ? "New" : "Used"}
                                  </span>
                                  <div className="product_description_view">
                                    <div className="d-flex align-items-end justify-content-between ">
                                      <Form.Check
                                        className="d-inline"
                                        type="checkbox"
                                        checked={val?.isSelected}
                                        onChange={(e) =>
                                          handleCheck(
                                            e.target.checked,
                                            val?._id,
                                            "secondHandBid"
                                          )
                                        }
                                      />
                                          <div className="buysellimg product-table-img"> 
                                      <LazyLoadImage
                                        //src={`${imageUrl}${val?.image[0]}`}
                                        src={
                                          val?.image[0]?.startsWith("http")
                                            ? val?.image[0]
                                            : `${imageUrl}${val?.image[0]}`
                                        }
                             
                                        // onClick={() =>
                                        //   nevigate(`/product/${val._id}`)
                                        // }
                                        onClick={() => window.open(`/product/${val._id}`, '_blank')}
                                      />
                                      </div>
                                      {descriptionindex == i ? (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex()}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      ) : (
                                        <Button
                                          className="bg-none border-0"
                                          onClick={() => setdescriptionindex(i)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#0000ff"
                                            className="bi bi-chevron-down"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              filerule="evenodd"
                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                            />
                                          </svg>
                                        </Button>
                                      )}
                                    </div>

                                    <OutsideClickHandler
                                      onOutsideClick={(e) => {
                                        if (
                                          !e.target.closest(".modal_custom_")
                                        ) {
                                          setdescriptionindex();
                                        }
                                      }}
                                    ></OutsideClickHandler>
                                  </div>
                                  <div> 
                                  {descriptionindex == i ? (
                                    <div className="modal_custom_">
                                      <div className="inner_customModal_one">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: `${
                                              val?.description
                                                ? val?.description.substring(
                                                    0,
                                                    351
                                                  )
                                                : "No product description"
                                            }${
                                              val?.description?.length > 351
                                                ? "..."
                                                : ""
                                            }`,
                                          }}
                                        ></h5>
                                        {val?.description?.length > 351 ? (
                                          <a
                                            onClick={() =>
                                              nevigate(`/product/${val._id}`)
                                            }
                                          >
                                            See more
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) :  ""}
                                  </div>
                                </td>

                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.chipset}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.brand}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.series}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="text-breakdown"
                                    title={val.model}
                                  >
                                    {val.model}
                                  </div>
                                </td>
                                <td>
                                  <div style={{ cursor: "default" }}>
                                    {val.inventory}
                                  </div>
                                </td>
                                <td>
                                  
                                  {/* {val?.type == 1
                                    ? val?.new_retail_market_price
                                      ? "$" + val?.new_retail_market_price
                                      : ""
                                    : val?.type == 2
                                    ? val.new_second_hand_house_ask
                                      ? val?.new_second_hand_market_price
                                        ? "$" +
                                          val?.new_second_hand_market_price
                                        : ""
                                      : ""
                                    : val.used_house_ask
                                    ? val?.used_market_price
                                      ? "$" + val?.used_market_price
                                      : ""
                                    : ""} */}
                                      <div>
                               
                               {val?.type == 1
                                  ? val?.new_retail_market_price
                                    ? "$" + val?.new_retail_market_price
                                    : 0
                                  : val?.type == 2
                                  ? val.new_second_hand_house_ask
                                    ? val?.new_second_hand_market_price
                                      ? "$" + val?.new_second_hand_market_price
                                      : 0
                                    : 0
                                  : val?.used_house_ask
                                  ? val?.used_market_price
                                    ? "$" + val?.used_market_price
                                    : 0
                                  : ""}
                                  </div>
                                </td>

                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <BuySpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      buy={
                                        typeof val.highest_bid === "number"
                                          ? val.highest_bid
                                          : 0
                                      }
                                    />
                                  )}
                                </td>

                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : (
                                    <SellSpread
                                      productId={val._id}
                                      userId={
                                        JSON.parse(
                                          localStorage.getItem("admin")
                                        ).id
                                      }
                                      sell={val.lowest_ask}
                                    />
                                  )}
                                </td>

                                <td>
                                  {val?.type == 1
                                    ? "Retail Only"
                                    : Math.abs(
                                        val.highest_bid - val.lowest_ask
                                      ).toFixed(2)}
                                </td>

                                {/* Highest Bid */}
                                <td>
                                  {val?.type == 1 ? (
                                    " Retail Only"
                                  ) : val?.highest_bid > 0 ? (
                                    <Button
                                      className="border bg-danger"
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - {"$" + val?.highest_bid}
                                    </Button>
                                  ) : (
                                    <Button
                                      className="border bg-danger"
                                      disabled
                                      // onClick={() =>
                                      //   nevigate(
                                      //     "/bidask/" + val?._id + "/sell"
                                      //   )
                                      // }
                                      onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                    >
                                      Sell Now - "TBD"
                                    </Button>
                                  )}
                                </td>

                                <td className="">
                                <div className="d-flex align-items-center"> 
                                  {
                                    
                                    val?.type == 1 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - $
                                        {val?.new_retail_website_price}
                                      </Button>
                                    ) : val?.lowest_ask > 0 ? (
                                      <Button
                                        className="border bg-success"
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open( "/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - {"$" + val?.lowest_ask}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="border bg-success"
                                        disabled
                                        // onClick={() =>
                                        //   nevigate(
                                        //     "/bidask/" + val?._id + "/buy"
                                        //   )
                                        // }
                                        onClick={() => window.open( "/bidask/" + val?._id + "/buy", '_blank')}
                                      >
                                        Buy Now - "TBD"
                                      </Button>
                                    )
                                  }
                                  <div> 
                                  {AskBid == i ? (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      disabled
                                      onClick={() => setAskBid()}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  ) : (
                                    <Button
                                      className="bg-none border-0 mt-2"
                                      onClick={() => ASKBID(val?._id, i)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#0000ff"
                                        className="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          filerule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </Button>
                                  )}
                                  </div>
                                 <div> 
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setAskBid();
                                      setCount(0);
                                      setCount1(0);
                                    }}
                                  >
                                    {AskBid == i ? (
                                      <div className="big-modal inner_customModal custom-tab-mode">
                                        <div
                                          className="overlay"
                                          onClick={() => setAskBid()}
                                        >
                                          
                                          <tr>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Bid</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {Bid?.length > 0
                                                        ? Bid.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val?.bidAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Bids"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                            <td>
                                              <Table
                                                responsive="sm"
                                                striped
                                                bordered
                                                hover
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Ask</th>
                                                    <th>Qty</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {count1 == 0 ? (
                                                    <>
                                                      <div
                                                        className="loader-icon"
                                                        style={{
                                                          marginBlock: "80px",
                                                        }}
                                                      >
                                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {ask?.length > 0
                                                        ? ask.map((val, i) => {
                                                            return (
                                                              <tr  key={i}>
                                                                <td>
                                                                  $
                                                                  {
                                                                    val.askAmount
                                                                  }
                                                                </td>
                                                                <td> 1</td>
                                                              </tr>
                                                            );
                                                          })
                                                        : "No Asks"}
                                                    </>
                                                  )}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        </div>
                                      </div>
                                    ) : //   </div>
                                    // </div>
                                    ""}
                                  </OutsideClickHandler>
                                  </div>
                                  </div>

                                </td>

                                <td>
                                  {val?.type == 1 ? (
                                    "Retail Only"
                                  ) : val?.type == 2 ? (
                                    <Button className="border bg-danger">
                                      {"$" + val?.new_second_hand_house_bid}
                                    </Button>
                                  ) : (
                                    // <UserAskBidList
                                    //   productId={val._id}
                                    //   userId={
                                    //     JSON.parse(localStorage.getItem("admin")).id
                                    //   }
                                    //   bidListStatus={secondHandHouseBidStatus}
                                    // />
                                    <Button className="border bg-danger">
                                      {"$" + val?.used_house_bid}
                                    </Button>
                                    // <UserAskBidList
                                    //   productId={val._id}
                                    //   userId={
                                    //     JSON.parse(localStorage.getItem("admin")).id
                                    //   }
                                    //   bidListStatus={secondHandHouseBidStatus}
                                    // />
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mx-2 border bg-none text-dark border-dark"
                                    onClick={() => handleUpdate(val)}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <span>No Data Found</span>
                        )
                      ) : Data?.length > 0 ? (
                        Data?.map((val, i) => {
                  
                          return (
                            <tr  key={i}>
                              <td>
                                <span className="float-end" style={{ cursor: "default" }}>
                                  {/* {val?._id} */}
                                  {/* {val?.type == 1
                                       ? "New"
                                       : val?.type == 2
                                         ? "2nd Hand"
                                         : "Used"} */}
                                  {val?.type == 2 ? "New" : "Used"}
                                </span>
                                <div className="product_description_view">
                                  <div className="d-flex align-items-end justify-content-between ">
                                    <Form.Check
                                      className="d-inline"
                                      type="checkbox"
                                      checked={val?.isSelected}
                                      onChange={(e) =>
                                        handleCheck(
                                          e.target.checked,
                                          val?._id,
                                          "normal"
                                        )
                                      }
                                    />
                                        <div className="buysellimg product-table-img"> 
                                    <LazyLoadImage
                                      //src={`${imageUrl}${val?.image[0]}`}
                                      src={
                                        val?.image[0]?.startsWith("http")
                                          ? val?.image[0]
                                          : `${imageUrl}${val?.image[0]}`
                                      }
                              
                                      // onClick={() =>
                                      //   nevigate(`/product/${val._id}`)
                                      // }
                                      onClick={() => window.open(`/product/${val._id}`, '_blank')}
                                    />
                                    </div>
                                    <div> 
                                    {descriptionindex == i ? (
                                      <Button
                                        className="bg-none border-0"
                                        onClick={() => setdescriptionindex()}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="#0000ff"
                                          className="bi bi-chevron-down"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            filerule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </Button>
                                    ) : (
                                      <Button
                                        className="bg-none border-0"
                                        onClick={() => setdescriptionindex(i)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="#0000ff"
                                          className="bi bi-chevron-down"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            filerule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                    </div>
                                  </div>

                                  <OutsideClickHandler
                                    onOutsideClick={(e) => {
                                      if (!e.target.closest(".modal_custom_")) {
                                        setdescriptionindex();
                                      }
                                    }}
                                  >
                                    {/* <div className="custom"></div> */}
                                  </OutsideClickHandler>
                                </div>
                                <div> 
                                {descriptionindex == i ? (
                                  <div className="modal_custom_">
                                    <div className="inner_customModal_one">
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: `${
                                            val?.description
                                              ? val?.description.substring(
                                                  0,
                                                  351
                                                )
                                              : "No product description"
                                          }${
                                            val?.description?.length > 351
                                              ? "..."
                                              : ""
                                          }`,
                                        }}
                                      ></h5>
                                      {val?.description?.length > 351 ? (
                                        <a
                                          onClick={() =>
                                            nevigate(`/product/${val._id}`)
                                          }
                                        >
                                          See more
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                ) : ""}
                                </div>
                              </td>

                              <td>
                                <div style={{ cursor: "default" }}>
                                  {val?.chipset}
                                </div>
                              </td>
                              <td>
                                <div style={{ cursor: "default" }}>
                                  {val?.brand}
                                </div>
                              </td>
                              <td>
                                <div style={{ cursor: "default" }}>
                                  {val?.series}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="text-breakdown"
                                  style={{ cursor: "default" }}
                                  title={val.model}
                                >
                                  {val.model}
                                </div>
                              </td>
                              <td>
                                <div style={{ cursor: "default" }}>
                                  {val?.inventory}
                                </div>
                              </td>
                              <td>
                                {val?.type == 1
                                  ? val?.new_retail_market_price
                                    ? "$" + val?.new_retail_market_price
                                    : 0
                                  : val?.type == 2
                                  ? val.new_second_hand_house_ask
                                    ? val?.new_second_hand_market_price
                                      ? "$" + val?.new_second_hand_market_price
                                      : 0
                                    : 0
                                  : val?.used_house_ask
                                  ? val?.used_market_price
                                    ? "$" + val?.used_market_price
                                    : 0
                                  : ""}
                              </td>
                              {/* Buy Spread */}
                              <td>
                                {val?.type == 1 ? (
                                  "Retail Only"
                                ) : (
                                  <BuySpread
                                    productId={val._id}
                                    userId={
                                      JSON.parse(localStorage.getItem("admin"))
                                        ?.id
                                    }
                                    buy={
                                      typeof val.highest_bid === "number"
                                        ? val.highest_bid
                                        : 0
                                    }
                                  />
                                )}
                              </td>
                              {/* Sell Spread */}
                              <td>
                                {val?.type == 1 ? (
                                  "Retail Only"
                                ) : (
                                  <SellSpread
                                    productId={val._id}
                                    userId={
                                      JSON.parse(localStorage.getItem("admin"))
                                        .id
                                    }
                                    sell={val.lowest_ask}
                                  />
                                )}
                              </td>
                              {/* Spread */}
                              <td>
                                {val?.type == 1
                                  ? "Retail Only"
                                  : Math.abs(
                                      val.highest_bid - val.lowest_ask
                                    ).toFixed(2)}
                              </td>
                              {/* Highest Bid */}
                              <td>
                                {val?.type == 1 ? (
                                  " Retail Only"
                                ) : val?.highest_bid > 0 ? (
                                  <Button
                                    className="border bg-danger"
                                    // onClick={() =>
                                    //   nevigate("/bidask/" + val?._id + "/sell")
                                    // }
                                    onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                  >
                                    Sell Now - {"$" + val?.highest_bid}
                                  </Button>
                                ) : (
                                  <Button
                                    className="border bg-danger"
                                    disabled
                                    // onClick={() =>
                                    //   nevigate("/bidask/" + val?._id + "/sell")
                                    // }
                                    onClick={() => window.open("/bidask/" + val?._id + "/sell", '_blank')}
                                  >
                                    Sell Now - "TBD"
                                  </Button>
                                )}
                              </td>
                              {/* Lowest Ask */}
                              <td className=" ">
                                <div className="d-flex align-items-center"> 
                                {val?.type == 1 ? (
                                  <Button
                                    className="border bg-success"
                                    // onClick={() =>
                                    //   nevigate("/bidask/" + val?._id + "/buy")
                                    // }
                                    onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                  >
                                    Buy Now - ${val?.new_retail_website_price}
                                  </Button>
                                ) : val?.lowest_ask > 0 ? (
                                  <Button
                                    className="border bg-success"
                                    // onClick={() =>
                                    //   nevigate("/bidask/" + val?._id + "/buy")
                                    // }
                                    onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                  >
                                    Buy Now - {"$" + val?.lowest_ask}
                                  </Button>
                                ) : (
                                  <Button
                                    className="border bg-success"
                                    disabled
                                    // onClick={() =>
                                    //   nevigate("/bidask/" + val?._id + "/buy")
                                    // }
                                    onClick={() => window.open("/bidask/" + val?._id + "/buy", '_blank')}
                                  >
                                    Buy Now - "TBD"
                                  </Button>
                                )}
                                <div> 
                                {AskBid == i ? (
                                  <Button
                                    className="bg-none border-0 mt-2"
                                    disabled
                                    onClick={() => setAskBid()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Button>
                                ) : (
                                  <Button
                                    className="bg-none border-0 mt-2"
                                    onClick={() => ASKBID(val?._id, i)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Button>
                                )}
                                </div>
                            <div> 
                                <OutsideClickHandler
                                  onOutsideClick={() => {
                                    setAskBid();
                                    setCount(0);
                                    setCount1(0);
                                  }}
                                >
                                  {AskBid == i ? (
                                    <div className="big-modal inner_customModal custom-tab-mode">
                                      <div
                                        className="overlay"
                                        onClick={() => setAskBid()}
                                      >
                                        
                                        <tr>
                                          <td>
                                            <Table
                                              responsive="sm"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Bid</th>
                                                  <th>Qty</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {count == 0 ? (
                                                  <>
                                                    <div
                                                      className="loader-icon"
                                                      style={{
                                                        marginBlock: "80px",
                                                      }}
                                                    >
                                                      <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {Bid?.length > 0
                                                      ? Bid.map((val, i) => {
                                                          return (
                                                            <tr  key={i}>
                                                              <td>
                                                                $
                                                                {val?.bidAmount}
                                                              </td>
                                                              <td> 1</td>
                                                            </tr>
                                                          );
                                                        })
                                                      : "No Bids"}
                                                  </>
                                                )}
                                              </tbody>
                                            </Table>
                                          </td>
                                          <td>
                                            <Table
                                              responsive="sm"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Ask</th>
                                                  <th>Qty</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {count1 == 0 ? (
                                                  <>
                                                    <div
                                                      className="loader-icon"
                                                      style={{
                                                        marginBlock: "80px",
                                                      }}
                                                    >
                                                      <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {ask?.length > 0
                                                      ? ask.map((val, i) => {
                                                          return (
                                                            <tr  key={i}>
                                                              <td>
                                                                ${val.askAmount}
                                                              </td>
                                                              <td> 1</td>
                                                            </tr>
                                                          );
                                                        })
                                                      : "No Asks"}
                                                  </>
                                                )}
                                              </tbody>
                                            </Table>
                                          </td>
                                        </tr>
                                      </div>
                                    </div>
                                  ) : //   </div>
                                  // </div>
                                  ""}
                                </OutsideClickHandler>
                                </div>

                                </div>
                              </td>
                              <td>
                                {val?.type === 1 ? (
                                  "Retail Only"
                                ) : val?.askPrice && val?.bidPrice !== null ? (
                                  <>
                                    <Button className="border bg-success">
                                      ${val?.askPrice}
                                    </Button>
                                    <Button className="border bg-danger">
                                      ${val?.bidPrice}
                                    </Button>
                                  </>
                                ) : (
                                  <UserAskBidList
                                    productId={val._id}
                                    userId={
                                      JSON.parse(localStorage.getItem("admin"))
                                        .id
                                    }
                                  />
                                )}
                                {/* {val?.type == 1 ? (
                               "Retail Only"
                             ) : (
                               <UserAskBidList
                                 productId={val._id}
                                 userId={
                                   JSON.parse(localStorage.getItem("admin")).id
                                 }
                               />
                               
                             
                             )} */}
                                {/* {val?.type === 1 ? (
                             "Retail Only"
                           ) : (
                             <>
                               {askListdata !== undefined && askListdata !== null && (
                                 <Button className="border bg-success">{"$" + askListdata}</Button>
                               )}
                               {!askListdata && bidListdata !== undefined && bidListdata !== null && (
                                 <Button className="border bg-danger">{"$" + bidListdata}</Button>
                               )}
                               {askListdata !== undefined && askListdata !== null && bidListdata !== undefined && bidListdata !== null && (
                                 <>
                                   <Button className="border bg-success">{"$" + askListdata}</Button>
                                   <Button className="border bg-danger">{"$" + bidListdata}</Button>
                                 </>
                               )}
                               {!askListdata && !bidListdata && (
                                 <Button className="border bg-none text-dark border-dark">
                                   Place Bid/Ask
                                 </Button>
                               )}
   
                             </>
                           )} */}

                                {/* {askList ? (
                               <Button
                                 className="border bg-success"
                               >
                                 {"$" + askList}
                               </Button>
                             ) : props?.bidListStatus ? (
                               <Button
                                 className="border bg-danger"
                               >
                                 {"$" + bidList}
                               </Button>
                             ) : askList && bidList ? (
                               <>
                                 <Button
                                   className="border bg-success"
                                 >
                                   {"$" + askList}
                                 </Button>
                                 <Button
                                   className="border bg-danger"
                                 >
                                   {"$" + bidList}
                                 </Button>
                               </>
                             ) : askList ? (
                               <Button
                                 className="border bg-danger"
                               >
                                 {"$" + askList}
                               </Button>
                             ) : bidList ? (
                               <Button
                                 className="border bg-success"
                               >
                                 {"$" + bidList}
                               </Button>
                             ) : (
                               <Button
                                 className="border bg-none text-dark border-dark"
                                 // onClick={() => {
                                 //   navigate("/bidask/" + .productId);
                                 // }}
                               >
                                 Place Bid/Ask
                               </Button>
                             )} */}
                              </td>
                              <td>
                                <Button
                                  className="mx-2 border bg-none text-dark border-dark"
                                  onClick={() => handleUpdate(val)}
                                >
                                  Edit
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        // allloader == true ? <> 
                        // <div
                        //     style={{
                        //       alignItems: "center",
                        //       justifyContent: "center",
                        //     }}
                        //   >
                        //     <div
                        //       className="loader-icon custom-loader"
                        //       style={{ marginBlock: "80px" }}
                        //     >
                        //       <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                        //     </div>
                        //   </div></> :  
                          <div> 
                                <span>No Data Found</span>
                                </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <h5>
                        <b>Loading...</b>
                      </h5>
                    
                    </div>
                  )} 
                  
                </tbody>
              </Table>
            </div>

            <div className="pagination_box">
              {Data && askResult && activeData ? (
                // <ReactPaginate
                //   previousLabel={"< Previous"}
                //   nextLabel={"Next >"}
                //   breakLabel={"..."}
                //   breakClassName={"page-item"}
                //   breakLinkClassName={"page-link"}
                //   pageCount={totalPages}
                //   marginPagesDisplayed={2}
                //   pageRangeDisplayed={5}
                //   onPageChange={handlePageClick}
                //   containerClassName={"pagination"}
                //   activeClassName={"active"}
                //   pageClassName={"page-item"}
                //   pageLinkClassName={"page-link"}
                //   previousClassName={"page-item"}
                //   previousLinkClassName={"page-link"}
                //   nextClassName={"page-item"}
                //   nextLinkClassName={"page-link"}
                //   forcePage={pageOffset}
                // />
                <Stack spacing={2}>
                <Pagination
                className="order_pagination"
                count={totalPages}
                color="primary"
                hidePrevButton
                hideNextButton
                page={page}
                onChange={handlePageClick}
              
              />
                 </Stack>
              ) : (
                ""
              )}
            </div>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Col>
      </Row>

      {/*Start Product Update Modal */}
      <Modal
        className="update_product"
        show={modalShow}
        onHide={() => setModalShow(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Update</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Row>
            <Col lg={12}>
              <div className="basicinformation">
                <h4 className="fw-bold">Basic Information</h4>
                <p className="text-muted fw-bold">
                  Section to config basic product information
                </p>
                <Form>
                  <FormLabel name="category" className="fw-bold fs-6">
                    Category
                  </FormLabel>
                  <FormSelect
                    id="select1"
                    className="mb-3"
                    onChange={handleSelectChange}
                    value={selectedValue}
                  >
                    <option value="63ff36fb23ad0386e761641f">
                      Graphics Cards
                    </option>
                  </FormSelect>

                  <FormLabel className="fw-bold fs-6">Chipset</FormLabel>
                  <Select
                    options={chipsetData}
                    onChange={handleOnSearchChipset}
                    // onFocus={() => setBrand1Value(false)}
                    value={showChipset2}
                    placeholder="i.e. MSI,PNY,Zotac etc..."
                    autocomplete="off"
                    required
                  />
                  
                  <FormLabel className="fw-bold fs-6">Brand</FormLabel>
                  <Select
                    options={brandData}
                    onChange={handleOnSearchBrand}
                    // onFocus={() => setBrand1Value(false)}
                    value={showBrand2}
                    placeholder="i.e. MSI,PNY,Zotac etc..."
                    autocomplete="off"
                    required
                  />
                  <FormLabel className="fw-bold fs-6">Series</FormLabel>
                   
                  {newseries && (
                    <Select
                      options={newseries?.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      onChange={handleOnSearchSeries}
                      // onFocus={() => setBrand1Value(false)}
                      value={showSeries2}
                      placeholder="i.e. MSI,PNY,Zotac etc..."
                      autocomplete="off"
                      required
                    />
                  )}               
                  <FormLabel className="fw-bold fs-6">Model</FormLabel>
                  <FormControl
                    name="model"
                    className="mb-3"
                    value={model2}
                    // disabled
                   onChange={(e) => setModel2(e.target.value)}
               
                    placeholder="i.e. Gaming X,Gaming X Trio etc.. "
                  />
              
                  <FormLabel className="fw-bold fs-6">MPN</FormLabel>
                  <FormControl
                    className="mb-3"
                    name="sku"
      
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                    placeholder="MPN Unique Per product"
                  />
                  <FormLabel className="fw-bold fs-6">UPC</FormLabel>

                  <FormControl
                    className="mb-3"
                    name="upc"
                    value={upc}
                    onChange={(e) => setUpc(e.target.value)}
                    placeholder="UPC Unique Per product"
                  />
                 

                  <FormLabel className="fw-bold fs-6">Product Videos</FormLabel>
                  <Form className="mb-3">
                    {inputList.map((x, i) => {
                      if (true) {
                        return (
                          <div className="d-flex mb-3"  key={i}>
                            <FormControl
                              name={`embeddedVideoLink${i + 1}`}
                              placeholder="Embedded Video Link"
                              value={x}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <button
                              type="button"
                              className="butt bg-none border-0"
                              onClick={handleAddClick}
                            >
                              <BiPlusCircle style={{ fontSize: "30px" }} />
                            </button>
                             {inputList.length !== 1 && (
                              <button
                                type="button"
                                className="bg-none border-0 ms-2"
                                onClick={() => handleRemoveClick(i)}
                              >
                                <BiMinusCircle style={{ fontSize: "30px" }} />
                              </button>
                            )}
                          </div>
                        );
                      }
                    })}
                  </Form>
                </Form>
              </div>
            </Col>
            <Col lg={12}>
              <section className="mt-3 mb-3">
                <h4 className="fw-bold">Gallery Image</h4>
                <p className="text-muted fw-bold">
                  Add or change image for the Gallery
                </p>
                <div className="d-flex justify-content-center align-items-center border px-3 py-5">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="mb-3">Support : Jpeg,Png,webp</span>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        class="bi bi-image me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
                      </svg>
                      Drag and drop your images here.
                    </div>
                  </div>
                </div>

                {/* Start Image Preview for the New Image Uploades */}
                {multipleFiles?.length > 0 ? (
                  <Button
                    className="remove_all_images border bg-primary mt-3"
                    onClick={deleteallimages}
                  >
                    Remove All
                  </Button>
                ) : (
                  ""
                )}
                {multipleFiles?.length > 4 ? (
                  <div
                    className="Whole_image_box mt-2 extra-images"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    {multipleFiles.map((file, index) => (
                      <div className="parent_div">
                        <div
                          className="image_div"
                          key={index}
                          draggable
                          onDragStart={(e) => dragNewStart(e, index)}
                          onDragEnter={(e) => dragNewEnter(e, index)}
                          onDragEnd={dropNew}
                        >
                          <LazyLoadImage
                            className="show_multi_images"
                            src={file.preview}
                            alt={file.name}
                            height="200px"
                            width="100%"
                          />
                        </div>
                        <div className="icon_div">
                          {/* <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleFileChange(e, file.path)}
                            />
                            <EditIcon />
                          </IconButton> */}
                          <IconButton
                            color="error"
                            onClick={() => handledeletesingleimage(file.path)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="Whole_image_box mt-2"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    {multipleFiles.map((file, index) => (
                      <div className="parent_div">
                        <div
                          className="image_div"
                          key={index}
                          draggable
                          onDragStart={(e) => dragNewStart(e, index)}
                          onDragEnter={(e) => dragNewEnter(e, index)}
                          onDragEnd={dropNew}
                        >
                          <LazyLoadImage
                            className="show_multi_images"
                            src={file.preview}
                            alt={file.name}
                            height="200px"
                            width="100%"
                          />
                        </div>
                        <div className="icon_div">
                          {/* <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleFileChange(e, file.path)}
                            />
                            <EditIcon />
                          </IconButton> */}
                          <IconButton
                            color="error"
                            onClick={() => handledeletesingleimage(file.path)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* end image preview of the new uploaded images */}

                {/* start image preview of the old images */}
                {currentImage?.length > 0 ? (
                  <Button
                    className="remove_all_images border bg-primary mt-3"
                    // onClick={() => setCurrentImage([])
                    // }
                    onClick={deleteallimages}
                  >
                    Remove All
                  </Button>
                ) : (
                  ""
                )}
                {currentImage?.length > 4 ? (
                  <div
                    className="Whole_image_box mt-2 extra-images"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    {currentImage.map((file, index) => (
                      <div className="parent_div">
                        <div
                          className="image_div"
                          key={index}
                          draggable
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                        >
                          <img
                            className="show_multi_images"
                            // src={`${imageUrl}${file}`}
                            src={
                              file.startsWith("http")
                                ? file
                                : `${imageUrl}${file}`
                            }
                            alt={file}
                            height="200px"
                            width="100%"
                          />
                        </div>
                        <div className="icon_div">
                          {/* <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleFileChange(e, file)}
                            />
                            <EditIcon />
                          </IconButton> */}
                          <IconButton
                            color="error"
                            onClick={() => handledeletesingleimage(file)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="Whole_image_box mt-2"
                    style={{ display: "flex", marginTop: "20px" }}
                  >
                    {currentImage.map((file, index) => (
                      <div className="parent_div">
                        <div
                          className="image_div"
                          key={index}
                          draggable
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                        >
                          <img
                            className="show_multi_images"
                            src={`${file}`}
                            // src={
                            //   file.startsWith("http")
                            //     ? file
                            //     : `${imageUrl}${file}`
                            // }
                            alt={file}
                            height="200px"
                            width="100%"
                          />
                        </div>
                        <div className="icon_div">
                          {/* <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleFileChange(e, file)}
                            />
                            <EditIcon />
                          </IconButton> */}
                          <IconButton
                            color="error"
                            onClick={
                              () => handledeletesingleimage(file)
                              // testhandle(file)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* end image preview of the old images */}
              </section>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="protyppri mt-3">
                <h4 className="mb-3 fw-bold">Product Type and Price</h4>
                <div className="marretweb border rounded bg-custom-light p-2">
                  {/* {productData?.type == 1 ? ( */}
                  <>
                    <div className="marbidask">
                      <h5 className="invisible"></h5>
                      <h5 className="invisible"></h5>
                      <h5 className="text-center mb-3 update_font ">
                        Market Price
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        Retail Price
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        Website Price
                      </h5>
                    </div>
                    <div className="newret">
                      <h5 className="update_font">New-Retail</h5>
                      {/* {productType1?.length > 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={
                                productType1[0].status == true ? true : false
                              }
                              onChange={handleCheckClickOne}
                            />
                          }
                        />
                      </FormGroup>
                    ) : ( */}
                 
                    {inactivenew === false ? <div>&nbsp;</div>:  <div> <input
                        type="checkbox"
                        id="checkboxx"
                        name="checkbox"
                        value="1"
                        checked={checkbox1}
                        onClick={handleCheckClickone1}
                      />
                      </div>}
                      
                      {/* )} */}
                      <div>
                        <FormControl
                          name="marketPrice"
                          value={marketPrice}
                          onChange={(e) => handleMarketPrice(e)}
                          placeholder="Comp Price"
                        />
                        <p>
                          Date:
                          {marketPriceDate}
                        </p>
                      </div>
                      <div>
                        <FormControl
                          name="retailPrice"
                          value={retailPrice}
                          onChange={(e) => {
                            setRetailPrice(e.target.value);
                            handleItemss(e);
                          }}
                          placeholder="MSRP"
                        />
                      </div>
                      <div>
                        <FormControl
                          name="websitePrice"
                          value={websitePrice}
                          onChange={(e) => {
                            setWebsitePrice(e.target.value);
                            handleItemss(e);
                          }}
                          placeholder="Our Price"
                        />
                        <p>
                          Inventory:
                          {productType1.length > 0
                            ? productType1.map((item) => {
                                return item.inventory ? item.inventory : "";
                              })
                            : 0}
                        </p>
                        <p>
                          COGS Price :
                          {productType1.length > 0
                            ? productType1.map((item) => {
                                return item.cogs ? item.cogs.toFixed(2) : "";
                              })
                            : 0}
                        </p>
                      </div>
                    </div>
                  </>
                  {/* )
                  : productData?.type == 2 ? ( */}
                  <>
                    <div className="marbidask">
                      <h5 className="invisible"></h5>
                      <h5 className="invisible"></h5>
                      <h5 className="text-center mb-3 update_font ">
                        Market Price
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        House Bid
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        House Ask
                      </h5>
                    </div>
                    <div className="marbidask">
                      <h5 className="update_font">New-2nd Hand</h5>
                      {/* {productType2?.length > 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={
                                productType2[0].status == true ? true : false
                              }
                              onChange={handleCheckClickTwo}
                            />
                          }
                        />
                      </FormGroup>
                    ) : ( */}
                      {inactivenew === false ? <div>&nbsp;</div>: 
                      <div> 
                      <input
                        type="checkbox"
                        id="checkboxx"
                        value="2"
                        name="checkbox"
                        checked={checkbox2}
                        onClick={handleCheckClickTwo2}
                      />
                      </div> }
                      {/* )} */}
                      <div>
                        <FormControl
                          name="secondHandMarketPrice"
                          value={secondHandMarketPrice}
                          onChange={(e) => handleSecondHandMarketPrice(e)}
                          placeholder=""
                        />
                        <p>Date : {secondMarketPriceDate} </p>
                      </div>
                      <div>
                        <FormControl
                          name="secondHandHouseBid"
                          value={secondHandHouseBid}
                          onChange={(e) => {
                            setSecondHandHouseBid(e.target.value);
                            handleItemss(e);
                          }}
                          placeholder=""
                        />
                      </div>
                      <div>
                        <FormControl
                          name="secondHandHouseAsk"
                          value={secondHandHouseAsk}
                          placeholder=""
                          onChange={(e) => {
                            setSecondHandHouseAsk(e.target.value);
                            handleItemss(e);
                          }}
                        />
                      </div>
                    </div>
                  </>
                  {/* ):( */}
                  <>
                    <div className="marbidask">
                      <h5 className="invisible"></h5>
                      <h5 className="invisible"></h5>
                      <h5 className="text-center mb-3 update_font ">
                        Market Price
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        House Bid
                      </h5>
                      <h5 className="text-center mb-3 update_font ">
                        House Ask
                      </h5>
                    </div>
                    <div className="marbidask">
                      <h5 className="update_font">Used</h5>
                      {/* {productType3?.length > 0 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={
                                productType3[0].status == true ? true : false
                              }
                              onChange={handleCheckClickThree}
                            />
                          }
                        />
                      </FormGroup>
                    ) : ( */}
                      {inactivenew === false ? <div>&nbsp;</div>: 
                      <div> 
                      <input
                        type="checkbox"
                        id="checkboxx"
                        name="checkbox"
                        checked={checkbox3}
                        value="3"
                        onClick={handleCheckClickThree3}
                      />
                      </div>}
                      {/* )} */}
                      <div>
                        <FormControl
                          name="usedMarketPrice"
                          value={usedMarketPrice}
                          onChange={(e) => handleUsedMarketPrice(e)}
                          placeholder=""
                        />
                        <p>Date: {usedMarketDate} </p>
                      </div>
                      <div>
                        <FormControl
                          name="usedHouseBid"
                          value={usedHouseBid}
                          onChange={(e) => {
                            setUsedHouseBid(e.target.value);
                            handleItemss(e);
                          }}
                          placeholder=""
                        />
                      </div>
                      <div>
                        <FormControl
                          name="usedHouseAsk"
                          value={usedHouseAsk}
                          onChange={(e) => {
                            setUsedHouseAsk(e.target.value);
                            handleItemss(e);
                          }}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="descrip mt-3 fs-4 fw-bold">
                <h4 className="fs-6 fw-bold">Description</h4>
                <div className="desc-main">
                  {/* <CKEditor
                    editor={ClassicEditor}
                    name="description"
                    data={editorContent}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorContent(data);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  /> */}
                     <CKEditor
                editor={ClassicEditor}
                data={editorContent}
                config={{
                  allowedContent: true, 
                  plugins: [
                    Font,
                    Bold,
                    Italic,
                    Link,
                    Strikethrough,
                    Subscript,
                    Underline,
                    List,
                    BlockQuote,
                    Heading,
                    Image,
                    MediaEmbed,
                    Undo 
                  ],
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "Underline",
                      "Superscript",
                      "Subscript",
                      "Strikethrough",
                      "fontSize",
                      "fontColor",
                      "fontBackgroundColor",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                      "mediaEmbed",
                      "imageUpload",
                      "link",
                      "fontFamily",
                      "alignment",
                     
                     
                      
                    ],
                  },
                  mediaEmbed: {
                    previewsInData: true,
                  },
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "|",
                      "imageStyle:full",
                      "imageStyle:side",
                    ],
                    styles: ["alignLeft", "alignCenter", "alignRight"],
                  },
                  fontFamily: {
                    options: ["Helvetica", "Open Sans"],
                  },
                
                 
                }}
                onChange={(event, editor) => {
                  const descriptionData = editor.getData();
                  setEditorContent((prevState) => ({
                    ...prevState,
                    description: descriptionData,
                  }));
                }}
              />
                </div>
              </div>
              <div className="descrip">
                <Form>
                  <FormLabel className="fw-bold fs-6 mt-3">SEO Title</FormLabel>
                  <FormControl
                    className="mb-3"
                    name="seoTitle"
                    value={seoTitle}
                    onChange={(e) => setSeoTitle(e.target.value)}
                    placeholder=""
                  />
                </Form>
              </div>
              <div className="descr">
                <Form>
                  <FormLabel className="fw-bold fs-6">
                    SEO Meta Description
                  </FormLabel>
                  <FormControl
                    name="metaDescription"
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    placeholder=""
                  />
                </Form>
              </div>
              {/* {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <div className="loader-icon" style={{ marginBlock: "80px" }}>
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                  </div>
                </div>
              ) : ( */}

              <div className="text-center my-5">
                <Button
                  className="text-white px-5 border-0 py-2 bg-success"
                  type="button"
                  // onClick={handleSubmit}  
                  onClick={inactivenew === false ? handleSubmitdata : handleSubmit}
                >
                  {saveloading ? "Saving..." : "Save"}
                </Button>
              </div>
              {/* )} */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* End Product Update Modal */}

      {/*Start bulkupdate Update Modal */}
      {/* <Modal
        className="update_product"
        show={modalShownew}
        onHide={() => setModalShownew(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Update</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Row>
            <Col lg={12}>
              <div className="basicinformation">
                <h4 className="fw-bold">Basic Information</h4>
                <p className="text-muted fw-bold">
                  Section to config basic product information
                </p>
                <Form>
                  <FormLabel className="fw-bold fs-6">Series</FormLabel>
                  <Select
                    options={newseries?.map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    onChange={handleOnSearchadminSeries}
                    value={selectedSeries}
                    placeholder="i.e. MSI, PNY, Zotac, etc..."
                    isClearable
                    required
                  />
                  <FormLabel className="fw-bold fs-6">House ask</FormLabel>
                  <FormControl
                    name="model"
                    className="mb-3"
                    type="number"
                    value={houseask}
                    onChange={(e) => {
                      const input = e.target.value;

                      const inputValue = parseFloat(input);

                      if (!isNaN(inputValue) || input === "") {
                        setHouseask(inputValue);
                      }
                    }}
                    placeholder="Enter the houseask value.."
                  />
                  <FormLabel className="fw-bold fs-6">House bid</FormLabel>
                  <FormControl
                    className="mb-3"
                    name="housebid"
                    type="number"
                    value={housebid}
                    onChange={(e) => {
                      const input = e.target.value;
                      // Convert input to a number (if possible)
                      const inputValue = parseFloat(input);
                      // Only update state if the input is a valid number or is empty
                      if (!isNaN(inputValue) || input === "") {
                        setHousebid(inputValue);
                      }
                    }}
                    placeholder="Enter the housebid value.."
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "25px",
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      label="used"
                      checked={option1Checked}
                      onChange={(e) => setOption1Checked(e.target.checked)}
                    />
                    <Form.Check
                      type="checkbox"
                      label="2nd hand"
                      checked={option2Checked}
                      onChange={(e) => setOption2Checked(e.target.checked)}
                    />
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="text-center my-5">
                <Button
                  className="text-white px-5 border-0 py-2 bg-success"
                  type="button"
                  onClick={handlebulkupdate}
                  disabled={btnLoading}
                >
                  {btnLoading ? "Updating...." : "Update"}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal> */}
      {/* End bulkupdate Update Modal */}

      {/* Start Filter Model */}
      <Modal
        show={showFilterModel}
        onHide={() => setShowFilterModel(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <div className="mt-sm-5 mt-3 border mb-sm-5">
            <div className="sidebar-accordian p-3">
              <div>
                <span>
                  <b>Condition</b>
                </span>
                {/* {FilterList?.condition?.map((val, i) => { */}
                {FilterList?.condition
                  ?.filter((item) => item != 1)
                  ?.map((val, i) => {
                    return (
                      <Form.Group className="mb-2" controlId={val}  key={i}>
                        <Form.Check
                          type="checkbox"
                          // checked={
                          //   conditionKey[i][
                          //   val == 1
                          //     ? "New-Retail"
                          //     : val == 2
                          //       ? "New-2nd Hand"
                          //       : "Used"
                          //   ]
                          // }
                          checked={conditionKey[i][val == 2 ? "New" : "Used"]}
                          onChange={(e) => conditionKeyFilter(e, i)}
                          // name={
                          //   val == 1
                          //     ? "New-Retail"
                          //     : val == 2
                          //       ? "New-2nd Hand"
                          //       : "Used"
                          // }
                          name={val == 2 ? "New" : "Used"}
                          min={val}
                          // label={
                          //   val == 1
                          //     ? "New-Retail"
                          //     : val == 2
                          //       ? "New-2nd Hand"
                          //       : "Used"
                          // }
                          label={val == 2 ? "New" : "Used"}
                        />
                      </Form.Group>
                    );
                  })}
                {conditionKey?.length > 6 ? (
                  <Button className="bg-none mb-3 border-0 p-0 text-dark">
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>
                    Show More
                  </Button>
                ) : null}
              </div>

              <div>
                <span>
                  <b>Chipset</b>
                </span>
                {chipsetShow
                  ? FilterList?.chipset?.map((val, i) => {
                      return (
                        <>
                          <Form.Group className="mb-2" controlId={val}  key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={chipsetKey[i][val]}
                              onChange={(e) => ChipsetFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        </>
                      );
                    })
                  : FilterList?.chipset?.map((val, i) => {
                      return (
                        <>
                          {i < 6 ? (
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                               key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={chipsetKey[i][val]}
                                onChange={(e) => ChipsetFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          ) : null}
                        </>
                      );
                    })}
                {chipsetShow ? (
                  FilterList?.chipset?.length > 6 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlechipsetshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.chipset?.length > 6 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handlechipsetshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>

              <div>
                <span>
                  <b>Brand </b>
                </span>
                {brandShow
                  ? FilterList?.brands?.map((val, i) => {
                      return (
                        <>
                          <Form.Group className="mb-2" controlId={val}  key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={brandKey[i][val]}
                              onChange={(e) => BrandtFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        </>
                      );
                    })
                  : FilterList?.brands?.map((val, i) => {
                      return (
                        <>
                          {i < 3 ? (
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                               key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={brandKey[i][val]}
                                onChange={(e) => BrandtFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          ) : null}
                        </>
                      );
                    })}
                {brandShow ? (
                  FilterList?.brands?.length > 3 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlebrandshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        </svg>
                      </span>
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.brands?.length > 3 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handlebrandshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>
                    Show More
                  </Button>
                ) : null}
              </div>

              <div>
                <span>
                  <b>Series</b>
                </span>
                {seriesShow ? (
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {FilterList?.series?.map((val, i) => {
                      return (
                        <>
                          <Form.Group className="mb-2" controlId={val}  key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={seriesKey[i][val]}
                              onChange={(e) => seriesFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    {FilterList?.series?.map((val, i) => {
                      return (
                        <>
                          {i < 6 ? (
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                               key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={seriesKey[i][val]}
                                onChange={(e) => seriesFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                )}
                {seriesShow ? (
                  FilterList?.series?.length > 10 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handleSeriesshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        </svg>
                      </span>{" "}
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.series?.length > 3 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handleSeriesshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>

              <div>
                <span>
                  <b>Model</b>
                </span>
                {modelShow ? (
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {FilterList?.model?.map((val, i) => {
                      return (
                        <>
                          <Form.Group className="mb-2" controlId={val}  key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={modalKey[i][val]}
                              onChange={(e) => modalFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  FilterList?.model?.map((val, i) => {
                    return (
                      <>
                        {i < 6 ? (
                          <Form.Group className="mb-2" controlId={val}  key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={modalKey[i][val]}
                              onChange={(e) => modalFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        ) : null}
                      </>
                    );
                  })
                )}
                {modelShow ? (
                  FilterList?.model?.length > 10 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlemodelshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        </svg>
                      </span>{" "}
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.model?.length > 6 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handlemodelshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end Filter Model */}
    </div>
  );
}
