import React, { useContext, useEffect, useMemo } from 'react';
import UserContext from '../../../../context/UserContext';
import { Container } from 'react-bootstrap';
import FavouriteList from './favouriteList/FavouriteList';

function Favourites() {
  const { newlySelectedProducts, setNewlySelectedProducts } = useContext(UserContext);
 

  // useEffect(() => {
  //   const authToken = localStorage.getItem('token');
  //   if (!authToken) {
  //     setNewlySelectedProducts([]);
  //   }
  // }); 
 
   

  return (
    newlySelectedProducts?.length > 0 && (
        <div className="favourite py-4 py-lg-5">
            <Container>
                <h2 className="mb-3 text-start">Your Favourite</h2>
                <div className="products-container">
                <div className="row products-list m-0">
                    <FavouriteList />
                </div>
                </div>
            </Container>
        </div>
    )
  );
}

export default Favourites;
