import React, {useState, useEffect} from 'react';
import { Table } from 'react-bootstrap';
// import './shipping.css';
import ShippingModal from '../components/ShippingModal';
import {BaseApi} from "../api/BaseApi";
import axios from 'axios';
import { toast } from 'react-toastify';

function ShippingDescriptions() {
    const [showModal, setShowModal] = useState(false);
    const [shippingData, setShippingData] = useState([]);
    const [singleData, setSingleData] = useState([]);
    const [description, setDescription] = useState('');
    const token = localStorage.getItem("token")

    useEffect(() => {
        axios.get("https://api.gpuwiz.com/" + 'user/getStatusList', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
        })
        .then(function (response) {
       
            setShippingData(response.data.detail)
        })
        .catch(function (error) {
            console.log(error);
        })
    }, [shippingData])

    const handleSingleData = (e, id) => {
        e.preventDefault();
        setShowModal(true)
        axios.get("https://api.gpuwiz.com/" + 'user/getStatus', {
            params: {
                statusid: id
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
        })
        .then(function (response) {
       
            setSingleData(response.data.detail);
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleShippingEdit = (e, id, description) => {
        e.preventDefault();
        axios.post("https://api.gpuwiz.com/" + 'user/updateStatus', {
            statusId: id,
            description: description 
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        })
        .then(function (response) {
         
            toast.success("Details Edited Successfully");
            axios.get("https://api.gpuwiz.com/" + 'user/getStatusList', {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
             
                setShippingData(response.data.detail)
            })
            .catch(function (error) {
                console.log(error);
            })
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(setShowModal(false))
    }
  return (
    <div className='shipping mt-5'>
        <h2>Shipping Details</h2>
        <Table striped className="align-middle mt-4">
            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {shippingData?.length > 0 ? (
                    shippingData.map((data, i) => {
                        return(
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.description}</td>
                                <td>
                                    <button className='btn d-flex align-items-center' onClick={(e) => {handleSingleData(e, data?._id)}}>Edit<span>
                                    <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.936 494.936"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path> <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path> </g> </g> </g></svg></span></button>
                                </td>
                            </tr>
                        )
                    })
                )
                :
                <h5 className='text-center'>No Data Found</h5>}
            </tbody>
        </Table>
        {showModal && <ShippingModal show={showModal} handleClose={handleClose} data={singleData} description={description} setDescription={setDescription} handleShippingEdit={handleShippingEdit} />}
    </div>
  )
}

export default ShippingDescriptions