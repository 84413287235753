import Table from "react-bootstrap/Table";
import React, { useEffect, useState } from "react";

function Tablepart(props) {
  const [userId, setUserId] = useState(0); // State to store the user's ID

  // Use useEffect to set the user's ID from local storage when the component mounts
  useEffect(() => {
    if (localStorage.getItem("tokenuser")) {
      setUserId(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      setUserId(JSON.parse(localStorage.getItem("admin")).id);
    }
  }, []);
 
  return (
    <>
      <p className="bidask_label text-center">Asks</p>
      <Table responsive="sm" striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => props.sortAskHere("askAmount")}>
              {props.h2}
            </th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {props.count == 0 ? (
            <tr>
              <td>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
              </td>
            </tr>
          ) : props.count == 1 ? (
            <>
              {props.data?.length > 0 || props.amount ? (
                props?.data.map((val) => {
                  return val?.userId == userId ? (
                    <>
                      <tr style={{ backgroundColor: "#75b299" }}>
                        <td>${val.askAmount}</td>
                        <td> 1</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>${val.askAmount}</td>
                        <td> 1</td>
                      </tr>
                    </>
                  );
                })
              ) : null}
            </>
          ) : (
            <tr>
              <td colSpan="2" className="text-center">No Asks</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default Tablepart;