import React, { useState, useEffect } from "react";
import { Accordion, Button, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ExportApi from "../api/ExportApi";

export default function AdminSideBar2() {
  const [togle, setTogle] = useState(false);
  const [userdata, setUserData] = useState();
  const [userdatann, setUserDatann] = useState();
  let userId = JSON.parse(localStorage.getItem("tokenuser"));
  let Street = JSON.parse(localStorage.getItem("useraddress"));
  let phone = JSON.parse(localStorage.getItem("phoneNumber"));
  let Location = useLocation();
  function handlePayment(event) {
    event.preventDefault();
    toast.error('Please complete User information')
  }

  const handleSingleUserData = (id) => {

    ExportApi.getSingleUserData(id)
      .then((resp) => {
  
        if (resp.data.message == "user not found") {

          setUserDatann(resp.data.message)
        } else {
          let Data = resp?.data?.data;
          setUserData(Data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split('/');
    const id = parts[parts.length - 1];

    handleSingleUserData(id);
  }, []);

  return (
    <div
      className={
        togle
          ? "bg-custom-light d-inline-block adminsidebar showleftbar"
          : "bg-custom-light d-inline-block adminsidebar"
      }
    >
      <Button
        className="mob-toggle"
        onClick={() => {
          setTogle(!togle);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            filerule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          /> {/* User Sidebar */}
        </svg>
      </Button>
      <Nav defaultActiveKey="/home" className="flex-column">
        <Link to="/buySellManagment/selling" className={Location.pathname == "/buySellManagment/selling" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-house"
              viewBox="0 0 16 16"
            >
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
            </svg>
          </span>
          Selling
        </Link>
        <Link to="/buySellManagment/buying" className={Location.pathname == "/buySellManagment/buying" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-inbox-fill"
              viewBox="0 0 16 16"
            >
              <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z" />
            </svg>
          </span>
          Buying
        </Link>

        <Link to="/buySellManagment/pending" className={Location.pathname == "/buySellManagment/pending" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
          </span>
          Pending
        </Link>
        <Link to="/buySellManagment/history" className={Location.pathname == "/buySellManagment/history" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-bank2"
              viewBox="0 0 16 16"
            >
              <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
            </svg>
          </span>
          History
        </Link>

        {/* <Link to="#" className={Location.pathname == "#" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-bar-chart-line-fill"
              viewBox="0 0 16 16"
            >
              <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
            </svg>
          </span>
          Message
        </Link> */}
        <Link to="/buySellManagment/favorites" className={Location.pathname == "/buySellManagment/favorites" ? "active" : ""}>
          <span className="me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-suit-heart-fill"
              viewBox="0 0 16 16"
            >
              <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
            </svg>
          </span>
          Favourites
        </Link>



        <Link to="#">
          <Accordion>
            {
              Location.pathname == "/profile/" + userId.id || Location.pathname == "/stripeconnectaccount/" + userId.id ?
                <Accordion.Item>
                  <Accordion.Header>
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-tag-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                      </svg>
                    </span>
                    Setting
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to={"/profile/" + userId.id} className={Location.pathname == "/profile/" + userId.id ? "active" : ""}>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-suit-heart-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                        </svg>
                      </span>
                      User Info
                    </Link>
                    {/* {
                   Street == null ?
                   <Link to="" onClick={handlePayment}>
                   <span className="me-2">
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       width="16"
                       height="16"
                       fill="currentColor"
                       className="bi bi-suit-heart-fill"
                       viewBox="0 0 16 16"
                     >
                       <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                     </svg>
                   </span>
                   Payments and Payouts 
                 </Link>
                  :   */}

                    <Link 
                    to={"/stripeconnectaccount/" + userId.id} className={Location.pathname == "/stripeconnectaccount/" + userId.id ? "active" : ""} 
                    //to={"/stripeconnectaccount"} className={Location.pathname == "/stripeconnectaccount" ? "active" : ""} 
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-suit-heart-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                        </svg>
                      </span>
                      Payments and Payouts
                    </Link>
                    <Link 
                    to={"/g2fa"}   
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-suit-heart-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                        </svg>
                      </span>
                     2FA
                    </Link>
                    {/* }  */}
                    {/* <Link to="#">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-suit-heart-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                    </svg>
                  </span>
                  Security
                </Link> */}
                  </Accordion.Body>
                </Accordion.Item> :
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-tag-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                      </svg>
                    </span>
                    Setting
                  </Accordion.Header>
                  <Accordion.Body>
                    <Link to={"/profile/" + userId.id} className={Location.pathname == "/profile/" + userId.id ? "active" : ""}>
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-suit-heart-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                        </svg>
                      </span>
                      User Info
                    </Link>
                    {

                      //    Street == null ?
                      //    <Link to="/" onClick={handlePayment}>
                      //    <span className="me-2">
                      //      <svg
                      //        xmlns="http://www.w3.org/2000/svg"
                      //        width="16"
                      //        height="16"
                      //        fill="currentColor"
                      //        className="bi bi-suit-heart-fill"
                      //        viewBox="0 0 16 16"
                      //      >
                      //        <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                      //      </svg>
                      //    </span>
                      //    Payments and Payouts
                      //  </Link>

                      //        :  

                      <Link to={"/stripeconnectaccount/" + userId?.id} className={Location.pathname == "/stripeconnectaccount/" + userId?.id ? "active" : ""} >
                        <span className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-suit-heart-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                          </svg>
                        </span>
                        Payments and Payouts
                      </Link>
                      
              }  
              {
                  <Link 
                  to={"/g2fa"}   
                  >
                    <span className="me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-suit-heart-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                      </svg>
                    </span>
                   2FA
                  </Link>
              }
                {/* <Link to="#">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-suit-heart-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                    </svg>
                  </span>
                  Security
                </Link> */}
                  </Accordion.Body>
                </Accordion.Item>
            }
          </Accordion>
        </Link>
      </Nav>
      <ToastContainer />
    </div>
  );
}
