import React from "react";
import { useEffect } from "react";
import { Table, Button, Row, Col, Form } from "react-bootstrap";
import ExportApi from "../api/ExportApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PendingSingleTable from "./PendingSingleTable";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
const HistoryTable = () => {
  const navigate = useNavigate();
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const [sellerHistoryData, setSellerHistoryData] = useState([]);
  const [logineduser, setLogineduser] = useState(null);
  const [buyerHistoryData, setBuyerHistoryData] = useState([]);
  const [currentSortKey, setCurrentSortKey] = useState(null);
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [showBuyerData, setShowBuyerData] = useState(true);
  const dataToDisplay = showBuyerData ? buyerHistoryData : sellerHistoryData;

  const [orderNumber, setOrderNumber] = useState();

  const [descriptionindex, setdescriptionindex] = useState();

  const [productId, setProductId] = useState();
  const dataToDisplayText = showBuyerData ? "Data2" : "Data";
  const [productStatus, setProductStatus] = useState();

  const [position, setPosition] = useState({ top: 0, left: 130 });
  const [positionShipping, setPositionShipping] = useState({
    top: 56,
    left: 100,
  });
  const [sortBy, setSortBy] = useState("");

 

  // useEffect(() => {
  //   if (sortBy) {

  //     let tempData = [...(showBuyerData ? buyerHistoryData : sellerHistoryData)];

  //     switch (sortBy) {
  //       case 'chipset asc':
  //         tempData.sort((a, b) => {
  //           const chipsetA = a?.productId?.chipset;
  //           const chipsetB = b?.productId?.chipset;

  //           // Handle cases where chipset values might be missing or undefined
  //           if (chipsetA === undefined || chipsetB === undefined) {
  //             console.warn('Chipset value missing in some items', { a, b });
  //             return 0; // Default behavior when chipset is missing
  //           }

  //           // Sort alphabetically by chipset (assuming chipset is a string)
  //           return chipsetA.localeCompare(chipsetB);
  //         });
  //         break;

  //       case 'chipset des':
  //         tempData.sort((a, b) => {
  //           const chipsetA = a?.productId?.chipset;
  //           const chipsetB = b?.productId?.chipset;

  //           // Handle cases where chipset values might be missing or undefined
  //           if (chipsetA === undefined || chipsetB === undefined) {
  //             console.warn('Chipset value missing in some items', { a, b });
  //             return 0; // Default behavior when chipset is missing
  //           }

  //           // Sort alphabetically by chipset in descending order
  //           return chipsetB.localeCompare(chipsetA); // Reversed order
  //         });
  //         break;

  //       case 'brand asc':
  //         tempData.sort((a, b) => {
  //           const brandA = a?.productId?.brand;
  //           const brandB = b?.productId?.brand;

  //           // Handle cases where brand values might be missing or undefined
  //           if (brandA === undefined || brandB === undefined) {
  //             console.warn('Brand value missing in some items', { a, b });
  //             return 0; // Default behavior when brand is missing
  //           }

  //           // Sort alphabetically by brand (assuming brand is a string)
  //           return brandA.localeCompare(brandB);
  //         });
  //         break;

  //       case 'brand des':
  //         tempData.sort((a, b) => {
  //           const brandA = a?.productId?.brand;
  //           const brandB = b?.productId?.brand;

  //           // Handle cases where brand values might be missing or undefined
  //           if (brandA === undefined || brandB === undefined) {
  //             console.warn('Brand value missing in some items', { a, b });
  //             return 0; // Default behavior when brand is missing
  //           }

  //           // Sort alphabetically by brand in descending order
  //           return brandB.localeCompare(brandA); // Reversed order
  //         });
  //         break;

  //       case 'series asc':
  //         tempData.sort((a, b) => {
  //           const seriesA = a?.productId?.series;
  //           const seriesB = b?.productId?.series;

  //           // Handle cases where series values might be missing or undefined
  //           if (seriesA === undefined || seriesB === undefined) {
  //             console.warn('Series value missing in some items', { a, b });
  //             return 0; // Default behavior when series is missing
  //           }

  //           // Sort alphabetically by series (assuming series is a string)
  //           return seriesA.localeCompare(seriesB);
  //         });
  //         break;

  //       case 'series des':
  //         tempData.sort((a, b) => {
  //           const seriesA = a?.productId?.series;
  //           const seriesB = b?.productId?.series;

  //           // Handle cases where series values might be missing or undefined
  //           if (seriesA === undefined || seriesB === undefined) {
  //             console.warn('Series value missing in some items', { a, b });
  //             return 0; // Default behavior when series is missing
  //           }

  //           // Sort alphabetically by series in descending order
  //           return seriesB.localeCompare(seriesA); // Reversed order
  //         });
  //         break;
  //         case 'model asc':
  //           tempData.sort((a, b) => {
  //             const modelA = a?.productId?.model;
  //             const modelB = b?.productId?.model;

  //             // Handle cases where model values might be missing or undefined
  //             if (modelA === undefined || modelB === undefined) {
  //               console.warn('Model value missing in some items', { a, b });
  //               return 0; // Default behavior when model is missing
  //             }

  //             // Sort alphabetically by model (assuming model is a string)
  //             return modelA.localeCompare(modelB);
  //           });
  //           break;

  //         case 'model des':
  //           tempData.sort((a, b) => {
  //             const modelA = a?.productId?.model;
  //             const modelB = b?.productId?.model;

  //             // Handle cases where model values might be missing or undefined
  //             if (modelA === undefined || modelB === undefined) {
  //               console.warn('Model value missing in some items', { a, b });
  //               return 0; // Default behavior when model is missing
  //             }

  //             // Sort alphabetically by model in descending order
  //             return modelB.localeCompare(modelA); // Reversed order
  //           });
  //           break;

  //       case 'orderId asc':
  //         tempData.sort((a, b) => {
  //           const orderIdA = a?.orderId;
  //           const orderIdB = b?.orderId;

  //           // Sort by order ID (assuming _id is a string or number)
  //           return orderIdA - orderIdB;
  //         });
  //         break;

  //       case 'orderId des':
  //         tempData.sort((a, b) => {
  //           const orderIdA = a?.orderId;
  //           const orderIdB = b?.orderId;

  //           // Sort by order ID in descending order (assuming _id is a string or number)
  //           return orderIdB - orderIdA; // Reversed order
  //         });
  //         break;

  //         case 'status asc':
  //           tempData.sort((a, b) => {
  //             const getStatus = (item) => {
  //               if (item?.sellerId?._id === logineduser || item?.buyerId?._id === logineduser) {
  //                 return dataToDisplayText === "Data2"
  //                   ? item?.deliveryStatusId?.deliveryStatusForBuyer?.status || "No description available"
  //                   : item?.deliveryStatusId?.deliveryStatusForSeller?.status || "No description available";
  //               }
  //               if (item?.deliveryStatusId?.last_cancel_type === "2") return 'Failed to Ship';
  //               if (item?.deliveryStatusId?.last_cancel_type === "3") return 'Authentication Failed';
  //               return 'Order Cancelled';
  //             };

  //             const statusA = getStatus(a);
  //             const statusB = getStatus(b);

  //             // Handle cases where status values might be missing or undefined
  //             if (statusA === undefined || statusB === undefined) {
  //               console.warn('Status value missing in some items', { a, b });
  //               return 0; // Default behavior when status is missing
  //             }

  //             // Sort alphabetically by status (assuming status is a string)
  //             return statusA.localeCompare(statusB);
  //           });
  //           break;

  //         case 'status des':
  //           tempData.sort((a, b) => {
  //             const getStatus = (item) => {
  //               if (item?.sellerId?._id === logineduser || item?.buyerId?._id === logineduser) {
  //                 return dataToDisplayText === "Data2"
  //                   ? item?.deliveryStatusId?.deliveryStatusForBuyer?.status || "No description available"
  //                   : item?.deliveryStatusId?.deliveryStatusForSeller?.status || "No description available";
  //               }
  //               if (item?.deliveryStatusId?.last_cancel_type === "2") return 'Failed to Ship';
  //               if (item?.deliveryStatusId?.last_cancel_type === "3") return 'Authentication Failed';
  //               return 'Order Cancelled';
  //             };

  //             const statusA = getStatus(a);
  //             const statusB = getStatus(b);

  //             // Handle cases where status values might be missing or undefined
  //             if (statusA === undefined || statusB === undefined) {
  //               console.warn('Status value missing in some items', { a, b });
  //               return 0; // Default behavior when status is missing
  //             }

  //             // Sort alphabetically by status in descending order
  //             return statusB.localeCompare(statusA); // Reversed order
  //           });
  //           break;
  //       case 'createdAt asc':
  //         tempData.sort((a, b) => {
  //           const createdAtA = new Date(a?.createdAt);
  //           const createdAtB = new Date(b?.createdAt);

  //           // Sort by order date (assuming createdAt is a valid date string)
  //           return createdAtA - createdAtB;
  //         });
  //         break;

  //       case 'createdAt des':
  //         tempData.sort((a, b) => {
  //           const createdAtA = new Date(a?.createdAt);
  //           const createdAtB = new Date(b?.createdAt);

  //           return createdAtB - createdAtA;
  //         });
  //         break;

  //       case 'subTotal asc':
  //         tempData.sort((a, b) => {
  //           const subTotalA = a?.bidId?.subTotal;
  //           const subTotalB = b?.bidId?.subTotal;

  //           return subTotalA - subTotalB;
  //         });
  //         break;

  //       case 'subTotal des':
  //         tempData.sort((a, b) => {
  //           const subTotalA = a?.bidId?.subTotal;
  //           const subTotalB = b?.bidId?.subTotal;

  //           return subTotalB - subTotalA;
  //         });
  //         break;

  //       default:
  //         console.warn('Unsupported sortBy criteria:', sortBy);
  //         break;
  //     }

  //     setSellerHistoryData(tempData);
  //   }
  // }, [sortBy]);

  useEffect(() => {
    if (sortBy) {
      // Determine which dataset to sort
      let tempData = showBuyerData
        ? [...buyerHistoryData]
        : [...sellerHistoryData];

      const sortData = (a, b, getValueFn) => {
        const valueA = getValueFn(a);
        const valueB = getValueFn(b);

        // Convert values to strings if they are not already strings
        const strValueA = String(valueA);
        const strValueB = String(valueB);

        // Handle cases where values might be missing or not convertible to strings
        if (strValueA === undefined || strValueB === undefined) {
          console.warn("Value missing or not string in some items", { a, b });
          return 0; // Default behavior when value is missing or not string
        }

        // Use localeCompare for string comparison
        return (
          strValueA.localeCompare(strValueB) * (sortBy.endsWith("des") ? -1 : 1)
        );
      };

      switch (sortBy) {
        case "chipset asc":
        case "chipset des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => item?.productId?.chipset || "")
          );
          break;

        case "brand asc":
        case "brand des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => item?.productId?.brand || "")
          );
          break;

        case "series asc":
        case "series des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => item?.productId?.series || "")
          );
          break;

        case "model asc":
        case "model des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => item?.productId?.model || "")
          );
          break;

        case "orderId asc":
        case "orderId des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => item?.orderId || "")
          );
          break;

        case "status asc":
        case "status des":
          tempData.sort((a, b) =>
            sortData(a, b, (item) => {
              if (
                item?.sellerId?._id === logineduser ||
                item?.buyerId?._id === logineduser
              ) {
                return dataToDisplayText === "Data2"
                  ? item?.deliveryStatusId?.deliveryStatusForBuyer?.status ||
                      "No description available"
                  : item?.deliveryStatusId?.deliveryStatusForSeller?.status ||
                      "No description available";
              }
              if (item?.deliveryStatusId?.last_cancel_type === "2")
                return "Failed to Ship";
              if (item?.deliveryStatusId?.last_cancel_type === "3")
                return "Authentication Failed";
              return "Order Cancelled";
            })
          );
          break;

        case "createdAt asc":
        case "createdAt des":
          tempData.sort((a, b) => {
            const dateA = new Date(a?.createdAt);
            const dateB = new Date(b?.createdAt);
            return (dateA - dateB) * (sortBy.endsWith("des") ? -1 : 1);
          });
          break;

        case "subTotal asc":
        case "subTotal des":
          tempData.sort((a, b) => {
            const subTotalA = a?.bidId?.subTotal || 0;
            const subTotalB = b?.bidId?.subTotal || 0;
            return (subTotalA - subTotalB) * (sortBy.endsWith("des") ? -1 : 1);
          });
          break;

        default:
          console.warn("Unsupported sortBy criteria:", sortBy);
          break;
      }

      // Update the correct state based on showBuyerData flag
      if (showBuyerData) {
        setBuyerHistoryData(tempData);
      } else {
        setSellerHistoryData(tempData);
      }
    }
  }, [
    sortBy,
    showBuyerData,
    buyerHistoryData,
    sellerHistoryData,
    logineduser,
    dataToDisplayText,
  ]);

  //  Sorting Table Data
  const sortHere = (key) => {
 
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;

    const sortedProducts = sellerHistoryData?.slice().sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      // Handle null or undefined values
      if (aValue == null) return 1 * sortOrder;
      if (bValue == null) return -1 * sortOrder;

      // Handle string comparison
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * sortOrder;
      }

      // Handle number comparison
      if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * sortOrder;
      }

      // Fallback to default comparison
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return sortOrder;
      return 0;
    });

    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
    setSellerHistoryData(sortedProducts);
  };

  const HandleHistoryData = (id) => {
    ExportApi.getPendingData(id)
      .then((resp) => {
        let data = resp?.data?.Data;
      

        let SellerCompleteOrderData = data?.sellerOrderlist?.filter((item) =>
          item?.sellerId?._id != logineduser
            ? item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Sale Complete" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Order Cancelled" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Failed to Ship" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Authentication Failed" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Refunded"
            : // "Sale Complete" Refunded
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Sale Complete" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Order Cancelled" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Failed to Ship" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Authentication Failed" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Refunded"
        );

        setSellerHistoryData(SellerCompleteOrderData);
        let BuyerCompleteOrderData = data?.buyerOrderlist?.filter(
          (item) =>
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
              "Delivered" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
              "Payment Failed" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status == "Refunded"
        );

      
        setBuyerHistoryData(BuyerCompleteOrderData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSingleUserData = (id) => {
    ExportApi.getSingleUserData(id)
      .then((resp) => {
        setLogineduser(resp.data.data._id);
        if (resp.data.message == "user not found") {
          window.dispatchEvent(new Event("Loginout"));
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  window.addEventListener("Loginout", () => {
    localStorage.clear();
    navigate("/");
  });

  useEffect(() => {
    if (localStorage.getItem("tokenuser")) {
      HandleHistoryData(JSON.parse(localStorage.getItem("tokenuser")).id);
      handleSingleUserData(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      HandleHistoryData(JSON.parse(localStorage.getItem("admin")).id);
      handleSingleUserData(JSON.parse(localStorage.getItem("admin")).id);
    } else {
      console.log("No Data Found");
    }
  }, []);

  const handleOrderNumberfield = (e) => {
  
    setOrderNumber(e.target.value);
  };

  const orderSearch = () => {
    if (!orderNumber) {
      toast.error("Enter Order number");
    } else {
      ExportApi.searchOrder(orderNumber)
        .then((resp) => {
          let data = resp.data.Data;

          let SellerCompleteOrderData = data?.sellerOrderlist?.filter(
            (item) =>
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Failed to Ship" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Sale Complete" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Item returned"
          );

          setSellerHistoryData(SellerCompleteOrderData);
          let BuyerCompleteOrderData = data?.buyerOrderlist?.filter(
            (item) =>
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Delivered" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Payment Failed" ||
              item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
                "Refunded"
          );
          setBuyerHistoryData(BuyerCompleteOrderData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Row>
        <Col
          lg={12}
          className="product_list_table table-responsive mt-sm-5 mt-2"
        >
          <div className="data-list d-flex mt-4">
            <button
              className={`btn border me-3 ${showBuyerData ? "active" : ""}`}
              onClick={() => setShowBuyerData(true)}
            >
              Buyer Data
            </button>
            <button
              className={`btn border ${showBuyerData ? "" : "active"}`}
              onClick={() => setShowBuyerData(false)}
            >
              Seller Data
            </button>
            <div className="d-flex align-items-center ms-auto">
              <Form.Control
                // value={value}
                onChange={(e) => {
                  handleOrderNumberfield(e);
                }}
                placeholder=""
                type="text"
                // className="border-start-0"
                aria-label="number"
                aria-describedby="basic-addon1"
              />
              <Button
                className="text-capitalize border rounded bg-transparent text-black"
                onClick={() => orderSearch()}
              >
                Search
              </Button>
            </div>
          </div>
          <>
            <Table
              bordered
              striped
              className="align-middle text-center managment-table"
            >
              <thead>
                <tr>
                  <th className="text-start"></th>
                  <th className="sorthead">
                    Chipset
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("chipset asc")} />
                      <IoIosArrowDown
                        onClick={() => setSortBy("chipset des")}
                      />
                    </span>
                  </th>
                  <th className="sorthead">
                    Brand
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("brand asc")} />
                      <IoIosArrowDown onClick={() => setSortBy("brand des")} />
                    </span>
                  </th>
                  <th className="sorthead">
                    Series
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("series asc")} />
                      <IoIosArrowDown onClick={() => setSortBy("series des")} />
                    </span>
                  </th>
                  <th className="sorthead">
                    Model
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("model asc")} />
                      <IoIosArrowDown onClick={() => setSortBy("model des")} />
                    </span>
                  </th>

                  <th className="sorthead">
                    Order No.
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("orderId asc")} />
                      <IoIosArrowDown
                        onClick={() => setSortBy("orderId des")}
                      />
                    </span>
                  </th>
                  <th className="sorthead">
                    Status
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("status asc")} />
                      <IoIosArrowDown onClick={() => setSortBy("status des")} />
                    </span>
                  </th>
                  <th className="sorthead">
                    Order Date
                    <span className="sortingarrow">
                      <IoIosArrowUp
                        onClick={() => setSortBy("createdAt asc")}
                      />
                      <IoIosArrowDown
                        onClick={() => setSortBy("createdAt des")}
                      />
                    </span>
                  </th>
                  <th>
                    Price
                    <span className="sortingarrow">
                      <IoIosArrowUp onClick={() => setSortBy("subTotal asc")} />
                      <IoIosArrowDown
                        onClick={() => setSortBy("subTotal des")}
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay?.length > 0 ? (
                  dataToDisplay?.map((item, index) => {
            
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-end position-relative">
                            {item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Ready to Ship" ? (
                              <Form.Check
                                className="d-inline"
                                type="checkbox"
                                // checked={}
                                onChange={(e) =>
                                  handleCheck(
                                    e.target.checked,
                                    item.productId?._id,
                                    item._id
                                  )
                                }
                              />
                            ) : (
                              ""
                            )}
                            <img
                              // src={`${imageUrl}${item.productId?.image[0]}`}
                              src={
                                imageUrl.startsWith("http")
                                  ? item.productId?.image[0]
                                  : `${imageUrl}${item.productId?.image[0]}`
                              }
                              style={{ width: "70px" }}
                              onClick={() =>
                                navigate(`/product/${item.productId._id}`)
                              }
                            />
                            <div className="position-relative ms-1">
                              <span className="d-block text-center">
                                {item.productId?.type == 1
                                  ? "New"
                                  : item.productId?.type == 2
                                  ? "2nd Hand"
                                  : "Used"}
                              </span>
                              {descriptionindex == index ? (
                                <Button
                                  className="bg-none border-0"
                                  onClick={() => setdescriptionindex()}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#0000ff"
                                    className="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      filerule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </Button>
                              ) : (
                                <Button
                                  className="bg-none border-0"
                                  onClick={() => setdescriptionindex(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#0000ff"
                                    className="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      filerule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </Button>
                              )}
                            </div>
                          </div>
                          {descriptionindex == index ? (
                            <div
                              className="modal_custom_ pending-mod"
                              style={{
                                top: `${position.top}px`,
                                left: `${position.left}px`,
                              }}
                            >
                              <div className="inner_customModal_one">
                                {/* <h6
                                dangerouslySetInnerHTML={{
                                  __html: item?.productId?.description,
                                }}
                              ></h6> */}
                                <h6
                                style={{ marginBottom: 0 }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${
                                      item?.productId?.description
                                        ? item?.productId?.description.substring(
                                            0,
                                            351
                                          )
                                        : "No product description"
                                    }${
                                      item?.productId?.description?.length > 351
                                        ? "..."
                                        : ""
                                    }`,
                                  }}
                                ></h6>
                                {item?.productId?.description?.length > 351 ? (
                                  <a
                                    onClick={() =>
                                      navigate(
                                        `/product/${item?.productId?._id}`
                                      )
                                    }
                                  >
                                    See more
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : null}
                        </td>

                        <td
                          style={{ cursor: "default" }}
                          title={item?.productId?.chipset}
                        >
                          {item.productId?.chipset}
                        </td>
                        <td
                          style={{ cursor: "default" }}
                          title={item?.productId?.brand}
                        >
                          {item.productId?.brand}
                        </td>
                        <td
                          style={{ cursor: "default" }}
                          title={item?.productId?.series}
                        >
                          {item.productId?.series}
                        </td>
                        <td
                          style={{
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: "default",
                          }}
                          title={item?.productId?.model}
                        >
                          {item.productId?.model}
                        </td>
                        <td>
                          {item.orderId}{" "}
                          {productId == index ? (
                            <Button
                              className="bg-none border-0"
                              onClick={() => setProductId()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#0000ff"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  filerule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </Button>
                          ) : (
                            <Button
                              className="bg-none border-0"
                              onClick={() => setProductId(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#0000ff"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  filerule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </Button>
                          )}
                          {productId == index ? (
                            <div
                              className="modal_custom_ pending-mod  new_pending_drop"
                              style={{ minWidth: "fit-content" }}
                            >
                              <div
                                className="inner_customModal_two"
                                style={{ padding: "10px" }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `<div class="order-detail-list">
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">Sale Price</p>
                                        <p>$${
                                          dataToDisplayText == "Data2"
                                            ? item?.bidId?.bidAmount
                                            : item?.askId?.askAmount
                                        }</p>
                                    </div>
                                    ${
                                      dataToDisplayText == "Data"
                                        ? `<div class='d-flex justify-content-between'>
                                            <p class="me-3">Transaction Fee</p>
                                            <p>$${item?.askId?.transactionFee}</p>
                                        </div>`
                                        : ""
                                    }
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">${
                                          dataToDisplayText == "Data2"
                                            ? "Processing Fee"
                                            : "Payment Processing Fee"
                                        }</p>
                                        <p>$${
                                          dataToDisplayText == "Data2"
                                            ? item?.bidId?.processingFee
                                            : item?.askId?.processingFee
                                        }</p>
                                    </div>
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">Shipping</p>
                                        <p>$${
                                          dataToDisplayText == "Data2"
                                            ? item?.bidId?.shippingFee
                                            : item?.askId?.shippingFee
                                        }
                                        </p>
                                    </div>
                                    ${
                                      dataToDisplayText == "Data2"
                                        ? `<div class='d-flex justify-content-between'>
                                          <p class="me-3">Sales Tax</p>
                                          <p>$${item?.askId?.transactionFee}</p>
                                      </div>`
                                        : ""
                                    }
                                    <div class='d-flex justify-content-between fw-bold'>
                                        <p class="me-3">TOTAL</p>
                                        <div class="d-flex align-items-center">
                                        <p class="mb-0 me-1">$${
                                          dataToDisplayText == "Data2"
                                            ? item?.bidId?.subTotal
                                            : item?.askId?.subTotal
                                        }
                                      </p>
                                    ${
                                      item?.payment_details?.status == "Paid"
                                        ? // If payment status is "paid"
                                          `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008000" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                      </svg>`
                                        : item?.payment_details?.status ==
                                          "failed"
                                        ? // If payment status is "failed"
                                          `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                        </svg>`
                                        : // If payment status is neither "paid" nor "failed"
                                          `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5L3 10L8 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3 10H11C16.523 10 21 14.477 21 20V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>`
                                    }
                                    </div>
                                    </div>
                                </div>
                                ${
                                  dataToDisplayText == "Data"
                                    ? `<div class='d-flex justify-content-between'>
                                      <p class="me-3">Inspection Result</p>
                                      <p>${
                                        item?.deliveryStatusId?.authStatus
                                          ?.status
                                      }</p> &nbsp;
                                        ${
                                          item?.deliveryStatusId?.authStatus
                                            ?.status === "fail"
                                            ? item?.deliveryStatusId?.authStatus
                                                ?.reason?.length > 0 &&
                                              item?.deliveryStatusId?.authStatus?.reason.map(
                                                (reason, index) =>
                                                  `<p key={index}>${reason}test</p>`
                                              )
                                            : ""
                                        }   
                                  </div>
                                  <div class='d-flex justify-content-between'>   
                                      <p class="me-3">${
                                        item?.deliveryStatusId?.authStatus
                                          ?.comment
                                          ? "Comment"
                                          : ""
                                      }</p>                             
                                      <p>${
                                        item?.deliveryStatusId?.authStatus
                                          ?.comment
                                          ? item?.deliveryStatusId?.authStatus
                                              ?.comment
                                          : ""
                                      }</p>
                                  </div>`
                                    : ""
                                }`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          ) : null}{" "}
                        </td>
                        <td>
                          {dataToDisplayText == "Data2" ? (
                            item?.deliveryStatusId?.deliveryStatusForBuyer
                              ?.status == "Waiting on Seller" ? (
                              <Button className="order_status_waiting border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Payment Failed" ? (
                              <Button className="order_status_shipped_seller border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Pending Inspection" ? (
                              <Button className="order_status_ready_test border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Ready For Shipment" ? (
                              <Button className="order_status_ready_shipment border text-white">
                                {item?.deliveryStatusId?.deliveryStatus?.status}
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Shipped Out" ? (
                              <Button className="order_status_shipped_out border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Delivered" ? (
                              <Button className="order_status_recently_delivered border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Placed with New Seller" ? (
                              <Button className="order_status_return border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                ?.status == "Refunded" ? (
                              <Button className="order_status_refunded  border text-white">
                                {
                                  item?.deliveryStatusId?.deliveryStatusForBuyer
                                    ?.status
                                }
                              </Button>
                            ) : (
                              ""
                            )
                          ) : item?.sellerId?._id != logineduser ? (
                            item?.deliveryStatusId?.last_cancel_type == "2" ? (
                              <Button
                                style={{ cursor: "not-allowed" }}
                                className="order_status_failed_to_ship border text-white"
                              >
                                Failed to Ship
                              </Button>
                            ) : item?.deliveryStatusId?.last_cancel_type ==
                              "3" ? (
                              <Button
                                style={{ cursor: "not-allowed" }}
                                className="order_status_failed_to_ship border text-white"
                              >
                                Authentication Failed
                              </Button>
                            ) : (
                              <Button
                                style={{ cursor: "not-allowed" }}
                                className="order_status_refunded border text-white"
                              >
                                Order Cancelled
                              </Button>
                            )
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Waiting on Seller" ? (
                            <Button className="order_status_waiting border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Payment Failed" ? (
                            <Button className="order_status_shipped_seller border text-white">
                              {
                                item?.deliveryStatusId.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Pending Inspection" ? (
                            <Button className="order_status_ready_test border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Ready to Ship" ? (
                            <Button className="order_status_ready_shipment border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Shipped Out" ? (
                            <Button className="order_status_shipped_out border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Delivered" ? (
                            <Button className="order_status_recently_delivered border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Placed with New Seller" ? (
                            <Button className="order_status_return border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Refunded" ? (
                            <Button className="order_status_return border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Sale Complete" ? (
                            <Button className="order_status_return border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Authentication Failed" ? (
                            <Button className="order_status_failed_to_ship border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Order Cancelled" ? (
                            <Button className="order_status_refunded border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : item?.deliveryStatusId?.deliveryStatusForSeller
                              ?.status == "Failed to Ship" ? (
                            <Button className="order_status_failed_to_ship  border text-white">
                              {
                                item?.deliveryStatusId?.deliveryStatusForSeller
                                  ?.status
                              }
                            </Button>
                          ) : (
                            ""
                          )}

                          {productStatus == index ? (
                            <Button
                              className="bg-none border-0"
                              onClick={() => setProductStatus()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#0000ff"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  filerule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </Button>
                          ) : (
                            <Button
                              className="bg-none border-0"
                              onClick={() => setProductStatus(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#0000ff"
                                className="bi bi-chevron-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  filerule="evenodd"
                                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </Button>
                          )}
                          <OutsideClickHandler
                            onOutsideClick={(e) => {
                              if (!e.target.closest(".modal_custom_")) {
                                setdescriptionindex();
                                setProductId();
                                setProductStatus();
                              }
                            }}
                          ></OutsideClickHandler>
                          {productStatus == index ? (
                            <div
                              className="modal_custom_ pending-mod right-tooltip"
                              style={{
                                top: `${positionShipping.top}px`,
                                left: `${positionShipping.left}px`,
                              }}
                            >
                              <div className="inner_customModal_one">
                                {/* <h6
                                dangerouslySetInnerHTML={{
                                  __html: dataToDisplayText == "Data2" ? (item?.deliveryStatusId?.deliveryStatusForBuyer?.status) : (item?.deliveryStatusId?.deliveryStatusForSeller?.status)
                                }}
                              ></h6>                               */}
                                <h6
                                style={{ marginBottom: 0 }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      item?.sellerId?._id === logineduser ||
                                      item?.buyerId?._id === logineduser
                                        ? dataToDisplayText === "Data2"
                                          ? item?.deliveryStatusId
                                              ?.deliveryStatusForBuyer
                                              ?.status ||
                                            "No description available"
                                          : item?.deliveryStatusId
                                              ?.deliveryStatusForSeller
                                              ?.status ||
                                            "No description available"
                                        : item?.deliveryStatusId
                                            ?.last_cancel_type === "2"
                                        ? "Failed to Ship"
                                        : item?.deliveryStatusId
                                            ?.last_cancel_type === "3"
                                        ? "Authentication Failed"
                                        : "Order Cancelled",
                                  }}
                                ></h6>
                              </div>
                            </div>
                          ) : null}
                        </td>

                        <td>{moment(item?.createdAt).format("l")}</td>
                        <td>{"$" + item?.bidId?.subTotal}</td>
                      </tr>
                    );
                  })
                ) : (
                  <span>
                    {showBuyerData
                      ? "No Buyer Data Found"
                      : "No Seller Data Found"}
                  </span>
                )}
              </tbody>
            </Table>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default HistoryTable;
