// import { DotenvConfigOptions } from "dotenv";
// DotenvConfigOptions()
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { Route } from "react-router-dom";
import OrderView from "./pages/OrderView";
import NewProduct from "./Admin/NewProduct";
import BuySell from "./pages/BuySell";
import AddInventory from "./Admin/Invertory/AddInventory";
import OrderDetail from "./Admin/Order/OrderDetail";
import CustomerMain from "./Admin/Customer/CustomerMain";
import CustomerDetail from "./Admin/Customer/CustomerDetail";
import GraphicTab from "./components/GraphicTab";
import AdminUi from "./Layout/AdminUi";
import UseUi from "./Layout/UseUi";
import Home from "./pages/home/Home";
import Categories from "./pages/categories";
import Product from "./pages/Product";
import Bidask from "../src/pages/bidask";
import BuySellManagment from "./pages/BuySellManagment";
import "react-toastify/dist/ReactToastify.css";
import UserBuySell from "./components/UserBuySell";
import Compare from "./components/Compare";
import FavouriteList from "./components/FavouriteList";
import Blanck from "./components/Blanck";
import ForgotPassword from "./components/ForgotPassword";
import Tooltip from "./components/Tooltip";
import ResetPassword from "./components/ResetPassword";
import TermCondition from "./components/TermCondition";
import OrderConfirmation from "./components/OrderConfirmation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import NewProfile from "./components/NewProfile";
import PackageSlip from "./components/PackageSlip";
import StripeAccount from "./Admin/Customer/StripeAccount";
import Shipping from "./Admin/Customer/Shipping";
import Banner from "./components/Banner";
import { AuthProvider } from "./context/UserContext";
import PendingTable from "./components/PendingTable";
import HistoryTable from "./components/HistoryTable";
import ShippingDescriptions from "./pages/ShippingDescriptions";
import CreateTopic from "./Admin/HelpData/Topic/CreateTopic";
import TopicList from "./Admin/HelpData/Topic/TopicList";
import CreateCategory from "./Admin/HelpData/Category/CreateCategory";
import CategoryList from "./Admin/HelpData/Category/CategoryList";
import Help from "./pages/Help/Help";
import ExportApi from "./api/ExportApi";
import Threshold from "./Admin/Threshold/Threshold";
import SellerOrder from "./Admin/SellerOrder/SellerOrder";
import AboutUs from './pages/About/index';
import Globaldescription from "./Admin/Globaldescription";
import News from "../src/pages/News/News"
import List from "./pages/Listpage/List";
import AdminNews from "./Admin/News/Adminnews";
import AdminNewsList from "./Admin/News/Newslist"
import Cookiepolicy from "./components/Cookiepolicy";
import Singlenews from "./pages/Listpage/viewnews";
import Customerview from "./Admin/Customer/Customerview";
import Updatebulkprice from "./components/Updatebulkprice";
import G2fa from "./Admin/Gauth/g2fa";
import G2auth from "./components/G2auth";
import { checkSessionExpiry, updateActivityTime } from './sessionUtils';
 
import OrderBook from "./Admin/OrderBook/OrderBook";
import EditComponent from "./Admin/News/EditComponent";
 
const Main = () => {
  const [searchValue, setSearchValue] = useState();

  // useEffect(() => {
  //   const token = localStorage.getItem("token");  
  //   if (token) {
  //    ExportApi.checkJwt().then((resp) => {
  //     const data = resp?.data;
  //       if (data?.status) {
  //         let expiry = data?.status
  //       }else{
  //         alert("Your Session is expired, Login again!!!")
  //         localStorage.removeItem("admin");
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("tokenuser");
  //         localStorage.removeItem("favourite");
  //         localStorage.removeItem("useraddress");
  //         location.reload('/')     
  //       }
  //     })
  //         .catch((err) => console.log(err));     
  //   }

  //  }, []);
   
  // useEffect(() => {
  //   const token = localStorage.getItem("token");  
  //   if (token) {
  //     const loginTime = localStorage.getItem("loginTime");
  //     const currentTime = new Date().getTime();
  //     const sessionDuration = 7200 * 1000; 
   
  
  //     if (currentTime - loginTime > sessionDuration) {
  //       // Session expired
  //       alert("Your Session has expired. Please log in again!");
  //       localStorage.removeItem("admin");
  //       localStorage.removeItem("token");
  //       localStorage.removeItem("tokenuser");
  //       localStorage.removeItem("favourite");
  //       localStorage.removeItem("useraddress");
  //       localStorage.removeItem("loginTime");
  //       localStorage.removeItem("load");
  //       localStorage.removeItem("expirationTime");
  //       location.reload('/');
  //     }
  //   }
  // }, []);
  
  setInterval(() => {
    
    const loginTime = localStorage.getItem("loginTime");


    if (loginTime) {

      checkSessionExpiry();
      const handleActivity = () => {
        updateActivityTime();
      };

      // Event listeners for detecting user activity
      // window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keypress', handleActivity);
      window.addEventListener('click', handleActivity);

      // Periodic check for session expiry
      const sessionCheckInterval = setInterval(() => {
        checkSessionExpiry();
      }, 1000); 
      return () => {
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keypress', handleActivity);
        window.removeEventListener('click', handleActivity);
        clearInterval(sessionCheckInterval);
      };
    } else {
    
     
    }
  }, 1000);


  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          {/* <Route path="/" element={<Home searchValue={searchValue} setSearchValue={setSearchValue} />} /> */}
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset/:id" element={<ResetPassword />} />
          <Route path="/error" element={<Blanck />} />
          <Route path="/tooltip" element={<Tooltip />} />
          <Route path="/bidask/:id" element={<Bidask />} />
          <Route path="/bidask/:id/:buy" element={<Bidask />} />
          <Route path="/userBuySell" element={<UserBuySell />} />
          <Route path="/compareBuySell" element={<Compare />} />
          <Route path="/categories/:id" element={<Categories />} />
          <Route path="/categories/:id/:name" element={<Categories />} />
          <Route path="/OrderDetail/:id" element={<OrderDetail />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/terms" element={<TermCondition />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<Cookiepolicy />} />
          <Route path="/order" element={<OrderConfirmation />} />

          <Route path="/help" element={<Help />} />
          <Route path="/QualityStandards" element={<Help />} />
          <Route path="/PreSaleInspectionGuide" element={<Help />} />
          <Route path="/BuyingandSellingGuide" element={<Help />} /> 
          <Route path="/ShippingInstructions" element={<Help />} /> 
          <Route path="/BuyingandSellingGuide" element={<Help />} /> 
          <Route path="/about" element={<AboutUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/list" element={<List />} />
          <Route path="/news/:id" element={<Singlenews />} />
      
        
          {/* Start admin layout */}
          <Route path="/" element={<AdminUi />}>
            <Route path="/buySell" element={<BuySell />} />
            <Route path="/orderView" element={<OrderView />} />
            <Route path="/createproduct" element={<NewProduct />} />
            <Route path="/globaldescription" element={<Globaldescription />} />
            <Route path="/productlist" element={<GraphicTab />} />
            <Route path="/bulkpriceupdate" element={<Updatebulkprice />} />
            <Route path="/addInventory/:id" element={<AddInventory />} />
            {/* <Route path="/customerMain" element={<CustomerMain />} /> */}
            <Route path="/customerMain" element={<Customerview />} />
            <Route path="/customerDetail/:id" element={<CustomerDetail />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/shipping-descriptions" element={<ShippingDescriptions />} />
            {/* <Route path="/admin/stripeconnectaccount/:id" element={<StripeAccount />} /> */}
            <Route path="/house-pending" element={<PendingTable />} />
            <Route path="/house-history" element={<HistoryTable />} />
            <Route path="/admin/create-topic" element={<CreateTopic />} />
            <Route path="/admin/topic-list" element={<TopicList />} />
            <Route path="/admin/create-category" element={<CreateCategory />} />
            <Route path="/admin/category-list" element={<CategoryList />} />
            <Route path="/admin/threshold" element={<Threshold />} />
            <Route path="/2fa" element={<G2fa />} />
            <Route path="/admin/sellerorder" element={<SellerOrder />} />
            <Route path="/admin/orderbook" element={<OrderBook />} />
            <Route path="/adminnews" element={<AdminNews />} />
            <Route path="/admin/editnews/:id" element={<EditComponent />} />
            <Route path="/adminnewslist" element={<AdminNewsList />} />
          </Route>


          {/* End Admin Layout */}

          {/* Start user Layout */}
          <Route path="/" element={<UseUi />}>
            <Route
              path="/buySellManagment/selling"
              element={<BuySellManagment />}
            />
            <Route path="/buySellManagment/buying" element={<BuySellManagment />} />
            {/* <Route path="/updateUser/:id" element={<UpdateCustomer />} /> */}
            <Route path="/profile/:id" element={<NewProfile />} />
            <Route path="/profile" element={<NewProfile />} />
            <Route path="/g2fa" element={<G2auth />} />
            <Route path="/stripeconnectaccount/:id" element={<StripeAccount />} />
            {/* <Route path="/stripeconnectaccount" element={<StripeAccount />} /> */}
            <Route path="/package" element={<PackageSlip />} />
            <Route
              path="/buySellManagment/pending"
              element={<BuySellManagment />}
            />
            <Route
              path="/buySellManagment/history"
              element={<BuySellManagment />}
            />
            <Route
              path="/buySellManagment/setting"
              element={<BuySellManagment />}
            />
            <Route path="/buySellManagment/favorites" element={<FavouriteList />} />
          </Route>
          {/* End User Layout */}
        </Routes>
        {/* </div>
      </div> */}
      </BrowserRouter>
    </AuthProvider>
  )
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
reportWebVitals();
