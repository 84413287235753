import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import ExportApi from "../api/ExportApi";
import Sonnet from "../../src/components/sonnet";
import { toast } from "react-toastify";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
import StripeElement from "./StripeElement";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import states from "states-us";
import {
  PaymentElement,
  useStripe, useElements, Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

import InputMask from "react-input-mask";


const stripePromise = loadStripe('pk_test_51N5ispHPaRDrtdL2GeydDgCPMGxVg4i7NhYNw6IO1MgsghsqzAFzvZlrV5RPQUFLYIehV8CmPBN1IFKh7XMxjNnr00TT87qqvt');

function UncontrolledExample(props) {

  const location = useLocation();
  // const updateBidAmout = ;
 
  const [updateBidAmount, setUpdateBidAmount] = useState(location?.state?.bidamount ? location.state?.bidamount : 0)
  const [updating, setUpdating] = useState(false)



  const prams = useParams();
  const navigate = useNavigate();
  const [Show, setShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [value, setvalue] = useState(0);
  const [sales, setSales] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [total, setTotal] = useState(0);
  const [Finalchecks, setFinalchecks] = useState();
  const [Finalchecks1, setFinalchecks1] = useState();
  const [Finalchecks2, setFinalchecks2] = useState();
  const [FinalchecksError, setFinalchecksError] = useState(false);
  const [Finalchecks1Error, setFinalchecks1Error] = useState(false);
  const [Finalchecks1Error1, setFinalchecks1Error1] = useState(false);
  const [input, setInput] = useState(false);
  const [showCardModel, setShowCardModel] = useState(false);
  const [userAsk, setUserAsk] = useState(true);
  const [askAmount, setAskAmount] = useState();
  const [bidAmount, setBidAmount] = useState();
  const [shippingFee, setShippingFee] = useState();
  const [buyNowStatus, setBuyNowStatus] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [bidStatus, setBidStatus] = useState(false);
  const [showTaxesDetails, setShowTaxesDetails] = useState(false);

  const [street, setStreet] = useState();
  const [subscribe, setSubscribe] = useState();
  const [validEmail, setValidEmail] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [shippingAddress, setShippingAddress] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [validStreet, setValidStreet] = useState();
  const [validCity, setValidCity] = useState();
  const [validState, setValidState] = useState();
  const [validcountry, setValidCountry] = useState();
  const [validPostalCode, setValidPostalCode] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [buttonAddressLoader, setButtonAddressLoader] = useState(false);


  const [primaryAccountModel, setPrimaryAccountModel] = useState(false)

  const [tax, setTax] = useState(7);
  const [buttonPhoneLoader, setButtonPhoneLoader] = useState(false);

  const [mobileNumber, setMobileNumber] = useState();
  const [paymentbtn, setPaymentbtn] = useState();
  // const [usershipaddress ,setUsershipaddress] = useState();
  const [usershipaddress, setUsershipaddress] = useState({
    street: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    phone: ''
  });
  const [intialdata, setIntialdata] = useState();

  // Toggle taxes-detail class

  const shippingValue = (value === 0  || value=== '' ||  isNaN(value)) ? 0 : 25;


  const toggleTaxesDetails = () => {
    setShowTaxesDetails(!showTaxesDetails);
  };
  // let shippingFeeFetched = false;
  //For the Place Order and place Bids for buy Products
  const hendleBid = () => {
    setLoading(true);
    if (localStorage.getItem("tokenuser") || localStorage.getItem("admin")) {
      if (bidStatus) {
        if (props.Ask == undefined) {
          toast.error(
            "You cannot create bid because there is no ask for this product"
          );
          setLoading(false);
        } else {
          // toast.error("Your bid is higher than the Lowest Ask");
          toast.error("your bid is higher than the highest ask");
          setLoading(false);

        }
      } else {
        setLoading(true);
        if (Finalchecks) {
          setFinalchecksError(false);
          if (Finalchecks1) {
            setFinalchecks1Error(false);
            if (Finalchecks2) {
              setFinalchecks1Error1(false);
              if (localStorage.getItem("tokenuser")) {
                setLoading(false);
                if (input) {
                  setLoading(false);
                } else {
                  let confirm = window.confirm(
                    "Are you sure you want to create order"
                  );
                  setLoading(true);
                  if (!confirm) {
                    setLoading(false);
                  } else {
                    handleUserAsk(
                      JSON.parse(localStorage.getItem("tokenuser")).id
                    );
                  }
                }
              } else if (localStorage.getItem("admin")) {
                setLoading(false);
                if (input) {
                  setLoading(false);
                } else {
                  let confirm = window.confirm(
                    "Are you sure you want to create order"
                  );
                  setLoading(true);
                  if (!confirm) {
                    setLoading(false);
                  } else {
                    handleUserAsk(JSON.parse(localStorage.getItem("admin")).id);
                  }
                }
              } else {
                setLoading(false);
                setShowModal(true);
              }
            } else {
              setLoading(false);
              setFinalchecks1Error1(true);
              toast.error(
                "Complete necessary checkboxes for seamless shopping"
              );
            }
          } else {
            setLoading(false);
            setFinalchecks1Error(true);
            toast.error("Complete necessary checkboxes for seamless shopping");
          }
        } else {
          setLoading(false);
          setFinalchecksError(true);
          toast.error("Complete necessary checkboxes for seamless shopping");
        }
      }
    } else {
      setLoading(false);
      setShowModal(true);
    }
  };

 
  // const handlePlaceOrder = () => {
  
  //   setLoading(true);
  //   if (localStorage.getItem("tokenuser") || localStorage.getItem("admin")) {
  //     setLoading(true);
  //     if (bidStatus) {
  //       setLoading(true);
  //       if (props.Ask == undefined) {
  //         setLoading(false);
  //         toast.error(
  //           "You cannot create bid because there is no ask for this product"
  //         );
  //       } else {
  //         setLoading(false);
  //         toast.error("Your bid is higher than the lower Ask");
  //       }
  //     } else {
  //       setLoading(true);
  //       if (Finalchecks) {
  //         setFinalchecksError(false);
  //         if (Finalchecks1) {
  //           setFinalchecks1Error(false);
  //           if (Finalchecks2) {
  //             setFinalchecks1Error1(false);
  //             if (localStorage.getItem("tokenuser")) {
  //               setLoading(false);
  //               if (input) {
  //                 setLoading(false);
  //               } else {
  //                 let confirm = window.confirm(
  //                   "Are you sure you want to create order"
  //                 );
  //                 if (!confirm) {
  //                   setLoading(false);
  //                 } else {
  //                   handleUserAsk(
  //                     JSON.parse(localStorage.getItem("tokenuser")).id
  //                   );
  //                 }
  //               }
  //             } else if (localStorage.getItem("admin")) {
  //               setLoading(false);
  //               if (input) {
  //                 setLoading(false);
  //               } else {
  //                 let confirm = window.confirm(
  //                   "Are you sure you want to create order"
  //                 );
  //                 if (!confirm) {
  //                 } else {
  //                   handleUserAsk(JSON.parse(localStorage.getItem("admin")).id);
  //                 }
  //               }
  //             } else {
  //               setLoading(false);
  //               setShowModal(true);
  //             }
  //           } else {
  //             setLoading(false);
  //             setFinalchecks1Error1(true);
  //             toast.error(
  //               "Complete necessary checkboxes for seamless shopping"
  //             );
  //           }
  //         } else {
  //           setLoading(false);
  //           setFinalchecks1Error(true);
  //           toast.error("Complete necessary checkboxes for seamless shopping");
  //         }
  //       } else {
  //         setLoading(false);
  //         setFinalchecksError(true);
  //         toast.error("Complete necessary checkboxes for seamless shopping");
  //       }
  //     }
  //   } else {
  //     setLoading(false);
  //     setShowModal(true);
  //   }
  // };
  const handlePlaceOrder = () => {
    setLoading(true); // Set the loader to true initially

   

    if (localStorage.getItem("tokenuser") || localStorage.getItem("admin")) {
      if (bidStatus) {
        if (props.Ask == undefined) {
          setLoading(false); // Stop the loader if this condition is met
          toast.error("You cannot create bid because there is no ask for this product");
        } else {
          setLoading(false); // Stop the loader if this condition is met
          // toast.error("Your bid is higher than the Lowest Ask");
          toast.error("your bid is higher than the highest ask");

        }
      } else {
        if (Finalchecks && Finalchecks1 && Finalchecks2) {
          setFinalchecksError(false);
          setFinalchecks1Error(false);
          setFinalchecks1Error1(false);

          if (localStorage.getItem("tokenuser") || localStorage.getItem("admin")) {
            if (!input) {
              let confirm = window.confirm("Are you sure you want to create order");
              if (!confirm) {
                setLoading(false); // Stop the loader if the user cancels the confirmation
              } else {
                handleUserAsk(localStorage.getItem("tokenuser") ? JSON.parse(localStorage.getItem("tokenuser")).id : JSON.parse(localStorage.getItem("admin")).id);
              }
            } else {
              setLoading(false); // Stop the loader if input is present
            }
          } else {
            setLoading(false); // Stop the loader if neither tokenuser nor admin is present
            setShowModal(true);
          }
        } else {
          setLoading(false); // Stop the loader if necessary checkboxes are not completed
          if (!Finalchecks) {
            setFinalchecksError(true);
          }
          if (!Finalchecks1) {
            setFinalchecks1Error(true);
          }
          if (!Finalchecks2) {
            setFinalchecks1Error1(true);
          }
          toast.error("Complete necessary checkboxes for seamless shopping");
        }
      }
    } else {
      setLoading(false); // Stop the loader if no tokenuser or admin is present
      setShowModal(true);
    }
  };


  //function for creating New Products
  //productId used for the productId
  //userId used for the UserId
  const handleCreateOrder = (productId, userId) => {
    setLoading(true);
    ExportApi.createOrder(productId, userId, total)
      .then((response) => {
        setLoading(false);
        navigate("/order");
      })
      .catch((err) => {
        console.log(err);
      });
  };


  //Function for creating 2nd Hand
  //ProductId used for the ProductId
  //UserId used for the userId
  //Value is the input value of the bid
  const handleCreateSecondHandOrder = (productId, userId, value) => {
  
    setLoading(true);
    ExportApi.CreateSecondHandOrder(productId, userId, value, "Buy", props?.Bidid ? props?.Bidid : 0)
      .then((resp) => {
        if (resp.data.status === "0") {
          toast.error("Rate limit exceeded. Please try again later.");
          setLoading(false);
        } else {
          if (resp.data.message == "Order Created Successfully") {

            setLoading(false);
            navigate("/order");
          } else {
            setLoading(false);
            toast.error(resp.data.message);
          }
        }

      })
      .catch((err) => {
        console.log(err,"CreateSecondHandOrder12");
      });
  };

  //For the Buy products API's
  //userId used for the userId

  const handleUserAsk = (userid) => {

    // return
    if (props.error) {
      toast.error(
        "Shipping Service not available Please Contact to Support Service11"
      );
    }
    else if (updateBidAmount) {
      if (updateBidAmount) {
      
        ExportApi.handleUpdateBid(
          location?.state?.bidId,
          userid,
          JSON.parse(value),
          JSON.parse(sales),
          JSON.parse(processing),
          total,
        )
          .then((resp) => {
       
            if (resp.data.message == "Bid updated successfully") {
              window.dispatchEvent(new Event("List"));
              toast.success("Bid updated successfully");
              setLoading(false);
              setFullPageLoader(false);
              setvalue(0)
              setUpdateBidAmount(0)
              navigate("/buySellManagment/buying")
            } else {
              setFullPageLoader(false);
              toast.error("Bid is not created! Something Went Wrong");
            }
          })
          .catch((err) => console.log(err));

      }
    }
    else {
      ExportApi.getSingleUserData(userid).then((resp) => {
        let data = resp?.data?.data;
     
        if (data?.shipping_address?.street != null) {

          ExportApi.getCardData(userid)
            .then((response) => {
              let data = response.data.data;
              setCardBrand(data.brand);
              setCardExpiry(data.lastFour);
              setFullPageLoader(false);
              if (data.brand) {
                if (prams.buy != undefined) {
                  if (props.type == 1) {
                    handleCreateOrder(prams.id, userid);
                  } else {
                    handleCreateSecondHandOrder(prams.id, userid, value);
                    setFullPageLoader(false);
                  }
                } else {
                  if (buyNowStatus) {
                    if (props.type == 1) {
                      handleCreateOrder(prams.id.userid);
                      // navigate("/order");
                    } else {
                      handleCreateSecondHandOrder(prams.id, userid, value);
                      setFullPageLoader(false);
                    }
                  } else {
                    setLoading(true);
                    let expiration = new Date();
                    ExportApi.Bid(
                      prams.id,
                      userid,
                      value,
                      total,
                      99,
                      processing,
                      sales,
                      expiration,
                      shippingFee,
                      bidExp
                    )
                      .then((resp) => {
                        if (resp.data.message == "Bid created successfully") {
                          window.dispatchEvent(new Event("List"));
                          toast.success("Bid created successfully");
                          setLoading(false);
                          setFullPageLoader(false);
                          // window.location.reload();
                        } else {
                          if (resp.data.message == "Please add card first") {
                            setShowCardModel(true);
                            setFullPageLoader(false);
                            setLoading(false);
                          } else {
                            // toast.error("Bid is not created");
                            toast.error(resp?.data?.message);
                            setFullPageLoader(false);
                            setLoading(false);
                          }
                        }
                      })
                      .catch((err) => console.log(err));
                  }
                }
              } else {
                if (localStorage.getItem("tokenuser")) {
                  toast.error("Add payment information to place an order");
                  // navigate("/stripeconnectaccount/" + userid);
                } else if (localStorage.getItem("admin")) {
                  toast.error("Please setup your payment detail");
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          toast.error("Please complete User information");
          // setPopup(true);
        }
      });
    }
  };

  // for the Back Button
  const handleback = () => {
    if (
      prams.buy == "buy4" ||
      prams.buy == "buy2" ||
      prams.buy == "buy3" ||
      prams.buy == "buy"
    ) {
      setShow(true);
      navigate("/product/" + prams?.id);
    } else {
      setShow(true);
    }
  };

  //for the input value in the bid
  //e used for the getting the input value of the bid
  const handlePlaceBidValue = (e) => {
    setUpdating(true)
    if (props.Ask == undefined) {
  
      let askvall = e.target.value;
      setvalue(e.target.value);
      let salesValue = (e.target.value / 100) * tax;
    
      setSales(salesValue.toFixed(2));
      let processingValue = (e.target.value / 100) * 3;
      setProcessing(processingValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * e.target.value;
    
      let fee = shippingCost
      let sf = Number(props.shippingFee);
      //let shipping = (fee + sf)?.toFixed(2);
  
  
      let shipping = (askvall === 0 || askvall === '' ) ? 0 : 10;
      let sippedvalue = (askvall === 0 || askvall === '') ? 0 : 25;
   
    
      setShippingFee(shipping);
      let totalNumber =
        Number(e.target.value) +
        (e.target.value / 100) * 3 +
        Number(shipping) +
        (e.target.value / 100) * tax +
        sippedvalue;
     
      setTotal(totalNumber.toFixed(2));
      setInput(false);
      setBidStatus(true);
      return;
    } else if (Number(e.target.value) > props.Ask) {
   
      setvalue(e.target.value);
      let askvall = e.target.value;
      let salesValue = (e.target.value / 100) * tax;
      setSales(salesValue.toFixed(2));
      let processingValue = (e.target.value / 100) * 3;
      setProcessing(processingValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * e.target.value;
      let fee = shippingCost
      let sf = Number(props.shippingFee);
      // let shipping = (fee + sf)?.toFixed(2);
  
     
 
      let shipping = (askvall === 0 || askvall === '') ? 0 : 10;
      let sippedvalue = (askvall === 0 || askvall === '') ? 0 : 25;
 
      setShippingFee(shipping);
      let totalNumber =
        Number(e.target.value) +
        (e.target.value / 100) * 3 +
        Number(shipping) +
        (e.target.value / 100) * tax +
        sippedvalue;
   
      setTotal(totalNumber.toFixed(2));
      setInput(false);
      setBidStatus(true);
      return;
      // toast.error('Your ask is not higher than the highest bid')
    } else if (Number(e.target.value) <= props.Bid) {

      let askvall = e.target.value;
      setvalue((prev) => e.target.value);
      let salesValue = (e.target.value / 100) * tax;
      setSales(salesValue.toFixed(2));
      let processingValue = (e.target.value / 100) * 3;
      setProcessing(processingValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * e.target.value;
      let fee = shippingCost
      let sf = Number(props.shippingFee);
      // let shipping = (fee + sf)?.toFixed(2);
  
 
      let shipping = (askvall === 0 || askvall === '') ? 0 : 10;
      let sippedvalue = (askvall === 0 || askvall === '') ? 0 : 25;
      
   
      setShippingFee(shipping);
      let totalNumber =
        Number(e.target.value) +
        (e.target.value / 100) * 3 +
        Number(shipping) +
        (e.target.value / 100) * tax +
        sippedvalue;
      
      setShow(true);

      setTotal(totalNumber.toFixed(2));
      setInput(false);
      setBidStatus(false);
    } else {
 
      setInput(false);
      setvalue(e.target.value);
      let askvall = e.target.value;
      let salesValue = (e.target.value / 100) * tax;
      setSales(salesValue.toFixed(2));
      let processingValue = (e.target.value / 100) * 3;
      setProcessing(processingValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * e.target.value;
      let fee = shippingCost
      let sf = Number(props.shippingFee);
      // let shipping = (fee + sf)?.toFixed(2);
    
 
      let shipping = (askvall === 0 || askvall === '') ? 0 : 10;
      let sippedvalue = (askvall === 0 || askvall === '') ? 0 : 25;

      setShippingFee(shipping);
      let totalNumber =
        Number(e.target.value) +
        (e.target.value / 100) * 3 +
        Number(shipping) +
        (e.target.value / 100) * tax +
        sippedvalue;
  
      setTotal(totalNumber.toFixed(2));
      setBidStatus(false);
    }
  };

  //Click on the Buy Now Button
  //price used for the ask
  const handleBuyNow = (price) => {
    setBuyNowStatus(true);
    if (price) {
      setvalue(price);
      let salesValue = (price / 100) * tax;
      setSales(salesValue.toFixed(2));
      let processingValue = (price / 100) * 3;
      setProcessing(processingValue.toFixed(2));
      let shippingCost = Number(0.5 / 100) * price;
      let fee = shippingCost
      let sf = Number(props.shippingFee);

      //let shipping = (fee + sf)?.toFixed(2);
     
 
      let shipping = (value == '' || value == 0) ? 0 : 10;
     
      setShippingFee(shipping);

      let totalNumber =
        Number(price) +
        (price / 100) * 3 +
        Number(shipping) +
        (price / 100) * tax +
        shippingValue;
      
      setTotal(totalNumber.toFixed(2));
      setShow(false);
    } else {
      toast.error("You cannot Buy this Time! Please try again later");
    }
  };

  //get the bid list
  const HandleBid = () => {
    if (localStorage.getItem("tokenuser")) {
      ExportApi.getBidList(JSON.parse(localStorage.getItem("tokenuser")).id)
        .then((resp) => {
          let data = resp.data.data;
          let result = [];
          for (let index = 0; index < resp.data.data?.length; index++) {
            let obj = {};
            const element = resp.data.data[index];
            obj = { ...element, ...element.productId };
            result.push(obj);
          }
          let data2 = result?.filter((item) => item.productId._id == prams.id);
          if (data2.length > 0) {
            setBidAmount(data2[0].bidAmount);
          }
        })
        .catch((err) => console.log(err));
    } else {
    }
  };

  // for the back button in the Ask page
  const handleUserAsk1 = (show) => {
    if (prams.buy == "sell3" || prams.buy == "sell2" || prams.buy == "sell") {
      navigate(-1);
    } else {
      setUserAsk(show);
    }
  };

  //get the ask list
  const handleaskList = () => {
    if (localStorage.getItem("tokenuser")) {
      ExportApi.askList(JSON.parse(localStorage.getItem("tokenuser")).id)
        .then((resp) => {
          let data = resp.data.data;
 
          let result = [];
          for (let index = 0; index < resp.data.data?.length; index++) {
            let obj = {};
            const element = resp.data.data[index];
            obj = { ...element, ...element.productId };
            result.push(obj);
          }

          let data2 = result?.filter((item) => item.productId._id == prams.id);
          let data3 = data2?.filter((item) => item.askStatus == true);
        
          if (data2.length > 0) {
         
            if (prams.buy == "buy" && !props?.finalPriceBuy) {

              // setAskAmount(data3[0].askAmount);
              // direct buy now redirect
          
              let salesValue = (data3[0].askAmount / 100) * tax;
              let processingValue = (data3[0].askAmount / 100) * 3;
              let shippingCost = Number(0.5 / 100) * data3[0].askAmount;
              let fee = shippingCost
              let sf = Number(props.shippingFee);
              // let shipping = (fee + sf)?.toFixed(2);
       
              let shipping = (value == '' || value == 0) ? 0 : 10;
          
              let total =
                Number(data3[0].askAmount) +
                (data3[0].askAmount / 100) * 3 +
                (data3[0].askAmount / 100) * tax +
                shippingValue;
              let finalTotal = (total + Number(shipping))?.toFixed(2);
           
              setvalue(data3[0].askAmount);
              setSales(salesValue.toFixed(2));
              setProcessing(processingValue.toFixed(2));
              setShippingFee(shipping);
              setTotal(finalTotal);
            } else if (prams.buy !== "buy" && updateBidAmount && !updating) {
              // setAskAmount(data3[0].askAmount);
              // direct buy now redirect
              let salesValue = (updateBidAmount / 100) * tax;
              let processingValue = (updateBidAmount / 100) * 3;
              let shippingCost = Number(0.5 / 100) * updateBidAmount;
              let fee = shippingCost
              let sf = Number(props.shippingFee);
              // let shipping = (fee + sf)?.toFixed(2);
         
              let shipping = (value == '' || value == 0) ? 0 : 10;
         
              let total =
                Number(updateBidAmount) +
                (updateBidAmount / 100) * 3 +
                (updateBidAmount / 100) * tax +
                shippingValue;
            
              let finalTotal = (total + Number(shipping))?.toFixed(2);
         
              setvalue(updateBidAmount);
              setSales(salesValue.toFixed(2));
              setProcessing(processingValue.toFixed(2));
              setShippingFee(shipping);
              setTotal(finalTotal);
       
            }

            // setShow(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
    }
  };
 
  //get the card Data
  const handleCardData = (id) => {
    ExportApi.getCardData(id)
      .then((resp) => {

        let data = resp.data.data;
        setPaymentbtn(data)
     
        setCardBrand(data.brand);
        setCardExpiry(data.exp_year);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectToAnotherPage = (id) => {
    navigate(`/profile/${id}`);
  };
  //start validation for the checkbox
  const handleFinalCheck = (value) => {
    if (value) {
      setFinalchecks(true);
      setFinalchecksError(false);
    } else {
      setFinalchecks(false);
      setFinalchecksError(true);
    }
  };
  const handleFinalCheck1 = (value) => {
    if (value) {
      setFinalchecks1(true);
      setFinalchecks1Error(false);
    } else {
      setFinalchecks1(false);
      setFinalchecks1Error(true);
    }
  };

  const handleFinalCheck2 = (value) => {
    if (value) {
      setFinalchecks2(true);
      setFinalchecks1Error1(false);
    } else {
      setFinalchecks2(false);
      setFinalchecks1Error1(true);
    }
  };

  useEffect(() => {
    let id;
    if (localStorage.getItem('tokenuser')) {
      id = JSON.parse(localStorage.getItem('tokenuser'))?.id;
    } else {
      id = JSON.parse(localStorage.getItem('admin'))?.id;
    }

    ExportApi.getSingleUserData(id)
      .then((resp) => {
        const Data = resp?.data?.data;
        const userdata = {
          street: Data?.shipping_address?.street,
          city: Data?.shipping_address?.city,
          state: Data?.shipping_address?.state,
          country: Data?.shipping_address?.country,
          postal_code: Data?.shipping_address?.postal_code,
          phone: Data?.phone
        };

        setUsershipaddress(userdata);
        setValidStreet(userdata.street);
        setValidCity(userdata.city);
        setValidState(userdata.state);
        setValidCountry(userdata.country);
        setValidPostalCode(userdata.postal_code);
        setMobileNumber(userdata.phone);
        const newdata = {
          street: Data.shipping_address.street,
          city:Data?.shipping_address?.city,
          country:Data?.shipping_address?.country,
          postal_code:Data?.shipping_address?.postal_code,
          state:Data?.shipping_address?.state,
          phone:Data?.phone
        }
        setIntialdata(newdata)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); useEffect(() => {
    let id;
    if (localStorage.getItem('tokenuser')) {
      id = JSON.parse(localStorage.getItem('tokenuser'))?.id;
    } else {
      id = JSON.parse(localStorage.getItem('admin'))?.id;
    }

    ExportApi.getSingleUserData(id)
      .then((resp) => {
        const Data = resp?.data?.data;
        const userdata = {
          street: Data?.shipping_address?.street,
          city: Data?.shipping_address?.city,
          state: Data?.shipping_address?.state,
          country: Data?.shipping_address?.country,
          postal_code: Data?.shipping_address?.postal_code,
          phone: Data?.phone
        };

        setUsershipaddress(userdata);
        setValidStreet(userdata.street);
        setValidCity(userdata.city);
        setValidState(userdata.state);
        setValidCountry(userdata.country);
        setValidPostalCode(userdata.postal_code);
        setMobileNumber(userdata.phone);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  

  useEffect(() => {

    if (localStorage.getItem("tokenuser")) {
      var id = JSON.parse(localStorage.getItem("tokenuser"))?.id
    } else {
      var id = JSON.parse(localStorage.getItem("admin"))?.id
    }

    ExportApi.getSingleUserData(id)
      .then((resp) => {
        // let addressData = []
        let Data = resp?.data?.data;
     
        // const userdata = {
        //   street : Data?.shipping_address?.street,
        //   city : Data?.shipping_address?.city,
        //   state: Data?.shipping_address?.state,
        //   country: Data?.shipping_address?.country,
        //   postal_code:Data?.shipping_address?.postal_code,
        //   phone: Data?.phone

        // }
 
        // setUsershipaddress(userdata);

        setStreet(Data?.shipping_address?.street);
        setValidStreet(Data?.shipping_address?.street);
        setCity(Data?.shipping_address?.city);
        setValidCity(Data?.shipping_address?.city);
        setState(Data?.shipping_address?.state);
        setValidState(Data?.shipping_address?.state);
        setCountry(Data?.shipping_address?.country);
        setValidCountry(Data?.shipping_address?.country);
        setPostalCode(Data?.shipping_address?.postal_code);
        setValidPostalCode(Data?.shipping_address?.postal_code);

      })
      .catch((err) => {
        console.log(err);
      });

    if (localStorage.getItem("tokenuser")) {
      // If there's a "tokenuser" in local storage, handle something (e.g., shipping fee).
      // HandleShippingFee(JSON.parse(localStorage.getItem("tokenuser")).id);
      ExportApi.getTaxPercent(value, JSON.parse(localStorage.getItem("tokenuser")))
        .then((resp) => {

          // let addressData = []
          let Data = resp?.data;
  
          // setTax(Data?.percentage);
          setTax(7);

          // if (resp.data.status == false) {
          
          //   toast.error("API(Test mode) rate limit exceeded. Limit requests to <1000 per 24 hours.");
      
          // }
        })
        .catch((err) => {
     
          toast.error("An error occurred while calculating the tax.");
        });

      let number = JSON.parse(localStorage.getItem("tokenuser")).phone;
      let numberString = number == null ? null : number?.toString();
      let maskedResponse = "";
      if (numberString == null) {
        maskedResponse = null;
      } else {
        maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
          3,
          6
        )}-${numberString.slice(6)}`;
      }

     

      setMobileNumber(maskedResponse)

    } else if (localStorage.getItem("admin")) {
      // If there's an "admin" in local storage, handle something else (e.g., shipping fee).
      // HandleShippingFee(JSON.parse(localStorage.getItem("admin")).id);
      ExportApi.getTaxPercent(value, JSON.parse(localStorage.getItem("admin")))
        .then((resp) => {
          // let addressData = []
          let Data = resp?.data;
     
          // setTax(Data?.percentage);
          setTax(7);
          if (resp.data.status == false) {
          
            toast.error("API(Test mode) rate limit exceeded. Limit requests to <1000 per 24 hours.");
      
          }
        })
        .catch((err) => {
      
          toast.error("An error occurred while calculating the tax.");
        });

      let number = JSON.parse(localStorage.getItem("admin")).phone;
      let numberString = number == null ? null : number?.toString();
      let maskedResponse = "";
      if (numberString == null) {
        maskedResponse = null;
      } else {
        maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
          3,
          6
        )}-${numberString.slice(6)}`;
      }

   

      setMobileNumber(maskedResponse)
    }

  }, [value, props, props.prams])

  // useEffect(() => { }, [sales, processing]);

  // getPackageData

  useEffect(() => {
    handleaskList();
    HandleBid();
    if (localStorage.getItem("tokenuser")) {
      handleCardData(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      handleCardData(JSON.parse(localStorage.getItem("admin")).id);
    } else {
    }
  }, []);

  useEffect(() => {

    if (prams.buy == "buy" && props.finalPriceBuy) {
      let salesValue = (props.finalPriceBuy / 100) * tax;
      let processingValue = (props.finalPriceBuy / 100) * 3;
      let shippingCost = Number(0.5 / 100) * props.finalPriceBuy;
      let fee = shippingCost
      let sf = Number(props.shippingFee);
      // let shipping = (fee + sf)?.toFixed(2);
 
      let shipping = (value == '' || value == 0 || props?.bid == 0) ? 0 : 10;

      let total =
        Number(props.finalPriceBuy) +
        (props.finalPriceBuy / 100) * 3 +
        (props.finalPriceBuy / 100) * tax +
        shippingValue;
      let finalTotal = (total + Number(shipping))?.toFixed(2);
   
      setvalue(props.finalPriceBuy);
      setSales(salesValue.toFixed(2));
      setProcessing(processingValue.toFixed(2));
      setShippingFee(shipping);
      setTotal(finalTotal);
      setShow(false);

    }

    
  }, [props, props?.prams]);

  useEffect(() => {
  
    if (props?.Bid) {
      let salesValue = (props?.Bid / 100) * tax;
      let processingValue = (props?.Bid / 100) * 3;

      let total =
        Number(props?.Bid) +
        (props?.Bid / 100) * 3 +
        (props?.Bid / 100) * tax +
        10;
      let finalTotal = (total + 10);
 
      //setvalue(props.finalPriceBuy);
      setSales(salesValue.toFixed(2));
      setProcessing(processingValue.toFixed(2));
      // setShippingFee(10);
      setTotal(finalTotal);
      // setShow(false);

    }

  }, [props?.Bid]);
  useEffect(() => {
    if (popup) {
      const timer = setTimeout(() => {
        setPopup(false);
        if (localStorage.getItem("tokenuser")) {
          redirectToAnotherPage(
            JSON.parse(localStorage.getItem("tokenuser")).id
          );
        } else if (localStorage.getItem("admin")) {
          redirectToAnotherPage(JSON.parse(localStorage.getItem("admin")).id);
        } else {
        }
      }, 2000); // Specify the duration in milliseconds (2 seconds in this example)

      return () => clearTimeout(timer);
    }
  }, [popup]);

  const [bidExp, setBidExp] = useState("30");
  const bidExpiration = (e) => {

    setBidExp(e.target.value)
  }

  const handlePaymentEditClick = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem('token')) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      if (localStorage.getItem('admin')) {
        navigate("/profile/" + userId)
      }
      else {
        navigate("/profile/" + userId)
      }
    }
    else {
      setShowModal(true)
    }
  }
  const handlePaymentEditClicknew = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem('token')) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      setPrimaryAccountModel(true)
      // if(localStorage.getItem('admin')){
      //   navigate("/profile/"+userId)
      // } 
      // else{
      //   navigate("/profile/"+userId)  
      // }
    }
    else {
      setShowModal(true)
      setPrimaryAccountModel(false)
    }
    // window.location.replace(payoutLink);
    // navigate(payoutLink);
  }

  const handleShippingAddressEditClick = () => {
    if (localStorage.getItem("tokenuser") || localStorage.getItem('token')) {
      let userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
      setShowAddressModal(true)
      // if(localStorage.getItem('admin')){
      //   // navigate("/stripeconnectaccount/"+userId)
      // } 
      // else{
      //   navigate("/stripeconnectaccount/"+userId)  
      // }
    }
    else {
      setShowModal(true)
      setShowAddressModal(false)
    }


  }

  // Function to handle the "Back" button click in the review step
  const handleReviewAsk = () => {
 
    // if (Number(askValue) <= 0) {
    //   toast.error("Please Input the Ask first");
    //   return;
    // } else {
    //   if (Number(askValue) < props.Data2) {
    //     // If askValue is less than Data2, show an error toast.
    //     toast.error("Your ask is less than the higher bid");
    //   } else {

    if (!localStorage.getItem("tokenuser") || !localStorage.getItem('token')) {
      setShowModal(true)
    }
    else if (cardBrand && cardExpiry && street && bidExp) {
      setShow(false)
      // props.handleUserAsk1(!Show);
    } else {
      toast.error("Please Fill all User Information");
    }
    // If askValue is acceptable, toggle the "Show" state and call a prop function.
  }
  // }
  // };

  //update the address
  // const handleUpdateAddress = () => {
  //   setButtonAddressLoader(true);

  //   if (
  //     !validStreet?.trim() ||
  //     !validCity?.trim() ||
  //     !validState?.trim() ||
  //     !validcountry?.trim()
  //   ) {
  //     toast.error("Address fields cannot be empty or not contain spaces");
  //     setButtonAddressLoader(false);
  //     return;
  //   }

  //   if (!mobileNumber) {
  //     toast.error("Phone Number is Required");
  //     setButtonAddressLoader(false);
  //     return;
  //   }

  //   let data = mobileNumber.replace(/\D/g, "");
  //   const isValidNumber = /^[1-9][0-9]*$/.test(data);

 
  //   // return

  //   const postalCodeRegex = /^\d{5}(-\d{4})?$/; // Postal code can be 5 digits or 5 digits followed by - and 4 more digits

  //   if (!postalCodeRegex.test(validPostalCode)) {
  //     toast.error(
  //       'Postal Code must be 5 digits or in the format "12345" or "12345-6789"'
  //     );
  //     setButtonAddressLoader(false);
  //     return;
  //   }
  //   if (isValidNumber) {
  //     if (data?.length > 9 && data?.length < 11) {

  //       if (localStorage.getItem("tokenuser")) {
  //         var id = JSON.parse(localStorage.getItem("tokenuser"))?.id
  //       } else {
  //         var id = JSON.parse(localStorage.getItem("admin"))?.id
  //       }

  //       ExportApi.updateSingleUserData(
  //         id,
  //         validStreet,
  //         validCity,
  //         validState,
  //         validcountry,
  //         validPostalCode,
  //         data
  //       )
  //         .then((resp) => {
  //           let Data = resp?.data?.result;
  //           setPostalCode(Data?.shipping_address?.postal_code);
  //           setCountry(Data?.shipping_address?.country);
  //           setState(Data?.shipping_address?.state);
  //           setCity(Data?.shipping_address?.city);
  //           setStreet(Data?.shipping_address?.street);
  //           localStorage.setItem("useraddress", JSON.stringify(validStreet));
  //           toast.success("Address Updated Successfully");
  //           setShowAddressModal(false);
  //           setButtonAddressLoader(false);
  //           var localData = localStorage.getItem("tokenuser");
  //           var parsedData = JSON.parse(localData);
  //           parsedData.phone = Data?.phone;
  //           localStorage.setItem('tokenuser', JSON.stringify(parsedData));

  //         })
  //         .catch((err) => {
  //           setButtonAddressLoader(false);
  //           console.log(err);
  //         });
  //     } else {
  //       toast.error("Enter Valid Mobile Number");
  //       // setButtonPhoneLoader(false);
  //       setButtonAddressLoader(false);
  //     }
  //   } else {
  //     toast.error("Number Cannot be started with zero");
  //     // setButtonPhoneLoader(false);
  //     setButtonAddressLoader(false);
  //   }
  // };
  const handleUpdateAddress = () => {
    setButtonAddressLoader(true);
  
    if (
      !validStreet?.trim() ||
      !validCity?.trim() ||
      !validState?.trim() ||
      !validcountry?.trim()
    ) {
      toast.error("Address fields cannot be empty or contain only spaces");
      setButtonAddressLoader(false);
      return;
    }
  
    if (!mobileNumber) {
      toast.error("Phone Number is Required");
      setButtonAddressLoader(false);
      return;
    }
  
    const newPhoneNumber = mobileNumber.toString().replace(/\D/g, ""); // Ensure mobileNumber is treated as a string before replace
  
    const postalCodeRegex = /^\d{5}(-\d{4})?$/;
  
    if (!postalCodeRegex.test(validPostalCode)) {
      toast.error(
        'Postal Code must be 5 digits or in the format "12345" or "12345-6789"'
      );
      setButtonAddressLoader(false);
      return;
    }
  
    // Construct the new address object
    const newAddress = {
      street: validStreet,
      city: validCity,
      state: validState,
      country: validcountry,
      postal_code: validPostalCode,
      phone: newPhoneNumber,
    };
  
    // Check if the new address is the same as the current address
    if (
      intialdata.street == validStreet  &&
      intialdata.city == validCity  &&
      intialdata.state ==  validState  &&
      intialdata.country ==validcountry &&
      intialdata.postal_code == validPostalCode  &&
      intialdata.phone ==   newPhoneNumber 
    ) {
      toast.error("Address is the same as the current address");
      setButtonAddressLoader(false);
      return;
    }
  
    // Proceed with updating the address if it has changed
    let id;
    if (localStorage.getItem("tokenuser")) {
      id = JSON.parse(localStorage.getItem("tokenuser"))?.id;
    } else {
      id = JSON.parse(localStorage.getItem("admin"))?.id;
    }
  
    ExportApi.updateSingleUserData(
      id,
      validStreet,
      validCity,
      validState,
      validcountry,
      validPostalCode,
      newPhoneNumber
    )
      .then((resp) => {
        let Data = resp?.data?.result;
        setPostalCode(Data?.shipping_address?.postal_code);
        setCountry(Data?.shipping_address?.country);
        setState(Data?.shipping_address?.state);
        setCity(Data?.shipping_address?.city);
        setStreet(Data?.shipping_address?.street);
        localStorage.setItem("useraddress", JSON.stringify(validStreet));
        toast.success("Address Updated Successfully");
        setShowAddressModal(false);
        setButtonAddressLoader(false);
        const newdata = {
          street: Data.shipping_address.street,
          city:Data?.shipping_address?.city,
          country:Data?.shipping_address?.country,
          postal_code:Data?.shipping_address?.postal_code,
          state:Data?.shipping_address?.state,
          phone:Data?.phone
        }
        setIntialdata(newdata);
        setTimeout(() => {
          window.location.reload();
        },  2000);
       
        var localData = localStorage.getItem("tokenuser");
        var parsedData = JSON.parse(localData);
        parsedData.phone = Data?.phone;
        localStorage.setItem("tokenuser", JSON.stringify(parsedData));
        setUsershipaddress(newAddress); // Update state to trigger useEffect
      })
      .catch((err) => {
        setButtonAddressLoader(false);
        console.log(err);
      });
  };
  
  
  
  

  const valueToAdd = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  const shippingFeeToAdd = isNaN(parseFloat(shippingFee)) ? 0 : parseFloat(shippingFee);
  const salesToAdd = isNaN(parseFloat(sales)) ? 0 : parseFloat(sales);
  const processingToAdd = isNaN(parseFloat(processing)) ? 0 : parseFloat(processing);
 

  // Calculate subtotal

  const adjustedValue = props?.type === 2 ? 0 : 25;
  const subtotal = valueToAdd + adjustedValue + 10 + salesToAdd + processingToAdd;

  const calculatedValue = value === 0 
  ? (props?.Bid || 0)
  : (
    isNaN(total)
      ? (typeof value === "string" && value.trim() === "")
        ? (parseFloat(0) + parseFloat(processing) + parseFloat(sales))
        : (parseFloat(value) + shippingValue + parseFloat(processing) + parseFloat(sales))
      : parseFloat(total)
  ).toFixed(2);
  return (
    <>
      {fullPageLoader ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <Tabs
          // onSelect={handleTabChange}
          defaultActiveKey={
            (prams.buy == "buy" ||
              updateBidAmount) || prams.buy == undefined
              ? "profile"
              : "home"
          }
          id="uncontrolled-tab-example"
          className="mb-3"
          hidden={
            prams.buy == "buy" ||
            prams.buy == "buy2" ||
            prams.buy == "buy3" ||
            prams.buy == "buy4" ||
            prams.buy == "sell2" ||
            prams.buy == "sell3" ||
            prams.buy == "sell" ||
            Show == false
          }
        >
          <Tab eventKey="home" title="Place Ask">
            <Sonnet
              Show={Show}
              update={setShow}
              Askid={props?.Askid}
              Data={props?.Ask}
              Data2={props?.Bid}
              Data3={props?.secondBid}
              Data4={askAmount}
              Data5={props?.usedBid}
              Type={props?.type}
              Data7={props?.website}
              handleUserAsk1={handleUserAsk1}
              shipping={props?.shippingFee}
              cardExpiry={cardExpiry}
              cardBrand={cardBrand}
              error={props?.error}
              FinalPriceSell1={props?.FinalPriceSell ? props?.FinalPriceSell : 0}
            />
          </Tab>
          <Tab
            eventKey="profile"
            title="Place Bid"
            className="place-bid-active"
          >
            <div>
              {Show ? (
                <>
                  {props?.Bid ? (
                    <p className="d-flex justify-content-end">
                      Highest Bid: ${props?.Bid}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="d-flex border rounded p-2">
                    <div className="quantity-custom">
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          id="basic-addon1"
                          className="bg-none border-end-0 text-dark"
                        >
                          $
                        </InputGroup.Text>
                        <Form.Control
                          value={value}
                          onChange={(e) => {
                            handlePlaceBidValue(e);
                          }}
                          placeholder=""
                          type="number"
                          className="border-start-0"
                          aria-label="number"
                          aria-describedby="basic-addon1"
                        />
                      </InputGroup>
                    </div>

                    <div className="ms-auto customsell-btn">
                      {props.Ask ? (
                        <Button
                          className="sell-btn border-0 buy-button ms-2"
                          onClick={() =>
                            navigate("/bidask/" + prams.id + "/buy")
                            
                          }
                        >
                          Buy Now for ${props.Ask}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* Start Commented Code for the sell Now Button */}
                    {/* <div className="ms-auto customsell-btn">
                  {props.Ask ? (
                    <Button
                      className="sell-btn border-0 buy-button ms-2"
                      onClick={() => handleBuyNow(props.Ask)}
                    >
                      Buy Now for ${props.Ask}
                    </Button>
                  ) : props.type == 2 ? (
                    props.second > 0 ? (
                      <Button
                        className="sell-btn border-0 buy-button ms-2"
                        onClick={() => handleBuyNow(props.second)}
                      >
                        Buy Now for ${props.second}
                      </Button>
                    ) : (
                      ""
                    )
                  ) : props.type == 3 ? (
                    props.used > 0 ? (
                      <Button
                        className="sell-btn border-0 buy-button ms-2"
                        onClick={() => handleBuyNow(props.used)}
                      >
                        Buy Now for ${props.used}
                      </Button>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div> */}
                    {/* End Commented Code for the Sell Now Button */}
                  </div>
                  {localStorage.getItem("tokenuser") ? (
                    value > 0 ? (
                      props?.Bid <= value ? (
                        <h2 className="text-capitalize text-center fs-4 my-4">
                          you are the highest bid!
                        </h2>
                      ) : props?.Bid > value ? (
                        <h2 className="text-capitalize text-center fs-4 my-4">
                          you are not the highest bid!
                        </h2>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : value > 0 ? (
                    props?.Bid <= value ? (
                      <h2 className="text-capitalize text-center fs-4 my-4">
                        you are the highest bid!
                      </h2>
                    ) : props?.Bid > value ? (
                      <h2 className="text-capitalize text-center fs-4 my-4">
                        you are not the highest bid!
                      </h2>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">Your Bid</p>
                    <p className="ms-auto mb-0 truncated-number">


                      ${(value === 0 || value === undefined) ? props?.Bid || 0 : value?.trim() === "" ? 0 : value}

            


                    </p>
                  </div>
                  {props?.type == 2 ? "":<>
                  <div className="d-flex align-items-center border-bottom pb-2 mb-3">
                    <p className="mb-0">Testing Fees</p>
                    <ReactTooltip
                      className="custom-tooltip text-center"
                      anchorId={`app-batch-spread-tab`}
                      place="bottom"
                      content=<div
                        dangerouslySetInnerHTML={{ __html: "<p>Every used graphic card is tested and benchmarked to verify it meets our quality standards.</p>" }}
                      />
                    />
                    <svg
                      id={`app-batch-spread-tab`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-question-circle-fill ms-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg>

                    {/* {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0">$0</p>
                    ) : (
                      <p className="ms-auto mb-0">$25.00
                   
                      </p>
                    )} */}
                {
  localStorage.getItem("admin") ? (
    <p className="ms-auto mb-0">$0</p>
  ) : (
    props.type === 2 ? (
      <p className="ms-auto mb-0">$0</p>
    ) : (
      props.Bid ? (
        <p className="ms-auto mb-0">$25.00</p>
      ) : (
        (Number(processing) + Number(sales)) ? (
          <p className="ms-auto mb-0">$25.00</p>
        ) : (
          <p className="ms-auto mb-0">$25.00</p>
        )
      )
    )
  )
}


                  </div></>}
                  {/* {(Number(processing)+Number(sales)).toFixed(2)} */}

                  {/* 
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">Sales Tax</p>
                    {
                      localStorage.getItem('admin') ? 
                      <p className="ms-auto mb-0">$0</p>:<p className="ms-auto mb-0">${sales}</p>
                    }
                  </div>

                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">
                      Processing Fee &nbsp;
                      <ReactTooltip
                        anchorId="order_bid"
                        place="bottom"
                        content="Processing Fee"
                      />
                      <svg
                        id="order_bid"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-question-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                      </svg>
                    </p>
                    {
                      localStorage.getItem('admin') ? 
                      <p className="ms-auto mb-0">$0</p>:<p className="ms-auto mb-0">${processing}</p>
                    }
                  </div> */}

                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">Shipping</p>
                    {
                      localStorage.getItem('admin') ?
                        <p className="ms-auto mb-0">
                          $0
                        </p> : <p className="ms-auto mb-0">
             
                        ${props?.Bid ? 10 : (value === 0 || isNaN(value) || value === '' ? 0 : isNaN(shippingFee) ? "TBD" : shippingFee)}

                        
                        </p>
                    }

                  </div>

                  <div className='d-flex flex-column align-items-center  border-bottom pb-2 mb-3'>
                    <div className='d-flex  align-items-center w-100' onClick={toggleTaxesDetails} style={{ cursor: 'pointer' }}>
                      <p className="mb-0 text-capitalize">Taxes & Other
                        {/* <FontAwesomeIcon icon={faCaretDown} style={{ color: "#000", width: "15px", height: "15px"}} /> */}
                      </p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512" id="down-arrow" ><path d="M98.9 184.7l1.8 2.1 136 156.5c4.6 5.3 11.5 8.6 19.2 8.6 7.7 0 14.6-3.4 19.2-8.6L411 187.1l2.3-2.6c1.7-2.5 2.7-5.5 2.7-8.7 0-8.7-7.4-15.8-16.6-15.8H112.6c-9.2 0-16.6 7.1-16.6 15.8 0 3.3 1.1 6.4 2.9 8.9z"></path></svg>
                      {
                        localStorage.getItem('admin') ?
                          <p className='ms-auto mb-0'>$0</p>
                          :
                          <p className='ms-auto mb-0 truncated-number'>${(Number(processing) + Number(sales)).toFixed(2)}</p>
                      }

                    </div>

                    {showTaxesDetails && (
                      <div className="taxes-details w-100 ps-5 mt-3">
                        <div className='d-flex align-items-center pb-2 mb-0'>
                          <p className='mb-0 text-capitalize'>Sales Tax</p>
                          {/* <p className='ms-auto mb-0'>                               */}
                          {localStorage.getItem("admin") ? (
                            <p className="ms-auto mb-0">$0</p>
                          ) : (
                            <p className="ms-auto mb-0 truncated-number">
                              {isNaN(sales) ? "TBD" : "$" + sales}
                            </p>
                          )}
                          {/* </p> */}
                        </div>
                        <div className='d-flex align-items-center pb-2 mb-0'>
                          <p className='mb-0 text-capitalize'>Processing Fee &nbsp;
                            <ReactTooltip
                              anchorId="processing-bid"
                              place="bottom"
                              content="The cost associated with processing the order"
                            />
                            <svg id="processing-bid" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path></svg></p>
                          {localStorage.getItem("admin") ? (
                            <p className="ms-auto mb-0">$0</p>
                          ) : (
                            <p className="ms-auto mb-0" truncated-number>${processing}</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">Total</p>
                    {
                      localStorage.getItem('admin') ?
                        <p className="ms-auto mb-0 truncated-number">
                        {"$" + (value ? value : "0")}

                        </p> : <p className="ms-auto mb-0 ">
                         
                          {/* ${value === 0 ? (props?.Bid || 0) : (isNaN(total) ? value.trim() === "" ? (parseFloat(0) + parseFloat(processing) + parseFloat(sales)) : (parseFloat(value) + shippingValue + parseFloat(processing) + parseFloat(sales)) : parseFloat(total)).toFixed(2)} */}

                          {/* ${value === 0 || value =="" ? "0" : parseFloat(total).toFixed(2)} */}

                          {/* {props.Bid ? props.Bid + 25 + 10 +processing+sales :  } */}
                          {/* ${value === 0 ? (props?.Bid || 0).toFixed(2) : parseFloat(total || 0).toFixed(2)} */}

                          {/* {
                          props?.Bid ?
                         `$${(parseFloat(props?.Bid) + 25 + 10 + parseFloat(processing) + parseFloat(sales)).toFixed(2)}`
                          :
                          `$${value === 0 ? parseFloat(props?.Bid || 0).toFixed(2) : parseFloat(total || 0).toFixed(2)}` 
                          } */}
                        {
                         value === 0 && props?.Bid ?
                        `$${(parseFloat(props?.Bid) + (props?.type == 2 ? 0 : 25) + 10 + parseFloat(processing) + parseFloat(sales)).toFixed(2)}`
                          :
                        value === undefined && props?.Bid ?
                         '$0.00'
                            :
                           value > 0 ?
                          // `$${parseFloat(total).toFixed(2)}`
                           `$${(parseFloat(props?.type == 2 ? total - 25 : total)).toFixed(2)}` 

                            :
                          '$0.00'
                           }

          

        
                 

                        </p>
                    }

                  </div>

                  <div class="border rounded p-2 ask-main">
                    <div class="ask-expiration d-flex">
                      <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch me-1" viewBox="0 0 16 16"><path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"></path><path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"></path></svg>Bid Expiration</p>
                      <div class="ms-auto">
                        <select class="border py-1 px-2" onChange={(e) => bidExpiration(e)}>
                          <option value="1">1 day</option>
                          <option value="3">3 days</option>
                          <option value="7">7 days</option>
                          <option value="14">14 days</option>
                          <option selected value="30">30 days</option>
                          <option value="60">60 days</option>
                        </select>
                      </div>
                    </div>
                    <div className="payment-status">

                      {/* <div class="ask-expiration d-flex mt-3"> */}
                      {/* <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet me-2" viewBox="0 0 16 16"><path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path></svg>Payment method : {cardBrand && cardExpiry ? "Active" : (<span className="error-messages">Required</span>)}

                        </p> */}
                      {/* <div class="ms-auto">
                                <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handlePaymentEditClick}>edit</button>
                            </div> */}
                      {/* </div> */}

                      <div class="ask-expiration d-flex mt-3">
                        <p class="mb-0  d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet me-2" viewBox="0 0 16 16"><path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path></svg><span>Payment method </span>&nbsp; : &nbsp; {cardBrand && cardExpiry ? cardBrand + cardExpiry : (<span className="error-messages">Required</span>)}
                          {/* {paymentStatus != 'null' ? 'Visa 4242' : <span className='text-danger fw-bold'>Required</span>} */}
                        </p>

                        {!paymentbtn ? <>
                          <div class="ms-auto">
                            <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"


                              onClick={() => {
                                if (!localStorage.getItem("tokenuser") || !localStorage.getItem("token")) {
                                  setShowModal(true);
                                } else {
                                  setPrimaryAccountModel(true);
                                }
                              }}

                            //onClick={() => handlePaymentEditClicknew()}

                            >  {!localStorage.getItem("tokenuser") || !localStorage.getItem('token') ? <>edit</> : <>add</>}
                            </button>
                          </div>
                        </> : <>
                          <div class="ms-auto">
                            <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                              //onClick={() => setPrimaryAccountModel(true)} 
                              onClick={() => handlePaymentEditClicknew()}

                            >edit</button>
                          </div></>}
                        {/* <div class="ms-auto">
                          <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary"
                              //onClick={() => setPrimaryAccountModel(true)} 
                           onClick={() => handlePaymentEditClicknew()}
                            
                            >edit</button>
                        </div> */}
                      </div>

                      <div className="ask-expiration d-flex mt-3">
                        <p className="mb-0 d-flex align-items-start me-2"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-wallet me-2 mt-1" viewBox="0 0 16 16"><path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path></svg>Shipping Address :
                        &nbsp; {street ? (
                            <div className="d-flex align-items-start flex-column ms-2">
                              <p className="mb-0">{street}</p>
                              <p className="mb-0">{city}</p>
                              <p className="mb-0">{postalCode}</p>
                            </div>
                          ) : (<span className="error-messages">Required</span>)}
                        </p>
                        <div class="ms-auto">
                          <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handleShippingAddressEditClick}>edit</button>
                          {/* {localStorage.getItem("tokenuser") ? <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handleShippingAddressEditClick}>edit 1111</button> :
                           <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handleShippingAddressEditClick}>edit 888</button> } */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <p>
                    "Including duties,taxes and fees based on buyer and server
                    <br /> location
                  </p>
                  <div className="border rounded p-2 ask-main">
                    <div className="ask-expiration d-flex">
                      <p className="mb-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-card me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                        </svg>
                        {cardBrand ? cardBrand : ""} Ending in{" "}
                        {cardExpiry ? cardExpiry : ""}
                      </p>
                    </div>

                    <div className="ask-expiration d-flex mt-3">
                      <p className="mb-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-wallet me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                        </svg>
                        Payout method : Active
                      </p>
                    </div>
                  </div> */}
                </>
              ) : (
                <div className="review-part">
                  {prams.buy != undefined ? (
                    <h2 className="text-capitalize text-center fs-4 mt-4 mb-2">
                      Buy Now
                    </h2>
                  ) : (
                    <h2 className="text-capitalize text-center fs-4 mt-4 mb-2">
                      Place Bid
                    </h2>
                  )}
                  <p className="text-center">
                    Please confirm your Purchase details below
                  </p>
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    {prams.buy != undefined ? (
                      <p className="mb-0 text-capitalize">Purchase price</p>
                    ) : (
                      <p className="mb-0 text-capitalize">Your Bid</p>
                    )}
                    <p className="ms-auto mb-0 truncated-number">
                    {isNaN(value) ? "TBD" : "$" + value}
                    </p>
                  </div>
                  {/* <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0 text-capitalize">Sales Tax</p>
                    {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0">$0</p>
                    ) : (
                      <p className="ms-auto mb-0">
                        {isNaN(sales) ? "TBD" : "$" + sales}
                      </p>
                    )}
                  </div> */}
                  {props?.type == 2 ? "":<>
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0">Testing Fees</p>
                    <ReactTooltip
                      className="custom-tooltip text-center"
                      anchorId={`app-batch-spread-tab`}
                      place="bottom"
                      content=<div
                        dangerouslySetInnerHTML={{ __html: "<p>Every used graphic card is tested and benchmarked to verify it meets our quality standards.</p>" }}
                      />
                    />
                    <svg
                      id={`app-batch-spread-tab`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-question-circle-fill ms-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg>
                    {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0">$0</p>
                    ) : (
                      <p className="ms-auto mb-0">
                        {/* $25.00 */}
                        {props?.type == 2 ? "$0" : "$25.00"}
                   
                      </p>
                    )}
                  </div></>}


                  {/* <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0 text-capitalize">
                      Processing Fee &nbsp;
                      <ReactTooltip
                        anchorId="order_bid"
                        place="bottom"
                        content="Processing Fee"
                      />true
                      <svg
                        id="order_bid"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-question-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                      </svg>
                    </p>
                    {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0">$0</p>
                    ) : (
                      <p className="ms-auto mb-0">${processing}</p>
                    )}
                  </div> */}
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0 text-capitalize">shipping</p>
                    {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0">$0</p>
                    ) : (
                      <p className="ms-auto mb-0">
                     
                        {/* {isNaN(shippingFee) ? "TBD" : "$" + shippingFee} */}
                        $10
                      </p>
                    )}
                  </div>
                  <div className='d-flex flex-column align-items-center  border-bottom pb-2 mb-3'>
                    <div className='d-flex  align-items-center w-100' onClick={toggleTaxesDetails} style={{ cursor: 'pointer' }}>
                      <p className="mb-0 text-capitalize">Taxes & Other
                        {/* <FontAwesomeIcon icon={faCaretDown} style={{ color: "#000", width: "15px", height: "15px"}} /> */}
                      </p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512" id="down-arrow"><path d="M98.9 184.7l1.8 2.1 136 156.5c4.6 5.3 11.5 8.6 19.2 8.6 7.7 0 14.6-3.4 19.2-8.6L411 187.1l2.3-2.6c1.7-2.5 2.7-5.5 2.7-8.7 0-8.7-7.4-15.8-16.6-15.8H112.6c-9.2 0-16.6 7.1-16.6 15.8 0 3.3 1.1 6.4 2.9 8.9z"></path></svg>
                      {
                        localStorage.getItem('admin') ?
                          <p className='ms-auto mb-0'>$0</p>
                          :
                          <p className='ms-auto mb-0 truncated-number'>${(Number(processing) + Number(sales)).toFixed(2)}</p>
                      }

                    </div>

                    {showTaxesDetails && (
                      <div className="taxes-details w-100 ps-5 mt-3">
                        <div className='d-flex align-items-center pb-2 mb-0'>
                          <p className='mb-0 text-capitalize'>Sales Tax</p>
                          {/* <p className='ms-auto mb-0'>                               */}
                          {localStorage.getItem("admin") ? (
                            <p className="ms-auto mb-0">$0</p>
                          ) : (
                            <p className="ms-auto mb-0 truncated-number">
                              {isNaN(sales) ? "TBD" : "$" + sales}
                            </p>
                          )}
                          {/* </p> */}
                        </div>
                        <div className='d-flex align-items-center pb-2 mb-0'>
                          <p className='mb-0 text-capitalize'>Processing Fee &nbsp;
                            <ReactTooltip
                              anchorId="processing-bid"
                              place="bottom"
                              content="The cost associated with processing the order"
                            />
                            <svg id="processing-bid" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path></svg></p>
                          {localStorage.getItem("admin") ? (
                            <p className="ms-auto mb-0">$0</p>
                            
                          ) : (
                            <p className="ms-auto mb-0 truncated-number">${processing}</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center  border-bottom pb-2 mb-3">
                    <p className="mb-0 text-capitalize">Total(incl. tax.)</p>
                    {localStorage.getItem("admin") ? (
                      <p className="ms-auto mb-0 fw-bold">
                        {isNaN(value) ? "TBD" : "$" + value}
                      </p>
                    ) : (
                      <p className="ms-auto mb-0 fw-bold truncated-number" 
                      //  style={{
                      //   width: subtotal?.length < 4 ? '40px' : '60px',
                      //   overflow: 'hidden',
                      //   textOverflow: 'ellipsis',
                      //   whiteSpace: 'nowrap'
                      // }}
                      >
                        {/* {isNaN(total) ? `$${subtotal}` : `$${total}`} */}
                        { `$${parseFloat(subtotal).toFixed(2)}` }

           
                     
                      </p>
                    )}
                  </div>

                  <div class="border rounded p-3 ask-main">
                    {prams.buy != undefined ? (<></>) : (
                      <div class="ask-expiration d-flex">
                        <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch me-1" viewBox="0 0 16 16"><path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z"></path><path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z"></path></svg>Bid Expiration</p>
                        <div class="ms-auto">
                          {/* <select class="border py-1 px-2">
                            <option askvalue="1">1 day</option>
                            <option askvalue="2">2 day</option>
                            <option askvalue="3">3 day</option>
                            <option askvalue="4">4 day</option>
                            <option askvalue="5">5 day</option>
                            <option askvalue="10">10 day</option>
                            <option askvalue="15">15 day</option>
                            <option askvalue="30">30 day</option>
                        </select> */}
                          {bidExp} Day
                        </div>
                      </div>)
                    }
                    <div className="payment-status">

                      <div class="ask-expiration d-flex mt-3">
                        <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet me-2" viewBox="0 0 16 16">
                          <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z"></path>
                        </svg>
                          Payment method :&nbsp;

                        </p>

                        <div class="ms-auto d-flex">
                          <p className="text-end">
                            {cardBrand && cardExpiry ? cardBrand + cardExpiry : (<span className="error-messages">Required</span>)}
                          </p>
                          {/* <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary">edit</button> */}

                          {prams.buy != undefined ? (
                            <div class="ms-auto">
                              <button type="button" class="text-uppercase ms-1 edit-btn bg-transparent border-0 btn btn-primary" onClick={() => handlePaymentEditClicknew()}>edit</button>
                            </div>
                          ) : <></>}
                        </div>
                      </div>
                      <div class="ask-expiration  mt-3">
                        <p class="mb-0 d-flex align-items-start ">
                          <svg fill="currentColor" className="me-2 mt-1" height="19px" width="19px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 487.4 487.4" enable-background="new 0 0 487.4 487.4"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M381.6,144.1h-63.3V25.9H0v369.2h33.2c1.1,36.4,31.8,66.4,68.4,66.4s67.3-30,68.4-66.4h154.6c1.1,36.4,31.8,66.4,68.4,66.4 s67.3-30,68.4-66.4h26V251L381.6,144.1z M101.6,440.7c-25.9,0-47.7-21.8-47.7-47.7s21.8-47.7,47.7-47.7s47.7,21.8,47.7,47.7 S127.5,440.7,101.6,440.7z M297.6,374.4H167.4c-8.3-28.5-34.9-49.8-65.8-49.8s-57.5,21.3-65.8,49.8H20.7V46.6h276.9V374.4z M387.9,179.6l65.5,66.2h-65.5V179.6z M393,440.7c-25.9,0-47.7-21.8-47.7-47.7s21.8-47.7,47.7-47.7s47.7,21.8,47.7,47.7 S419,440.7,393,440.7z M466.7,374.4h-7.9l0,0c-8.3-28.5-34.9-49.8-65.8-49.8s-57.5,21.3-65.8,49.8h-7.9V164.9H367v101.6h99.7V374.4 z"></path> </g> </g></svg>
                       <p style={{ whiteSpace: "nowrap" }}>Return Shipping Address  :</p> 
                          &nbsp;{street ? (
                            <div className="d-flex  flex-column ">
                              <p className="mb-0">{street}</p>
                              <p className="mb-0">{city}</p>
                              <p className="mb-0">{postalCode}</p>
                            </div>
                          ) : (<span className="error-messages">Required</span>)}
                          <div class="ms-auto">
                            {/* {street ? (
                            <>
                              {street}
                              {city}
                              {country + " " + postalCode}
                            </>
                          ) : (<span className="error-messages">Required</span>)} */}
                            {prams.buy != undefined ? (
                              <div class="ms-auto">
                                <button type="button" class="text-uppercase edit-btn bg-transparent border-0 btn btn-primary" onClick={handleShippingAddressEditClick}>edit</button>
                              </div>
                            ) : <></>}
                          </div>
                        </p>

                      </div>
                    </div>
                  </div>
                  {/* "Including duties,taxes and fees based on buyer and server
                  location
                  <div className="pb-2 mt-3 mb-3">
                    <p className="mb-0 text-capitalize border-bottom pb-2 mb-2">
                      {cardBrand ? cardBrand : ""} Ending in{" "}
                      {cardExpiry ? cardExpiry : ""}
                    </p>
                    <p className="ms-auto mb-0">Payout Method:Active</p>
                  </div> */}
                  <div className="pb-2 mt-3 mb-3 border rounded p-4">
                    <h5 className="mb-4">Pre Purchase Verification</h5>
                    <p className="d-flex align-items-center mb-0 pb-2 mb-2">
                      <input
                        className="me-2"
                        type="checkbox"
                        onChange={(e) => {
                          handleFinalCheck(e.target.checked);
                        }}
                      />
                      {/* Brand Name */}
                      I understand that once my bid is accepted by a seller, I cannot cancel the order.
                    </p>
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {/* {!FinalchecksError ? "" : "Complete necessary checkboxes for seamless shopping"} */}
                    </p>
                    <hr />
                    <p className="d-flex align-items-center mb-0 pb-2 mb-2">
                      <input
                        className="me-2"
                        type="checkbox"
                        onChange={(e) => {
                          handleFinalCheck1(e.target.checked);
                        }}
                      />
                      {/* StockX Verified */}
                      I understand that every used graphics card goes through a testing and inspection process and cannot be returned.<br /><br /> I understand that every new graphics card may be opened and inspected.
                    </p>
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {/* {!Finalchecks1Error ? "" : "Complete necessary checkboxes for seamless shopping"} */}
                    </p>
                    <hr />

                    <p className="d-flex align-items-center mb-0 pb-2 mb-2">
                      <input
                        className="me-2"
                        type="checkbox"
                        onChange={(e) => {
                          handleFinalCheck2(e.target.checked);
                        }}
                      />
                      I understand that shipping can take longer due to seller performance and the inspection process.
                    </p>
                    <p style={{ color: "red", fontSize: "16px" }}>
                      {/* {!Finalchecks1Error1 ? "" : "Complete necessary checkboxes for seamless shopping"} */}
                    </p>
                  </div>
                </div>
              )}

              <div className="d-flex align-items-center mt-5">
                {Show ? (
                  ""
                ) : (
                  <Button
                    className="text-capitalize border rounded bg-transparent text-black"
                    onClick={() => handleback()}
                  >
                    back
                  </Button>
                )}
                {shippingFee == undefined ? (
                  ""
                ) : Show ? (
                  <Button
                    className="text-capitalize ms-auto bg-dark text-white border-0"
                    onClick={() => handleReviewAsk()}
                    disabled={value > 0 ? false : true}
                  >
                    review Bid
                  </Button>
                ) : prams.buy != undefined || buyNowStatus ? (
                  <Button
                    className="text-capitalize ms-auto bg-dark text-white border-0"
                    disabled={isLoading}
                    //onClick={() => handlePlaceOrder()}
                    onClick={() => {
                      setLoading(true);
                      handlePlaceOrder();
                    }}

                  >
                    {isLoading ? "Please Wait..." : "Place Order"}
                  </Button>
                ) : (
                  <Button
                    className="text-capitalize ms-auto bg-dark text-white border-0"
                    disabled={isLoading}
                    onClick={() => hendleBid()}
                  >
                    {isLoading ? "Please Wait..." : "Place Bid"}
                  </Button>
                )}
              </div>

              {/* Start Login Model */}
              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop={true}
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Login</Modal.Title>
                </Modal.Header>
               <Modal.Body className="scrollable-modal-body">

                  <h6>
                    <strong>
                      In order to buy and sell, you need to login/sign up
                    </strong>
                  </h6>
                  <Login modal={() => setShowModal(false)} />
                </Modal.Body>
              </Modal>

              {/* Start Card Model */}
              <Modal
                show={showCardModel}
                onHide={() => setShowCardModel(false)}
                backdrop={true}
                keyboard={false}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Card Details</Modal.Title>
                </Modal.Header>
               <Modal.Body className="scrollable-modal-body">

                  {localStorage.getItem("tokenuser") ? (
                    <StripeElement
                      id={
                        JSON.parse(localStorage.getItem("tokenuser")).id
                          ? JSON.parse(localStorage.getItem("tokenuser")).id
                          : ""
                      }
                      modalClose={() => setShowCardModel(false)}
                    />
                  ) : localStorage.getItem("admin") ? (
                    <StripeElement
                      id={
                        JSON.parse(localStorage.getItem("admin")).id
                          ? JSON.parse(localStorage.getItem("admin")).id
                          : ""
                      }
                      modalClose={() => setShowCardModel(false)}
                    />
                  ) : (
                    ""
                  )}
                </Modal.Body>
              </Modal>

              <Modal
                show={popup}
                onHide={() => setPopup(false)}
                backdrop={true}
                keyboard={false}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Address Details</Modal.Title>
                </Modal.Header>
               <Modal.Body className="scrollable-modal-body">
please fill your address first</Modal.Body>
              </Modal>
            </div>

            {/* Start Shipping Address */}
            <Modal
              show={showAddressModal}
              onHide={() => setShowAddressModal(false)}
              backdrop={true}
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Address</Modal.Title>
              </Modal.Header>
              <Modal.Body className="scrollable-modal-body">

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Address"
                    value={validStreet}
                    onChange={(e) => setValidStreet(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your City"
                    value={validCity}
                    onChange={(e) => setValidCity(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  {/* <Form.Control type="text" placeholder="Enter Your State" value={validState} onChange={(e) => setValidState(e.target.value)} /> */}
                  <Form.Select aria-label="Default select example" onChange={(e) => setValidState(e.target.value)}>
                    {
                      validState ?
                        <option selected>{validState}</option> :
                        <option selected>Select State</option>
                    }
                    {
                      states?.map((item) => {
                        return (
                          <option value={item?.abbreviation}>{item?.name}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Country"
                    value={validcountry}
                    onChange={(e) => setValidCountry(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Postal Code"
                    value={validPostalCode}
                    onChange={(e) => setValidPostalCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <InputMask
                    className="form-control"
                    mask="(999) 999-9999"
                    placeholder="(123) 456-7890"
                    type="tel"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                  
                </Form.Group>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowAddressModal(false)}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  disabled={buttonAddressLoader}
                  onClick={handleUpdateAddress}
                >
                  {buttonAddressLoader ? "Please Wait..." : "Save"}
                </Button>
              </Modal.Footer>
            </Modal>
            {/* End Shipping Address */}

            {/* Model start Primary Payment Account */}
            <Modal show={primaryAccountModel} size='lg' onHide={() => setPrimaryAccountModel(false)} backdrop={true}>
              <Modal.Header closeButton>
                <Modal.Title>Update Primary Payment Account</Modal.Title>
              </Modal.Header>
             <Modal.Body className="scrollable-modal-body">

                <StripeElement />
              </Modal.Body>
            </Modal>
            {/* Model End Primary Payment Account */}
          </Tab>
        </Tabs>
      )}
    </>
  );
}

export default UncontrolledExample;
