import React, { useEffect } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  Row,
  Table,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DropdownBasicExample from "./DropdownBasicExample";
import { useParams } from "react-router-dom";
import ExportApi from "../../api/ExportApi";
import { useState } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

export default function Customerdetails() {
  // Initialize state variables
  let navigate = useNavigate();
  let params = useParams();
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [country, setCountry] = useState();
  const [totalSpent, setTotalSpent] = useState();
  const [totalSold, setTotalSold] = useState();
  const [totalSale, setTotalSale] = useState();
  const [totalPurchase, setTotalPurchase] = useState();
  const [lastOrder, setLastOrder] = useState();
  const [lastSale, setLastSale] = useState();
  const [accountCreated, setAccountCreated] = useState();
  const [activeOrderData, setActiveOrderData] = useState([]);
  const [activeBidData, setActiveBidData] = useState([]);
  const [activeAskData, setActiveAskData] = useState();
  const [orderHistoryData, setOrderHistoryData] = useState();
  const [postalCode, setPostalCode] = useState();
  const [bidAskListHistory, setBidAskListHistory] = useState();
  const [showUserModal, setShowUserModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [validEmail, setValidEmail] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [validStreet, setValidStreet] = useState();
  const [validCity, setValidCity] = useState();
  const [validState, setValidState] = useState();
  const [validCountry, setValidCountry] = useState();
  const [validPostalCode, setValidPostalCode] = useState();
  const [chatdata, setChatdata] = useState([]);
  const [tickdata, setTicketdata] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUrl, setCurrentUrl] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [sendAttachment, setSendAttachment] = useState();
  const [comments, setComments] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  // Function to fetch and handle user data
  const { id } = useParams();
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [subscribe, setSubscribe] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      ExportApi.getallnegativeseries(id)
        .then((response) => {
          // Filter items where status is "Failed to Ship" or "Authentication Failed"
          const filteredData = response.data.data.filter(
            (item) =>
              item.deliveryStatusId.deliveryStatusForSeller.status ===
                "Failed to Ship" ||
              item.deliveryStatusId.deliveryStatusForSeller.status ===
                "Authentication Failed"
          );

          setFilteredStatus(filteredData);
          // Use filteredData as needed in your application
        })
        .catch((error) => {
          console.error("Error fetching negative series:", error);
          // Handle errors
        });
    }
  }, [id]);

  const handleUserData = () => {
    // Make an API call to get user data
    setLoading(true);
    ExportApi.getSingleCustomerAllData(params.id)
      .then((resp) => {
    
        // Extract data from API response and update state variables
        let data = resp?.data?.data[0];
        let maindata = resp?.data?.data;
        setLoading(false);
        //  setFirstName(maindata?._id?.firstname);
        setTotalSpent(maindata?.totalSpent);
        setTotalSold(maindata?.totalSold);
        setTotalSale(maindata?.salesCount);
        setTotalPurchase(maindata?.purchaseCount);
        // setLastSale(data.)
        setLastOrder(data?.purchaselist[0]?.createdAt);
        // setActiveOrderData(data?.activeOrders);

        //setActiveOrderData(maindata?.activeOrderalldetails);

        const filteredActiveorderData = maindata?.activeOrderalldetails?.filter(
          (item) => item?.productDetails?.length > 0
        );
        if (filteredActiveorderData?.length > 0) {
          setActiveOrderData(filteredActiveorderData);
        }

        // setActiveBidData(maindata?.bidList);
        const filteredBidData = maindata?.bidList?.filter(
          (item) => item?.productDetails?.length > 0
        );
        if (filteredBidData?.length > 0) {
          setActiveBidData(filteredBidData);
        }

        // setActiveAskData(maindata?.askList);

        const filteredAskData = maindata?.askList?.filter(
          (item) => item?.productDetails?.length > 0
        );
        if (filteredAskData?.length > 0) {
          setActiveAskData(filteredAskData);
        }

        // setOrderHistoryData(maindata?.purchaselist);

        const filteredpurchaselistData = maindata?.purchaselist?.filter(
          (item) => item?.productId?.length > 0
        );
        if (filteredpurchaselistData?.length > 0) {
          setOrderHistoryData(filteredpurchaselistData);
        }

        // setBidAskListHistory(maindata?.bidAskHistory);

        const filteredBidaskData = maindata?.bidAskHistory?.filter(
          (item) => item?.productDetails?.length > 0
        );
        if (filteredBidaskData?.length > 0) {
          setBidAskListHistory(filteredBidaskData);
        }

        // ... (update other state variables)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // Handle error or perform actions on error
      });
  };

  // Function to handle user details
  const handleUserDetails = () => {
    // Make an API call to get user personal details
    ExportApi.getSingleUserData(params.id)
      .then((resp) => {
        // Extract personal details from API response and update state variables
        let Data = resp.data.data;
        setSubscribe(Data?.subscribe);
        setFirstName(Data?.firstname);
        setLastName(Data?.lastname);
        setValidEmail(Data?.email);
        setPhone(Data?.phone);
        setStreet(Data?.shipping_address?.street);
        setCity(Data?.shipping_address?.city);
        setState(Data?.shipping_address?.state);
        setCountry(Data?.shipping_address?.country);
        setPostalCode(Data?.shipping_address?.postal_code);
        // ... (update other personal details)
      })
      .catch((err) => console.log(err));
  };

  // Function to validate email format
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to update email
  const handleUpdateEmail = () => {
    setButtonLoader(true);
    if (email == "" || email == null) {
      toast.error("Please Enter Email");
      setButtonLoader(false);
    } else if (!validateEmail(email)) {
      setIsValidEmail(false);
      toast.error("Please Enter Valid Email");
      setButtonLoader(false);
    } else {
      // Make an API call to update email
      ExportApi.updateEmail(params.id, email)
        .then((resp) => {
          if (resp.data.message == "Data updated sucessfully") {
            toast.success("Email Updated Successfully");
            // ... (update relevant state variables)
          } else {
            toast.error(resp.data.message);
            // ... (perform actions on update failure)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Function to update address
  const handleUpdateAddress = () => {
    setButtonLoader(true);
    // Check for empty or spaces in address fields
    if (
      validStreet.trim() === "" ||
      validCity.trim() === "" ||
      validState.trim() === "" ||
      validCountry.trim() === ""
    ) {
      toast.error("Address fields cannot be empty or contain only spaces");
      setButtonLoader(false);
      return;
    }
    // Validate postal code format
    const postalCodeRegex = /^\d{5}-\d{4}$/;
    if (!postalCodeRegex.test(validPostalCode)) {
      toast.error('Postal Code must be in the format "12345-6789"');
      setButtonLoader(false);
      return;
    }
    // Make an API call to update user address
    ExportApi.updateSingleUserData(
      params.id,
      street,
      city,
      state,
      country,
      postalCode,
      null
    )
      .then((resp) => {
        toast.success("Address Updated Successfully");
        // ... (perform actions on successful address update)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch user data and details on component mount
  useEffect(() => {
    handleUserData();
    handleUserDetails();
  }, []);

  // Event listener to clear local storage and navigate to login page
  window.addEventListener("Loginout", () => {
    localStorage.clear();
    navigate("/");
  });

  useEffect(() => {
    // Extract the customer ID from the URL
    const url = window.location.href;
    const parts = url.split("/");
    const customerIdFromUrl = parts[parts.length - 1]; // Get the last part of the URL
    setCurrentUrl(customerIdFromUrl);
  }, []);

  const fetchChatData = async () => {
    setIsLoading(true);
    try {
      // Make an API call to get user data
      const userDataResponse = await ExportApi.getSingleCustomerAllData(
        params.id
      );
      const userId = userDataResponse?.data?.data?._id?.id;
      if (!userId) {
        setIsLoading(false);
        return;
      }

      // Use getchatbyid to fetch chat data
      const chatDataResponse = await ExportApi.getchatbyid(userId);
      if (!chatDataResponse.data) {
        setIsLoading(false);
        return;
      }
      const combinedData = {
        Chat: chatDataResponse?.data?.Chat,
        tickets: chatDataResponse?.data?.tickets,
      };

      setCombinedData(combinedData);
      setChatdata(chatDataResponse?.data?.Chat);
      setTicketdata(chatDataResponse?.data?.tickets);
  
      // setChatdata(chatData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChatData();
  }, [params.id]);

 

  // const filteredData = chatdata
  //   .map((item, index) => ({ userType: item?.userType, index })) // Create an object with userType and index
  //   .filter(item => item?.userType === "user") // Filter the items by userType
  //   .map(item => item?.index); // Extract only the indices



  // const filteredticketData = tickdata
  //   .map((item, index) => ({ ticketId: item?.ticketId, index }))
  //   .filter(item => !!item?.ticketId)
  //   .map(item => item?.index);


  const combinedList = isChecked
    ? [
        ...(combinedData?.Chat?.filter(
          (item) => item.userType === "admin" || item.userType === "user"
        ) || []),
        ...(combinedData?.tickets || []),
      ]
    : [
        ...(combinedData?.Chat?.filter((item) => item.userType === "user") ||
          []),
        ...(combinedData?.tickets || []),
      ];


  const sortedCombinedList = combinedList.slice().sort((a, b) => {
    const aTime = a.startTime || a.time || 0; // Replace with the actual property names in your data
    const bTime = b.startTime || b.time || 0;

    return new Date(bTime) - new Date(aTime);
  });

  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setComments(comments + emoji);
  };

  const saveComment = () => {
    const cmt = comments;
    const formData = new FormData();
    if (!cmt) {
      toast.error("Comment is required");
      return;
    }
    formData.append("status", comments);
    formData.append("userType", "admin");
    // formData.append('userId', orderidnew);

    if (sendAttachment) {
      formData.append("image", sendAttachment);
    }

    const data = {
      userType: "admin",
      content: comments,
      userId: currentUrl,
    };

    ExportApi.addChatbyAdmin(data)
      .then((resp) => {

        toast.success("Commented Successfully");
        setShowEmojis(false);
        setComments("");
        fetchChatData();
      })
      .catch((err) => {
        console.error(err);
        toast.error("Failed to comment");
      });
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      <ToastContainer />
      <div className="customer-topbar d-flex align-items-center py-2 border-bottom">
        <div className="d-flex align-items-center">
          <Button
            className="ms-2 bg-transparent p-0 border-0"
            onClick={() => navigate("/customerMain")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              //   className="bi bi-arrow-left-short"
              viewBox="0 0 16 16"
              className="text-black"
            >
              <path
                fillRule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </Button>
          {firstName} {lastName}
          <Button className="bg-transparent p-0 border-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="text-black ms-2"
            >
              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
            </svg>
          </Button>
          <Link className="ms-3" to="#">
            Issue With payment processing
          </Link>
        </div>
        <div className="ms-auto">
          <DropdownBasicExample />
        </div>
      </div>

      <Row>
        <Col xs="12" md="9">
          <Table className="mt-3">
            <tr>
              <th>Status</th>
              <th>History</th>
              <th></th>
            </tr>
            <tbody>
              <tr className="border-0 border-white">
                <td className="py-2 px-0">
                  Active Buyer &nbsp;
                  {totalSpent?.toFixed(2) === "0.00" ? (
                    <MdCancel style={{ color: "red", fontSize: "24px" }} />
                  ) : (
                    <FaCircleCheck
                      style={{ color: "green", fontSize: "22px" }}
                    />
                  )}
                </td>
                <td className="py-2 px-0">Account Created</td>
                <td className="py-2 px-0">1/2/22</td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0">
                  Active Seller &nbsp;
                  {totalSpent?.toFixed(2) === "0.00" ? (
                    <MdCancel style={{ color: "red", fontSize: "24px" }} />
                  ) : (
                    <FaCircleCheck
                      style={{ color: "green", fontSize: "22px" }}
                    />
                  )}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#198754"
                    className="bi bi-check-circle-fill ms-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> */}
                </td>
                <td className="py-2 px-0">Last Order</td>
                <td className="py-2 px-0">{moment(lastOrder).format("l")}</td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0">
                  Subscription{" "}
                  {subscribe == true ? (
                    <FaCircleCheck
                      style={{ color: "green", fontSize: "22px" }}
                    />
                  ) : (
                    <MdCancel style={{ color: "red", fontSize: "24px" }} />
                  )}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#198754"
                    className="bi bi-check-circle-fill ms-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> */}
                </td>
                <td className="py-2 px-0">Last Sale</td>
                <td className="py-2 px-0">1/5/23</td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0">Positive Rating 8(88.9%)</td>
                <td className="py-2 px-0">Purchases</td>
                <td className="py-2 px-0">{totalPurchase}</td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0">
                  Negative Rating{" "}
                  <span className="negativepoint">
                    {filteredStatus?.length}
                  </span>
                </td>
                <td className="py-2 px-0">Sales</td>
                <td className="py-2 px-0">{totalSale}</td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0"></td>
                <td className="py-2 px-0">Total Spent</td>
                <td className="py-2 px-0">
                  <span className="spent">
                    {/* {"$" + totalSpent?.toFixed(2)} */}
                    {totalSpent !== undefined && totalSpent !== null
                      ? `$${totalSpent.toFixed(2)}`
                      : "Loading..."}
                  </span>
                </td>
              </tr>

              <tr className="border-0 border-white">
                <td className="py-2 px-0"></td>
                <td className="py-2 px-0">Total Sold</td>
                <td className="py-2 px-0">
                  <span className="sold">
                    {/* {"$" + totalSold?.toFixed(2)} */}
                    {totalSold !== undefined && totalSold !== null
                      ? `$${totalSold.toFixed(2)}`
                      : "Loading..."}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="mt-5">
            <div className="d-flex align-items-center gap-5">
              <p className="mb-0">Customer Notes</p>
              <p className="mb-0">Returns</p>
              <p className="mb-0">0</p>
            </div>
            <div>
              <input
                className="w-50 border rounded p-2"
                type="text"
                placeholder="Add a Note"
              />
            </div>
          </div>

          <div className="mt-4 active-order">
            <p className="border-bottom py-2 fw-bold">Active order</p>

            <div className="inner-custom-data">
              {loading ? (
                <div className="loader-icon" style={{ height: "200px" }}>
                  <i
                    className="fa-solid fa-spinner fa-spin-pulse"
                    style={{ fontSize: "60px" }}
                  ></i>
                </div>
              ) : activeOrderData?.length === 0 ? (
                <p className="text-center">Products are not found</p>
              ) : (
                activeOrderData?.map((item) => {
                  return (
                    <>
                      <p
                        className="fw-500"
                        onClick={() =>
                          navigate("/OrderDetail/" + item?.orderId)
                        }
                      >
                        {item?._id}
                      </p>
                      <div className="d-flex align-items-center gap-4 w-75 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              item?.productDetails[0]?.image[0]?.includes(
                                "http"
                              )
                                ? item?.productDetails[0]?.image[0]
                                : `${imageUrl}${item?.productDetails[0]?.image[0]}`
                            }
                            height="70px"
                            width="70px"
                          />
                          <div>
                            <p className="mb-0">
                              {item?.productDetails[0]?.productname}
                            </p>
                            <p className="text-black-50">
                              {item?.productDetails[0]?.sku ? (
                                <>sku: {item?.productDetails[0]?.sku}</>
                              ) : (
                                <>upc: {item?.productDetails[0]?.upc}</>
                              )}
                            </p>
                          </div>
                        </div>
                        <div>{moment(item?.createdAt).format("l")}</div>
                      </div>
                    </>
                  );
                })
              )}

              {/* {activeOrderData?.map((item) => {
                return (
                  <>
                    <p
                      className="fw-500"
                      onClick={() => navigate("/OrderDetail/" + item?._id)}
                    >
                      {item?._id}
                    </p>
                    <div className="d-flex align-items-center gap-4 w-75 justify-content-between ">
                      {item?.productId?.map((data) => {
                        return (
                          <div className="d-flex align-items-center">
                            <img
                              // src={`${imageUrl}${data?.image[0]}`}
                              src={
                                data?.image[0]?.includes("http")
                                  ? data?.image[0]
                                  : `${imageUrl}${data?.image[0]}`
                              }
                              height="70px"
                            />
                            <div>
                              <p className="mb-0">{data?.productname}</p>
                              <p className="text-black-50">sku:{data?.sku}</p>
                            </div>
                          </div>
                        );
                      })}
                      <div>{moment(item?.createdAt).format("l")}</div>
                      <div>
                        <span className="spent fw-bold">$250</span>
                      </div>
                    </div>
                  </>
                );
              })} */}

              {/* {
                bidAskListHistory[0]?.map((item) => {
                  return (
                    <>
                      <p className="fw-500">{item?._id}</p>
                      <div className="d-flex align-items-center gap-4 w-75 justify-content-between ">
                        {
                          item?.productId?.map((data) => {
                            return (
                              <div className="d-flex align-items-center">
                                <img src={`${imageUrl}${data?.image[0]}`} height="70px" />
                                <div>
                                  <p className="mb-0">{data?.productname}</p>
                                  <p className="text-black-50">sku:{data?.sku}</p>
                                </div>
                              </div>
                            )
                          })
                        }
                        <div>2/4/22</div>
                        <div>
                          <span className="spent fw-bold">${item?.bidAmount}</span>
                        </div>
                      </div>
                    </>
                  )
                })
              } */}
            </div>

            <p className="border-bottom py-2 fw-bold mt-5">Active Bids/Ask</p>
            <div className="inner-custom-data">
              {loading ? (
                <div className="loader-icon" style={{ height: "200px" }}>
                  <i
                    className="fa-solid fa-spinner fa-spin-pulse"
                    style={{ fontSize: "60px" }}
                  ></i>
                </div>
              ) : (
                <>
                  {/* Check if both activeBidData and activeAskData are empty */}
                  {activeBidData?.length === 0 &&
                  activeAskData?.length === 0 ? (
                    <p className="text-center">Products are not found</p>
                  ) : (
                    <>
                      {activeBidData?.map((item) => {
                        return (
                          <div className="active-bid-data-grid align-items-center gap-4 w-75">
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  item?.productDetails[0]?.image[0]?.includes(
                                    "http"
                                  )
                                    ? item?.productDetails[0]?.image[0]
                                    : `${imageUrl}${item?.productDetails[0]?.image[0]}`
                                }
                                height="70px"
                                width="70px"
                              />
                              <div>
                                <p>{item.productname}</p>
                                <div className="d-flex align-item-center gap-5">
                                  {item?.productDetails[0]?.sku ? (
                                    <p>Sku: {item?.productDetails[0]?.sku}</p>
                                  ) : (
                                    <p>Upc: {item?.productDetails[0]?.upc}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <p>
                                Condition:
                                {item?.type === 1
                                  ? "New"
                                  : item?.type === 2
                                  ? "2nd Hand"
                                  : "Used"}
                              </p>
                            </div>
                            <div>{moment(item?.createdAt).format("l")}</div>
                            <div>
                              <span className="spent fw-bold text-success">
                                {"$" + item?.bidAmount}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                      {activeAskData?.map((item) => {
                        return (
                          <div className="active-bid-data-grid align-items-center gap-4 w-75">
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  item?.productDetails[0]?.image[0]?.includes(
                                    "http"
                                  )
                                    ? item?.productDetails[0]?.image[0]
                                    : `${imageUrl}${item?.productDetails[0]?.image[0]}`
                                }
                                height="70px"
                                width="70px"
                              />
                              <div>
                                <p>{item?.productname}</p>
                                <div className="d-flex align-item-center gap-5">
                                  {item?.productDetails[0]?.sku ? (
                                    <p>Sku: {item?.productDetails[0]?.sku}</p>
                                  ) : (
                                    <p>Upc: {item?.productDetails[0]?.upc}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <p>
                                Condition:
                                {item?.type === 1
                                  ? "New"
                                  : item?.type === 2
                                  ? "2nd Hand"
                                  : "Used"}
                              </p>
                            </div>
                            <div>{moment(item?.createdAt).format("l")}</div>
                            <div>
                              <span className="spent fw-bold text-danger">
                                {"$" + item?.askAmount}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-5">
            <Accordion defaultActiveKey="1">
              <Accordion.Item>
                <Accordion.Header>
                  <b>Order History</b>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="mt-4 active-order">
                    <div className="inner-custom-data">
                      {loading ? (
                        <div
                          className="loader-icon"
                          style={{ height: "200px" }}
                        >
                          <i
                            className="fa-solid fa-spinner fa-spin-pulse"
                            style={{ fontSize: "60px" }}
                          ></i>
                        </div>
                      ) : orderHistoryData?.length === 0 ? (
                        <p className="text-center">No orders found</p>
                      ) : (
                        orderHistoryData?.map((item) => {
                          return (
                            <>
                              <p>{item?._id}</p>
                              <div className="order-history-grid align-items-center gap-4 pe-sm-5">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      item?.productId[0]?.image[0]?.includes(
                                        "http"
                                      )
                                        ? item?.productId[0]?.image[0]
                                        : `${imageUrl}${item?.productId[0]?.image[0]}`
                                    }
                                    height="70px"
                                    width="70px"
                                  />
                                  <div>
                                    <p className="mb-0">{item?.productname}</p>
                                    <p className="mb-0">
                                      {item?.type === 1
                                        ? "New"
                                        : item?.type === 2
                                        ? "2nd Hand"
                                        : "Used"}
                                    </p>
                                    <div>
                                      <p className="text-black-50">
                                        {item?.productId[0]?.sku
                                          ? `sku: ${item?.productId[0]?.sku}`
                                          : `upc: ${item?.productId[0]?.upc}`}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div>{moment(item?.createdAt).format("l")}</div>
                                <div>
                                  {item?.bidId?.map((data) => {
                                    return (
                                      <span className="spent fw-bold">
                                        {data?.subTotal
                                          ? "$" + data?.subTotal
                                          : ""}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          );
                        })
                      )}

                      {/* <div className="d-flex align-items-center gap-4 mt-4 w-75 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={Ls2} height="100px" />
                          <div>
                            <p>MSI RTX 3080TI Gaming X Trio</p>
                            <p className="mb-0">Used</p>
                            <p className="text-black-50">sku:c23</p>
                          </div>
                        </div>
                        <div>2/4/22</div>
                        <div>
                          <span className="sold fw-bold">$250</span>
                        </div>
                      </div>

                      <div className="d-flex align-items-center gap-4 mt-4 w-75 justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src={Ls2} height="100px" />
                          <div>
                            <p>MSI RTX 3080TI Gaming X Trio</p>
                            <p className="mb-0">Used</p>
                            <p className="text-black-50">sku:c23</p>
                          </div>
                        </div>
                        <div>2/4/22</div>
                        <div>
                          <span className="spent fw-bold">$250</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mt-5 askbid-cont">
            {/* <h4 className="mb-2">Bid Ask/History</h4> */}
            <Accordion defaultActiveKey="1">
              <Accordion.Item>
                <Accordion.Header>
                  <b>Bid/Ask History</b>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="mt-4 active-order">
                    <div className="inner-custom-data">
                      {/* Check if bidAskListHistory is empty */}
                      {bidAskListHistory?.length === 0 ? (
                        <p className="text-center">No bids or asks found</p>
                      ) : (
                        bidAskListHistory?.map((item) => {
                          return (
                            <div className="order-history-grid align-items-center gap-3 w-100 mb-3 pe-5">
                              {item?.productDetails?.map((data) => {
                                return (
                                  <>
                                    <div>
                                      <p className="mb-0">
                                        {data?.productname}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0 text-start">
                                        {data?.sku
                                          ? `Sku: ${data?.sku}`
                                          : `Upc: ${data?.upc}`}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        Condition:{" "}
                                        {data?.type === 1
                                          ? "New"
                                          : data?.type === 2
                                          ? "2nd Hand"
                                          : "Used"}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                              <div>{moment(item?.createdAt).format("l")}</div>
                              <div>
                                <span className="spent fw-bold text-success">
                                  {"$" +
                                    (item?.bidAmount
                                      ? item?.bidAmount
                                      : item?.askAmount)}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>

                  {/* <div className="mt-4 active-order">
      <div className="inner-custom-data">
        {hasBidData && bidAskListHistory.map((item, index) => (
       
          <div key={index} className="d-flex align-items-center gap-4 w-75 justify-content-between">
            {item?.productId?.map((data, idx) => (
              <div key={idx} className="d-flex align-items-center">
                <div>
                  <p>{data?.productname}</p>
                  <div className="d-flex align-item-center gap-5">
                    <p>Sku: {data?.sku}</p>
                    <p>
                      Condition: {data?.type === 1 ? "New" : data?.type === 2 ? "2nd Hand" : "Used"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div>{moment(item?.createdAt).format("l")}</div>
            <div>
              <span className="spent fw-bold text-success">
                {"$" + item?.bidAmount}
              </span>
            </div>
          </div>
        ))}

        {hasAskData && bidAskListHistory[1].map((item, index) => (
          <div key={index} className="d-flex align-items-center gap-4 w-75 justify-content-between">
            {item?.productId?.map((data, idx) => (
              <div key={idx} className="d-flex align-items-center">
                <div>
                  <p>{data?.productname}</p>
                  <div className="d-flex align-item-center gap-5">
                    <p>Sku: {data?.sku}</p>
                    <p>
                      Condition: {data?.type === 1 ? "New" : data?.type === 2 ? "2nd Hand" : "Used"}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <div>{moment(item?.createdAt).format("l")}</div>
            <div>
              <span className="spent fw-bold text-danger">
                {"$" + item?.askAmount}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="bg-custom-light p-3 my-3 rounded">
            <div className="py-2 d-flex justify-content-between align-items-center">
              <h6>Timeline</h6>
              <Form.Check
                type="checkbox"
                label="Show Comments"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </div>
            <hr />
            <div className="d-flex py-3 gap-4">
              <div className="leave-comment">
                <div className="timeline-custom">
                  <div className="timeline-block timeline-block-right">
                    <div className="ee">
                      <div className="message-user">
                        <img src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" />
                      </div>
                    </div>
                  </div>
                  {/* {(!isLoading && chatdata?.length > 0) ? chatdata?.map((item) => (
                    <div className="timeline-block timeline-block-left">
                      <div className="marker active"></div>
                    </div>
                  )) : <></>

                  } */}
                  {/* {(tickdata?.length > 0) ? tickdata?.map((item) => (
                    <div className="timeline-block timeline-block-left">
                      <div className="marker active"></div>
                    </div>
                  )) : <></>

                  } */}
                  {/* {(!isLoading && (chatdata?.length > 0 || tickdata?.length > 0)) ? (
                    [...chatdata, ...tickdata].map((item, index) => (
                      <div key={index} className="timeline-block timeline-block-left">
                        <div className="marker active"></div>
                        
                      </div>
                    ))
                  ) : <></>} */}

                  {/* {isLoading ? (
                    <p></p>
                  ) : sortedCombinedList.length > 0 && sortedCombinedList.map((item, index) => (
                    <div key={index} className="timeline-block timeline-block-left">
                      <div className="marker active"></div>
                    </div>
                  ))} */}

                  {isLoading ? (
                    <p></p>
                  ) : (
                    sortedCombinedList?.length > 0 &&
                    sortedCombinedList?.map((item, index) => {
                      if (
                        (isChecked &&
                          (item?.userType === "admin" ||
                            item?.userType === "user" ||
                            item?.event === "ticket:create")) ||
                        (!isChecked &&
                          (item?.userType === "user" ||
                            item?.event === "ticket:create"))
                      ) {
                        return (
                          <div
                            key={index}
                            className="timeline-block timeline-block-left"
                          >
                            <div className="marker active"></div>
                          </div>
                        );
                      }
                    })
                  )}

                  {/* {isLoading ? (
                    <p></p>
                  ) : sortedCombinedList.length > 0 && sortedCombinedList.map((item, index) => {
                    if (isChecked && filteredData.includes(index)) {
                      return null; // Hide the timeline block if isChecked is true and index is in filteredData
                    }
                    return (
                      <div key={index} className="timeline-block timeline-block-left">
                        <div className="marker active"></div>
                      </div>
                    );
                  })} */}
                </div>
              </div>
              <div className="w-100 position-relative">
                {/* <textarea className="w-100 p-2 w-95"></textarea> */}
                <textarea
                  className="w-100 p-2 w-95"
                  placeholder="Leave a Comment"
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                ></textarea>
                <div className="iconlist d-flex align-items-center gap-2 position-absolute">
                  <div className="iconlist d-flex align-items-center gap-2 position-absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-emoji-smile"
                      viewBox="0 0 16 16"
                      onClick={() => setShowEmojis(!showEmojis)}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                    </svg>

                    <label for="custom-input" className="custom-input-label">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-file-earmark-code"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                        <path d="M8.646 6.646a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 9 8.646 7.354a.5.5 0 0 1 0-.708zm-1.292 0a.5.5 0 0 0-.708 0l-2 2a.5.5 0 0 0 0 .708l2 2a.5.5 0 0 0 .708-.708L5.707 9l1.647-1.646a.5.5 0 0 0 0-.708z" />
                      </svg>
                    </label>
                    <Form.Control
                      type="file"
                      className="custom-input-file"
                      id="custom-input"
                      accept=".jpg, .jpeg, .png"
                      name="custom-input"
                      onChange={(e) => {
                        // Check if a file is selected
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();

                          reader.onloadend = () => {
                            // Set the image preview
                            setUpdateLogo(reader.result);
                          };

                          // Read the image as a data URL
                          reader.readAsDataURL(file);
                        }

                        setSendAttachment(file);
                      }}
                    />

                    <Button
                      className="px-4 border-0 bg-secondary text-white"
                      onClick={saveComment}
                    >
                      Post
                    </Button>
                    {showEmojis && (
                      <div className="emoji-picker-custom">
                        <Picker data={data} onEmojiSelect={addEmoji} />
                      </div>
                    )}
                  </div>
                </div>
                <p className="text-end mb-0">
                  Only you and other staff can see comments
                </p>

                <div>
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : sortedCombinedList?.length > 0 ? (
                    sortedCombinedList?.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mt-2 p-2 mb-3"
                      >
                        <p className="mb-0">
                          {item?.userType === "user" &&
                            `Chat Started ${
                              moment(item?.startTime, moment.ISO_8601).isValid()
                                ? moment(item?.startTime).format(
                                    "MM/DD/YYYY hh:mma"
                                  )
                                : "Invalid Date"
                            }`}

                          {item.ticketId &&
                            `Ticket created ${item?.ticketId} ${
                              moment(item?.time, moment.ISO_8601).isValid()
                                ? moment(item?.time).format("MM/DD/YYYY hh:mma")
                                : "Invalid Date"
                            }`}

                          {isChecked &&
                            item?.userType === "admin" &&
                            item?.content}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No data available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col xs="12" md="3">
          <div className="overview-part mt-4">
            <div className="border-bottom pb-3">
              <div className="d-flex align-items-center">
                <h4 className="text-capitalize fs-6">customer overview</h4>
                {/* <Button className="ms-auto bg-success border-0 py-2 px-4" onClick={() => setShowUserModal(true)}>
                  Edit
                </Button> */}
              </div>
              <Link to="#">{validEmail}</Link>
              <p>{phone}</p>
            </div>

            <div className="border-bottom pb-3 mt-4">
              <div className="d-flex align-items-center">
                <h4 className="text-capitalize fs-6">DEFAULT ADDRESS</h4>
                <Button
                  className="ms-auto bg-success border-0 py-2 px-4"
                  onClick={() => setShowAddressModal(true)}
                >
                  Manage
                </Button>
              </div>

              <p>
                {firstName} {lastName}
                <br /> {street}
                <br />
                {city} {state} {postalCode}
                <br />
                {country}
              </p>
              <div className="tags">
                <Link to="#" onClick={() => setShowAddressModal(true)}>
                  Add new address
                </Link>
                <div className="tag-input-part">
                  <div className="d-flex align-items-center">
                    <p>Tags</p>
                    <Link to="#" className="ms-auto">
                      View all tags
                    </Link>
                  </div>
                  <input
                    className="w-100 form-control"
                    type="text"
                    placeholder="VIP,Sale,Shopper etc."
                  />
                </div>
              </div>
            </div>

            <div className="border-bottom pb-3 mt-4 tag-input-part rounded">
              <h5 className="fs-6">Support History</h5>
              <div className="support-history">
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">Shipping Inquery</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">New Account</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">Billing Inquery</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
              </div>
            </div>

            {/* <div className="border-bottom pb-3 mt-4 tag-input-part rounded">
              <h5 className="fs-6">Negative Rating History</h5>
              <div className="support-history">
                <div className="d-flex align-items-center mb-2">
                  {/* <p className="mb-0">Failed to ship item</p>
                  <p className="mb-0 ms-auto">1/12/2022</p> */}
            {/* </div>
              </div>
            </div>  */}
            <div className="border-bottom pb-3 mt-4 tag-input-part rounded">
              <h5 className="fs-6">Negative Rating History</h5>
              <div className="support-history">
                {filteredStatus.length > 0 ? (
                  filteredStatus.map((status, index) => (
                    <div className="d-flex align-items-center mb-2" key={index}>
                      <p className="mb-0">
                        {/* {status.deliveryStatusId.deliveryStatusForSeller.status} */}
                        Failed to ship item
                      </p>
                      <p className="mb-0 ms-auto">
                        {new Date(
                          status.deliveryStatusId.deliveryStatusForSeller.date
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No negative rating history found.</p>
                )}
              </div>
            </div>

            <div className="border-bottom pb-3 mt-4 tag-input-part rounded">
              <h5 className="fs-6">IP History</h5>
              <div className="support-history">
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">xxx xxx xxx</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">xxx xxx xxx</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <p className="mb-0">xxx xxx xxx</p>
                  <p className="mb-0 ms-auto">1/12/2022</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* for the update email popup */}
      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              autoComplete="false"
              placeholder="Enter Your Email"
              onChange={(e) => handleEmail(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonLoader}
            onClick={handleUpdateEmail}
          >
            {buttonLoader ? "is Loading" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* for the update address */}
      <Modal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Address"
              value={validStreet}
              onChange={(e) => setValidStreet(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your City"
              value={validCity}
              onChange={(e) => setValidCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your State"
              value={validState}
              onChange={(e) => setValidState(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Country"
              value={validCountry}
              onChange={(e) => setValidCountry(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Postal Code"
              value={validPostalCode}
              onChange={(e) => setValidPostalCode(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddressModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonLoader}
            onClick={handleUpdateAddress}
          >
            {buttonLoader ? "is Loading" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
