import React, {
  Children,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Breadcrumb,
  Container,
  Row,
  Col,
  Dropdown,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Footer from "../components/footer";
import { useNavigate, useParams } from "react-router-dom";
import ExportApi from "../api/ExportApi";
import Header from "../../src/components/header";
import Login from "../components/Login";
import Heart from "react-heart";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { animateScroll as scroll } from "react-scroll";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "@mui/material/Pagination";

export default function Categories() {
  let prams = useParams();
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [Compare, setCompare] = useState([]);
  const [CompareData, setCompareData] = useState([]);
  const [category, setCategory] = useState([]);
  const [res, setres] = useState();
  const [customPrice, setCustomPrice] = useState(false);
  const [customMinPrice, setCustomMinPrice] = useState("");
  const [customMaxPrice, setCustomMaxPrice] = useState("");
  const [checkBoxData, setCheckBoxData] = useState(false);

  const [conditionKey, setconditionKey] = useState([]);
  const [chipsetKey, setchipsetKey] = useState([]);
  const [brandKey, setbrandKey] = useState([]);
  const [seriesKey, setseriesKey] = useState([]);
  const [modalKey, setmodalKey] = useState([]);
  const [PriceKey, setPriceKey] = useState([]);
  const [chipset, setchipset] = useState([]);
  const [condition, setcondition] = useState([]);
  const [condition1, setcondition1] = useState([]);
  const [hide, setHide] = useState();
  const [brand, setbrand] = useState([]);
  const [series, setseries] = useState([]);
  const [model, setmodel] = useState([]);
  const [Price, setPrice] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [FilterList, setFilterList] = useState();
  const [brandShow, setBrandShow] = useState(false);
  const [chipsetShow, setChipsetShow] = useState(false);
  const [seriesShow, setSeriesShow] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [sorting, setSorting] = useState("Normal");
  const [sort, setSort] = useState("Normal");
  const [customFilterPrice, setCustomFilterPrice] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  //Loaders
  const [showConditionLoader, setShowConditionLoader] = useState(false);
  const [showChipsetLoader, setShowChipsetLoader] = useState(false);
  const [showBrandLoader, setShowBrandLoader] = useState(false);
  const [showSeriesLoader, setShowSeriesLoader] = useState(false);
  const [showModalLoader, setShowModalLoader] = useState(false);
  const [showPriceLoader, setShowPriceLoader] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState({
    popUp: false,
    id: null,
  });
  const [searchloader, setSearchLoader] = useState();
  const [newsearch, setNewsearch] = useState(false);
  const [mainloader, setMainLoader] = useState(true);
  const [sortingLoader, setSortingLoader] = useState(false);
  const [total, setTotal] = useState();
  const [selectedData, setSelectedData] = useState(null);
  const [selectedfilterdata, setSelectedFilterData] = useState(null);
  const [totalpages, setTotalPages] = useState(1);
  const [showMore, setShowMore] = useState({
    showMore: false,
    showLess: false,
  });

  useEffect(() => {
    const chip =
      chipset?.length +
      condition1?.length +
      brand?.length +
      series?.length +
      model?.length +
      Price?.length;
    setTotal(chip);

    if (chip > 4 && !showMore.showLess) {
      setShowMore((prev) => ({
        ...prev,
        showMore: true,
      }));
    } else if (chip > 4 && showMore.showLess) {
      setShowMore((prev) => ({
        ...prev,
        showMore: false,
      }));
    } else {
      setShowMore((prev) => ({
        ...prev,
        showMore: false,
        showLess: false,
      }));
    }
  }, [chipset, condition1, brand, series, model, Price]);

  const SearchFilterChecknewd = (
    search,
    sort,
    isChecked,
    chipset,
    brand,
    series,
    model,
    Price,
    condition
  ) => {
    setShowConditionLoader(true);
    setMainLoader(true);
 
    ExportApi.GetAllcategorySerch(
      search,
      sort,
      condition,
      chipset,
      brand,
      series,
      model,
      Price,
      customFilterPrice,
      isChecked
    )
      .then((resp) => {
        let data = resp?.data?.Data;
        setTotalPages(resp?.data?.totalPages);
        setShowConditionLoader(false);
        setMainLoader(false);
     
        setSearchLoader(resp?.data?.Loader);
        //localStorage.setItem(cacheKey, JSON.stringify(data));
        //localStorage.setItem("loaderkey", resp?.data?.Loader);

        let chipsetKeyArray = [];
        let conditionKeyArray = [];
        let brandKeyArray = [];
        let modalKeyArray = [];
        let seriesKeyArray = [];
        setres(resp?.data.filterKey);
        resp?.data.filterKey?.chipset?.map((val, i) => {
          let key = { [val]: false };
          chipsetKeyArray.push(key);
        });
        setchipsetKey([...chipsetKeyArray]);
        resp?.data?.filterKey?.condition?.map((val) => {
          if (val == 1) {
            let key = { ["New-Retail"]: false };
            conditionKeyArray.push(key);
          } else if (val == 2) {
            let key = { ["New-2nd Hand"]: false };
            conditionKeyArray.push(key);
          } else {
            let key = { ["Used"]: false };
            conditionKeyArray.push(key);
          }
        });
        setconditionKey([...conditionKeyArray]);
        resp?.data?.filterKey?.series?.map((val) => {
          let key = { [val]: false };
          seriesKeyArray.push(key);
        });
        setseriesKey([...seriesKeyArray]);
        resp?.data?.filterKey?.brands?.map((val) => {
          let key = { [val]: false };

          brandKeyArray.push(key);
        });
        setbrandKey([...brandKeyArray]);
        resp?.data?.filterKey?.model?.map((val) => {
          let key = { [val]: false };
          modalKeyArray.push(key);
        });
        setmodalKey([...modalKeyArray]);
        if (prams.name == undefined) {
       
          setCategory(resp?.data?.Data);
        } else {
          setCategory([]);
        }

        setCount(1);
        setFilterList(resp?.data?.filterKey);
        const mdata = true;
        localStorage.setItem("secondData", JSON.stringify(mdata));
        if (resp.data.Message == "Data Fetched Successfully") {
          setCategory([...data]);
          setShowConditionLoader(false);
        } else {
          setCategory([]);
          setShowConditionLoader(false);
        }
      })
      .catch((err) => {
       
        setMainLoader(false);
        setShowConditionLoader(false);
      });
  };
  const handleMouseEnter = (id) => {
    setTooltipVisible({
      ...tooltipVisible,
      popUp: true,
      id: id,
    });
  };


  const handleMouseLeave = () => {
    setTooltipVisible({
      ...tooltipVisible,
      popUp: false,
      id: null,
    });
  };

  const extractCategoryIdFromUrl = () => {
    const pathArray = window.location.pathname.split("/");
    return pathArray[pathArray.length - 1];
  };

  const handlePageClick = (event, value) => {


    setPage(value);
    setPageOffset(value);
    // setPage(selected);

    const id = extractCategoryIdFromUrl();
    const npage = value;

    // GetData(id,page + 1 );
    SearchFilterpage(value);
  };

  const currentItems = category;

  const GetData = (id, page) => {
    const compareData = localStorage.getItem("Compare");
     
    if (compareData) {

      let result = JSON.parse(localStorage.getItem("Compare"));
      setShowConditionLoader(true);
      setCompareData(result);
      ExportApi.GetSinglecategory(id, page)
        .then((resp) => {
          setShowConditionLoader(false);

          setTotalPages(resp?.data?.data?.totalPages);
          let chipsetKeyArray = [];
          let conditionKeyArray = [];
          let brandKeyArray = [];
          let modalKeyArray = [];
          let seriesKeyArray = [];

          setCount(1);
          setres(resp?.data.data);
          resp?.data.data?.filterKey?.chipset?.map((val, i) => {
            let key = { [val]: false };
            chipsetKeyArray.push(key);
          });
          setchipsetKey([...chipsetKeyArray]);
          resp?.data.data?.filterKey?.condition?.map((val) => {
            if (val == 1) {
              let key = { ["New-Retail"]: false };
              conditionKeyArray.push(key);
            } else if (val == 2) {
              let key = { ["New-2nd Hand"]: false };
              conditionKeyArray.push(key);
            } else {
              let key = { ["Used"]: false };
              conditionKeyArray.push(key);
            }
          });
          setconditionKey([...conditionKeyArray]);
          resp?.data.data?.filterKey?.series?.map((val) => {
            let key = { [val]: false };
            seriesKeyArray.push(key);
          });
          setseriesKey([...seriesKeyArray]);
          resp?.data.data?.filterKey?.brands?.map((val) => {
            let key = { [val]: false };

            brandKeyArray.push(key);
          });
          setbrandKey([...brandKeyArray]);
          resp?.data.data?.filterKey?.model?.map((val) => {
            let key = { [val]: false };
            modalKeyArray.push(key);
          });
          setmodalKey([...modalKeyArray]);
          let data = resp?.data?.data?.productList1;
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            for (let index = 0; index < data.length; index++) {
              const element1 = data[index];

              if (element?._id.includes(element1?._id)) {
                data[index].isCompare = true;
              }
            }
          }
          if (prams.name == undefined) {
            setCategory([...data]);
          } else {
            setCategory([]);
          }
          setFilterList(resp?.data?.data?.filterKey);
        })
        .catch((err) => {
    
          setShowConditionLoader(false);
        });
    } else {

      const filterData = localStorage.getItem("selectedFilterData");
       if (filterData) {
        
            const parsedData = JSON.parse(filterData);

            const series = parsedData
              .filter((item) => item.type === "series" && item.checked)
              .map((item) => item.name);

   

            const chipset = parsedData
              .filter((item) => item.type === "chipset" && item.checked)
              .map((item) => item.name);

        
            if (chipset.length > 0) {
              SearchFilterChecknewd("", "Normal", true, chipset, []);
            } else {
              SearchFilterChecknewd("", "Normal", true, series, []);
            }
          } else {
            SearchFilterChecknewd("", "Normal", false, []);
          }
      // ExportApi.Getallproducts(page,series,chipset)
      //   .then((resp) => {
  
      //     setTotalPages(resp?.data?.totalPages);

      //     const data = JSON.parse(localStorage.getItem("selectedData"));

      //     // if (data) {

      //     //     SearchFilterCheck(search, sort);

      //     // } else {
      //     // SearchFilterChecknewd("", "Normal", false, []);
      //     // }

      //     // const filterData = localStorage.getItem("selectedFilterData");

      //     // if (filterData) {
  

      //     //   const parsedData = JSON.parse(filterData);

      //     //   const series = parsedData
      //     //     .filter((item) => item.type === "series" && item.checked)
      //     //     .map((item) => item.name);

    

      //     //   const chipset = parsedData
      //     //     .filter((item) => item.type === "chipset" && item.checked)
      //     //     .map((item) => item.name);

      

      //     //   if (chipset.length > 0) {
      //     //     SearchFilterChecknewd("", "Normal", true, chipset, []);
      //     //   } else {
      //     //     SearchFilterChecknewd("", "Normal", true, series, []);
      //     //   }
      //     // } else {
      //     //   SearchFilterChecknewd("", "Normal", false, []);
      //     // }

      //     let chipsetKeyArray = [];
      //     let conditionKeyArray = [];
      //     let brandKeyArray = [];
      //     let modalKeyArray = [];
      //     let seriesKeyArray = [];
      //     setres(resp?.data.filterKey);
      //     resp?.data.filterKey?.chipset?.map((val, i) => {
      //       let key = { [val]: false };
      //       chipsetKeyArray.push(key);
      //     });
      //     setchipsetKey([...chipsetKeyArray]);
      //     resp?.data?.filterKey?.condition?.map((val) => {
      //       if (val == 1) {
      //         let key = { ["New-Retail"]: false };
      //         conditionKeyArray.push(key);
      //       } else if (val == 2) {
      //         let key = { ["New-2nd Hand"]: false };
      //         conditionKeyArray.push(key);
      //       } else {
      //         let key = { ["Used"]: false };
      //         conditionKeyArray.push(key);
      //       }
      //     });
      //     setconditionKey([...conditionKeyArray]);
      //     resp?.data?.filterKey?.series?.map((val) => {
      //       let key = { [val]: false };
      //       seriesKeyArray.push(key);
      //     });
      //     setseriesKey([...seriesKeyArray]);
      //     resp?.data?.filterKey?.brands?.map((val) => {
      //       let key = { [val]: false };

      //       brandKeyArray.push(key);
      //     });
      //     setbrandKey([...brandKeyArray]);
      //     resp?.data?.filterKey?.model?.map((val) => {
      //       let key = { [val]: false };
      //       modalKeyArray.push(key);
      //     });
      //     setmodalKey([...modalKeyArray]);
      //     if (prams.name == undefined) {
   
      //       setCategory(resp?.data?.Data);
      //     } else {
      //       setCategory([]);
      //     }

      //     setCount(1);
      //     setFilterList(resp?.data?.filterKey);
      //   })
      //   .catch((err) => {
 
      //     setShowConditionLoader(false);
      //   });
    }
  };

  const GetData1 = (id, userid) => {
    if (localStorage.getItem("Compare")) {
      let result = JSON.parse(localStorage.getItem("Compare"));
      setCompareData(result);
      setShowConditionLoader(true);
      ExportApi.GetSinglecategory1(id, userid)
        .then((resp) => {
          setTotalPages(resp?.data?.data?.totalPages);
          let chipsetKeyArray = [];
          let conditionKeyArray = [];
          let brandKeyArray = [];
          let modalKeyArray = [];
          let seriesKeyArray = [];
          setCount(1);
          setres(resp?.data.data);
          resp?.data.data?.filterKey?.chipset?.map((val, i) => {
            let key = { [val]: false };
            chipsetKeyArray.push(key);
          });
          setchipsetKey([...chipsetKeyArray]);
          resp?.data.data?.filterKey?.condition?.map((val) => {
            if (val == 1) {
              let key = { ["New-Retail"]: false };
              conditionKeyArray.push(key);
            } else if (val == 2) {
              let key = { ["New-2nd Hand"]: false };
              conditionKeyArray.push(key);
            } else {
              let key = { ["Used"]: false };
              conditionKeyArray.push(key);
            }
          });
          setconditionKey([...conditionKeyArray]);
          resp?.data.data?.filterKey?.series?.map((val) => {
            let key = { [val]: false };
            seriesKeyArray.push(key);
          });
          setseriesKey([...seriesKeyArray]);
          resp?.data.data?.filterKey?.brands?.map((val) => {
            let key = { [val]: false };

            brandKeyArray.push(key);
          });
          setbrandKey([...brandKeyArray]);
          resp?.data.data?.filterKey?.model?.map((val) => {
            let key = { [val]: false };
            modalKeyArray.push(key);
          });
          setmodalKey([...modalKeyArray]);
          let data = resp?.data?.data?.productList1;
          for (let i = 0; i < result.length; i++) {
            const element = result[i];
            for (let index = 0; index < data.length; index++) {
              const element1 = data[index];

              if (element?._id.includes(element1?._id)) {
                data[index].isCompare = true;
              }
            }
          }
          // setTimeout(() => {
          if (prams.name == undefined) {
        
            setCategory([...data]);
          } else {
            setCategory([]);
          }

          // });
          // setCategory(resp?.data?.data?.productList1);

          setFilterList(resp?.data?.data?.filterKey);
        })
        .catch((err) => {
      
          setShowConditionLoader(false);
        });
    } else {
      // setShowConditionLoader(true);
      const filterData = localStorage.getItem("selectedFilterData");
      if (filterData) {
         
           const parsedData = JSON.parse(filterData);

           const series = parsedData
             .filter((item) => item.type === "series" && item.checked)
             .map((item) => item.name);

    

           const chipset = parsedData
             .filter((item) => item.type === "chipset" && item.checked)
             .map((item) => item.name);

     

           if (chipset.length > 0) {
             SearchFilterChecknewd("", "Normal", true, chipset, []);
           } else {
             SearchFilterChecknewd("", "Normal", true, series, []);
           }
         } else {
           SearchFilterChecknewd("", "Normal", false, []);
         }
      // ExportApi.GetSinglecategory1(id, userid)
      //   .then((resp) => {
    
      //     setTotalPages(resp?.data?.data?.totalPages);
      //     setShowConditionLoader(false);

      //     //     const data = JSON.parse(localStorage.getItem("selectedData"));

      //     // if (data) {

      //     //     SearchFilterCheck(search, sort);

      //     // } else {
      //     //SearchFilterChecknewd("", "Normal", false, []);
      //     // }

      //     const filterData = localStorage.getItem("selectedFilterData");

      //     if (filterData) {
    

      //       const parsedData = JSON.parse(filterData);

      //       const series = parsedData
      //         .filter((item) => item.type === "series" && item.checked)
      //         .map((item) => item.name);

      //       const chipset = parsedData
      //         .filter((item) => item.type === "chipset" && item.checked)
      //         .map((item) => item.name);

      //     

      //       if (chipset.length > 0) {
      //         SearchFilterChecknewd("", "Normal", true, chipset, []);
      //       } else {
      //         SearchFilterChecknewd("", "Normal", true, series, []);
      //       }
      //     } else {
      //       SearchFilterChecknewd("", "Normal", false, []);
      //     }

      //     let chipsetKeyArray = [];
      //     let conditionKeyArray = [];
      //     let brandKeyArray = [];
      //     let modalKeyArray = [];
      //     let seriesKeyArray = [];
      //     setCount(1);
      //     setres(resp?.data.data);
      //     resp?.data.data?.filterKey?.chipset?.map((val, i) => {
      //       let key = { [val]: false };
      //       chipsetKeyArray.push(key);
      //     });
      //     setchipsetKey([...chipsetKeyArray]);
      //     resp?.data.data?.filterKey?.condition?.map((val) => {
      //       if (val == 1) {
      //         let key = { ["New-Retail"]: false };
      //         conditionKeyArray.push(key);
      //       } else if (val == 2) {
      //         let key = { ["New-2nd Hand"]: false };
      //         conditionKeyArray.push(key);
      //       } else {
      //         let key = { ["Used"]: false };
      //         conditionKeyArray.push(key);
      //       }
      //     });
      //     setconditionKey([...conditionKeyArray]);
      //     resp?.data.data?.filterKey?.series?.map((val) => {
      //       let key = { [val]: false };
      //       seriesKeyArray.push(key);
      //     });
      //     setseriesKey([...seriesKeyArray]);
      //     resp?.data.data?.filterKey?.brands?.map((val) => {
      //       let key = { [val]: false };

      //       brandKeyArray.push(key);
      //     });
      //     setbrandKey([...brandKeyArray]);
      //     resp?.data.data?.filterKey?.model?.map((val) => {
      //       let key = { [val]: false };
      //       modalKeyArray.push(key);
      //     });
      //     setmodalKey([...modalKeyArray]);
      //     if (prams.name == undefined) {
      //       setCategory(resp?.data?.data?.productList1);
      //     } else {
      //       setCategory([]);
      //     }


      

      //     setFilterList(resp?.data?.data?.filterKey);
      //   })
      //   .catch((err) => {
   
      //     setShowConditionLoader(false);
      //   });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleFevButton = (data, id, i) => {
    if (data) {
      if (localStorage.getItem("tokenuser")) {
        HandleAddtoFevreat(
          id,
          JSON.parse(localStorage.getItem("tokenuser")).id,
          i
        );
      } else if (localStorage.getItem("admin")) {
        HandleAddtoFevreat(id, JSON.parse(localStorage.getItem("admin")).id, i);
      } else {
        handleShow();
      }
    } else {
      if (localStorage.getItem("tokenuser")) {
        setToken(JSON.parse(localStorage.getItem("tokenuser")));
        HandleRemovetoFevreat(
          id,
          JSON.parse(localStorage.getItem("tokenuser")).id,
          i
        );
      } else if (localStorage.getItem("admin")) {
        setToken(JSON.parse(localStorage.getItem("admin")));
        HandleRemovetoFevreat(
          id,
          JSON.parse(localStorage.getItem("admin")).id,
          i
        );
      } else {
        handleShow();
      }
    }
  };

  //Add in the Fav. List
  //data used for the fav is checked or not
  //i used for the index
  //id used for the productId
  const HandleAddtoFevreat = (id, userId, i) => {
    ExportApi.AddtoFevreat(id, userId)
      .then((resp) => {
        category[i].isfav = true;
        setCategory([...category]);
        // GetData1(prams.id, JSON.parse(localStorage.getItem("admin")).id);

        // setCategory(resp.data.data);
      })
      .catch((err) => console.log(err));
  };

  //Remove from the Fav.
  //id used for the productId
  //i used for the index
  const HandleRemovetoFevreat = (id, userId, i) => {
    ExportApi.RemovetoFevreat(id, userId)
      .then((resp) => {
        category[i].isfav = false;
        setCategory([...category]);
      })
      .catch((err) => console.log(err));
  };

  // Search Filter Api
  const SearchFilterCheck = (search, sort, isChecked, page) => {

    setShowConditionLoader(true);
    setSortingLoader(true);

    ExportApi.GetAllcategorySerch(
      search,
      sort,
      condition,
      chipset,
      brand,
      series,
      model,
      Price,
      customFilterPrice,
      isChecked,
      page
    )
      .then((resp) => {
        setShowConditionLoader(false);
        setSortingLoader(false);
        let data = resp?.data?.Data;

        setTotalPages(resp?.data?.totalPages);
        if (
          resp?.data?.Message ==
          "Negative values are not allowed for min and max."
        ) {
          toast.error("Negative values are not allowed for min and max.");
        }
        setSearchLoader(resp?.data?.Loader);
        if (resp?.data?.Message == "Data Fetched Successfully") {
          // setTimeout(() => {
          setCategory([...data]);

          setShowConditionLoader(false);
        } else {
          setCategory([]);

          setShowConditionLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const SearchFilterpage = (page) => {
   
    setShowConditionLoader(true);
    setSortingLoader(true);

    ExportApi.GetAllcategorySerch(
      search,
      sort,
      condition,
      chipset,
      brand,
      series,
      model,
      Price,
      customFilterPrice,
      isChecked,
      page
    )
      .then((resp) => {
        setShowConditionLoader(false);
        setSortingLoader(false);
        let data = resp?.data?.Data;
  
        setTotalPages(resp?.data?.totalPages);
        if (
          resp?.data?.Message ==
          "Negative values are not allowed for min and max."
        ) {
          toast.error("Negative values are not allowed for min and max.");
        }
        setSearchLoader(resp?.data?.Loader);
        if (resp?.data?.Message == "Data Fetched Successfully") {
          // setTimeout(() => {
          setCategory([...data]);

          setShowConditionLoader(false);
        } else {
          setCategory([]);

          setShowConditionLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // Start clear All Filter

  const clearAllFilters = async () => {

    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedFilterData");
    setPage(1);
    setShowConditionLoader(true);
    setIsChecked(false);
    setPageOffset(0);
    // setPage(0);
    setCustomMinPrice("");
    setCustomMaxPrice("");
    setCheckBoxData(false);
    setBrandShow(false);
    setSeriesShow(false);
    setModelShow(false);

    document.getElementById("Min").value = "";
    document.getElementById("Max").value = "";
    if (!prams.name) {

      setShowConditionLoader(true);
      // setPage(1);
      setmodel([]);
      setseries([]);
      setbrand([]);
      setchipset([]);
      setcondition([]);
      setcondition1([]);
      setSorting("Normal");
      setCustomFilterPrice([]);
      setPrice([]);
      setCheckBoxData(false);

      //SearchFilterChecknewd("", "Normal", false,[]);
      //localStorage.removeItem("selectedData");
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

      if (localStorage.getItem("tokenuser")) {
        GetData1(prams.id, JSON.parse(localStorage.getItem("tokenuser")).id);
        setShowConditionLoader(false);
      } else if (localStorage.getItem("admin")) {
        GetData1(prams.id, JSON.parse(localStorage.getItem("admin")).id);
        setShowConditionLoader(false);
      } else {
        GetData(prams.id);
        setShowConditionLoader(false);
      }
    } else {

      setShowConditionLoader(false);
      navigate("/categories/63ff36fb23ad0386e761641f");
    }
    setShowConditionLoader(false);
  };

  const newy = JSON.parse(localStorage.getItem("newkey"));


  useEffect(() => {
    if (newy == true) {
      clearAllFilters();
    }
  }, [newy]);

  useEffect(() => {
    if (mainloader === false) {
      const data = JSON.parse(localStorage.getItem("selectedData"));
      if (selectedData) {
        if (selectedData.type === "chipset") {
          const previousChipsetIndex =
            selectedData?.index !== undefined
              ? selectedData?.index
              : selectedData?.name === "AMD"
              ? 0
              : 1;
          ChipsetFilter(
            { target: { name: selectedData?.name, checked: false } },
            previousChipsetIndex
          );
        } else if (selectedData.type === "brand") {
          BrandtFilter(
            { target: { name: selectedData?.name, checked: false } },
            selectedData?.index
          );
          if (selectedData?.index >= 6) {
            setBrandShow(true);
          }
        } else if (selectedData.type === "series") {
          seriesFilter(
            { target: { name: selectedData?.name, checked: false } },
            selectedData?.index
          );
          if (selectedData?.index >= 6) {
            setSeriesShow(true);
          }
        } else if (selectedData.type === "model") {
          modalFilter(
            { target: { name: selectedData?.name, checked: false } },
            selectedData?.index
          );
          if (selectedData?.index >= 6) {
            setModelShow(true);
          }
        }
      }
      if (data) {
        if (data.type === "chipset") {
          const chipsetIndex =
            data?.index !== undefined
              ? data?.index
              : data?.name === "AMD"
              ? 0
              : 1;
          if (!chipsetKey[chipsetIndex]) chipsetKey[chipsetIndex] = {};
          ChipsetFilter(
            { target: { name: data?.name, checked: data?.checked } },
            chipsetIndex
          );
        } else if (data.type === "brand") {
          BrandtFilter(
            { target: { name: data?.name, checked: data?.checked } },
            data?.index
          );
          if (data?.index >= 6) {
            setBrandShow(true);
          }
        } else if (data.type === "series") {
          seriesFilter(
            { target: { name: data?.name, checked: data?.checked } },
            data?.index
          );
          if (data?.index >= 6) {
            setSeriesShow(true);
          }
        } else if (data.type === "model") {
          modalFilter(
            { target: { name: data?.name, checked: data?.checked } },
            data?.index
          );
          if (data?.index >= 6) {
            setModelShow(true);
          }
        }
      }
      setSelectedData(data);
    }
  }, [localStorage.getItem("selectedData"), mainloader]);



  useEffect(() => {
    if (mainloader === false) {
      const storedData = JSON.parse(localStorage.getItem("selectedFilterData"));
  

      if (Array.isArray(storedData)) {
        storedData.forEach((data) => {
          if (data.type === "chipset") {
            const chipsetIndex =
              data.index !== undefined
                ? data.index
                : data.name === "AMD"
                ? 0
                : 1;
            if (!chipsetKey[chipsetIndex]) chipsetKey[chipsetIndex] = {};
            ChipsetFilter(
              { target: { name: data.name, checked: data.checked } },
              chipsetIndex
            );
          } else if (data?.type === "brand") {
            BrandtFilter(
              { target: { name: data.name, checked: data.checked } },
              data.index
            );
            if (data.index >= 6) {
              setBrandShow(true);
            }
          } else if (data?.type === "series") {
            seriesFilter(
              { target: { name: data.name, checked: data.checked } },
              data.index
            );
            if (data.index >= 6) {
              setSeriesShow(true);
            }
          } else if (data?.type === "model") {
            modalFilter(
              { target: { name: data.name, checked: data.checked } },
              data.index
            );
            if (data?.index >= 6) {
              setModelShow(true);
            }
          } else if (data?.type === "price") {
            priceFilter(
              {
                target: { checked: data.checked, min: data.min, max: data.max },
              },
              data.index
            );
          } else if (data?.type === "condition") {
            conditionKeyFilter(
              {
                target: {
                  name: data.name,
                  checked: data.checked,
                  min: data.min,
                },
              },
              data.index
            );
          }
        });
      }

      if (selectedfilterdata) {
    
        if (selectedfilterdata.type === "chipset") {
          const previousChipsetIndex =
            selectedfilterdata.index !== undefined
              ? selectedfilterdata.index
              : selectedfilterdata.name === "AMD"
              ? 0
              : 1;
          ChipsetFilter(
            { target: { name: selectedfilterdata.name, checked: false } },
            previousChipsetIndex
          );
        } else if (selectedfilterdata.type === "brand") {
          BrandtFilter(
            { target: { name: selectedfilterdata.name, checked: false } },
            selectedfilterdata.index
          );
          if (selectedfilterdata.index >= 6) {
            setBrandShow(true);
          }
        } else if (selectedfilterdata.type === "series") {
          seriesFilter(
            { target: { name: selectedfilterdata.name, checked: false } },
            selectedfilterdata.index
          );
          if (selectedfilterdata.index >= 6) {
            setSeriesShow(true);
          }
        } else if (selectedfilterdata.type === "model") {
          modalFilter(
            { target: { name: selectedfilterdata.name, checked: false } },
            selectedfilterdata.index
          );
          if (selectedfilterdata.index >= 6) {
            setModelShow(true);
          }
        } else if (selectedfilterdata.type === "price") {
          priceFilter(
            {
              target: {
                checked: false,
                min: selectedfilterdata.min,
                max: selectedfilterdata.max,
              },
            },
            selectedfilterdata.index
          );
        } else if (selectedfilterdata.type === "condition") {
          conditionKeyFilter(
            {
              target: {
                name: selectedfilterdata.name,
                checked: false,
                min: selectedfilterdata.min,
              },
            },
            selectedfilterdata.index
          );
        }
      }

      setSelectedFilterData(storedData);
    }
  }, [mainloader]);

  // const conditionKeyFilter = (e, i) => {
  //   setShowConditionLoader(true);
  //   //setPage(0);
  //   setPageOffset(0);
  //   setCount(0);
  //   const { name, checked, min } = e.target;
  //   let number = parseInt(min);
  //   let index = condition.indexOf(number);
  //   let index1 = condition1.indexOf(name);
  //   if (checked || index === -1 || index1 === -1) {
  //     condition.push(number);
  //     condition1.push(name);
  //     conditionKey[i][name] = checked;
  //     setcondition([...condition]);
  //     setcondition1([...condition1]);
  //     SearchFilterCheck(search, sort);
  //     setShowConditionLoader(false);
  //     setCount(1);
  //   } else {
  //     conditionKey[i][name] = checked;
  //     condition.splice(index, 1);
  //     setcondition([...condition]);
  //     condition1.splice(index1, 1);
  //     setcondition1([...condition1]);
  //     SearchFilterCheck(search, sort);
  //     setShowConditionLoader(false);
  //     setCount(1);
  //   }
  // };

  const conditionKeyFilter = (e, i) => {
    setShowConditionLoader(true);
    setPageOffset(0);
    setCount(0);

    const { name, checked, min } = e.target;
    let number = parseInt(min);
    let index = condition.indexOf(number);
    let index1 = condition1.indexOf(name);
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    const data = {
      type: "condition",
      name: name,
      min: number,
      checked: checked,
      index: i,
    };

    if (checked || index === -1 || index1 === -1) {
      condition.push(number);
      condition1.push(name);
      conditionKey[i][name] = checked;

      // Check if the data already exists in localStorage
      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.name === data.name &&
          item.min === data.min &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setcondition([...condition]);
      setcondition1([...condition1]);
      SearchFilterCheck(search, sort);
      setShowConditionLoader(false);
      setCount(1);
    } else {
      conditionKey[i][name] = checked;
      condition.splice(index, 1);
      condition1.splice(index1, 1);

      // Remove the item from storedData when unchecked
      storedData = storedData.filter(
        (item) =>
          !(
            item.type === data.type &&
            item.name === name &&
            item.min === number &&
            item.index === i
          )
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));

      setcondition([...condition]);
      setcondition1([...condition1]);
      SearchFilterCheck(search, sort);
      setShowConditionLoader(false);
      setCount(1);
    }
  };

  // End condition Filter

  const conditionKeyFilterClose = (i, name) => {
    setPageOffset(0);

    localStorage.removeItem("selectedData");
    setShowConditionLoader(true);
    let index = -1;
    for (let i = 0; i < conditionKey.length; i++) {
      if (Object.keys(conditionKey[i])[0] == name) {
        index = i;
      }
    }
    conditionKey[index][name] = false;
    condition1.splice(i, 1);
    condition.splice(i, 1);
    setcondition1([...condition1]);
    setcondition([...condition]);
    SearchFilterCheck(search, sort);
    setShowConditionLoader(false);
  };

  // const ChipsetFilter = (e, i) => {
  //   setShowChipsetLoader(true);
  //   setPageOffset(0);
  //   const { name, checked } = e.target;
  //   let index = chipset.indexOf(name);
  //   if (checked || index === -1) {
  //     chipset.push(name);
  //     if (!chipsetKey[i]) chipsetKey[i] = {};
  //     chipsetKey[i][name] = checked;
  //     setchipset([...chipset]);
  //     SearchFilterCheck(search, sort);
  //     setShowChipsetLoader(false);
  //   } else {
  //     if (!chipsetKey[i]) chipsetKey[i] = {};
  //     chipsetKey[i][name] = checked;
  //     chipset.splice(index, 1);
  //     setchipset([...chipset]);
  //     SearchFilterCheck(search, sort);
  //     setShowChipsetLoader(false);
  //   }
  // };

  const ChipsetFilter = (e, i) => {
    setShowChipsetLoader(true);
    setPageOffset(0);
    const { name, checked } = e.target;
    let index = chipset.indexOf(name);
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    if (checked || index === -1) {
      chipset.push(name);
      if (!chipsetKey[i]) chipsetKey[i] = {};
      chipsetKey[i][name] = checked;

      const data = {
        type: "chipset",
        name: name,
        checked: checked,
        index: i,
      };

      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.name === data.name &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setchipset([...chipset]);
      SearchFilterCheck(search, sort);
      setShowChipsetLoader(false);
    } else {
      if (!chipsetKey[i]) chipsetKey[i] = {};
      chipsetKey[i][name] = checked;
      chipset.splice(index, 1);

      storedData = storedData.filter(
        (item) =>
          !(item.type === "chipset" && item.name === name && item.index === i)
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      setchipset([...chipset]);
      SearchFilterCheck(search, sort);
      setShowChipsetLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [category]);

  const ChipsetFilterClose = (id, name) => {
    setPageOffset(0);

    localStorage.removeItem("selectedData");
    setShowChipsetLoader(true);
    let index = -1;
    for (let i = 0; i < chipsetKey.length; i++) {
      if (Object.keys(chipsetKey[i])[0] == name) {
        index = i;
      }
    }

    chipsetKey[index][name] = false;
    chipset.splice(id, 1);
    setchipset([...chipset]);
    SearchFilterCheck(search, sort);
    setShowChipsetLoader(false);
  };

  // const BrandtFilter = (e, i) => {
  //   setShowBrandLoader(true);
  //   setPageOffset(0);
  //   const { name, checked } = e.target;
  //   if (!brandKey[i]) {
  //     brandKey[i] = {};
  //   }

  //   let index = brand.indexOf(name);
  //   if (checked) {
  //     brand.push(name);
  //     brandKey[i][name] = checked;
  //     setbrand([...brand]);
  //     SearchFilterCheck(search, sort);
  //     setShowBrandLoader(false);
  //   } else {
  //     brandKey[i][name] = checked;
  //     brand.splice(index, 1);
  //     setbrand([...brand]);
  //     SearchFilterCheck(search, sort);
  //     setShowBrandLoader(false);
  //   }
  // };

  const BrandtFilter = (e, i) => {
    setShowBrandLoader(true);
    setPageOffset(0);
    const { name, checked } = e.target;

    if (!brandKey[i]) {
      brandKey[i] = {};
    }

    let index = brand.indexOf(name);
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    if (checked) {
      brand.push(name);
      brandKey[i][name] = checked;

      const data = {
        type: "brand",
        name: name,
        checked: checked,
        index: i,
      };

      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.name === data.name &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setbrand([...brand]);
      SearchFilterCheck(search, sort);
      setShowBrandLoader(false);
    } else {
      brandKey[i][name] = checked;
      brand.splice(index, 1);

      storedData = storedData.filter(
        (item) =>
          !(item.type === "brand" && item.name === name && item.index === i)
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      setbrand([...brand]);
      SearchFilterCheck(search, sort);
      setShowBrandLoader(false);
    }
  };

  const brandtFilterClose = (i, name) => {
    setPageOffset(0);

    localStorage.removeItem("selectedData");
    setShowBrandLoader(true);
    let index = -1;
    for (let i = 0; i < brandKey.length; i++) {
      if (Object.keys(brandKey[i])[0] == name) {
        index = i;
      }
    }
    brandKey[index][name] = false;
    brand.splice(i, 1);
    setbrand([...brand]);
    SearchFilterCheck(search, sort);
    setShowBrandLoader(false);
  };

  // useEffect(() => {
  //   // Fetch saved series from localStorage
  //   const savedSeries = localStorage.getItem("selectedData");
  //   if (savedSeries) {
  //     const parsedSeries = JSON.parse(savedSeries);
  //     setseries(parsedSeries);

  //     // Initialize seriesKey based on saved series
  //     const newSeriesKey = FilterList?.series?.reduce((acc, val, i) => {
  //       acc[i] = {};
  //       if (parsedSeries.includes(val)) {
  //         acc[i][val] = true;
  //       }
  //       return acc;
  //     }, {});

  //     setseriesKey(newSeriesKey || {});
  //   }
  // }, []);

  const seriesFilter = (e, i) => {
    setShowSeriesLoader(true);
    setPageOffset(0);
    const { name, checked } = e.target;


    if (!seriesKey[i]) {
      seriesKey[i] = {};
    }

    let index = series.indexOf(name);
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    // Ensure storedData is an array
    if (!Array.isArray(storedData)) {
      storedData = [];
    }

    const data = {
      type: "series",
      name: name,
      checked: checked,
      index: i,
    };

    if (checked) {
      series.push(name);
      seriesKey[i][name] = checked;

      // Check if the data already exists in localStorage
      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.name === data.name &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setseries([...series]);
      SearchFilterCheck(search, sort);
      setShowSeriesLoader(false);
    } else {
      seriesKey[i][name] = checked;
      series.splice(index, 1);

      // Remove the item from storedData when unchecked
      storedData = storedData.filter(
        (item) =>
          !(
            item.type === data.type &&
            item.name === data.name &&
            item.index === data.index
          )
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      setseries([...series]);
      SearchFilterCheck(search, sort);
      setShowSeriesLoader(false);
    }
  };

  const seriesFilterCsole = (i, name) => {
    setPageOffset(0);

    localStorage.removeItem("selectedData");
    setShowSeriesLoader(true);
    let index = -1;
    for (let i = 0; i < seriesKey.length; i++) {
      if (Object.keys(seriesKey[i])[0] == name) {
        index = i;
      }
    }
    seriesKey[index][name] = false;
    series.splice(i, 1);
    setseries([...series]);
    SearchFilterCheck(search, sort);
    setShowSeriesLoader(false);
  };

  // const modalFilter = (e, i) => {
  //   setShowModalLoader(true);

  //   setPageOffset(0);
  //   const { name, checked } = e.target;
  //   if (!modalKey[i]) {
  //     modalKey[i] = {};
  //   }
  //   let index = chipset.indexOf(name);
  //   if (checked) {
  //     model.push(name);
  //     modalKey[i][name] = checked;
  //     setmodel([...model]);
  //     SearchFilterCheck(search, sort);
  //     setShowModalLoader(false);
  //   } else {
  //     modalKey[i][name] = checked;
  //     model.splice(index, 1);
  //     setmodel([...model]);
  //     SearchFilterCheck(search, sort);
  //     setShowModalLoader(false);
  //   }
  // };

  const modalFilter = (e, i) => {
    setShowModalLoader(true);
    setPageOffset(0);
    const { name, checked } = e.target;

    if (!modalKey[i]) {
      modalKey[i] = {};
    }

    let index = model.indexOf(name);
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    // Ensure storedData is an array
    if (!Array.isArray(storedData)) {
      storedData = [];
    }

    const data = {
      type: "model",
      name: name,
      checked: checked,
      index: i,
    };

    if (checked) {
      model.push(name);
      modalKey[i][name] = checked;

      // Check if the data already exists in localStorage
      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.name === data.name &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setmodel([...model]);
      SearchFilterCheck(search, sort);
      setShowModalLoader(false);
    } else {
      modalKey[i][name] = checked;
      model.splice(index, 1);

      // Remove the item from storedData when unchecked
      storedData = storedData.filter(
        (item) =>
          !(
            item.type === data.type &&
            item.name === data.name &&
            item.index === data.index
          )
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      setmodel([...model]);
      SearchFilterCheck(search, sort);
      setShowModalLoader(false);
    }
  };

  const modalFilterCsole = (i, name) => {
    setPageOffset(0);
    localStorage.removeItem("selectedData");
    setShowModalLoader(true);
    let index = -1;
    for (let i = 0; i < modalKey.length; i++) {
      if (Object.keys(modalKey[i])[0] == name) {
        index = i;
      }
    }
    modalKey[index][name] = false;
    model.splice(i, 1);
    setmodel([...model]);
    SearchFilterCheck(search, sort);
    setShowModalLoader(false);
  };

  // const priceFilter = (e, i) => {
  //   setShowPriceLoader(true);
  //   setPageOffset(0);
  //   const { name, checked, min, max } = e.target;
  //   let total = { min: min, max: max };
  //   if (checked) {
  //     Price.push(total);
  //     PriceKey[i] = checked;
  //     setPrice([...Price]);
  //     SearchFilterCheck(search, sort);

  //     setShowPriceLoader(false);
  //   } else {
  //     let result = Price.filter((item) => {
  //       return item.min != min;
  //     });
  //     setPrice(result);
  //     setShowConditionLoader(true);
  //     ExportApi.GetAllcategorySerch(
  //       search,
  //       sort,
  //       condition,
  //       chipset,
  //       brand,
  //       series,
  //       model,
  //       result
  //     )
  //       .then((resp) => {
  //         setShowConditionLoader(false);
  //         setCategory(resp.data.Data);
  //         setShowPriceLoader(false);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
 
  //         setShowConditionLoader(false);
  //       });
  //   }
  // };

  const priceFilter = (e, i) => {
    setShowPriceLoader(true);
    setPageOffset(0);
    const { checked, min, max } = e.target;
    let total = { min: min, max: max };

    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];

    // Ensure storedData is an array
    if (!Array.isArray(storedData)) {
      storedData = [];
    }

    const data = {
      type: "price",
      checked: checked,
      min: min,
      max: max,
      index: i,
    };

    if (checked) {
      Price.push(total);
      PriceKey[i] = checked;

      // Check if the data already exists in localStorage
      const isDuplicate = storedData.some(
        (item) =>
          item.type === data.type &&
          item.min === data.min &&
          item.max === data.max &&
          item.index === data.index
      );

      if (!isDuplicate) {
        storedData.push(data);
        localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      }

      setPrice([...Price]);
      SearchFilterCheck(search, sort);
      setShowPriceLoader(false);
    } else {
      let result = Price.filter((item) => {
        return item.min !== min || item.max !== max;
      });

      PriceKey[i] = checked;

      // Remove the item from storedData when unchecked
      storedData = storedData.filter(
        (item) =>
          !(
            item.type === data.type &&
            item.min === data.min &&
            item.max === data.max &&
            item.index === data.index
          )
      );

      localStorage.setItem("selectedFilterData", JSON.stringify(storedData));
      setPrice(result);
      setShowConditionLoader(true);
 
      ExportApi.GetAllcategorySerch(
        search,
        sort,
        condition,
        chipset,
        brand,
        series,
        model,
        result
      )
        .then((resp) => {
          setShowConditionLoader(false);
          setCategory(resp?.data?.Data);
          setShowPriceLoader(false);
          setLoading(false);
        })
        .catch((err) => {
 
          setShowConditionLoader(false);
        });
    }
  };

  const priceFilterClose = (i, name) => {
    setPageOffset(0);
    localStorage.removeItem("selectedData");
    setShowPriceLoader(true);

    if (name?.min && name?.max) {
      document.getElementById(`$${name.min} - $${name.max}`).checked = false;
    } else if (name?.min && !name?.max) {
      let checkbox = document.getElementById(`$${name.min}+`); // Updated ID format
      if (checkbox) {
        checkbox.checked = false;
      }
    }

    let result = Price.filter((item) => {
      return item.min != name.min;
    });

    setPrice([...result]);
    setShowConditionLoader(true);


    ExportApi.GetAllcategorySerch(
      search,
      sort,
      condition,
      chipset,
      brand,
      series,
      model,
      result
    )
      .then((resp) => {
        setShowConditionLoader(false);
        setCategory(resp?.data?.Data);
        setShowPriceLoader(false);
        setLoading(false);
      })
      .catch((err) => {
    
        setShowConditionLoader(false);
      });
  };

  const handleCompare = (e, val, i) => {
    if (e) {
      val.isCompare = true;
      category[i].isCompare = true;
      setCategory([...category]);
      if (Compare.length <= 10) {
        Compare.push(val);
        setCompare([...Compare]);
        setModalShow(e);
        localStorage.setItem("Compare", JSON.stringify(Compare));
      }
    } else {
      let data;
      Compare.filter((vall, i) => {
        if (vall._id == val._id) {
          data = i;
        }
      });
      val.isCompare = false;
      category[i].isCompare = false;
      setCategory([...category]);
      Compare.splice(data, 1);
      if (Compare?.length > 0) {
        setCompare([...Compare]);
        localStorage.setItem("Compare", JSON.stringify(Compare));
      } else {
        HandleCompareClear();
      }
    }
  };

  //Show More Chipset
  const handlechipsetshowmore = (data) => {
    if (data == "More") {
      setChipsetShow(true);
    } else {
      setChipsetShow(false);
    }
  };

  //Show More Brand
  const handlebrandshowmore = (data) => {
    if (data == "More") {
      setBrandShow(true);
    } else {
      setBrandShow(false);
    }
  };

  //Show More Series
  const handleSeriesshowmore = (data) => {
    if (data == "More") {
      setSeriesShow(true);
    } else {
      setSeriesShow(false);
    }
  };

  //Show More Model
  const handlemodelshowmore = (data) => {
    if (data == "More") {
      setModelShow(true);
    } else {
      setModelShow(false);
    }
    // GetDataFilterList();
  };

  const handleSort = (e) => {
    setPage(1);
    setPageOffset(1);
    setSorting(e.target.innerText);
    setSort(e.target.getAttribute("value"));
    SearchFilterCheck(search, e.target.getAttribute("value"));
    const selectedValue = e.target.getAttribute("value");
    localStorage.setItem("selectedSortValue", selectedValue);
  };

  // Function to remove all items present in the Compare PopUp
  const HandleCompareClear = () => {
    // Remove comparison and favorite product data from local storage.
    localStorage.removeItem("Compare");
    localStorage.removeItem("FavouriteProducts");

    // Clear the Compare state and close the modal.
    setCompare([]);
    setModalShow(false);

    // setSort(e.target.getAttribute("value"));
    // SearchFilterCheck(search, e.target.getAttribute("value"));
    // Depending on the user's authentication status, retrieve data accordingly.
    if (localStorage.getItem("tokenuser")) {
      GetData1(prams.id, JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (localStorage.getItem("admin")) {
      GetData1(prams.id, JSON.parse(localStorage.getItem("admin")).id);
    } else {
      GetData(prams.id);
    }
  };

  // Function to remove a single product from the Compare Popup
  const handleCloseButton = (productId) => {
    // Filter out the product to be removed from the Compare state.
    let result = Compare.filter((item) => item._id != productId);

    // Update the category data to reflect that the removed product is no longer in comparison.
    let data = category;
    for (let i = 0; i < data.length; i++) {
      let element = data[i];
      if (element?._id.includes(productId)) {
        data[i].isCompare = false;
      }
    }

    // Check if there is only one product left in comparison, and close the modal if so.
    let data2 = JSON.parse(localStorage.getItem("Compare"));
    if (data2?.length == 1) {
      setModalShow(false);
    }

    // Update the state and local storage with the modified comparison data.
    setCategory([...data]);
    setCompare(result);
    localStorage.setItem("Compare", JSON.stringify(result));
  };

  useEffect(() => {
    if (Price.length > 0) {
      SearchFilterCheckn(search, sort, isChecked, page);
    }
  }, [Price]);

  const handleCustomPrice = (e, i, data) => {
    const filteredValue = e.target.value.replace(/\D/g, "");

    if (data === "checkBox") {
      if (!customMinPrice || !customMaxPrice) {
        alert("Please enter both minimum and maximum values.");
        e.target.checked = false;
        return;
      }

      const min = customMinPrice || "0";
      const max = customMaxPrice || "0";

      if (e.target.checked) {
        setCheckBoxData(true);
        setPrice([{ min, max }]); // Triggers useEffect
        setPriceKey({ [i]: true });
        setShowPriceLoader(false);
      } else {
        setCheckBoxData(false);
        clearAllFilters();
      }
    } else if (data === "Min") {
      setCustomMinPrice(filteredValue);
    } else if (data === "Max") {
      setCustomMaxPrice(filteredValue);
    }
  };

  const SearchFilterCheckn = (search, sort, isChecked, page) => {


    setShowConditionLoader(true);
    setSortingLoader(true);
  

    ExportApi.GetAllcategorySerch(
      search,
      sort,
      condition,
      chipset,
      brand,
      series,
      model,
      Price,
      customFilterPrice,
      isChecked,
      page
    )
      .then((resp) => {
        setShowConditionLoader(false);
        setSortingLoader(false);
        let data = resp?.data?.Data;
  
        setTotalPages(resp?.data?.totalPages);

        if (
          resp?.data?.Message ===
          "Negative values are not allowed for min and max."
        ) {
          toast.error("Negative values are not allowed for min and max.");
        }

        setSearchLoader(resp?.data?.Loader);
        if (resp?.data?.Message === "Data Fetched Successfully") {
          setCategory([...data]);
          setShowConditionLoader(false);
        } else {
          setCategory([]);
          setShowConditionLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // Function to handle search filter
  const handleSearchFilter = () => {
    setPageOffset(0);
    localStorage.removeItem("selectedData");
    navigate(`/categories/${prams.id}`);
    window.location.reload();
  };

  // Function to retrieve single user data based on UserId
  const handleSingleUserData = (UserId) => {
    ExportApi.getSingleUserData(UserId)
      .then((resp) => {
        if (resp.data.message == "user not found") {
          // If the response indicates that the user was not found, trigger a "Loginout" event.
          window.dispatchEvent(new Event("Loginout"));
        } else {
          // Handle user data if the user was found.
          // You may want to add logic here to process the user data as needed.
        }
      })
      .catch((err) => console.log(err));
  };

  // Event listener for the "Loginout" event
  window.addEventListener("Loginout", () => {
    localStorage.clear();
    navigate("/");
  });

  // Start of the first useEffect block
  useEffect(() => {
    // Event listener for the "Login" event
    window.addEventListener("Login", () => {
      if (localStorage.getItem("tokenuser")) {
        // If a user is logged in as "tokenuser", retrieve data and user information.
        GetData1(prams.id, JSON.parse(localStorage.getItem("tokenuser")).id);
        handleSingleUserData(
          prams.id,
          JSON.parse(localStorage.getItem("tokenuser")).id
        );
      } else if (localStorage.getItem("admin")) {
        // If an admin is logged in, retrieve data and admin information.
        GetData1(prams.id, JSON.parse(localStorage.getItem("admin")).id);
        handleSingleUserData(
          prams.id,
          JSON.parse(localStorage.getItem("admin")).id
        );
      }
      handleClose();
    });

    // Event listener for the "Loginout" event
    window.addEventListener("Loginout", () => {
      // Retrieve data when a user logs out.
      GetData(prams.id);
     
    });
  }, []); // This useEffect runs only once since the dependency array is empty.

  // Search Filter useEffect
  useEffect(() => {
    if (prams?.name) {
      
      setSearch(prams?.name);
      SearchFilterCheck(prams?.name, sort);
    }
  }, []);

  // Get data for comparison from local storage
  useEffect(() => {
    if (localStorage.getItem("Compare")) {
      // If there are items in local storage for comparison, set the Compare state.
      setCompare(JSON.parse(localStorage.getItem("Compare")));
    }
  }, []);

  // Another useEffect block for data retrieval

  useEffect(() => {
    // SearchFilterChecknewd("", "Normal", false, []);
    //setMainLoader(true);
    if (localStorage.getItem("tokenuser") && localStorage.getItem("Compare")) {
     

      // If a user is logged in as "tokenuser", retrieve data specific to that user.
      GetData1(prams.id, JSON.parse(localStorage.getItem("tokenuser")).id);
    } else if (
      localStorage.getItem("admin") &&
      localStorage.getItem("Compare")
    ) {
 
      // If an admin is logged in, retrieve data specific to that admin.
      GetData1(prams.id, JSON.parse(localStorage.getItem("admin")).id);
    } else {
      //setMainLoader(false);

      GetData(prams.id);
    }
    // GetDataFilterList(prams.id);
  }, []);

  // const handleCheckboxChange = () => {
  //   const updatedIsChecked = !isChecked;
  //   setIsChecked(updatedIsChecked);

  //   SearchFilterCheck(search, sort, updatedIsChecked);
  // };

  useEffect(() => {
    // Initialize checkbox state from localStorage
    const storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];
    const isAvailableNowChecked = storedData.some(
      (item) =>
        item.type === "availability" &&
        item.name === "Available Now" &&
        item.checked
    );
    setIsChecked(isAvailableNowChecked);
  }, []);

  const handleCheckboxChange = (e) => {
    const updatedIsChecked = e.target.checked;
    setIsChecked(updatedIsChecked);

    const filterData = {
      type: "availability",
      name: "Available Now",
      checked: updatedIsChecked,
    };

    // Get the current filter data from localStorage
    let storedData =
      JSON.parse(localStorage.getItem("selectedFilterData")) || [];
    if (!Array.isArray(storedData)) {
      storedData = [];
    }

    if (updatedIsChecked) {
      // Add the new filter data if it's checked
      if (
        !storedData.some(
          (item) =>
            item.type === filterData.type && item.name === filterData.name
        )
      ) {
        storedData.push(filterData);
      }
    } else {
      // Remove the filter data if it's unchecked
      storedData = storedData.filter(
        (item) =>
          !(item.type === filterData.type && item.name === filterData.name)
      );
    }

    // Update localStorage
    localStorage.setItem("selectedFilterData", JSON.stringify(storedData));

    // Trigger any other functions if necessary
    SearchFilterCheck(search, sort, updatedIsChecked);
  };

  return (
    <div>
      <Header />
      <Container className="mt-5">
        <Row>
          <Col lg={3}>
            {/* Start for the Showing Filter */}
            <div className="mt-sm-5 mt-3 border mb-sm-5">
              <div className="sidebar-accordian p-3">
                <div>
                  <span>{/* <b>Available </b> */}</span>
                  <Form.Group className="mb-2">
                    <Form.Check
                      type="checkbox"
                      label="Available Now"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </Form.Group>
                </div>

                <div>
                  <span>
                    <b>Condition </b>
                  </span>
                  {FilterList?.condition
                    .filter((item) => item != 1)
                    ?.map((val, i) => {
                      return (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                    
                          <Form.Check
                            type="checkbox"
                            checked={
                              conditionKey[i][
                                // val == 1
                                //   ? "New-Retail"
                                //   :
                                val == 2 ? "New" : "Used"
                              ]
                            }
                            onChange={(e) => conditionKeyFilter(e, i)}
                            name={
                              // val == 1
                              //   ? "New-Retail"
                              //   :
                              val == 2 ? "New" : "Used"
                            }
                            min={val}
                            label={
                              // val == 1
                              //   ? "New-Retail"
                              //   :
                              val == 2 ? "New" : "Used"
                            }
                          />
                        </Form.Group>
                      );
                    })}
                  {conditionKey?.length > 6 ? (
                    <Button className="bg-none mb-3 border-0 p-0 text-dark">
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>{" "}
                      Show More
                    </Button>
                  ) : null}
                </div>

                <div>
                  <span>
                    <b>Chipset</b>
                  </span>
                  {chipsetShow
                    ? FilterList?.chipset?.map((val, i) => {
                        return (
                          <>
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                              key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={chipsetKey[i][val]}
                                onChange={(e) => ChipsetFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          </>
                        );
                      })
                    : FilterList?.chipset?.map((val, i) => {
                        return (
                          <>
                            {i < 6 ? (
                              <Form.Group
                                className="mb-2"
                                controlId={val}
                                key={i}
                              >
                                <Form.Check
                                  type="checkbox"
                                  checked={chipsetKey[i][val]}
                                  onChange={(e) => ChipsetFilter(e, i)}
                                  name={val}
                                  label={val}
                                />
                              </Form.Group>
                            ) : null}
                          </>
                        );
                      })}
                  {chipsetShow ? (
                    FilterList?.chipset?.length > 6 ? (
                      <Button
                        className="bg-none mb-3 border-0 p-0 text-dark"
                        onClick={() => handlechipsetshowmore("Less")}
                      >
                        <span className="d-inline me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-square-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                          </svg>
                        </span>{" "}
                        Show Less
                      </Button>
                    ) : null
                  ) : FilterList?.chipset?.length > 6 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlechipsetshowmore("More")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>{" "}
                      Show More
                    </Button>
                  ) : null}
                </div>

                <div>
                  <span>
                    <b>Brand</b>
                  </span>
                  {brandShow
                    ? FilterList?.brands?.map((val, i) => {
                        return (
                          <>
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                              key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={brandKey[i][val]}
                                onChange={(e) => BrandtFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          </>
                        );
                      })
                    : FilterList?.brands?.map((val, i) => {
                        return (
                          <>
                            {i < 6 ? (
                              <Form.Group
                                className="mb-2"
                                controlId={val}
                                key={i}
                              >
                                <Form.Check
                                  type="checkbox"
                                  checked={brandKey[i][val]}
                                  onChange={(e) => BrandtFilter(e, i)}
                                  name={val}
                                  label={val}
                                />
                              </Form.Group>
                            ) : null}
                          </>
                        );
                      })}
                  {brandShow ? (
                    FilterList?.brands?.length > 6 ? (
                      <Button
                        className="bg-none mb-3 border-0 p-0 text-dark"
                        onClick={() => handlebrandshowmore("Less")}
                      >
                        <span className="d-inline me-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-dash-square-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                          </svg>
                        </span>{" "}
                        Show Less
                      </Button>
                    ) : null
                  ) : FilterList?.brands?.length > 6 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlebrandshowmore("More")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>{" "}
                      Show More
                    </Button>
                  ) : null}
                </div>

                {/* Series */}
                <div>
                  <span>
                    <b>Series</b>
                  </span>

                  {seriesShow ? (
                    <div style={{ maxHeight: "480px", overflowY: "auto" }}>
                      {FilterList?.series?.map((val, i) => {
                        return (
                          <Form.Group className="mb-2" controlId={val} key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={seriesKey[i][val]}
                              onChange={(e) => seriesFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {FilterList?.series?.map((val, i) => {
                        return i < 6 ? (
                          <Form.Group className="mb-2" controlId={val} key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={seriesKey[i][val]}
                              onChange={(e) => seriesFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        ) : null;
                      })}
                    </div>
                  )}
                  {FilterList?.series?.length > 15 && (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => setSeriesShow(!seriesShow)}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className={` ${
                            seriesShow
                              ? "bi bi-dash-square-fill"
                              : "bi bi-plus-square-fill"
                          }`}
                          viewBox="0 0 16 16"
                        >
                          {seriesShow ? (
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                          ) : (
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                          )}
                        </svg>
                      </span>
                      Show {seriesShow ? "Less" : "More"}
                    </Button>
                  )}
                </div>

                {/* Model */}
                <div>
                  <span>
                    <b>Model</b>
                  </span>

                  {modelShow ? (
                    <div style={{ maxHeight: "580px", overflowY: "auto" }}>
                      {FilterList?.model?.map((val, i) => {
                        return (
                          <Form.Group className="mb-2" controlId={val} key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={modalKey[i][val]}
                              onChange={(e) => modalFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      {FilterList?.model?.map((val, i) => {
                        return i < 6 ? (
                          <Form.Group className="mb-2" controlId={val} key={i}>
                            <Form.Check
                              type="checkbox"
                              checked={modalKey[i][val]}
                              onChange={(e) => modalFilter(e, i)}
                              name={val}
                              label={val}
                            />
                          </Form.Group>
                        ) : null;
                      })}
                    </div>
                  )}
                  {FilterList?.model?.length > 15 && (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => setModelShow(!modelShow)}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className={` ${
                            modelShow
                              ? "bi bi-dash-square-fill"
                              : "bi bi-plus-square-fill"
                          }`}
                          viewBox="0 0 16 16"
                        >
                          {modelShow ? (
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                          ) : (
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                          )}
                        </svg>
                      </span>
                      Show {modelShow ? "Less" : "More"}
                    </Button>
                  )}
                </div>

                <div>
                  <span>
                    <b>Price</b>
                  </span>
                  <>
                    <Form.Group className="mb-2">
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          priceFilter(e, 0);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        min="0"
                        max="300"
                        id="$0 - $300"
                        label="$0 to $300"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          priceFilter(e, 1);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        min="300"
                        max="500"
                        id="$300 - $500"
                        label="$300 to $500"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          priceFilter(e, 2);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        min="500"
                        max="700"
                        id="$500 - $700"
                        label="$500 to $700"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          priceFilter(e, 3);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        min="700"
                        max="900"
                        id="$700 - $900"
                        label="$700 to $900"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Check
                        // type="radio"
                        // name="priceFilter"
                        type="checkbox"
                        onChange={(e) => {
                          priceFilter(e, 4);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        min="900"
                        // max=""
                        id="$900+"
                        label="$900+"
                      />
                    </Form.Group>

                    <Form.Group className="mb-2">
                      <Row>
                        <Col md={1}>
                          <Form.Check
                            type="checkbox"
                            id="custom"
                            onChange={(e) =>
                              handleCustomPrice(e, 5, "checkBox")
                            }
                            checked={checkBoxData}
                          />
                        </Col>
                        {customPrice ? (
                          <>
                            <Col md={5}>
                              <Form.Control placeholder="$" disabled />
                            </Col>
                            <Col md={5}>
                              <Form.Control placeholder="$" disabled />
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col md={5}>
                              <Form.Control
                                id="Min"
                                min="0"
                                type="text"
                                onChange={(e) => handleCustomPrice(e, 5, "Min")}
                                pattern="\d*"
                                title="Only numbers are allowed"
                                value={customMinPrice}
                              />
                            </Col>
                            <Col md={5}>
                              <Form.Control
                                id="Max"
                                min="0"
                                type="text"
                                onChange={(e) => handleCustomPrice(e, 5, "Max")}
                                pattern="\d*"
                                title="Only numbers are allowed"
                                value={customMaxPrice}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Form.Group>
                  </>
                  {FilterList?.pricee?.length > 5 ? (
                    <Button className="bg-none border-0 p-0 text-dark">
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>{" "}
                      Show More
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            {/* End Showing Filter */}
          </Col>
          <Col lg={9}>
            <div className="d-sm-flex justify-content-between text-center">
              <div>
                <Breadcrumb className="mt-3 mt-sm-0">
                  {/* <Breadcrumb.Item
                    className="fs-5"
                    onClick={(e) => navigate(`/`)}
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="fs-5">
                    {category ? category[0]?.category?.name : null}
                  </Breadcrumb.Item> */}
                </Breadcrumb>
              </div>
              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    className="rounded-0 bg-white border text-dark px-5 py-2 fw-bold mb-3 mb-sm-0"
                    id="dropdown-basic"
                  >
                    Sort By: {sorting}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="rounded-0 w-100 mt-2"
                    onClick={(e) => handleSort(e)}
                  >
                    <Dropdown.Item value="Featured">Best Deals</Dropdown.Item>
                    <Dropdown.Item value="Low">Lowest Price</Dropdown.Item>
                    <Dropdown.Item value="High">Highest Price</Dropdown.Item>
                    <Dropdown.Item value="Popular">Most Popular</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            {/* Start Clear All Filter */}
            <div className="text-center text-sm-start d-flex flex-wrap gap-2">
              <Button
                className="rounded-0 bg-white border text-dark px-5 py-2 fw-bold"
                onClick={() => clearAllFilters()}
              >
                Clear all filters
              </Button>

              {prams.name ? (
                <span
                  className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                    !showMore.showLess ? "custom-childspan" : ""
                  }`}
                >
                  {prams?.name}
                  <Button
                    className="bg-none border-0 py-0 text-dark"
                    onClick={handleSearchFilter}
                  >
                    X
                  </Button>
                </span>
              ) : null}

              {conditionKey?.map((val, i) => {
                let key = condition1[i];
                let data = conditionKey.filter((item) => item[key] == true);
                return (
                  <>
                    {data.length != 0 ? (
                      <span
                        key={i}
                        className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                          !showMore.showLess ? "custom-childspan" : ""
                        }`}
                      >
                        {condition1[i]}{" "}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() =>
                            conditionKeyFilterClose(i, condition1[i])
                          }
                        >
                          X
                        </Button>
                      </span>
                    ) : null}
                  </>
                );
              })}
              {chipsetKey?.map((val, i) => {
                let key = chipset[i];
                let data = chipsetKey.filter((item) => item[key] == true);
                return (
                  <>
                    {data.length != 0 ? (
                      <>
                        <span
                          key={i}
                          className={`bg-custom-light border px-3 py-2 text-center  fw-500 fs-6  ${
                            !showMore.showLess ? "custom-childspan" : ""
                          }`}
                        >
                          {chipset[i]}
                          <Button
                            className="bg-none border-0 py-0 text-dark"
                            onClick={() => ChipsetFilterClose(i, chipset[i])}
                          >
                            X
                          </Button>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {brandKey?.map((val, i) => {
                let key = brand[i];
                let data = brandKey.filter((item) => item[key] == true);
                return (
                  <>
                    {data.length != 0 ? (
                      <span
                        key={i}
                        className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                          !showMore.showLess ? "custom-childspan" : ""
                        }`}
                      >
                        {brand[i]}{" "}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() => brandtFilterClose(i, brand[i])}
                        >
                          X
                        </Button>
                      </span>
                    ) : null}
                  </>
                );
              })}
              {seriesKey?.map((val, i) => {
                let key = series[i];
                let data = seriesKey.filter((item) => item[key] == true);
                return (
                  <>
                    {data.length != 0 ? (
                      <span
                        key={i}
                        className={`bg-custom-light border px-3 py-2 text-center  fw-500 fs-6  ${
                          !showMore.showLess ? "custom-childspan" : ""
                        }`}
                      >
                        {series[i]}{" "}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() => seriesFilterCsole(i, series[i])}
                        >
                          X
                        </Button>
                      </span>
                    ) : null}
                  </>
                );
              })}
              {modalKey?.map((val, i) => {
                let key = model[i];
                let data = modalKey.filter((item) => item[key] == true);
                return (
                  <>
                    {data.length != 0 ? (
                      <span
                        key={i}
                        className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                          !showMore.showLess ? "custom-childspan" : ""
                        }`}
                      >
                        {model[i]}{" "}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() => modalFilterCsole(i, model[i])}
                        >
                          X
                        </Button>
                      </span>
                    ) : null}
                  </>
                );
              })}
              {Price?.map((val, i) => {
                let key = Price[i].min;
                return (
                  <>
                    {val ? (
                      <span
                        key={i}
                        className={`bg-custom-light border px-3 py-2 text-center  fw-500 fs-6 ${
                          !showMore.showLess ? "custom-childspan" : ""
                        }`}
                      >
                        {Price[i]?.min && Price[i]?.max
                          ? `$${Price[i]?.min} - $${Price[i]?.max}`
                          : `$${Price[i]?.min}+`}
                        <Button
                          className="bg-none border-0 py-0 text-dark"
                          onClick={() => priceFilterClose(i, val)}
                        >
                          X
                        </Button>
                      </span>
                    ) : null}
                  </>
                );
              })}

              {showMore.showMore && (
                <button
                  className="btn btn-link"
                  onClick={() =>
                    setShowMore((prev) => ({
                      ...prev,
                      showMore: false,
                      showLess: true,
                    }))
                  }
                >
                  Show More
                </button>
              )}
              {showMore.showLess && (
                <button
                  className="btn btn-link"
                  onClick={() =>
                    setShowMore((prev) => ({
                      ...prev,
                      showLess: false,
                      showMore: true,
                    }))
                  }
                >
                  Show Less
                </button>
              )}
            </div>
            {/* end Clear All Filter */}
            {/* Start for Showing the Product */}

            {showConditionLoader ||
            showChipsetLoader ||
            showBrandLoader ||
            showSeriesLoader ||
            showModalLoader ||
            showPriceLoader ||
            // count == 0
            sortingLoader ||
            mainloader == true ? (
              <div className="loader-icon" style={{ marginBlock: "80px" }}>
                <i
                  className="fa-solid fa-spinner fa-spin-pulse"
                  style={{ marginBottom: "100%" }}
                ></i>
              </div>
            ) : (
              <div>
                <div className="product-divide mt-3 mb-5">
             
                  {currentItems?.length > 0 ? (
                    currentItems.map((val, i) => (
                      <div
                        key={i}
                        className="categeory_page product_homepage_card inner-pro-cont border px-3 pt-3 pb-0 position-relative"
                      >
                        <div
                          //  onClick={() => navigate(`/product/${val._id}`)}
                          onClick={() =>
                            window.open(`/product/${val._id}`, "_blank")
                          }
                        >
                          {val.chipset == "Nvidia" ? (
                            <p className="up-text position-absolute fw-bold">
                              {val?.chipset}
                            </p>
                          ) : val.chipset == "AMD" ? (
                            <p className="chipset-text position-absolute fw-bold">
                              {val?.chipset}
                            </p>
                          ) : val.chipset == "AYU" ? (
                            <p className="up-down-text position-absolute fw-bold">
                              {val?.chipset}
                            </p>
                          ) : (
                            <p className="chipset-text position-absolute fw-bold">
                              {val?.chipset}
                            </p>
                          )}
                          <div className="text-center image_parent">
                            <LazyLoadImage
                               src={`${val?.image[0]}`}
                              // src={
                              //   val?.image[0].includes("http")
                              //     ? val?.image[0]
                              //     : `${imageUrl}${val?.image[0]}`
                              // }
                              effect="blur"
                            />{" "}
                          </div>
                        </div>
                        <div className="combine_details">
                          <div
                          //  style={{height:"94px"}}
                          >
                            <p className="d-flex align-items-center justify-content-between w-full mb-0">
                              {val?.brand}
                            </p>
                            {/* <div className="w-25 text-end"></div> */}

                            <div>
                              <p className="mb-0">{val?.series}</p>
                              <p
                                className="mb-0 browsemodel"
                                title={val?.model}
                              >
                                {/* {val?.model} */}
                                {val?.model && val.model.length > 15
                                  ? `${val.model.substring(0, 17)}...`
                                  : val.model}
                              </p>

                              <div className="bl-text">
                                <p className="mb-0">
                                  Condition:{" "}
                                  {val?.type == 1
                                    ? "New-Retail"
                                    : val?.type == 2
                                    ? " New"
                                    : "Used"}
                                </p>
                                <p className="mb-0">{val.condition}</p>
                              </div>
                            </div>
                          </div>

                          <div>
                            <p>
                              {/* {val?.feature ?  <>  Bestdeal Score: {val?.feature} </>:""} */}
                              {val?.feature ? (
                                <>
                                  Bestdeal Score:
                                  {parseFloat(val?.feature).toFixed(2)}{" "}
                                </>
                              ) : (
                                ""
                              )}

                              {/* Bestdeal Score: {val?.feature} */}
                            </p>
                            {/* <p>{val?._id}</p> */}
                            <h2 className="text-center mt-3 mb-2">
                              {/* {"$" + (val?.type == 1 ? val?.new_retail_website_price : (val?.lowest_ask > 0 ? val?.lowest_ask : "Place bid/ask"))  } */}
                              {val?.type === 1 ? (
                                <div className="pricevalue">
                                  {" "}
                                  ${val?.new_retail_website_price}
                                </div>
                              ) : val?.lowest_ask > 0 ? (
                                <div className="pricevalue">
                                  ${val?.lowest_ask}
                                </div>
                              ) : (
                                <>
                                  <button
                                    className="askbidbtn border rounded"
                                    onClick={() =>
                                      navigate(`/bidask/${val?._id}`)
                                    }
                                  >
                                    Place bid/ask
                                    <span
                                      className="question_mark ms-1"
                                      onMouseEnter={() =>
                                        handleMouseEnter(val?._id)
                                      }
                                      onMouseLeave={handleMouseLeave}
                                      // onClick={() => handleClick(val._id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="app-best-deal"
                                        width="16"
                                        height="16"
                                        fillRule="currentColor"
                                        className="bi bi-question-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                                      </svg>
                                      {tooltipVisible.popUp &&
                                        tooltipVisible.id === val._id && (
                                          <div className="custom-tooltip-new">
                                            <p className="tool-tip-content">
                                              Let the market know at what price
                                              you are committed to make a deal.
                                              <br />
                                              <br />A Bid is the price at which
                                              you are willing to buy an item.
                                              <br />
                                              <br />
                                              An Ask is the price you are
                                              willing to sell an item.
                                              <br />
                                              <br /> Your Bid/Ask will stay
                                              active until someone accept ,
                                              expires or you cancel it.
                                              <br />
                                              <br />
                                              If Someone accept your Bid/Ask
                                              there is no backing out! <br />
                                              <br />
                                              For more information please see
                                              our{" "}
                                              <a
                                                href="/BuyingandSellingGuide"
                                                className="text-primary text-decoration-underline"
                                              >
                                                Buying and Selling Guide
                                              </a>
                                            </p>
                                          </div>
                                        )}
                                    </span>
                                  </button>
                                </>
                              )}
                            </h2>
                          </div>
                          <div className="compar_mar text-end">
                            <label className="form-check-label">Compare</label>
                            <input
                              type="checkbox"
                              checked={val.isCompare}
                              className="form-check-input ms-2"
                              onChange={(e) => {
                                handleCompare(e.target.checked, val, i);
                              }}
                            />
                          </div>
                        </div>
                        <Heart
                          className="hear-icon"
                          isActive={val?.isfav}
                          onClick={() =>
                            handleFevButton(!val.isfav, val._id, i)
                          }
                          animationScale={1.25}
                          style={{ width: "25px" }}
                        />
                      </div>
                    ))
                  ) : (
                    <h4
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        alignItems: "center",
                      }}
                    >
                      No Product Found
                    </h4>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end"></div>
              </div>
            )}
            {/* End Showing the Proudct */}
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-end">
          <Pagination
            className="order_pagination"
            count={totalpages}
            color="primary"
            hidePrevButton
            hideNextButton
            page={page}
            onChange={handlePageClick}
          />
        </div>
        {/* Start Login Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop={true}
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body className="scrollable-modal-body">
            <Login modal={() => handleClose()} />
          </Modal.Body>
        </Modal>
        {/* end Login Modal */}
      </Container>
      {/* } */}

      {/* Open Compare Modal  */}
      <Modal
        show={modalShow}
        className="compare-modal custom-model"
        aria-labelledby="contained-modal-title-vcenter"
        data-backdrop="false"
        centered
      >
        <div className="d-flex flex-coloumn">
          <Modal.Body className="scrollable-modal-body">
            <div className="model-custom-inner">
              {Compare.map((Compare, i) => {
                return (
                  <div
                    className="inner-pro-cont border p-3 position-relative"
                    id="modal-inner-cont"
                    key={i}
                  >
                    <button
                      className="close-box"
                      onClick={() => handleCloseButton(Compare._id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </button>
                    <div className="d-flex gap-2">
                      <div>
                        {Compare?.chipset == "Nvidia" ? (
                          <p className="up-text position-absolute fw-bold toptext">
                            {Compare?.chipset}
                          </p>
                        ) : Compare?.chipset == "AMD" ? (
                          <p className="chipset-text position-absolute fw-bold toptext">
                            {Compare?.chipset}
                          </p>
                        ) : Compare?.chipset == "AYU" ? (
                          <p className="up-down-text position-absolute fw-bold toptext">
                            {Compare?.chipset}
                          </p>
                        ) : (
                          <p className="chipset-text position-absolute fw-bold toptext">
                            {Compare?.chipset}
                          </p>
                        )}
                        <LazyLoadImage
                          // src={`${imageUrl}${Compare?.image[0]}`}
                          src={
                            Compare?.image[0]?.includes("http")
                              ? Compare?.image[0]
                              : `${imageUrl}${Compare?.image[0]}`
                          }
                          effect="blur"
                        />
                      </div>
                      <div className="custom-model-body text-start">
                        <p className=" w-full mb-0">
                          {Compare?.brand}
                          <div className="w-25 text-end"></div>
                        </p>
                        <p className="mb-0">{Compare?.model}</p>
                        <p className="mb-0">{Compare?.category?.name}</p>
                        <div className="bl-text">
                          <p className="mb-0">
                            Condition:
                            {Compare?.type == 1
                              ? "New-Retail"
                              : Compare?.type == 2
                              ? "New"
                              : "Used"}
                          </p>
                          <p className="mb-0">{Compare?.condition} </p>
                        </div>
                        <h2 className=" fw-bold">
                          {Compare?.type == 1
                            ? "$" + Compare?.new_retail_website_price
                            : Compare?.type == 2
                            ? Compare?.new_second_hand_house_ask
                              ? "$" + Compare?.new_second_hand_house_ask
                              : ""
                            : Compare?.used_house_ask
                            ? "$" + Compare?.used_house_ask
                            : ""}
                        </h2>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center mt-3">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#b8b8b8"
                className="bi bi-dash-circle position-absolute end-0 top-0 me-5 mt-3"
                viewBox="0 0 16 16"
                onClick={() => setModalShow(false)}
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </div>

            <Button
              varient="primary"
              className="px-4"
              onClick={() => navigate("/compareBuySell")}
            >
              Compare
            </Button>
            <Button
              className="px-4 bg-transparent text-blue border-0"
              onClick={() => {
                HandleCompareClear();
              }}
            >
              Clear All
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      {/* End Compare Modal */}

      {/* Start Compare Button */}
      {Compare[0]?._id && (
        <div className="main_compare_box compare_product_menu">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="compare_product_button">
                  <Button
                    varient="primary"
                    className="px-4"
                    onClick={() => setModalShow(true)}
                  >
                    Compare
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {/* end Compare Button */}

      <Footer />
    </div>
  );
}
