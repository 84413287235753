import React, { useState, useRef, useEffect } from "react";
import ReactImageMagnify from 'react-image-magnify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Productslider(props) {
 

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
 
  
  // useEffect(() => {
  //   if (nav2) {
  //     nav2.current.slickGoTo(0);  
  //     nav1.current.slickGoTo(0); 
  //   }
  // }, [props.activeIndex]); 

  return (
    <>
      <div className="slider-container">
      {props?.Data?.length <=1 ?
      props?.Data?.map((item, index) => (
        <div key={index}>
          <ReactImageMagnify
            {...{
              smallImage: {
                isFluidWidth: true,
                src: item,
                width: 600,
                height: 600,
              },
              largeImage: {
                src: item,
                width: 1200,
                height: 1200
              },
              enlargedImagePosition: 'over',
              enlargedImageContainerDimensions: { width: '100%', height: '100%' }
            }}
          />
        </div>
      )) :
        <Slider asNavFor={nav2} arrows= {false} ref={slider => (sliderRef1 = slider)}>
          {props?.Data?.map((item, index) => (
            <div key={index}>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    isFluidWidth: true,
                    src: item,
                    width: 600,
                    height: 600,
                  },
                  largeImage: {
                    src: item,
                    width: 1200,
                    height: 1200
                  },
                  enlargedImagePosition: 'over',
                  enlargedImageContainerDimensions: { width: '100%', height: '100%' }
                }}
              />
            </div>
          ))}
        </Slider>
}


      </div>
         
       {props.Data.length <5 ?  
      
         <div className="smallersize">
 
       {props?.Data[0] && (
          <div>
            <img
              style={{ width: '100%' }}
              src={props?.Data[0]}
              alt={`Thumbnail 1`}
              onClick={() => handleImageChange(0)}
            />
          </div>
        )}
       </div>  
                  : 
     <Slider
        asNavFor={nav1}
        ref={slider => (sliderRef2 = slider)}
        slidesToShow={5}
        swipeToSlide={true}
        focusOnSelect={true}
        centerMode={true}
      >
        {props?.Data?.map((item, index) => (
          <div key={index}>
            <img style={{width:'100%'}} src={item} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </Slider> 
       } 
    
    </>
  );
}
