// // import { create } from "apisauce";
// // const BaseApi = create({
// //   baseURL: "http://gpuwiz.com:3005/",
// //   // baseURL: "https://ed00-122-176-57-93.ngrok-free.app/",
// //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
// // });
// // export { BaseApi };

// import { create } from "apisauce";

// const getUpdatedToken = () => {
//   return localStorage.getItem("token");
// };

// const createBaseApi = () => {
//   const BaseApi = create({
//     baseURL: "http://localhost:3005"
//     //baseURL: "https://api.gpuwiz.com/"
//     // timeout: 5000
//     // baseURL: "http://gpuwiz.com:3005/",
//     // baseURL: "https://ed00-122-176-57-93.ngrok-free.app/",
//   });

//   // Set the Authorization header dynamically before making each request
//   BaseApi.addRequestTransform(request => {
//     const token = getUpdatedToken();
//     if (token) {
//       request.headers["Authorization"] = `Bearer ${token}`;
//     }
//   });

//   return BaseApi;
// };

// const BaseApi = createBaseApi();
// export { BaseApi };


import { create } from "apisauce";

// Function to create the API instance
const createBaseApi = () => {
  const BaseApi = create({
    //baseURL: "http://localhost:3006",
    baseURL: "https://api001.gpuwiz.com/",
    //baseURL: "https://api.gpuwiz.com/", // Uncomment if needed
   
    // timeout: 5000, // Optional timeout, set as needed
    withCredentials: true, 
  });
   BaseApi.addResponseTransform(response => {
    if (response.status === 401) {

      alert("Your session has expired. Please log in again.");
      localStorage.removeItem('tokenuser');
      localStorage.removeItem('loginTime');
      window.location.href = '/';
    }
  });

  return BaseApi;
};

const BaseApi = createBaseApi();

export { BaseApi };


 
