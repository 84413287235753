import { BaseApi } from "./BaseApi";

let token = localStorage.getItem("token");

const checkJwt = () =>{
  BaseApi.get("/user/checkjwt" , {},
  )}

//const checkJwt = (id) => BaseApi.get("/user/checkjwt", {});


// Function for user login
const UserLogin = (email, password) =>
  BaseApi.post("user/login", {
    email: email,
    password: password,
  });

const VerifyQr = (token, secret, userId) =>
  BaseApi.post("user/verify/qr", {
    token,
    secret,
    userId,
  });
  const verifyotp = (token, secret, userId) =>
    BaseApi.post("user/verifyotp", {
      token,
      secret,
      userId,
    });
    const verifyloginotp = (userId,token) =>
      BaseApi.post("user/verifylogin", {
        userId,
        token,
    
      });
 
  
const enable2FA = (userId) =>
  BaseApi.post("user/enable2FA", {userId});

const disable2FA = (userId,otp) =>
  BaseApi.post("user/disable2FA", {userId,otp});


// Function for user registration
const Register = (firstName, lastName, email, password, subscribe) =>
  BaseApi.post(
    "user/register",
    {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      subscribe,
    },
    {}
  );

// Function to get all products
const GetAllProduct = (email, date) => BaseApi.get("product/get/all", {});
const Alllatestproducts = () => BaseApi.get("product/all/products", {});

// Function to get all products based on user id and pagination
const GetAllProductUserid = (id, page) =>
  BaseApi.get(
    "product/get/all?id=" + id,
    {
      page: page,
    },
    {}
  );

// Function to get a list of all customers
const GetAllCustomerList = () => BaseApi.get("user/list", {});

// Function to get all products based on user id and pagination with fixed per page limit
const GetAllProductpaginationUserid = (id, val) =>
  BaseApi.get(
    "product/get/all?id=" + id + "&perPage=10&currentPage=" + val,
    {}
  );

// Function to get all product categories
const GetAllProductcategory = (email, date) => BaseApi.get("category/list", {});

// Function to get a single category based on its ID
// const GetSinglecategory = (id) => BaseApi.get("product/category/" + id, {});
// Assuming you are using axios
const GetSinglecategory = (id, page) => {
  return BaseApi.get(`product/category/${id}?page=${page+1}`, {});
}
 


// Function to get filtered product data
const GetFilterData = () => BaseApi.get("product/filterallKey", {});

// Function to get a single category based on its ID and user ID
const GetSinglecategory1 = (id, userid) =>
  BaseApi.get("product/category/" + id + "?id=" + userid, {});

// Function to search for products using smart search and pagination
const GetAllProductSerch = (data, page) =>
  BaseApi.post(
    "product/smartsearch",
    {
      searchString: data,
      page: page,
      filters: {
        condition: [],
        category: [],
        brand: [],
        series: [],
        model: [],
      },
    },
    {}
  );

// Function to add inventory data for a product
const GetAllProductinventory = (id, data, productId, Unit, Total, QTY) =>
  BaseApi.post(
    "inventory/add",
    {
      admin: id,
      productId: productId,
      ordernumber: data.Ordernumber,
      orderNotes: data.OrderNotes,
      supplier: data.Supplier,
      qty: QTY,
      total: Total,
      unit_cost: Unit,
    },
    {}
  );

// Function to get filtered product data based on sorting
const getFilteredData = (sort) =>
  BaseApi.post(
    "product/sort",
    {
      sort: sort,
    },
    {}
  );

// Function to get products based on various filters including search, sorting, and conditions
const GetAllcategorySerch = (
  search,
  sorting,
  condition,
  chipset,
  brand,
  series,
  model,
  price,
  customFilterPrice,
  isChecked,
  page
) =>
  BaseApi.post(
    `product/filter?page=${page}`,
    {
      //  {
        type: condition,
        chipset: chipset,
        brand: brand,
        series: series,
        model: model,
        price: price,
        sortby: sorting,
        searchString: search,
        custom: customFilterPrice,
        check: isChecked
      // },
    },
    {}
  );

  // const Getallproducts = (
  //     page
  // ) =>
  //   BaseApi.post(
  //     "product/filter",
  //     {
  //          page: page,
  //     },
  //     {}
  //   );
    const Getallproducts = (page) => {
      return BaseApi.post(`product/filter?page=${page}`, {});
    }
     

// Function to get products based on filters like condition, chipset, brand, series, model
const GetAllProductFilter = (condition, chipset, brand, series, model) =>
  BaseApi.post(
    "product/filter",
    {
      filters: {
        type: condition,
        chipset: chipset,
        brand: brand,
        series: series,
        model: model,
      },
    },
    {}
  );

// Function to get related products based on a specific brand
const GetAllRelatedProduct = (brand) =>
  BaseApi.post(
    "product/filter",
    {
      searchString: "",
      filters: {
        brand: brand,
      },
    },
    {}
  );

// Function to get details of a single product based on its ID
const GetSingleProductData = (id,userId) => BaseApi.get("product/get/" + id+ "?id=" + userId, {});

// Function to get authentication status of an order by its ID
const getAuthenticateStatus = (id) =>
  BaseApi.get("order/getAuthStatusByOrderId?id=" + id, {});

// Function to get details of a single product based on its ID and user ID
const HandleGetSingleUserProduct = (id, userId) =>
  BaseApi.get("product/get/" + id + "?id=" + userId, {});

// Function to add a product to user's favorites
const AddtoFevreat = (prodid, id) =>
  BaseApi.post(
    "user/addtofav/" + id,
    {
      productId: prodid,
    },
    {}
  );

// Function to remove a product from user's favorites
const RemovetoFevreat = (prodid, id) =>
  BaseApi.post(
    "user/removefromfav/" + id,
    {
      productId: prodid,
    },
    {}
  );

// Function to mark an order authentication as failed
const FailAuthentication = (reason, comment, waiveFee, status, orderId) =>
  BaseApi.post(
    "order/authShippment",
    {
      reason,
      comment,
      waiveFee,
      status,
      orderId,
    },
    {}
  );

// Function to mark an order authentication as passed
const PassAuthentication = (
  reason,
  comment,
  waiveFee,
  status,
  passStatus,
  sellerStatus,
  orderId
) =>
  BaseApi.post(
    "order/authShippment",
    {
      reason,
      comment,
      waiveFee,
      status,
      deliveryStatus: passStatus,
      sellerStatus,
      orderId,
    },
    {}
  );

// Function to get user's favorite list
const favouriteList = (id) => BaseApi.get("user/fav/list/" + id, {});

// Function to get shipping data for a specific order
const getShippingData = (id) =>
  BaseApi.get("order/getOrderDetailByOrderId/" + id, {});

// Function to get data of a single user based on their ID
// const getSingleUserData = (id) => BaseApi.get("user/single/" + id, {});
// const getSingleUserData = (id) => BaseApi.get(`user/single/${id}`, { withCredentials: true });
const getSingleUserData = (id) => BaseApi.get(`user/single/${id}`, {});



// Function to create a new product
// const Createproduct = (form) => {BaseApi.post("product/add", form, {});}
const Createproduct = (form) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return BaseApi.post("product/add", form, { headers });
};
 
const getAdminSeries = (id) => BaseApi.get(`product/adminlist/${id}`);


// Function to update shipping label data
// ,buyerStatus,sellerStatus
const updateShippingLabelData = (
  userId,
  id,
  adminStatus,
  buyerStatus,
  sellerStatus
) =>
  BaseApi.post(
    "order/createlabelByUser",
    { userId, orderId: id, adminStatus, buyerStatus, sellerStatus },
    {}
  );

// Function to update shipping status of an order
const updateShippingStatus = (id, shipmentId, rateId, status) =>
  BaseApi.post(
    "order/buyShipment",
    {
      orderId: id,
      deliveryStatus: status,
      shipmentId: shipmentId,
      rateId: rateId,
    },
    {}
  );

// Function to get shipping status data
const getShipStatusData = (status) =>
  BaseApi.post("order/getShippedList", { deliveryStatus: status }, {});

// Function to update product details
// const Updateproduct = (id, form) =>
//   BaseApi.patch("product/update/" + id, form, {});

  const Updateproduct = (id, form) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return BaseApi.patch("product/update/" + id, form, { headers });
  };

  const Updateproductdata = (id, form) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return BaseApi.patch("product/updatedata/" + id, form, { headers });
  };


// Function to delete a product
const Deleteproduct = (id, Ask, Bid) =>
  BaseApi.patch(
    "product/update/" + id,
    { id: id, new_second_hand_house_ask: Ask, new_second_hand_house_bid: Bid },
    {}
  );

// Function to update user's first name and last name
const updateSingleUserName = (id, firstname, lastName) =>
  BaseApi.patch(
    "user/update/" + id,
    {
      firstname,
      lastname: lastName,
    },
    {}
  );

// Function to update user's phone number
const updateSingleUserPhone = (id, phone) =>
  BaseApi.patch(
    "user/update/" + id,
    {
      phone,
    },
    {}
  );

// Function to update user's shipping address
const updateSingleUserData = (id, street, city, state, country, postal_code) =>
  BaseApi.patch(
    "user/update/" + id,
    {
      shipping_address: {
        street,
        city,
        state,
        country,
        postal_code,
      },
    },
    {}
  );

// Function to update user's password
const updatePassword = (id, password, oldPassword) =>
  BaseApi.patch(
    "user/update/" + id,
    {
      password,
      oldPassword,
    },
    {}
  );

// Function to update user's email
const updateEmail = (id, email) =>
  BaseApi.patch(
    "user/update/" + id,
    {
      email,
    },
    {}
  );

// Function to create a bid
const Bid = (
  productId,
  userId,
  bidAmount,
  total,
  discount,
  processingFee,
  salesTax,
  expiration,
  shipping,
  BidExp
) =>
  BaseApi.post(
    "bid/create",
    {
      productId: productId,
      userId: userId,
      bidAmount: bidAmount,
      salesTax,
      processingFee,
      shippingFee: shipping,
      subTotal: total,
      bidStatus: true,
      testingFee: "25.00",
      expirationTime: BidExp
    },
    {}
  );

// Function to get bid details for a specific product
const getBid = (productId) => BaseApi.get("bid/show/" + productId, {});

// Function to get a list of bids by a user
const getBidList = (userId) => BaseApi.get("bid/list/" + userId, {});

// Function to create an ask
const ask = (
  productId,
  userId,
  transactionFee,
  processingFee,
  askAmount,
  total,
  expiration,
  shipping,
  askExp
) =>
  BaseApi.post(
    "ask/create",
    {
      productId: productId,
      userId: userId,
      askAmount: askAmount,
      transactionFee,
      processingFee,
      shippingFee: shipping,
      subTotal: total,
      askStatus: true,
      expiration,
      expirationTime: askExp
    },
    {}
  );

// Function to get product data grouped by SKU
const updateMultiProductData = (sku) =>
  BaseApi.get("product/get/groupbysku?sku=" + sku, {});

// Function to get the highest bid for a product
const handleHighestBid = (id) => BaseApi.get("bid/highest/" + id, {});

// Function to get the lowest ask for a product
const handleLowestBid = (id) => BaseApi.get("ask/lowest/" + id, {});

// Function to get a list of asks by a user
const askList = (userId) => BaseApi.get("ask/list/" + userId, {});

// Function to update asks status
const updateAskList = (askIds, status) => BaseApi.post("ask/updateAskStatusById/",
  {
    askIds: askIds,
    status: status
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function to update bids status
const updateBidList = (bidIds, status) => BaseApi.post("bid/updateBidStatusById/",
  {
    BidIds: bidIds,
    status: status
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


// Function to duplicate bids/asks
const duplicateBidsAsks = (ids, type) => BaseApi.post("bid/createDuplicate/",
  {
    productList: ids,
    type: type
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function to update the Quantity and Expiration Asks

const updateExpirationAndQuantity = (id, Qty, time) => BaseApi.post("ask/updateAskExpirationById/" + id,
  {
    time: time,
    quantity: Qty
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// Function to update the Expiration Bids

const updateExpiration = (id, time) => BaseApi.post("bid/updateBidExpirationTime/" + id,
  {
    time: time,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


// Function to reset user's password
const resetPassword = (password, token) =>
  BaseApi.post(
    "user/setnewpassword",
    {
      newPassword: password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// Function to get SKU data
const GetSkuData = () => BaseApi.get("product/get/sku-model", {});

// Function to get order tracking details
const getOrderTracking = (id) => BaseApi.get("/order/getOrderById/" + id, {});

// Function to get ask details for a specific product
const getask = (productId) => BaseApi.get("ask/show/" + productId, {});

// Function to initiate the password recovery process
const ForgotPassword = (email) =>
  BaseApi.post("user/forgotpassword", { email: email }, {});

// Function to get a list of all categories
const GetAllcategoryList = (category) =>
  BaseApi.get("product/filterKey/63ff36fb23ad0386e761641f", {});

// Function to get a list of all orders
const getOrder = () => BaseApi.get("order/get/all", {});

// Function to get paginated order data
const getOrderPaginationData = (page) =>
  BaseApi.get("order/get/all?pageNumber=" + page, {});

// Function to get filtered order data
const getOrderFilterData = (data) => BaseApi.get("order/get/all", { data }, {});

// Function to get details of a single order
const getSingleOrderData = (id) =>
  BaseApi.post("order/details", { orderId: id }, {});

// Function to filter orders based on status and page number
const orderFilter = (status, page) =>
  BaseApi.post(
    "order/getFilterList",
    { deliveryStatus: status, pageNumber: page },
    {}
  );

// Function to get a list of lowest asks by a user for a specific product
const GetAskBidList = (product, user) =>
  BaseApi.post(
    "ask/get-lowestaskby-userid",
    { productId: product, userId: user },
    {}
  );
const GetallAskandBidList = (ids, user) =>

  BaseApi.post(
    "ask/getdata-lowestaskby",
    { productId: ids, userId: user },
    {}
  );
// Function to create a new order for a product
const createOrder = (productId, userId, total) =>
  BaseApi.post(
    "order/newadd",
    {
      user: userId,
      productId: productId,
      payment_method: "PhonePay",
      country: "India",
      city: "Sonipat",
      pincode: "131001",
      address: "HIG 265 Near IBY Hospital",
      total_price: total,
    },
    {}
  );

// // Function to create a new second-hand order for a product
// const CreateSecondHandOrder = (productId, userId, bidAmount, type) =>
//   BaseApi.post(
//     "order/second-hand-add",
//     {
//       user: userId,
//       productId: productId,
//       bidAmount: bidAmount,
//       userType: type,
//     },
//     {}
//   );

// Function to create a new second-hand order for a product
const CreateSecondHandOrder = (productId, userId, bidAmount, type, bidId) =>
  BaseApi.post(
    "order/buySecondHandOrder",
    {
      userId: userId,
      productId: productId,
      bidAmount: bidAmount,
      userType: type,
      id: bidId
    },
    {}
  );


// Function to create a new second-hand order for a product sell
const CreateSecondHandOrderSell = (productId, userId, bidAmount, type, askId) =>
  BaseApi.post(
    "order/sellSecondHandOrder",
    {
      userId: userId,
      productId: productId,
      bidAmount: bidAmount,
      userType: type,
      id: askId
    },
    {}
  );

// Function to get pending order data for a seller
const getPendingData = (id) =>
  BaseApi.get("order/get/allsellerorder/" + id, {});

// Function to get the total count of orders
const getOrderCount = () => BaseApi.get("order/getOrderCount", {});

// Function to get order data for a specific buyer
const getOrderData = (id) =>
  BaseApi.get("order/getOrderByBuyerId?buyerId=" + id, {});

// Function to get shipping fee data for a user
const getshippingFee = (userid) =>
  BaseApi.get("order/getshippingFeeByuserId/?userId=" + userid, {});

// Function to create a new batch for products
const createBatch = (batch, id, data) =>
  BaseApi.post("batch/createbatch", { batch, userId: id, pageType: data }, {});

// Function to handle user subscription
const handleSubscribe = (id, subscribe) =>
  BaseApi.patch("user/update/" + id, { subscribe }, {});

// Function to handle card creation and association with a user
const handleCard = (id, email,pagename) =>
  BaseApi.patch("/user/createAccount/" + id+"?pagename="+pagename, { email }, {});

// Function to handle card details for a specific order
const handleCardDetails = (id) => BaseApi.post("/order/addCard/" + id, {});

// Function to get card data associated with a user
const getCardData = (id) => BaseApi.get("/order/fetchCardByUserId/" + id, {});

// Function to get package data for a user
const getPackageData = (id) => BaseApi.get("/user/single/" + id, {});

// Function to get Stripe card data for a user
const getStripeCardData = (id) =>
  BaseApi.get("/order/fetchConnectAccountByUserId/" + id, {});

// Function to handle updating user's package dimensions
const handleUpdatePackage = (id, length, breadth, height, weight) =>
  BaseApi.patch("/user/update/" + id, { length, breadth, height, weight });

// Function to handle updating user's shipping address
const handleUpdateAddress = (id, street, city, state, country, zipcode) =>
  BaseApi.patch("/user/update/" + id, {
    shipping_address: { street, city, state, country, postal_code: zipcode },
  });

// Function to get all data for a single customer
const getSingleCustomerAllData = (id) =>
  BaseApi.post("/user/getUserById/" + id, {},);

// Function to get a list of category banners
const getCategoryBannerList = () => BaseApi.get("/admin/getBannerCategory", {});

// Function to get a list of banners for a specific category
const getBannerList = (id) => BaseApi.get("/admin/getBannerById?id=" + id, {});

// Function to create a new banner image
// const createBanner = (id, data) =>
//   BaseApi.post("/admin/addBannerImage/" + id, data);

  const createBanner = (id, data) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return BaseApi.post("/admin/addBannerImage/" + id, data, { headers });
  };
// Function to update banner image data
// const updateBannerData = (bannerId, data) =>
//   BaseApi.post("/admin/updateBannerImageById/" + bannerId, data);

const updateBannerData = (bannerId, data) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return BaseApi.post("/admin/updateBannerImageById/" + bannerId, data, { headers });
};

// Function to delete multiple banners by their IDs
const multideletebanner = (id) =>
  BaseApi.post("/admin/deleteByBannerId", { id: id });

// Function to update banner's active and inactive status
const updateBanner = (id, active, inActive) =>
  BaseApi.post("/admin/updateByBannerId", {
    id: id,
    active: active,
    inActive: inActive,
  });

// Function to search for inactive banners
const searchInactiveBanner = (data) =>
  BaseApi.post("/admin/searchBanner", { search: data });

// Function to create a new filter tab name
const createFilterTabName = (tabnameTaxt, FilterOnCModal, filterTabData, type) =>
  BaseApi.post("/admin/createFilterTabName", {
    tabName: tabnameTaxt,
    filterName: FilterOnCModal,
    filterId: filterTabData,
    filterType: type,
    buyerId: filterTabData
  });

// Function to get a list of all tab names based on filter type
const getAllTabName = (data) =>
  BaseApi.get("/admin/fetchAllFilter?filterType=" + data, {});

// Function to get tag name based on ID
const getTagName = (id) =>
  BaseApi.post("/admin/fetchDataBasedOnFilter", { id });

// Function to get customer's tag names based on their ID
const getCustomerTagName = (id) =>
  BaseApi.get("/admin/filterByUserId?id=" + id, {});

// Function to get date-wise filtered order data
const getdatewiseFilterData = (current, previous) =>
  BaseApi.post("/admin/getAllOrderData", { Date: current, previous });

// Function to update Express Stripe account information
const updateExpressStripeAccount = (data) =>
  BaseApi.post("/user/updateConnectAccount/" + data, {});

// Function to remove a filter by its name and associated tab
const removeFilter = (data, filter) =>
  BaseApi.post("/admin/removeTagByName", { name: data, tab: filter }, {});

// Function to update banner images and their status
const updateBannerImages = (id, data, type) =>
  BaseApi.post("/admin/addBanner", { id, status: data, type }, {});

// Function to get a list of all banners based on type
const getBannerAllList = (data) =>
  BaseApi.get("/admin/fetchBanner?type=" + data, {});

// Function to handle switching seller for an order
const handlewithnewseller = (id) =>
  BaseApi.post("/admin/changeSeller", { orderId: id });

// Function to handle refunding to a buyer based on charge ID
const handlebuyerRefund = (orderidnew, chargeId, amount, status) =>
  BaseApi.post("/admin/refundToBuyer", { orderidnew, chargeId, amount, status });

// Function to get banner name based on its ID
const getBannerNameById = (id) =>
  BaseApi.post("/admin/getCategoryNameById/" + id, {});

// Function to add a banner timer
const addBannerTimer = (time) => BaseApi.post("/admin/addBannerTime", { time });

// Function to update banner's count and rate
const updateBannerCountRate = (id, pageCount, imageCount) =>
  BaseApi.post("admin/updateImageCountById", {
    id: id,
    pageCount: pageCount,
    imageCount: imageCount,
  });

// Function to get the main banner's image count
const getMainBannerImageCount = (id) =>
  BaseApi.get("/admin/fetchImageById?id=" + id, {});

// Function to get data for creating a label
const getCreateLabelData = (id) =>
  BaseApi.post("/order/createPrintLabel", { id });

// Function to delete a product from a selling tab by its ID
const deleteSellingTabProduct = (id) => BaseApi.post("/ask/deleteById", { id });

// Function to delete a product from a buying tab by its ID
const deleteBuyingTabProduct = (id) => BaseApi.post("/bid/bidRemoveByid", { id });

// Function to show the OrderBook by product id 

const showOrderBook = (productId) => BaseApi.post("/bid/getOrderBookByProduct/" + productId)

// Function to update an order's shipping address by its ID
const updateOrderShippingAddress = (id, street, city, state, zipCode,validCountry) =>
  BaseApi.patch("/order/updateOrderById/" + id, {
    city: city,
    state: state,
    street: street,
    postal_code: zipCode,
    country: validCountry,
  });

// Function to update banner sorting order
const bannersorting = (data) =>
  BaseApi.post("/admin/updateBannerSortOrder", { bannerArray: data });

// Function to update batch status
const updateBatch = (data, productId, userId) =>
  BaseApi.put("/batch/updateBatchByUserId/" + userId, {
    status: data,
    productIds: productId,
  });

const getBestDealProducts = () =>
  BaseApi.get("/product/fetchBestDealProduct", {});

const getMostPopularProducts = () =>
  BaseApi.get("/product/getMostViewedProduct", {});

const getRecentlyViewedProducts = (id) =>
  BaseApi.get("/product/getRecenltyViewedProductByUserId?id=" + id, {});

const updateRecentlyViewedProduct = (id, productId) =>
  BaseApi.patch("/product/updateRecenltyViewedProductById/" + id, {
    productId,
  });

const updateMostPopularProduct = (id) => {
  BaseApi.post("/product/updateMostViewedProductById/" + id, {});
};

const updateVisitPageCount = (data) =>
  BaseApi.post("/admin/updatepageCountOnPageLoad", { pageCount: data });

// Function to check the batch Status of a user
const checkBatchStatus = (user_id) =>
  BaseApi.post("/batch/checkBatchStatus", { userId: user_id })

// Function to get the graph of products
const getGraphData = (id) =>
  BaseApi.get("/order/priceHistoryByProductId?id=" + id, {});

// Function to get the Order data
const getOrderDetailsData = (id) =>
  BaseApi.get("/order/getPreviousSellerDetails?id=" + id, {});

// Function to Update Bid
const handleUpdateBid = (id, userId, askAmount, transactionFee, processingFee, subTotal) =>
  BaseApi.put("/bid/updateBidById/" + id, {
    productId: id,
    userId: userId,
    bidAmount: askAmount,
    salesTax: transactionFee,
    processingFee: processingFee,
    subTotal: subTotal,
  });

// Function to Update Ask
const handleUpdateAsk = (id, userId, askAmount, transactionFee, processingFee, subTotal) =>
  BaseApi.put("/ask/updateAskById/" + id, {
    productId: id,
    userId: userId,
    askAmount: askAmount,
    transactionFee: transactionFee,
    processingFee: processingFee,
    subTotal: subTotal,
  });

// Function to Search order
const searchOrder = (id) => BaseApi.get("/order/getOrderByOrderId?id=" + id, {});

// Function to Change the Seller of Order
const changeSeller = (trackingDetails, type, newAskId, newSellerId) => BaseApi.post("/order/changeSellerByOrderId", {
  orderId: trackingDetails,
  type: type,
  sellerId: newSellerId,
  newAskId: newAskId,

})

// {{skewws}}/order/getOrderByOrderId?id=6512c2a909e638a4bd29cff9

// Function to Change the Status of the Products
const makeProductActiveInactive = (productIds) =>
  BaseApi.post("/product/productActiveInactive", {
    productIds: productIds
  })

// Function to get Tax percent of user
const getTaxPercent = (amount, user) =>
  BaseApi.post("/product/calculateTax", {
    amount: amount,
    user: user
  })

// Function to Add Help Management Category
const addHelpCategory = (name, desc) =>
  BaseApi.post("/helptopic/addcattopic", {
    name: name,
    description: desc
  })

// Function to Update Help Management Category
const updateHelpCategory = (name, desc, catId) =>
  BaseApi.post("/helptopic/updatecattopic", {
    name: name,
    description: desc,
    id: catId
  })

// Function to Delelte Help Management Category
const deleteHelpCategory = (id) =>
  BaseApi.delete("/helptopic/deletecattopic/" + id, {})

// Function to Search Help Management Data
const getHelpData = (data) =>
  BaseApi.post("/helptopic/searchdata", {
    searchdata: data
  })

// Function to Add Help Management Topic
const addHelpTopic = (formdata) =>
  BaseApi.post("/helptopic/addtopic", formdata, {})

// Function to Update Help Management Topic
const updateHelpTopic = (formdata) =>
  BaseApi.put("/helptopic/updatetopic", formdata, {})

// Function to get Help Management Topics
const getHelpTopics = (id) => BaseApi.get("/helptopic/gethelpTopics", {});

// Function to Delelte Help Management Category
const deleteHelpTopic = (id) =>
  BaseApi.delete("/helptopic/deletetopic/" + id, {})

// Function to Update Help Management Topic
const addCommentsonOrder = (formdata) =>
  BaseApi.post("/admin/addStatusTimeline", formdata,
    {})

// const getCommentsonOrder = (id) =>{
//   BaseApi.get("/admin/statusTimelineList/"+id , {})
// }

const getCommentsonOrder = (id) => BaseApi.get("admin/statusTimelineList/" + id, {});


// Function to Add Help Management Category
const addThreshold = (margin) =>
  BaseApi.post("/admin/addUpdateThreshold", {
    margin: margin
  })

const getThresholdData = () => BaseApi.get("admin/getthreshold", {});
const addCreateChat = (data) => BaseApi.post("/chat/createchat", data, {})
const getSellerOrderCancelfee = () => BaseApi.get("/admin/getSellerOrderCancelfee", {})
const addSellerOrderCancelfee = (data) => BaseApi.post("/admin/addSellerOrderCancelfee", data, {})
const getchatbyid = (id) => BaseApi.get("chat/getchat/" + id, {});

const addChatbyAdmin = (data) =>
  BaseApi.post("/chat/chatbyadmin", data,
    {})

//  ProductDescriptions

const getProductDescriptions = () => BaseApi.get("product/getallproductdescriptions", {});
const updateGlobaldescription = (data) => BaseApi.patch("product/updateglobaldescription", data, {})


// Admin New Handle

//  const postNews = (data) => BaseApi.post("news/createnews" , data, {})
const postNews = (data) => {
  const headers = {

    'Content-Type': 'multipart/form-data',

  };

  return BaseApi.post("news/createnews", data, { headers });
};

const getnewsbyid = (id) => BaseApi.get("news/singlenews/" + id, {});
const getnewsbyslug = (slug) => BaseApi.get("news/singlenewsbyslug/" + slug, {});
const getAllnews = (page) => BaseApi.get(`news/allnews?page=${page}`);


const getAllnewslist = (page) => BaseApi.get("news/allnewslist",);


const deleteNewsbyid = (id) => BaseApi.delete("news/deletenews/" + id, {});

// const updateNewsbyid = (id, data) => BaseApi.patch("news/updatenews/" + id, data, {});


const updateNewsbyid = (id, data) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return BaseApi.patch("news/updatenews/" +id, data, { headers });
};

const deletepicbyname = (data) => BaseApi.post("/product/deletepicbyname", data, {})
 
// smart filter 
const postfilterdata = (data) => BaseApi.post("/product/filterdata", data, {})

//unsubscribe
const unsubscribedata = (email) => BaseApi.post("/user/unsubscribe", { email }, {})

const totalcount = (id,data) => BaseApi.put("/news/count/" + id,  data , {})

//getallseries
const getallseries = () => BaseApi.get("product/getseries", {});

// const newssearch = ({ keyword, category, fromDate, toDate }) =>
//   BaseApi.post(
//     "news/searchnews",
//     {
//       keyword,
//       category,
//       fromDate,
//       toDate
//     },
//     {}
//   );
 
const newssearch = ({ keyword,limit = 10, page = 1 }) => {
  // Construct query string with parameters
  const queryParams = new URLSearchParams({
    keyword,
    limit,
    page
  }).toString();

  // Make GET request with query parameters
  return BaseApi.get(`news/searchnews?${queryParams}`);
};


  const getallusers = () => BaseApi.get("/user/totalcount", {});
  const getallproducts = () => BaseApi.get("/product/getallproductss", {});
  //const testallproducts = (pageNumber) => BaseApi.get(`/product/getallproductss`, { params: { page: pageNumber } });
  const testallproducts = (pageNumber) => BaseApi.get(`/product/getallproductss?page=${pageNumber}`);
  
  //buysellfilter 
    const buysellfilter = (data) =>
      BaseApi.post(
        "product/buysellfilter",
        {
          type: data.condition,
          chipset: data.chipset,
          brand: data.brand,
          series: data.series,
          model: data.model,
          searchString: data.searchString,  
          currentPage: data.currentPage,
          sortColumn: data.sortColumn,
          sortOrder: data.sortOrder,
        },
        {}
      );

      const productlistfilter = (data) =>
        BaseApi.post(
          "product/productlistfilter",
          {
            type: data.condition,
            chipset: data.chipset,
            brand: data.brand,
            series: data.series,
            model: data.model,
            searchString: data.searchString,  
            currentPage: data.currentPage,
            sortColumn: data.sortColumn,
            sortOrder: data.sortOrder,
            isActive: data.isActive,
          },
          {}
        );

      const getallseriesdata = () => BaseApi.get("/product/getAllSeriesdata", {});
    //new Updatebulkproduct
      const Updatebulkproduct = (editedData) => BaseApi.put("/product/updatebulkdata", editedData, {});
      //const Updatebulkproduct = (data) => BaseApi.put("/product/updatebulkdata", data, {});


      // const Updatebulkproduct = (editedData, userid) => {
      //   // Define your request body
      
      //   const url = `product/updatebulkdata?id=${userid}`;


      
      
      //   // Make the API call
      //   return BaseApi.put(url, editedData, {});
      // };
    //   const Updatebulkproduct = (id, editedData) => {
    //     // Construct the URL with the id as a query parameter
    //     const url = `product/updatebulkdata?id=${id}`;
    
    //     // Make the API call with the constructed URL and the editedData in the request body
    //     return BaseApi.put(url, editedData, {});
    // };

    const getallnegativeseries = (id) => {
      return BaseApi.get(`/order/getnegativerating/${id}`);
    };

  const getTrackingstatus = (data) => BaseApi.post("/admin/gettrackingstatus", data, {});
  const updatetracking = (data) => BaseApi.post("/order/updatetrackstatus", data, {});

  const trackingwebhook = (data) => BaseApi.post("/admin/customWebhook", data, {})

  const getallorders = () =>
    BaseApi.get("order/allordersids", {});

  
  // const getAllNewsByPage = (page) =>
  //   BaseApi.post("news/allnewsbypage", {page});
  const getAllNewsByPage = (page) =>
    BaseApi.get(`news/allnewsbypage?page=${page}`);


  const  saveapikey = (apiKey) =>
    BaseApi.post("user/saveapikey", {apiKey});
  const  getapikey = () =>
    BaseApi.get("user/getapikeys");
  const  updatekey = (apiKey) =>
    BaseApi.put("user/updatekey",{apiKey});

  const  getapikeydecode = () =>
    BaseApi.get("user/getapikeydes?service=Tawk");

  const  gettawk = () =>
    BaseApi.get("chat/tawkapikey");
   

  const orderbookdata = (pageNumber) =>
     BaseApi.get(`/product/orderbookdata?page=${pageNumber}`);
 
   
  const allorderbookdata = () =>
    BaseApi.get(`/product/orderbookdataall`);

  const updateNewsStatus = (id, data) =>
    BaseApi.patch(
        `/news/${id}/publish`, // Use template literal to insert id
        {
            published: data // Send published status directly
        },
        {}
    );

    const bulkdatasaved = (data) =>
      BaseApi.post(
          `/product/bulkdata`, 
          data,
          {}
      );
      const getBulkData = () =>
        BaseApi.get(`/product/getBulkData`);
    


export default {
  gettawk,
  getnewsbyslug,
  Updateproductdata,
  getBulkData,
  bulkdatasaved,
  updateNewsStatus,
  allorderbookdata,
  orderbookdata,
  getapikeydecode,
  updatekey,
  getapikey,
  saveapikey,
  getAllnewslist,
  getAllNewsByPage,
  Alllatestproducts,
  verifyloginotp,
  verifyotp,
  enable2FA,
  disable2FA,
  VerifyQr,
  getallorders,
  updatetracking,
  trackingwebhook,
  getTrackingstatus,
  getallnegativeseries,
  Getallproducts,
  Updatebulkproduct,
  getallseriesdata,
  buysellfilter,
  productlistfilter,
  testallproducts,
  getallproducts,
  getallusers,
  newssearch,
  getallseries,
  totalcount,
  unsubscribedata,
  postfilterdata,
  deletepicbyname,
  postNews,
  getnewsbyid,
  getAllnews,
  deleteNewsbyid,
  updateNewsbyid,
  getProductDescriptions,
  updateGlobaldescription,
  checkJwt,
  UserLogin,
  updateMultiProductData,
  Register,
  getBannerNameById,
  updateMostPopularProduct,
  updateRecentlyViewedProduct,
  getRecentlyViewedProducts,
  handlebuyerRefund,
  handlewithnewseller,
  getMostPopularProducts,
  getBestDealProducts,
  updateBatch,
  updateVisitPageCount,
  updateOrderShippingAddress,
  getCreateLabelData,
  getMainBannerImageCount,
  bannersorting,
  getBannerAllList,
  addBannerTimer,
  deleteSellingTabProduct,
  deleteBuyingTabProduct,
  showOrderBook,
  updateBannerCountRate,
  GetAllProduct,
  updateBannerData,
  getCustomerTagName,
  removeFilter,
  updateBannerImages,
  updateExpressStripeAccount,
  getdatewiseFilterData,
  getTagName,
  GetSinglecategory,
  getAllTabName,
  GetAllProductSerch,
  GetAskBidList,
  GetAllProductcategory,
  GetSingleProductData,
  AddtoFevreat,
  handleUpdateAddress,
  RemovetoFevreat,
  GetAllcategorySerch,
  GetAllProductUserid,
  favouriteList,
  GetAllProductinventory,
  Createproduct,
  Updateproduct,
  GetSinglecategory1,
  Bid,
  getFilteredData,
  ask,
  GetAllCustomerList,
  getBid,
  getask,
  askList,
  updateAskList,
  updateBidList,
  duplicateBidsAsks,
  updateExpirationAndQuantity,
  updateExpiration,
  getSingleUserData,
  getBidList,
  updateSingleUserData,
  GetAllcategoryList,
  HandleGetSingleUserProduct,
  handleHighestBid,
  handleLowestBid,
  GetAllRelatedProduct,
  Deleteproduct,
  GetFilterData,
  GetAllProductFilter,
  GetSkuData,
  ForgotPassword,
  updatePassword,
  resetPassword,
  createOrder,
  CreateSecondHandOrder,
  CreateSecondHandOrderSell,
  getOrder,
  getSingleOrderData,
  getPendingData,
  FailAuthentication,
  PassAuthentication,
  getShippingData,
  updateShippingLabelData,
  getShipStatusData,
  updateShippingStatus,
  getOrderPaginationData,
  getOrderFilterData,
  orderFilter,
  getOrderCount,
  getOrderData,
  getshippingFee,
  getAuthenticateStatus,
  getOrderTracking,
  updateSingleUserName,
  updateSingleUserPhone,
  createBatch,
  handleCard,
  handleSubscribe,
  updateEmail,
  handleCardDetails,
  getCardData,
  getStripeCardData,
  handleUpdatePackage,
  getPackageData,
  getSingleCustomerAllData,
  getCategoryBannerList,
  getBannerList,
  createBanner,
  multideletebanner,
  updateBanner,
  searchInactiveBanner,
  createFilterTabName,
  checkBatchStatus,
  getGraphData,
  getOrderDetailsData,
  handleUpdateBid,
  handleUpdateAsk,
  searchOrder,
  changeSeller,
  makeProductActiveInactive,
  getTaxPercent,
  addHelpCategory,
  updateHelpCategory,
  deleteHelpCategory,
  getHelpData,
  addHelpTopic,
  updateHelpTopic,
  getHelpTopics,
  deleteHelpTopic,
  addCommentsonOrder,
  getCommentsonOrder,
  addThreshold,
  getThresholdData,
  addCreateChat,
  getSellerOrderCancelfee,
  addSellerOrderCancelfee,
  getchatbyid,
  Updatebulkproduct,
  getAdminSeries,
  GetallAskandBidList,
  addChatbyAdmin
};